import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'calsoft-image-lazy-mouse-over',
  templateUrl: './image-lazy-mouse-over.component.html',
  styleUrls: ['./image-lazy-mouse-over.component.scss']
})
export class ImageLazyMouseOverComponent {
  @Input() src: string;

	@Input() fixedPt: string | number;
	@Input() minHeight: string;
	@Input() alt = 'banner';
	@Input() backgroundColor: string;

	@Input() loader: string = 'https://media.giphy.com/media/y1ZBcOGOOtlpC/200.gif';
 
  @Input() height: any;
  @Input() width: number;
  @Input() heightMobile: any;

  @Input() widthMobile: any;

  @Input() image: any;
  @Input() name: any;
  @Input() type: any="'carousel'";

  @Input() imageType: any=1;
  defaultImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

//	defaultImage = 'assets/images/products/noImage/no-image.png';
	isLoading: boolean;

	constructor(private el: ElementRef, private renderer: Renderer2) { }

	ngOnInit(): void {
		
		
		if(this.type==undefined){
			this.type='carousel';
		}
		this.isLoading = false;
	}

	handler(event: any) {
		switch (event.reason) {
			case 'setup':
				let value: string | number = '100%';

				if (this.fixedPt) {
					value = this.fixedPt;
				} else if (this.height && this.width) {
					value = Math.floor((parseFloat(this.height.toString()) / parseFloat(this.width.toString()) * 1000)) / 10 + '%';
				} else {
					value = '100%';
				}

				this.renderer.setStyle(this.el.nativeElement, 'padding-top', value);

				if (this.minHeight) {
					this.renderer.setStyle(this.el.nativeElement, 'min-height', this.minHeight + 'rem');
				}

				if (this.backgroundColor) {
					this.renderer.setStyle(this.el.nativeElement, 'background-color', this.backgroundColor);
				}
				break;
			case 'observer-emit':
				break;
			case 'loading-succeeded':
				if (this.minHeight) {
					this.renderer.setStyle(this.el.nativeElement.querySelector('img'), 'min-height', this.minHeight + 'rem');
				}
				break;
			default:
				break
		}
	}

}
