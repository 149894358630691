import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-slidebanner',
  templateUrl: './slidebanner.component.html',
  styleUrls: ['./slidebanner.component.scss']
})
export class SlidebannerComponent {

  public serverPath = environment.commonImageApi + '/home/';
  
  @Input() item: any;
  customField11:any;
  customField12:any;
  customField13:any;
  customField6:any;
  
  imageUrl="assets/images/1.jpg";
  title1="2018";
  title2="FASHION TRENDS";
  title3="SPECIAL OFFER";

  


}
