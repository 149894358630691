<div class="intro-slider-container">
    <owl-carousel-o carouselClasses="intro-slider owl-dots-inside owl-theme" [options]="LogoSlider">
        <ng-template carouselSlide>
            <div class="intro-slide bg-image intro-1 d-flex align-items-center"
                style="background-image: url(assets/images/slider-4.jpg); background-color: #222;">
                <div class="container">
                    <div class="intro-content position-static p-3 p-lg-0" contentAnim aniName="fadeInUpShorter">
                        <h4
                            class="intro-subtitle font-size-normal letter-spacing-large text-primary text-uppercase font-weight-normal mb-1">
                            <span>Deal Of The Day</span></h4>
                        <h2 class="intro-title text-white font-weight-normal text-uppercase">Our Favorite Things</h2>
                        <h2 class="intro-price text-white mb-2"><i>up to <span class="text-primary">60% off</span></i>
                        </h2>
                        <a routerLink="/shop/sidebar/list"
                            class="btn letter-spacing-large btn-white text-uppercase mb-2 mt-2">Discover Now</a>
                    </div>
                </div>
            </div>

        </ng-template>

    </owl-carousel-o>
</div>

<div class="banner-group ">

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="no-gutters my-md-n5">

        <div fxFlex="70%">
            <!-- Your content for the fourth column goes here -->
        </div>
        <div fxFlex="33.33%" fxFlex.xs="100%" fxLayoutAlign="start start" contentAnim aniName="fadeInLeftShorter">
            <div class="banner1 mb-0">
                <img src="assets/images/banner-1.jpg" alt="Banner" width="390" height="500" style="background-color: #c99563;">
                <div class="banner-content text-center banner-content-center mb-0 my-md-4">
                    <h4 class="banner-subtitle font-size-normal letter-spacing-large text-white text-uppercase font-weight-normal">Clearance</h4>
                    <h3 class="banner-title text-white font-weight-normal text-uppercase my-4 mb-0">Earrings&nbsp;<br>&amp; Rings&nbsp;</h3>
                    <h3 class="banner-price text-white text-uppercase my-4 mt-0">Save 30%</h3>
                    <a routerLink="/shop/sidebar/list" class="btn font-size-normal letter-spacing-large btn-dark text-uppercase mt-0 mt-md-3 font-weight-normal text-uppercase">Shop Clearance</a>
                </div>
            </div>
        </div>
        <div fxFlex="33.33%" fxFlex.xs="100%">
            <div class="banner banner-overlay mb-0 banner-middle bg-image" style="background-image: url(assets/images/banner-2.jpg); background-color: #c08e73;">
            </div>
        </div>
        <div fxFlex="33.33%" fxFlex.xs="100%" contentAnim aniName="fadeInRightShorter">
            <div class="banner1 mb-0" style="background-color: #f9f9f9;">
                <a routerLink="/shop/sidebar/list">
                    <img src="assets/images/banner-3.jpg" alt="Banner" width="390" height="500" fixedPt="49.9rem" >
                </a>
                <div class="banner-content text-center banner-content-center mt-2 mt-md-5">
                    <h3 class="banner-title font-weight-normal text-uppercase mt-1 my-1">Bracelets&nbsp;<br>&amp; Anklets</h3>
                    <a routerLink="/shop/sidebar/list" class="btn font-size-normal letter-spacing-large btn-dark text-uppercase mt-4 font-weight-normal text-uppercase">Shop Now</a>
                </div>
            </div>
        </div>
        <div fxFlex="70%">
            <!-- Your content for the fourth column goes here -->
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
        <div class="my-md-n5" contentAnim aniName="fadeInLeftShorter">
            <div class="image-container">
                <img src="assets/images/banner-4.jpg" alt="Banner" style="width: 100%; height: auto;">
                <!-- <h4 class="banner-subtitle font-size-normal letter-spacing-large text-white text-uppercase mb-2 font-weight-normal">Exclusive</h4>
                <h3 class="banner-title text-white text-uppercase my-4 mb-0">Our New Romantic<br>Collection 2021</h3>
                <p class="banner-txt text-white font-weight-normal my-4 mb-2 mb-md-4 mx-4">Nullam malesuada eratut turpis. Suspendisse urnanibh viverra non, semper suscipit, posuere a pede.</p>
                <a routerLink="/shop/sidebar/list" class="btn font-size-normal letter-spacing-large btn-primary btn-dark text-uppercase my-2 mb-0">Shop the Collection</a> -->
            </div>
        </div>
    </div>
</div>
