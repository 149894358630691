
<div class="table-responsive " >
    <div class="calsoft-list">

     
        <div class="list-header"    [fxHide]="type!=1" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex.lt-md class="list-name">
                <span fxHide fxShow.gt-sm>{{ name }}</span>
                <!-- <b  fxLayout="row" style="font-size: larger;" fxLayoutAlign="start center" fxShow fxHide.gt-sm>{{ name | slice:0:10}}</b> -->
            </div>

            <mat-form-field fxHide fxShow.md fxShow.gt-md fxFlex.lt-sm="50" class="filter-input"
                floatPlaceholder="never" fxFlex>
                <!-- <mat-icon [ngClass]="{'overlayProduct':searchTermMainPresent}" (click)="searchFilterMain()" matSuffix>
                    search</mat-icon> -->
                <button style="font-size: 24px;" [ngClass]="{'overlayProduct':searchTermMainPresent}"
                    (click)="searchFilterMain()" matSuffix mat-icon-button>
                    <mat-icon>search</mat-icon>
                </button>
                <input [autocomplete]="'off'" [disabled]="!searchRequired" matInput #filter
                    placeholder="Search&hellip;">
            </mat-form-field>

            <button *ngIf="!refreshRequired" fxHide fxShow.md fxShow.gt-md matTooltip="Refresh" mat-icon-button
                (click)="resetFilters()">
                <mat-icon>refresh</mat-icon>
            </button>

            <ng-container *ngIf="displayColumnRequired!='false'">

                <ng-container *ngIf="!displayFilerOption">



                    <button *ngIf="columns!=undefined" type="button" mat-icon-button [matMenuTriggerFor]="columnFilter">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                </ng-container>
            </ng-container>
            <ng-content select=".actions"></ng-content>
        </div>

        <!-- <mat-menu #columnFilter="matMenu" yPosition="below" xPosition="before">
            <button class="checkbox-item mat-menu-item" *ngFor="let column of columns;let i=index"
                (click)="toggleColumnVisibility(column, $event,i)" [disabled]="column.disabled">


                <mat-checkbox  [checked]="columns[i].visible" class="checkbox" #checkbox 
                    [disabled]="column.disabled">
                    {{ column.name |uppercase }}
                </mat-checkbox>
            </button>
        </mat-menu> -->
        <mat-menu #columnFilter="matMenu" yPosition="below" xPosition="before">
            <!-- <button class="checkbox-item mat-menu-item" *ngFor="let column of columns"
                (click)="toggleColumnVisibility(column, $event,index)" [disabled]="column.disabled">
                <mat-checkbox [(ngModel)]="column.visible" class="checkbox" #checkbox (click)="$event.stopPropagation()"
                    [disabled]="column.disabled">
                    {{ column.name |uppercase }}
                </mat-checkbox>
            </button> -->
        </mat-menu>

        <mat-progress-bar color="accent" mode="indeterminate" *ngIf="progress"></mat-progress-bar>
        <div *ngIf="searchRequired" fxHide fxShow.lt-md style="background: #ffffff; padding: 0px 15px;"
            fxLayout="column" fxLayoutGap="10px">
            <mat-form-field fxFlex class="filter-input" floatPlaceholder="never" fxFlex>
                <!-- <mat-icon [ngClass]="{'overlayProduct':searchTermMainPresent}" (click)="searchFilterMain()" matSuffix>
                    search</mat-icon> -->
                <!-- <button style="font-size: 24px;"
                    (click)="mobileSearch()" matSuffix mat-icon-button>
                    <mat-icon>search</mat-icon>
                </button> -->
                <input [autocomplete]="'off'" [formControl]="value" matInput #filter placeholder="Search&hellip;">
            </mat-form-field>
            <!-- <button matTooltip="Refresh" mat-icon-button (click)="resetFilters()">
                <mat-icon>refresh</mat-icon>
            </button> -->
            <div fxLayout="row" fxLayoutGap="10px">


                <button (click)="mobileSearch()" fxFlex color="primary" mat-flat-button>
                    Search
                </button>
                <button fxFlex color="accent" mat-flat-button (click)="resetFiltersMobile()">
                    Refresh
                </button>
            </div>
        </div>
        <div fxHide fxShow.md fxShow.gt-md >
            <div  class="list-column-filter" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="5px"
                *ngIf="filterSelectObj.length!=0" fxLayoutAlign.lt-md="center none">
                <!-- <div> -->

                <ng-container *ngFor="let filter of filterSelectObj;let i=index;">
                   

                    <ng-container *ngIf="filterSelectObj[i].type!='disable'">
                   
                    
                    <mat-form-field fxFlex *ngIf="columns[i] &&columns[i].visible&&filter.type!='date'&&filter.type!='disable'" style="margin-left: 5px;">
                        <mat-label>{{filter.name | uppercase }}</mat-label>

                    
                        <mat-select  [(ngModel)]="selectedValues[i]"  (selectionChange)="filterColumnMultiSelect($event,i,filter.type)" 
                        multiple [disableOptionCentering]="true" 
                               *ngIf="filter.type=='multiSelect'">
    
                               <ng-container *ngIf="filter.name =='Status Name'">
                                   <mat-option *ngFor="let opt of filtereddropdownTypeList; let i=index;"
                                       [value]="opt.statusName">
                                       {{opt.statusName}}
                                   </mat-option>
                               </ng-container>
   
                               <ng-container *ngIf="filter.name =='Source Name'">
                                   <mat-option
                                   *ngFor="let opt of filteredLeadSourceTypeList; let i=index;"
                                   [value]="opt.sourceName">
                                   {{opt.sourceName}}
                               </mat-option>
                               </ng-container>
   
                               <ng-container *ngIf="filter.name =='Owner'">
                                   <mat-option *ngFor="let opt of filtereduserTypeList; let i=index;"
                                      [value]="opt.email">
                                      {{opt.email}}
                                   </mat-option>
                               </ng-container>

                               <ng-container *ngIf="filter.name =='Activity Type'">
                                <mat-option *ngFor="let opt of filteredActivityTypeList; let i=index;"
                                   [value]="opt.activityType">
                                   {{opt.activityType}}
                                </mat-option>
                            </ng-container>
   
                               <ng-container *ngIf="filter.name !='Status Name' || filter.name !='Source Name'|| filter.name !='Activity Type' || filter.name !='Owner'">
                               <mat-option [disabled]="item.value=='disabled1'" *ngFor="let item of filter.options"
                                   [value]="item.value">
                                   {{item.viewValue}}
                               </mat-option>
                               </ng-container>
                           </mat-select>

                        <!-- <mat-select [disableOptionCentering]="true" [(value)]="this[filter['position']]   == undefined ? 'ALL'
                     : this[filter['position']]" (selectionChange)="filterColumn($event,i,filter.type)"
                            *ngIf="filter.type=='select'">
                            <mat-option value="ALL">(ALL)</mat-option>
                            <ng-container *ngIf="filter.name =='Status Name'">
                                <mat-option *ngFor="let opt of filtereddropdownTypeList; let i=index;"
                                    [value]="opt.statusName">
                                    {{opt.statusName}}
                                </mat-option>
                            </ng-container>

                            <ng-container *ngIf="filter.name =='Source Name'">
                                <mat-option
                                *ngFor="let opt of filteredLeadSourceTypeList; let i=index;"
                                [value]="opt.sourceName">
                                {{opt.sourceName}}
                            </mat-option>
                            </ng-container>

                            <ng-container *ngIf="filter.name =='Owner'">
                                <mat-option *ngFor="let opt of filtereduserTypeList; let i=index;"
                                   [value]="opt.email">
                                   {{opt.email}}
                                </mat-option>
                            </ng-container>

                            <ng-container *ngIf="filter.name =='Activity Type'">
                                <mat-option *ngFor="let opt of filteredActivityTypeList; let i=index;"
                                   [value]="opt.activityType">
                                   {{opt.activityType}}
                                </mat-option>
                            </ng-container>

                            <ng-container *ngIf="filter.name !='Status Name' || filter.name !='Source Name' || filter.name !='Activity Type' || filter.name !='Owner'">
                            <mat-option [disabled]="item.value=='disabled1'" *ngFor="let item of filter.options"
                                [value]="item.value">
                                {{item.viewValue}}
                            </mat-option>
                            </ng-container>
                        </mat-select> -->
                        <input [autocomplete]="'off'" #myText trim="blur"
                            (keyup)="filterColumnText($event,i,filter.modelValue)" *ngIf="filter.type=='text' " matInput
                            [(ngModel)]="filter.modelValue">

                        <input [autocomplete]="'off'" #myText trim="blur" numbersOnly
                            (keyup)="filterColumnText($event,i,filter.modelValue)" *ngIf="filter.type=='id' " matInput
                            [(ngModel)]="filter.modelValue">

                        <input [autocomplete]="'off'" #myText (keyup)="filterColumnText($event,i,filter.modelValue)"
                            numbersOnly *ngIf="filter.type=='price' " matInput [(ngModel)]="filter.modelValue">
                    </mat-form-field>

                    <div [formGroup]="filtersForm">
                        <mat-form-field fxFlex *ngIf=" columns[i] && columns[i].visible&&filter.type=='date'&&filter.type=='date'&&filter.type!='disable'"
                        style="margin-left: 5px;">
                        <mat-label>{{filter.name | uppercase }}</mat-label>
                        <mat-date-range-input  [formGroup]="filtersForm.get('ranges')['controls'][i]"  [rangePicker]="picker">
                            <input disabled [autocomplete]="'off'" disabled (dateChange)="start($event,'start',i)"
                                matStartDate formControlName="start" placeholder="Start date">
                            <input [autocomplete]="'off'" disabled (dateChange)="end($event,'end',i)" matEndDate
                                formControlName="end" placeholder="End date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker disabled="false" Customer Information #picker></mat-date-range-picker>
                        <!-- Invalid Date Messages -->



                    </mat-form-field>

                        
                    </div>

                
                  

    
                
             
                </ng-container>

                </ng-container>

                &nbsp;

                <button fxHide.lt-md [disabled]="searchTerm==undefined" matTooltip="Reset Filter" mat-icon-button
                    color="warn" (click)="resetFilters()">
                    <mat-icon>autorenew</mat-icon>
                </button>

                <button fxHide.lt-md [disabled]="searchTermPresent" matTooltip="search" mat-icon-button
                    (click)="searchFilter()">
                    <mat-icon>search</mat-icon>
                </button>

            </div>
        </div>

        <!-- <mat-accordion fxHide fxShow.lt-md *ngIf="filterSelectObj.length!=0">
            <mat-expansion-panel style="border-radius: 0px;" class="br0 mat-elevation-z0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Filters
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="list-column-filter" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="5px"
                    *ngIf="filterSelectObj.length!=0" fxLayoutAlign.lt-md="center none">
                

                    <ng-container *ngFor="let filter of filterSelectObj;let i=index;">
                        <ng-container *ngIf="filter.type!='disable'">
                        <mat-form-field fxFlex *ngIf="columns[i] &&columns[i].visible&&filter.type!='date'&&filter.type!='disable'"
                            style="margin-left: 5px;">
                            <mat-label>{{filter.name | uppercase }}</mat-label>
                            
                        <mat-select  [(ngModel)]="selectedValues[i]" multiple [disableOptionCentering]="true"  (selectionChange)="filterColumnMultiSelect($event,i,filter.type)"
                               *ngIf="filter.type=='multiSelect'">
                           
                               <ng-container *ngIf="filter.name =='Status Name'">
                                   <mat-option *ngFor="let opt of filtereddropdownTypeList; let i=index;"
                                       [value]="opt.statusName">
                                       {{opt.statusName}}
                                   </mat-option>
                               </ng-container>
   
                               <ng-container *ngIf="filter.name =='Source Name'">
                                   <mat-option
                                   *ngFor="let opt of filteredLeadSourceTypeList; let i=index;"
                                   [value]="opt.sourceName">
                                   {{opt.sourceName}}
                               </mat-option>
                               </ng-container>
   
                               <ng-container *ngIf="filter.name =='Owner'">
                                   <mat-option *ngFor="let opt of filtereduserTypeList; let i=index;"
                                      [value]="opt.email">
                                      {{opt.email}}
                                   </mat-option>
                               </ng-container>
   
                               <ng-container *ngIf="filter.name !='Status Name' || filter.name !='Source Name' || filter.name !='Owner'">
                               <mat-option [disabled]="item.value=='disabled1'" *ngFor="let item of filter.options"
                                   [value]="item.value">
                                   {{item.viewValue}}
                               </mat-option>
                               </ng-container>
                           </mat-select>
                            <mat-select [disableOptionCentering]="true" [(value)]="this[filter['position']]   == undefined ? 'ALL'
                         : this[filter['position']]" (selectionChange)="filterColumn($event,i,filter.type)"
                                *ngIf="filter.type=='select'">
                                <mat-option value="ALL">(ALL)</mat-option>
                                <mat-option [disabled]="item.value=='disabled1'" *ngFor="let item of filter.options"
                                    [value]="item.value">
                                    {{item.viewValue}}
                                </mat-option>
                            </mat-select>
                            <input [autocomplete]="'off'" #myText trim="blur"
                                (keyup)="filterColumnText($event,i,filter.modelValue)" *ngIf="filter.type=='text' "
                                matInput [(ngModel)]="filter.modelValue">

                            <input [autocomplete]="'off'" #myText trim="blur" numbersOnly
                                (keyup)="filterColumnText($event,i,filter.modelValue)" *ngIf="filter.type=='id' "
                                matInput [(ngModel)]="filter.modelValue">

                            <input [autocomplete]="'off'" #myText (keyup)="filterColumnText($event,i,filter.modelValue)"
                                numbersOnly *ngIf="filter.type=='price' " matInput [(ngModel)]="filter.modelValue">
                        </mat-form-field>
                        <div [formGroup]="filtersForm">
                        <mat-form-field fxFlex *ngIf="columns[i] &&columns[i].visible&&filter.type=='date'&&filter.type!='disable'"
                            style="margin-left: 5px;">
                            <mat-label>{{filter.name | uppercase }}</mat-label>
                            <mat-date-range-input [formGroup]="filtersForm.get('ranges')['controls'][i]" [rangePicker]="picker">
                                <input [autocomplete]="'off'" readOnly (dateChange)="start($event,'start',i)"
                                    matStartDate formControlName="start" placeholder="Start date">
                                <input [autocomplete]="'off'" readOnly (dateChange)="end($event,'end',i)" matEndDate
                                    formControlName="end" placeholder="End date">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                        </div>
                    </ng-container>
                    </ng-container>
                    <button fxFlex [disabled]="searchTerm==undefined" color="warn" (click)="resetFilters()"
                        mat-flat-button>Reset
                        Filter
                    </button>
                    <button fxFlex [disabled]="searchTermPresent" color="warn" (click)="searchFilter()" mat-flat-button>
                        Search Filter
                    </button>
                </div>
            </mat-expansion-panel>
        </mat-accordion> -->


        <ng-content></ng-content>
    </div>
</div>
