import { Component, OnInit } from '@angular/core';
import { AboutService } from 'src/app/services/about.service';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  public PageTitle = Constant.privacy
  public sitename = Constant.siteName
  constructor(
  ) { }

  ngOnInit() {

  }


}
