import { Component } from '@angular/core';

@Component({
  selector: 'calsoft-iconwithtext',
  templateUrl: './iconwithtext.component.html',
  styleUrls: ['./iconwithtext.component.scss']
})
export class IconwithtextComponent {
  images=[
    {
      img:'/assets/images/delivery-truck.png',title:'100% Safe & Secure',content:'We take all safety and hygiene measures to keep our customers safe.'
    },
    {
      img:'/assets/images/delivery-truck.png',title:'Click and Pick',content:'Click to buy a range of products across categories to keep our customers safe.'
    },
    {
      img:'/assets/images/delivery-truck.png',title:'Home Delivery',content:'We offer convenient home delivery of medicines & general goods.'
    },
    {
      img:'/assets/images/delivery-truck.png',title:'Unlimited Health Records',content:'Save all your health records (Medical Records...)'
    }  
  ]
}
