
<div *ngIf="item.customField15=='1'">
    
    <div fxLayout="column" fxLayout.gt-sm="row" [style.background-color]="item.customField17" style="padding: 30px;">
        <div fxFlex="100" fxFlex.gt-sm="50">
            <h2 [style.font-size]="item.customField27" [ngStyle.lt-md]="{ 'font-size': item.customField29 }"><b>{{item.customField16}}</b></h2>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="10">

        </div>

        <div fxFlex="100" fxFlex.gt-sm="40" style="padding-top: 20px;">
            <button *ngIf="item.customField6==0" mat-raised-button color="primary" [style.font-size]="item.customField22" [ngStyle.lt-md]="{ 'font-size': item.customField23 }"
                (click)="callToFunction(0,item.customField11)" [innerHTML]="item.title" class="smcss"></button>
            <button *ngIf="item.customField6==1" mat-raised-button color="primary" [style.font-size]="item.customField22" [ngStyle.lt-md]="{ 'font-size': item.customField23 }"
                (click)="callToFunction(1,item.customField5)" [innerHTML]="item.title" class="smcss"></button>
        </div>
    </div>
</div>
<div *ngIf="item.customField15=='3'">

    <div [style.background-color]="item.customField17" style="padding: 30px;text-align: center;">
        <h6 [style.color]="item.customField20" [style.font-size]="item.customField27" [ngStyle.lt-md]="{ 'font-size': item.customField29 }"><b>{{item.customField16}}</b></h6>
        <h6 [style.color]="item.customField20" [style.font-size]="item.customField30" [ngStyle.lt-md]="{ 'font-size': item.customField31 }">
            <calsoft-editor-text [data]="data" [text]="item.customField19"></calsoft-editor-text> 
        </h6>        
        <button  mat-raised-button class="btncss" [style.font-size]="item.customField22" [ngStyle.lt-md]="{ 'font-size': item.customField23 }" 
            [style.background-color]="item.customField21" [style.color]="item.customField20" style="margin-top: 20px;"
            (click)="(item.customField6 == 0) ? link(item.customField11) 
            : (item.customField6 == 1) ? dynamicFormPopup(item.customField5) 
            : (item.customField6 == 2) ? scrollDown(item.customField35): null">{{item.title}} &#160;
            <mat-icon *ngIf="item.customField18" [style.color]="item.customField20"
                style="font-size: 20px;margin-top: 4px;">{{item.customField18}}</mat-icon>
        </button>

    </div>
</div>

<div *ngIf="item.customField15=='5'">
    
    <div fxLayout="column" fxLayout.gt-sm="row" [style.background-color]="item.customField17" style="padding: 30px;">
        <div fxFlex="100" fxFlex.gt-sm="40">
            <h2 [style.font-size]="item.customField27" [ngStyle.lt-md]="{ 'font-size': item.customField29 }"><b>{{item.customField16}}</b></h2>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="10">

        </div>

        <div fxFlex="100" fxFlex.gt-sm="10" style="padding-top: 20px;">
            <button mat-raised-button [style.background-color]="item.customField24"
                (click)="callToFunction(0,item.customField11)" class="smcss" [style.font-size]="item.customField22" [ngStyle.lt-md]="{ 'font-size': item.customField23 }"
                style="color: white;">{{item.title}}</button>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="10" style="padding-top: 20px;">
            <button mat-raised-button [style.background-color]="item.customField25"
                (click)="callToFunction(0,item.customField26)" class="smcss" [style.font-size]="item.customField22" [ngStyle.lt-md]="{ 'font-size': item.customField23 }"
                style="color: white;">{{item.customField23}}</button>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="20">
        </div>
    </div>
</div>