import { Component, Input } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { SwiperConfigInterface } from 'src/app/theme/swiper/swiper.module';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-category-carousel',
  templateUrl: './category-carousel.component.html',
  styleUrls: ['./category-carousel.component.scss']
})
export class CategoryCarouselComponent {

  public serverPath = environment.commonImageApi + 'category/image/150x150/';
  public config: SwiperConfigInterface = { };
  categoryArray: any=[];
  categoryId7:any=0;
  layoutType:any;
  @Input() item: any;
  customField8: any;


  constructor(private commonService: CommonService,
    private categoryService: CategoryService) {

  
  }
  ngOnInit(): void {

    this.layoutType = this.item.customField20;
    this.getCategories();

    if(this.categoryId7!=undefined){
     this.categoryId7=this.item.categoryId7;
    }

    if(this.customField8!=undefined){
      this.customField8=this.item.customField8;
     }

    

  }
  getCategories() {
    this.categoryService.categoryList().subscribe(
      result => {
        this.categoryArray = result['data'];
      },
      error => {
      }
    )
  }

  ngAfterViewInit(){
    this.config = {
      slidesPerView: 7,
      spaceBetween: 16,         
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,  
      loop: true,
      preloadImages: true,
      lazy: true,     
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: "slide",
      breakpoints: {
        240: {
          slidesPerView: 3
        },
        480: {
          slidesPerView: 3
        },
        600: {
          slidesPerView: 4
        },
        960: {
          slidesPerView: 4
        },
        1280: {
          slidesPerView: 5
        },
        1500: {
          slidesPerView: 6
        }
      }
    }
  }

  goToCategory(menu) {
    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);

  }

  
  imageNavigation(id){


    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

}

