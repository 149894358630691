import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'calsoft-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {


  @Input()poptions : any;

  @Input() Datavalue : any;

  @Output() userChangeEnd: EventEmitter<any> = new EventEmitter();
  
  query = {
    amount: "",
    interest: "",
    tenureYr: "",
    tenureMo: ""
  }

  result = {
    emi: "",
    interest: "",
    total: "",
    loanAmount:""
  }
  yrToggel: boolean;


  update() {

    var loanAmount = Number(this.Datavalue) * 100000;
    var numberOfMonths = (this.yrToggel) ? (Number(this.Datavalue) * 12) : Number(this.Datavalue);
    var rateOfInterest = Number(this.Datavalue);
    var monthlyInterestRatio = (rateOfInterest / 100) / 12;

    this.query.amount = loanAmount.toString();
    this.query.interest = rateOfInterest.toString();
    if (this.yrToggel) {
      this.query.tenureYr = this.Datavalue.toString();
    }
    else {
      this.query.tenureMo = this.Datavalue.toString();
    }

    var top = Math.pow((1 + monthlyInterestRatio), numberOfMonths);
    var bottom = top - 1;
    var sp = top / bottom;
    var emi = ((loanAmount * monthlyInterestRatio) * sp);
    var full = numberOfMonths * emi;
    var interest = full - loanAmount;
    var int_pge = (interest / full) * 100;

    this.result.emi = emi.toFixed(0).toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    var loanAmount_str = loanAmount.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.result.total = full.toFixed(0).toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.result.interest = interest.toFixed(0).toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.result.loanAmount = loanAmount.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
    this.userChangeEnd.emit(this.result);
  }

  handleSliderChange(newValue: any) {
    // Use the new value from the slider
    var loanAmount = Number(newValue) * 100000;
    var numberOfMonths = (this.yrToggel) ? (Number(newValue) * 12) : Number(newValue);
    var rateOfInterest = Number(newValue);
    var monthlyInterestRatio = (rateOfInterest / 100) / 12;
  
    this.query.amount = loanAmount.toString();
    this.query.interest = rateOfInterest.toString();
  
    if (this.yrToggel) {
      this.query.tenureYr = newValue.toString();
    } else {
      this.query.tenureMo = newValue.toString();
    }
  
    var top = Math.pow((1 + monthlyInterestRatio), numberOfMonths);
    var bottom = top - 1;
    var sp = top / bottom;
    var emi = ((loanAmount * monthlyInterestRatio) * sp);
    var full = numberOfMonths * emi;
    var interest = full - loanAmount;
  
    this.result.emi = emi.toFixed(0).toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.result.total = full.toFixed(0).toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.result.interest = interest.toFixed(0).toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.result.loanAmount = loanAmount.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
