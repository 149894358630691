<!-- <section *ngFor="let item of categoryArray">
    <button *ngIf="item.subcategories.length > 0" mat-button>
        {{item.categoryName}}
    </button>
</section> -->
<div  class="visiblity" >



<section  fxLayout="row"  class="padding-10 backg " fxLayoutAlign="center center">

    <h3  class="accent-color ">SHOP BY CATEGORY</h3>
</section>
<section fxLayout="row wrap" >
    <section style="border: 1px solid #eee" (click)="goToCategory(item)" *ngFor="let item of categoryArray" fxFlex.xs="50" [fxFlex.lg]="viewCol"
        [fxFlex.gt-sm]="viewCol" fxFlex.lt-md="33.33" class="col padding-5 product-mobile backg">
        <section fxLayout="row" fxLayoutAlign="center center">
            <img class="images"  onerror="this.src='assets/images/error/no-picture-icon.jpg';" [src]="serverPath+item.image" [alt]="item.categoryName">
        </section>
        <section fxLayout="row" fxLayoutAlign="center center">
            <b> {{item.categoryName | characterPipe  }} </b>
        </section>
    </section>
</section>
</div>
<div      class="padding-10 visiblityMObile" >
    <div id="noProducts" fxLayout="row" fxLayoutAlign="center center">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div>
          <img src="assets/images/no-product.png" alt="no-product">
        </div>
        <div class="notFound">
          <span>Sorry, no results found! </span>
        </div>
        <div class="spell">
          <span fxLayoutAlign="center center" >Please check the spelling or try searching for something else </span>
        </div>
      </div>
    </div>
  </div>