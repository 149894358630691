import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, DoCheck, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-typing-banner',
  templateUrl: './typing-banner.component.html',
  styleUrls: ['./typing-banner.component.scss']
})
export class TypingBannerComponent implements OnInit {

  @Input() item : any;
  @Input() img : any;
  @Input() data : any ;
  strings: string[] = [];
  isInView: boolean = false; // Controls visibility of ngx-typed-js

  @ViewChild('typedSection', { static: false }) typedSection: ElementRef | undefined;



  ngOnInit() {
    this.updateTypedStrings();

    
  }

  updateTypedStrings() {
    this.strings = this.item.customField7.split(',');
  }

  ngAfterViewInit() {
    if (this.typedSection) {
      this.createObserver();
    }
  }
  constructor(private http: HttpClient, private router: Router,private cdr: ChangeDetectorRef){}

  createObserver() {
    const options = {
      root: null, // use the viewport
      rootMargin: '0px',
      threshold: 0.5 // trigger when 50% of the section is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isInView = false; // Hide ngx-typed-js
          this.cdr.detectChanges(); // Trigger change detection to remove it
          
          setTimeout(() => {
            this.isInView = true; // Show ngx-typed-js again after a small delay
            this.cdr.detectChanges(); // Trigger change detection again to re-render it
          }, 100); // 100ms delay to ensure it fully re-initializes
        }
      });
    }, options);

    if (this.typedSection) {
      observer.observe(this.typedSection.nativeElement);
    }
  }
  public serverPath1 = environment.commonImageApi + '/home/';

  goToLink(url: string) {
    
    if (!/^https?:\/\//i.test(url)) {
      
      url = 'http://' + url;
    }
    window.open(url, '_self'); 
  }

}
