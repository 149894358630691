import { Component } from '@angular/core';

@Component({
  selector: 'calsoft-image-content-new',
  templateUrl: './image-content-new.component.html',
  styleUrls: ['./image-content-new.component.scss']
})
export class ImageContentNewComponent {

}
