<div *ngIf="array.length==0; else imgMissing" fxLayoutAlign="center center">
</div>

<ng-template #imgMissing >
  <owl-carousel-o  fxLayoutAlign="center center" [options]="slideConfig">
    <ng-container *ngFor="let slide of array">
      <ng-template carouselSlide [id]="slide.id">

              <div class="snipcss0-9-12-13 snipcss0-10-17-18">
                <div class="__item snipcss0-10-13-14 snipcss0-11-18-19 style-LYcJz" id="style-LYcJz">
                  <h3 class="snipcss0-11-14-15 snipcss0-12-19-20"            [style.font-size]="item.customField33" [ngStyle.lt-md]="{ 'font-size': item.customField34 }">
                    {{slide.customField11}}
                    <span class="snipcss0-12-15-16 snipcss0-13-20-21"   [style.font-size]="item.customField31" [ngStyle.lt-md]="{ 'font-size': item.customField32 }">
                      {{slide.customField12}}
                    </span>
                  </h3>
                  <p >
                    <strong [innerHTML]="slide.customField15"   [style.font-size]="item.customField29" [ngStyle.lt-md]="{ 'font-size': item.customField30 }">
                    </strong>
                  </p>
                  <p  [innerHTML]="slide.customField16"  [style.font-size]="item.customField37" [ngStyle.lt-md]="{ 'font-size': item.customField38 }">
                    
                  </p>
                  <p [innerHTML]="slide.customField17"  [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">
                    
                  </p>
                </div>
              </div>
         
         
      </ng-template>
    </ng-container>
  </owl-carousel-o>
  </ng-template>
