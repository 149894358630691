import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'calsoft-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit{


  @Input() item: any;
  @Input() img: any;
  category: any;
  EnablePayment: any = 0;

  ngOnInit() {
  
    this.category= this.item.value 

    this.EnablePayment = this.item.customField13
  
  }

}
