<div class="background-container" [style.backgroundImage]="'url(' +serverPath1+item.customField6 + ')'">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="center center">

      <div class="headering" fxLayout="row">
        <h2 class="headers" style="color: white;">
          <calsoft-editor-text [data]="data" [text]="item?.title" [align]="item.customField34"
          [style.font-size]="item.customField31" [ngStyle.lt-md]="{ 'font-size': item.customField32 }">
          </calsoft-editor-text>
        </h2>
      </div>
    </div>

    <div *ngIf="item.customField7.length>0" fxLayout="row" class="paragraph-container" fxLayoutAlign="center center">
      <h3 class="paragraph"           [style.font-size]="item.customField33" [ngStyle.lt-md]="{ 'font-size': item.customField34 }">
        {{item.customField7}}
      </h3>
    </div>

    <div fxLayout="row" class="paragraph-container" fxLayoutAlign="center center">
      <p class="paragraph" >
        <calsoft-editor-text   [data]="data" [text]="item?.customField19" [align]="item.customField34"
        [style.font-size]="item.customField35" [ngStyle.lt-md]="{ 'font-size': item.customField36 }">
        </calsoft-editor-text>
      </p>
    </div>

    <div fxFlex="20">

    </div>

    <div [class.container]="item.customField23 == '1'" class="container" fxFlex="100" style="padding: 0px 20px;" fxLayout="column"  [style.height]="item.height"
      [ngStyle.lt-md]="{'height': item.heightMob}">
      <div class="overlay1" style="width: 100%;" fxFlex="100" fxLayout="row wrap">


        <div *ngFor="let img of item.images" fxFlex="50" fxFlex.xs="100" class="col">

          <div class="padding-10">

          </div>

          <div fxLayout="row" fxLayoutGap="10px"  fxLayout.xs="column">

            <div fxLayout="column" [fxLayoutAlign]="item.customField29" fxLayoutAlign.xs="center center">

              <!-- <img onerror="" [src]="serverPath + img.img" (click)="img.navigation == 0 ? imageNavigation(img.catId) 
            : link(img.navigatedUrl)" [alt]="img.img" class="icon" [style.width]="item.customField33" 
            [ngStyle.lt-md]="{'width': item.customField28}"> -->
            <calsoft-ng-optimized-image  class="icon"
            [navigation]="0" [altImage]="img.img"  [image]="serverPath + img.img"
            [i]="'0'">
            </calsoft-ng-optimized-image>



            </div>

            <div fxLayout="column">
              <div fxLayout="row" fxFlex="100"  fxLayoutAlign.xs="center center">
                <span class="text"            [style.font-size]="item.customField37" [ngStyle.lt-md]="{ 'font-size': item.customField38 }"   [innerHTML]="img.customField15"></span>

              </div>

              <div fxLayout="row" >
                <p class="text"  [ngStyle.lt-md]="{'text-align': 'center', 'margin': '0px 5px'}" >
                  <calsoft-editor-text [ngStyle.lt-md]="{'text-align': 'center'}"
                  [data]="data"
                  [text]="img?.customField16" [align]="item.customField34"           [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">
                  </calsoft-editor-text>
                </p>

              </div>
             

            </div>

          </div>

          <div class="padding-10">

          </div>




        </div>

      </div>
    </div>


    <!-- <div class="overlay" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}">
        <div fxLayout="row-wrap" fxLayout.xs="column">
          <div fxFlex="50" *ngFor="let img of item.images; let l = index" class="image-container">
      
            <div fxLayout="row-wrap" *ngIf="img.customField8=='0'" class="left-side">
              <div fxLayoutAlign="start start" fxLayout="column">
                <img [src]="serverPath + img.img" (click)="img.navigation == 0 ? imageNavigation(img.catId) : link(img.navigatedUrl)" [alt]="img.img">
              </div>
              <div class="text-container paragraph" style="text-align: left; padding-left: 20px;">
                <h3 class="text" style="color: black;">{{img.customField15}}</h3>
                <span class="title">
                  <p class="text" style="justify-content: center;" [innerHTML]="img.customField16"></p>
                </span>
              </div>
            </div>
      
          
            <div fxLayout="row-wrap" *ngIf="img.customField8=='1'" class="right-side">
              <div fxLayoutAlign="start start" fxLayout="column">
                <img [src]="serverPath + img.img" (click)="img.navigation == 0 ? imageNavigation(img.catId) : link(img.navigatedUrl)" [alt]="img.img">
              </div>
              <div class="text-container paragraph" style="text-align: left; padding-left: 20px;">
                <h3 class="text" style="color: black;">{{img.customField15}}</h3>
                <span class="title">
                  <p class="text" style="justify-content: center;" [innerHTML]="img.customField16"></p>
                </span>
              </div>
            </div>
      
          </div>
        </div>
      </div> -->


  </div>
</div>