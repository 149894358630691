import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-vedio-layout',
  templateUrl: './vedio-layout.component.html',
  styleUrls: ['./vedio-layout.component.scss']
})
export class VedioLayoutComponent {

  public serverPath = environment.commonImageApi + '/home/';

  @Input() img: any;

  @Input() item: any;

  @Input() index: any;
  autoplay:any=0;
  i: any;

  ngOnInit() {

    this.playVideo();
    this.i=this.index  }


  ngAfterViewInit() {
    this.playVideo();
  }

  playVideo() {
    const video = document.querySelector('video') as HTMLVideoElement;
    if (video) {
      if (this.img.autoplay === 1 || this.img.autoplay === 2) {
        video.play();
      }
    }
  }
  
}
