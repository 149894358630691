import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, Inject, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatIconRegistry } from '@angular/material/icon';
import { ActivatedRoute, Router } from "@angular/router";
import { CartService } from "src/app/services/cart.service";
import { UserService } from "src/app/services/user.service";
import { WishlistService } from "src/app/services/wishlist.service";

import { Product } from "../../../app/models/app.models";
import { CurrencyConversionService } from "src/app/services/currency-conversion.service";
import { Constant } from "src/app/constants/constant";
import { UntypedFormControl } from "@angular/forms";
import { CommonSignInComponent } from "src/app/shared/CommonSignIn/CommonSignIn.component";
import { CommonService } from "src/app/services/common.service";
import { DOCUMENT } from "@angular/common";
import { AnalyticService } from "src/app/services/analytic-service";
import { PixelService } from "src/app/services/pixel.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ShareChatTrackingService } from "src/app/services/share-chat-tracking.service";
import { ConfigService } from "src/app/services/config.service";
import { ConfigServiceService } from "src/app/services/config-service.service";
import { SlotDetailComponent } from "src/app/pages/slot/slot-detail/slot-detail.component";
import { PartnerCartService } from "src/app/services/partner-cart.service";
import { TiktokService } from "src/app/services/tiktok.service";
import { MessageComponent } from "src/app/pages/product/message/message.component";
declare let gtag: Function;

@Component({
  selector: "calsoft-controls",
  templateUrl: "./controls.component.html",
  styleUrls: ["./controls.component.scss"],
})
export class ControlsComponent implements OnInit, OnChanges {
  @Input() product: any;
  @Input() data: any;
  @Input() type: string;
  @Input() type1: string;
  @Input() disableControl: string;
  @Input() productCustomOption: any;
  @Input() productOption: any[];
  //  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onQuantityStockChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() addCart: EventEmitter<any> = new EventEmitter<any>();
  public count: number = 1;
  public align = "center center";
  cartCount: any;
  page1: string;
  productArray: any;
  authourized: string;
  qtyInput = new UntypedFormControl('0');

  stockInHandInput = new UntypedFormControl('0');
  map = this.cartServices.getCartValue();
  wishMap = this.cartServices.getWishListValue();

  courseList = this.userService.getCourseValue();
  qty = 0;
  stockInHandQty = 0;

  stockInFormControl = new UntypedFormControl(0);

  qtyFormControl = new UntypedFormControl(0);

  inc: number = 0;
  spinner: boolean = false;

  @Input() showCartIcon: any;

  @Input() coversionTrackingIdGa: any;

  @Input() showCartIncremented: any;


  @Input() buttonArray: any = [];

  @Input() subscription: any = [];


  @Input() configproduct: any = [];
  @Input() variant1Name: any;
  @Input() variant2Name: any;
  @Input() variant1Value: any;
  @Input() variant2Value: any;
  variantList: any = [];
  wishMapDisplay: boolean = false;
  addToCartType: any;
  addToCartSymbolType: any;

  bookingEnabled: boolean = false;

  courseEnabled: boolean = false;

  text: string;
  secondaryTenantId: any;
  subscribed: boolean = false;
  courseProduct: boolean = false;
  subscribedId: any;
  customizeImage: string;
  message: string;
  dateValue: string;


  constructor(

    public cartServices: CartService,
    public partnerCartService: PartnerCartService,
    private configService: ConfigServiceService,
    private userService: UserService,
    private wishlistService: WishlistService,
    private dialog: MatDialog,
    private currencyConversionService: CurrencyConversionService,
    private router: Router,
    public snackBar: MatSnackBar,
    private commonService: CommonService,
    private analyticService: AnalyticService,
    private pixelService: PixelService,
    @Inject(DOCUMENT) private document: Document,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private shareChatTrackingService: ShareChatTrackingService,
    private route: ActivatedRoute,
    public googleAnalyticsService: AnalyticService,
    private tiktokService: TiktokService
  ) {

    this.matIconRegistry.addSvgIcon(
      "whatsapp",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/whatsapp.svg")
    );

  }
  public values: any;
  ngOnInit() {

  //  console.log(this.product);

   // console.log(this.configproduct);



    if (this.product.type == 'Variants Subscription' || this.product.type == 'Course') {

      this.courseProduct = true;

    } else {
      this.courseProduct = false;
    }

    if (this.product) {

      if (this.product.cartCount > 0) {
        this.count = this.product.cartCount;
      }

    }

    if (this.product.type == 'Booking') {
      this.bookingEnabled = true;
      this.text = "BOOK NOW"
    }
    else if (this.product.type == 'Course') {
      this.courseEnabled = true;
      this.text = "ADD TO CART"
    }
    else if (this.product.type == 'Variants Subscription') {
      this.courseEnabled = true;
      this.text = "ADD TO CART"
    }
    else {
      this.text = "ADD TO CART"
    }

    this.secondaryTenantId = this.route.snapshot.params['id'];

    this.authourized = this.userService.getAuthenticatedUser();
    this.layoutAlign();

    if(this.product.entityId ! == null){
      this.setCount(this.product.entityId);
    }


    if (this.router.url == '/cart' || this.router.url.includes('/checkout')) {
      this.qtyInput.patchValue(this.product.controls.qty.value)

      this.stockInHandInput.patchValue(this.product.controls.stockInHand.value)

      this.previousQty = this.product.controls.qty.value;

      this.previousStockInHand = this.product.controls.stockInHand.value;
    }



  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes != undefined) {

      if (changes.product != undefined) {
        let objectLength: any = Object.keys(changes.product.currentValue).length != 0;
        if (objectLength) {
          let productId = changes.product.currentValue.entityId
          this.inc = 0
          if(productId!=null){
            this.setCount(productId);
          }
         
        } else if (changes.productOption != undefined) {

          this.productOption = changes.productOption.currentValue
        }

      }

    }

  }


  setCount(productId) {
    // console.log(this.configproduct);
    // console.log(this.product);


    if (this.wishMap == undefined) {
      this.wishMap = this.cartServices.getWishListValue();
    }


    if (this.courseList == undefined) {
      if(productId!=null){
        this.getCourseValues(productId);
      }


    } else  {

      if (this.courseList.length > 0) {


        let recursiveIndex = this.courseList.findIndex(orderItem => orderItem.productId == productId);

      //  console.log(recursiveIndex);


        if (recursiveIndex > -1 && !this.subscribed) {
          this.courseList[recursiveIndex].productId
          this.subscribedId=productId;
          this.subscribed = true;
          localStorage.setItem('subscribed', JSON.stringify(this.subscribed));

        }

        if ((this.configproduct.type == 'Variants Subscription') && !this.subscribed) {

          const configProduct = this.configproduct.configProduct;
          for (const key in configProduct) {
            if (configProduct.hasOwnProperty(key)) {
              // console.log(`Key: ${key}, Value: ${configProduct[key]}`);
              let recursiveIndex = this.courseList.findIndex(orderItem => orderItem.productId == key);
    
              if (recursiveIndex > -1 && !this.subscribed) {
                this.courseList[recursiveIndex].productId
    
                this.subscribedId=parseFloat(key);
                this.subscribed = true;
                localStorage.setItem('subscribed', JSON.stringify(this.subscribed));
    
              }
    
            }
          }
    
      
    
        }

      }

      

    }







    if (this.inc == 0 && this.product != undefined) {
      if (this.router.url.includes("/pr?sid=")) {
        this.inc = 1;

        this.qty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["qty"] == undefined ? 0 : this.map[productId]["qty"];
        this.stockInHandQty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["stockInHand"] == undefined ? 0 : this.map[productId]["stockInHand"];
        //this.getCustomerCount();
      } else if (this.router.url.includes('/home') || this.router.url == '/') {
        this.inc = 1;
        this.qty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["qty"] == undefined ? 0 : this.map[productId]["qty"];
        this.stockInHandQty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["stockInHand"] == undefined ? 0 : this.map[productId]["stockInHand"];
      }
      else if (this.router.url.includes('/course')) {
        this.inc = 1;
        this.qty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["qty"] == undefined ? 0 : this.map[productId]["qty"];
        this.stockInHandQty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["stockInHand"] == undefined ? 0 : this.map[productId]["stockInHand"];
      }
      else if (this.router.url.includes('/product')) {
        this.inc = 1;
        this.qty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["qty"] == undefined ? 0 : this.map[productId]["qty"];
        this.stockInHandQty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["stockInHand"] == undefined ? 0 : this.map[productId]["stockInHand"];
      } else if (this.router.url.includes('/search')) {
        this.inc = 1;
        this.qty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["qty"] == undefined ? 0 : this.map[productId]["qty"];
        this.stockInHandQty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["stockInHand"] == undefined ? 0 : this.map[productId]["stockInHand"];
      }

      if (this.wishMap != undefined) {

        if (this.wishMap[productId] != undefined) {
          this.wishMapDisplay = true;

        } else {
          this.wishMapDisplay = false;
        }

      } else {
        this.wishMapDisplay = false;
      }

      this.qtyFormControl.patchValue(this.qty)


      this.emitQtySubscription.emit(this.qty)

    }
  }


  getCourseValues(productId:any) {

    
    let authourized = this.userService.getAuthenticatedUser();
    let response: any = null;
    if(authourized){
    this.userService.getCourseSubscribed().subscribe(
      (count) => {

        this.courseList=count;
        if (this.courseList.length > 0) {

          let productId=this.product.entityId
    
          if(this.product['type'] == "Variants Subscription"){
    
            productId=productId;
          }
    
          let recursiveIndex = this.courseList.findIndex(orderItem => orderItem.productId ==productId);
    
         // console.log(recursiveIndex);
    
    
          if (recursiveIndex > -1) {
            this.courseList[recursiveIndex].productId
            this.subscribedId=parseFloat(productId);
            this.subscribed = true;
            localStorage.setItem('subscribed', JSON.stringify(this.subscribed));
            
          }
    
          if ((this.product.type == 'Variants Subscription') && !this.subscribed) {
    
            const configProduct = this.configproduct.configProduct;
            for (const key in configProduct) {
              if (configProduct.hasOwnProperty(key)) {
               // console.log(`Key: ${key}, Value: ${configProduct[key]}`);
                let recursiveIndex = this.courseList.findIndex(orderItem => orderItem.productId == key);
      
                if (recursiveIndex > -1 && !this.subscribed) {
                  this.courseList[recursiveIndex].productId
  
                  this.subscribedId=parseFloat(key);
                  this.subscribed = true;
                  localStorage.setItem('subscribed', JSON.stringify(this.subscribed));
      
                }
      
              }
            }
      
        
      
          }
    
    
    
    
        }

       

        
      },
      (err) => {}
    );
  }
  }

  @Output() emitQtySubscription: EventEmitter<any> = new EventEmitter<any>();

  public layoutAlign() {
    if (this.type == "all") {
      this.align = "start center";
    } else if (this.type == "wish") {
      this.align = "start center";
    } else {
      this.align = "start center";
    }
  }

  public increment(qty, type) {

    qty++;
    localStorage.removeItem(Constant.CART_COUNT)
    this.cartServices.responseCache1 = new Map();



    if (type == 'all') {
      this.changeQuantity(qty);
    } else {
      this.changeStockInHand(qty);
    }

  }
  previousQty: any = 0;

  previousStockInHand: any = 0;
  updateQty(value, event, type) {

    if (event.code == 'Enter') {
      event.stopImmediatePropagation();
    } else {
      event.stopImmediatePropagation();
      event.preventDefault();
    }


    if (type == 'all') {
      if (this.previousQty != value) {
        this.emit(value);
      } else {

        this.previousQty = value

      }

    } else {
      if (this.previousStockInHand != value) {
        this.changeStockInHand(value);
      } else {

        this.previousStockInHand = value

      }
    }
  }

  emit(value) {

    this.onQuantityChange.emit(value);
  }



  public decrementass(qty) {
    if (qty < 0) {
      qty = 0
    }
    localStorage.removeItem(Constant.CART_COUNT)
    this.cartServices.responseCache1 = new Map();
    qty--;
    if (qty < 0) {
      qty = 0
    }
    if (this.type == 'all' || this.type == 'wish') {
      this.changeQuantity(qty);
    } else {
      this.changeStockInHand(qty);
    }
  }

  addToWishlistText: string = "ADD TO WISHLIST";

  public addToWishList(product) {
    if (this.authourized == "" || this.authourized == undefined) {
      this.authourized = this.userService.getAuthenticatedUser();
    }
    if (this.authourized == "") {
      this.authourized = this.userService.getAuthenticatedUser();
    }
    if (this.authourized) {
      this.addWishListItem(product);
      this.addToWishlistText = "Go to Wishlist";
    } else {
      this.page1 = "/account/wishlist";
      this.loginModel();
      this.productArray = {
        product: product,
      };
    }
  }

  addWishListItem(product) {
    let currencyFrom = this.currencyConversionService.getCountryCode();
    if (currencyFrom == null) {
      currencyFrom = "INR";
    }
    this.authourized = this.userService.getAuthenticatedUser();
    let wish = {
      customerId: 0,
      itemsQty: 1,
      currencyCode: currencyFrom,
      operationType: "New",
      remoteIp: "192.168.5.99",
      wishlistItem: [
        {
          productId: product.entityId,
          qty: 1.0,
          operationType: "New",
          storeId: 1,
          wishlistItemOption: {
            productId: this.configproduct.entityId,
            code: "info_buyRequest",
            value: this.configproduct.type

          },
        },
      ],
    };

    this.wishlistService.addWishlist(wish).subscribe(
      (response) => {
        if (response["status_code"] == Constant.RESPONSE_SUCCESS) {
          this.snackBar.open(response["status_message"], "×", {
            panelClass: "success",
            verticalPosition: "bottom",
            duration: 3000,
          });


          let values = this.cartServices.getWishListValue();

          if (values == undefined) {

            let object = {
              "productId": this.product['entityId'],
              "stockInHand": 0
            }

            const convMap = {};

            convMap[this.product['entityId']] = object;
            values = convMap;


            this.cartServices.setWishValue(values);
            this.wishMap = this.cartServices.getWishListValue();
          } else {
            if (values[this.product['entityId']] == undefined) {

              let data = values;
              let object = {
                "productId": this.product['entityId'],
                "stockInHand": 0
              }
              data[this.product["entityId"]] = object;

              values = data;

            }

            this.cartServices.setWishValue(values);
            this.wishMap = this.cartServices.getWishListValue();
            this.wishMapDisplay = true;
          }

        } else {
          this.snackBar.open(response["status_message"], "×", {
            panelClass: "error",
            verticalPosition: "bottom",
            duration: 3000,
          });
          this.wishMapDisplay = true;
        }
      },
      (err) => { }
    );
  }

  messageRequired:boolean=false;

  public addToCart(product, type, value) {

    this.messageRequired=false;
    
    this.customizeImage = localStorage.getItem('savedCanvasImageData'+this.configproduct.entityId);

    this.message = localStorage.getItem('message'+this.configproduct.entityId);

    
    this.dateValue = localStorage.getItem('dateValue'+this.configproduct.entityId);
    

    if(this.data.messageRequired>0){


      if(this.message==null){
        this.formStatus.emit(value);
          this.messageRequired=true;
      }

       else if(this.message.length==0){
          this.formStatus.emit(value);
          this.messageRequired=true;
        }
      
     
     }

    if (this.authourized == "" || this.authourized == undefined) {
      this.authourized = this.userService.getAuthenticatedUser();
    }

    if (this.bookingEnabled) {
      if (this.authourized || this.data['guestLogin'] == 1) {
        this.openSlotComponent()
      } else {
        this.page1 = "/booking";
        this.loginModel();
        this.productArray = {
          product: product,
          type: 'booking',

        };
      }

    } else  {

      if(this.messageRequired){
console.log("here");

      }

     else if ((this.authourized || this.data['guestLogin'] == 1)) {
        this.addToCartProduct(product, type, value,);
      } else {
        this.page1 = "/cart";
        this.loginModel();
        this.productArray = {
          product: product,
          type: type,
        };
      }

    }
  }

  public partneraddToCart(product, type, value) {



    if (this.authourized == "" || this.authourized == undefined) {
      this.authourized = this.userService.getAuthenticatedUser();
    }

    if (this.bookingEnabled) {
      if (this.authourized || this.data['guestLogin'] == 1) {
        this.openSlotComponent()
      } else {
        this.page1 = "/booking";
        this.loginModel();
        this.productArray = {
          product: product,
          type: 'booking',

        };
      }

    } else {

      if (this.authourized || this.data['guestLogin'] == 1) {
        this.addToCartPartner(product, type, value,);
      } else {
        this.page1 = "/cart";
        this.loginModel();
        this.productArray = {
          product: product,
          type: type,
        };
      }

    }
  }

  openSlotComponent() {
    if (window.innerWidth > 768) {
      let dialogRef = this.dialog.open(SlotDetailComponent, {
        disableClose: true,
        width: '900px',
        data: {
          id: this.product.entityId,
          productData: this.product,
          data: this.data
        }
      });
      dialogRef.afterClosed().subscribe((result) => { });
    } else {
      let dialogRef = this.dialog.open(SlotDetailComponent, {
        minWidth: '100vw',
        height: '100vh',
        data: {
          id: this.product.entityId,
          productData: this.product,
        }
      });
      dialogRef.afterClosed().subscribe((result) => { });
    }
  }


  @Output() formStatus: EventEmitter<any> = new EventEmitter<any>();

  addToCartProduct(product, type, value) {


   


  


    if ((this.configproduct.type == 'Configurable'
      || this.configproduct.type == 'Variants')
      && this.configproduct.configurableProductDropdown != null) {
      if (this.configproduct.entityId != product.entityId) {

        this.customizeImage = localStorage.getItem('savedCanvasImageData'+this.configproduct.entityId);
        this.message = localStorage.getItem('message'+this.configproduct.entityId);
        this.dateValue = localStorage.getItem('dateValue'+this.configproduct.entityId);
        let object = {
          productId: this.configproduct.entityId,
          qty: value,
          operationType: "New",
          customizeImage: this.customizeImage,
          message:this.message,
          dateValue:this.dateValue,
        }
        this.productOption = [];
        this.variantList.push(object);
      }

    }

    //console.log(this.configproduct);

    if ((this.configproduct.type == 'Variants Subscription')
      && this.configproduct.configurableProductDropdown != null) {
      if (this.configproduct.entityId != product.entityId) {
        this.customizeImage = localStorage.getItem('savedCanvasImageData'+this.configproduct.entityId);
        this.customizeImage = localStorage.getItem('message'+this.configproduct.entityId);
        this.dateValue = localStorage.getItem('dateValue'+this.configproduct.entityId);
        let object = {
          productId: this.configproduct.entityId,
          qty: value,
          operationType: "New",
          renewalPeriod: this.product.renewalPeriod,
          renewalPeriodType: this.product.renewalPeriodType,
          customizeImage: this.customizeImage,
          message:this.message,
          dateValue:this.dateValue,
        }
        this.productOption = [];
        this.variantList.push(object);
      }

    }

    let subscription = null;

    if (this.type == 'subscription') {
      subscription = this.subscription;
    }
    this.spinner = true;
    let currency: any = localStorage.getItem('currency');
    this.pixelService.track('AddToCart', {
      content_type: "product",
      content_ids: [product.sku],  // Item SKUs
      value: product.price,
      content_name: product.name,
      currency: currency
    });

    const eventData = {
      contents: [
        {
          content_id: [product.sku],
          content_type: "product",
          content_name: product.name
        }
      ],
      value: value,
      currency: currency
    };

    this.tiktokService.track('AddToCart', eventData);


    if (this.data['shareChat'] != null) {
      let eventName = 'Add to cart';
      if (type != 'cart') {
        eventName = 'Initiate checkout';
      }
      const eventType = 'pre_defined';
      const customProps = {
        content_type: "product",
        content_ids: [product.sku],  // Item SKUs
        value: product.price,
        content_name: product.name,
        currency: currency
      }
      this.shareChatTrackingService.sendCustomEvent(eventName, eventType, customProps);
    }

    let currencyFrom = this.currencyConversionService.getCountryCode();
    if (currencyFrom == null) {
      currencyFrom = "INR";
    }
    this.authourized = this.userService.getAuthenticatedUser();
    let cart = {
      customerId: 0,
      itemsQty: 1,
      currencyCode: currencyFrom,
      operationType: "New",
      remoteIp: "192.168.5.99",
      userAuthenticated: "",
      salesFlatQuoteItem: [
        {
          productId: product.entityId,
          qty: value,
          operationType: "New",
          productType: this.configproduct.type,
          subChild: this.variantList,
          manageStockSepConfig: this.configproduct.maintainStockIndConfProduct,
          manageImageSepConfig: this.configproduct.manageImageSepConfig,
          managePriceSepConfig: this.configproduct.maintainPriceIndConfProduct,
          subscription: subscription,
          productCustomOption: this.productCustomOption,
          salesFlatQuoteItemOption: this.productOption,
          customizeImage: this.customizeImage,
          message:this.message,
          dateValue:this.dateValue,

        },
      ],
    };
    this.cart(cart, type, value, product);
  }


  cart(cart, type, value, product) {
    this.cartServices.addCartItem(cart).subscribe(
      (response) => {
        if (response["status_code"] == Constant.RESPONSE_SUCCESS) {

      //     localStorage.removeItem('savedCanvasImageData'+this.configproduct.entityId);

      //  localStorage.removeItem('message'+this.configproduct.entityId);

      //  localStorage.removeItem('dateValue'+this.configproduct.entityId);
      

          localStorage.setItem(Constant['CART_ID'], this.commonService.getEncryptedId(response['data']['entityId']))
          if (type != "buyNow") {
            if (window.innerWidth < 850) {
              this.snackBar.open(response["status_message"], "×", {
                panelClass: "success",
                verticalPosition: "top",
                duration: 3000,
              });
            } else {


              this.snackBar.open(response["status_message"], "×", {
                panelClass: "success",
                verticalPosition: "bottom",
                duration: 3000,
              });

            }

            let authourized = this.userService.getAuthenticatedUser();
            let guestLoginEnabled = localStorage.getItem(Constant['GUEST_LOGIN'])
            if (authourized || guestLoginEnabled == "1") {
              let cartId = localStorage.getItem(Constant['CART_ID']);
              if (cartId != null) {

                if (this.values == undefined) {
                  this.values = this.cartServices.getCartValue();
                }
              }
            }

            else if (this.values.size == 0) {
              this.values = this.cartServices.getCartValue();
            }



            this.values = this.cartServices.getCartValue();
            this.cartServices.responseCache1 = new Map();
            if (this.values == undefined) {

              let object = {
                "qty": value,
                "stockInHand": 0
              }
              // this.values = new Map<any, any>();
              // this.values.set(
              //   this.product['entityId'],
              //   object
              // );
              const convMap = {};

              convMap[this.product['entityId']] = object;
              this.values = convMap;

              this.cartServices.setCartValue(this.values);
              this.qty = this.values[this.product["entityId"]]["qty"];
              this.qtyFormControl.patchValue(this.qty)
            } else {
              if (this.values[this.product['entityId']] == undefined) {

                let data = this.values;
                let object = {
                  "qty": value,
                  "stockInHand": 0
                }
                data[this.product["entityId"]] = object;

                this.values = data;

                let encryptedValue = btoa(JSON.stringify(this.values));

                this.userService.storeProductCartCount(encryptedValue);
              } else {
                this.values[this.product["entityId"]]["qty"] =
                  this.values[this.product["entityId"]]["qty"] + value;
              }


              this.cartServices.setCartValue(this.values);

              this.qty = this.values[this.product["entityId"]]["qty"];





              if (this.qty == undefined) {
                this.qty = 0;
              }
              this.qtyFormControl.patchValue(this.qty)
            }



          } else {
            if (this.values == undefined) {

              let object = {
                "qty": value,
                "stockInHand": 0
              }
              // this.values = new Map<any, any>();
              // this.values.set(
              //   this.product['entityId'],
              //   object
              // );
              const convMap = {};

              convMap[this.product['entityId']] = object;
              this.values = convMap;

              this.cartServices.setCartValue(this.values);
              this.qty = this.values[this.product["entityId"]]["qty"];
              this.qtyFormControl.patchValue(this.qty)
            }
            if (this.values[this.product['entityId']] == undefined) {
              this.values[this.product["entityId"]] = value;
              let encryptedValue = btoa(JSON.stringify(this.values));

              this.userService.storeProductCartCount(encryptedValue);
            } else {
              this.values[this.product["entityId"]] =
                this.values[this.product["entityId"]] + value;
            }


            this.cartServices.setCartValue(this.values);

            this.qty = this.values[this.product["entityId"]];
            localStorage.removeItem(Constant.CART_COUNT)
            this.cartServices.responseCache1 = new Map();

            // if(this.product.)

            if (this.product['isCustomizedValue'] != null) {

              if (this.product['isCustomizedValue']['Prescription Required']
                != null && this.product['isCustomizedValue']['Prescription Required']
                != undefined && this.product['isCustomizedValue']['Prescription Required']
                != '') {

                let value = this.product['isCustomizedValue']['Prescription Required']
                  == 1 ? true : false;



                if (value) {
                  this.router.navigate(['/checkout/type'])
                } else {
                  this.router.navigate(["/checkout"]);
                }

              } else if (this.product['isCustomizedValue']['Prescription_Required']
                != null && this.product['isCustomizedValue']['Prescription_Required']
                != undefined && this.product['isCustomizedValue']['Prescription_Required']
                != '') {

                let value = this.product['isCustomizedValue']['Prescription_Required'] == 1 ? true : false;

                if (value) {
                  this.router.navigate(['/checkout/type'])
                } else {
                  this.router.navigate(["/checkout"]);
                }

              }


              else {
                this.router.navigate(["/checkout"]);
              }




              this.qtyFormControl.patchValue(this.qty)
            } else {
              this.router.navigate(["/checkout"]);
            }
          }

        } else {
          this.snackBar.open(response["status_message"], "×", {
            panelClass: "error",
            verticalPosition: "bottom",
            duration: 3000,
          });
        }
        this.spinner = false;
        this.cartServices.getCount();
      },
      (err) => { this.spinner = false; }
    );

  }


  addToCartPartner(product, type, value) {


    let entityId = 0;
    if ((this.configproduct.type == 'Configurable'
      || this.configproduct.type == 'Variants Subscription'
      || this.configproduct.type == 'Variants')
      && this.configproduct.configurableProductDropdown != null) {
      if (this.configproduct.entityId != product.entityId) {
        let object = {
          productId: this.configproduct.entityId,
          qty: value,
          operationType: "New",
        }
        this.productOption = [];
        this.variantList.push(object);
      }

    }

    let subscription = null;

    if (this.type == 'subscription') {
      subscription = this.subscription;
    }
    this.spinner = true;
    let currency: any = localStorage.getItem('currency');
    this.pixelService.track('AddToCart', {
      content_type: "product",
      content_ids: [product.sku],  // Item SKUs
      value: product.price,
      content_name: product.name,
      currency: currency
    });

    if (this.data['shareChat'] != null) {
      let eventName = 'Add to cart';
      if (type != 'cart') {
        eventName = 'Initiate checkout';
      }
      const eventType = 'pre_defined';
      const customProps = {
        content_type: "product",
        content_ids: [product.sku],  // Item SKUs
        value: product.price,
        content_name: product.name,
        currency: currency
      }
      this.shareChatTrackingService.sendCustomEvent(eventName, eventType, customProps);
    }

    let currencyFrom = this.currencyConversionService.getCountryCode();
    if (currencyFrom == null) {
      currencyFrom = "INR";
    }
    this.authourized = this.userService.getAuthenticatedUser();
    let cart = {
      customerId: 0,
      itemsQty: 1,
      currencyCode: currencyFrom,
      operationType: "New",
      remoteIp: "192.168.5.99",
      userAuthenticated: "",
      salesFlatQuoteItem: [
        {
          productId: product.entityId,
          qty: value,
          operationType: "New",
          productType: this.configproduct.type,
          subChild: this.variantList,
          manageStockSepConfig: this.configproduct.maintainStockIndConfProduct,
          manageImageSepConfig: this.configproduct.manageImageSepConfig,
          managePriceSepConfig: this.configproduct.maintainPriceIndConfProduct,
          subscription: subscription,
          productCustomOption: this.productCustomOption,
          salesFlatQuoteItemOption: this.productOption,
          customizeImage: this.customizeImage,
          

        },
      ],
    };
    this.partnercart(cart, type, value, product);
  }


  partnercart(cart, type, value, product) {
    this.partnerCartService.addCartPartnerItem(cart, this.secondaryTenantId).subscribe(
      (response) => {
        if (response["status_code"] == Constant.RESPONSE_SUCCESS) {

          localStorage.setItem(Constant['CART_ID'], this.commonService.getEncryptedId(response['data']['entityId']))
          if (type != "buyNow") {
            if (window.innerWidth < 850) {
              this.snackBar.open(response["status_message"], "×", {
                panelClass: "success",
                verticalPosition: "top",
                duration: 3000,
              });
            } else {


              this.snackBar.open(response["status_message"], "×", {
                panelClass: "success",
                verticalPosition: "bottom",
                duration: 3000,
              });

            }

            let authourized = this.userService.getAuthenticatedUser();
            let guestLoginEnabled = localStorage.getItem(Constant['GUEST_LOGIN'])
            if (authourized || guestLoginEnabled == "1") {
              let cartId = localStorage.getItem(Constant['CART_ID']);
              if (cartId != null) {

                if (this.values == undefined) {
                  this.values = this.partnerCartService.getPartnerCartValue(this.secondaryTenantId);
                }
              }
            }

            else if (this.values.size == 0) {
              this.values = this.partnerCartService.getPartnerCartValue(this.secondaryTenantId);
            }



            this.values = this.partnerCartService.getPartnerCartValue(this.secondaryTenantId);
            this.partnerCartService.responseCache1 = new Map();
            if (this.values == undefined) {

              let object = {
                "qty": value,
                "stockInHand": 0
              }

              const convMap = {};

              convMap[this.product['entityId']] = object;
              this.values = convMap;

              this.partnerCartService.setCartValue(this.values, this.secondaryTenantId);
              this.qty = this.values[this.product["entityId"]]["qty"];
              this.qtyFormControl.patchValue(this.qty)
            } else {
              if (this.values[this.product['entityId']] == undefined) {

                let data = this.values;
                let object = {
                  "qty": value,
                  "stockInHand": 0
                }
                data[this.product["entityId"]] = object;

                this.values = data;

                let encryptedValue = btoa(JSON.stringify(this.values));

                this.userService.storeProductCartCount(encryptedValue);
              } else {
                this.values[this.product["entityId"]]["qty"] =
                  this.values[this.product["entityId"]]["qty"] + value;
              }


              this.partnerCartService.setCartValue(this.values, this.secondaryTenantId);

              this.qty = this.values[this.product["entityId"]]["qty"];





              if (this.qty == undefined) {
                this.qty = 0;
              }
              this.qtyFormControl.patchValue(this.qty)
            }



          } else {
            if (this.values == undefined) {

              let object = {
                "qty": value,
                "stockInHand": 0
              }

              const convMap = {};

              convMap[this.product['entityId']] = object;
              this.values = convMap;

              this.partnerCartService.setCartValue(this.values, this.secondaryTenantId);

              this.qty = this.values[this.product["entityId"]]["qty"];

              this.qtyFormControl.patchValue(this.qty)
            }
            if (this.values[this.product['entityId']] == undefined) {
              this.values[this.product["entityId"]] = value;
              let encryptedValue = btoa(JSON.stringify(this.values));

              this.userService.storeProductCartCount(encryptedValue);
            } else {
              this.values[this.product["entityId"]] =
                this.values[this.product["entityId"]] + value;
            }


            this.partnerCartService.setCartValue(this.values, this.secondaryTenantId);

            this.qty = this.values[this.product["entityId"]];
            localStorage.removeItem(Constant.CART_COUNT)
            this.partnerCartService.responseCache1 = new Map();

            // if(this.product.)

            if (this.product['isCustomizedValue'] != null) {

              if (this.product['isCustomizedValue']['Prescription Required']
                != null && this.product['isCustomizedValue']['Prescription Required']
                != undefined && this.product['isCustomizedValue']['Prescription Required']
                != '') {

                let value = this.product['isCustomizedValue']['Prescription Required']
                  == 1 ? true : false;



                if (value) {
                  this.router.navigate(['/checkout/type'])
                } else {
                  this.router.navigate(["/checkout"]);
                }

              } else if (this.product['isCustomizedValue']['Prescription_Required']
                != null && this.product['isCustomizedValue']['Prescription_Required']
                != undefined && this.product['isCustomizedValue']['Prescription_Required']
                != '') {

                let value = this.product['isCustomizedValue']['Prescription_Required'] == 1 ? true : false;

                if (value) {
                  this.router.navigate(['/checkout/type'])
                } else {
                  this.router.navigate(["/checkout"]);
                }

              }


              else {
                this.router.navigate(["/checkout"]);
              }




              this.qtyFormControl.patchValue(this.qty)
            } else {
              this.router.navigate(["/checkout"]);
            }
          }

        } else {
          this.snackBar.open(response["status_message"], "×", {
            panelClass: "error",
            verticalPosition: "bottom",
            duration: 3000,
          });
        }
        this.spinner = false;
        this.partnerCartService.getCount(this.secondaryTenantId);
      },
      (err) => { this.spinner = false; }
    );

  }

  public changeQuantity(value) {
    this.onQuantityChange.emit(value);
  }

  public changeStockInHand(value) {
    this.onQuantityStockChange.emit(value);
  }


  loginModel() {
    if (window.innerWidth > 768) {
      let dialogRef = this.dialog.open(CommonSignInComponent, {
        width: "790px",
        data: { page: this.page1 },
      });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe((result) => {
        if (result["event"] != "close") {

          if (this.bookingEnabled) {

            this.openSlotComponent()

          }
          else if (this.page1 == "/account/wishlist" && result["event"] != "close") {
            this.addWishListItem(this.productArray.product);
          } else if (this.page1 == "/cart" && result["event"] != "close") {
            this.addToCartProduct(
              this.productArray.product,
              this.productArray.type,
              1
            );
          }
          if (!this.bookingEnabled) {
            this.router.navigate([this.page1]);
          }
        }
      });
    } else {
      let dialogRef = this.dialog.open(CommonSignInComponent, {
        minWidth: "100vw",
        height: "100vh",
        data: { page: this.page1 },
      });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe((result) => {
        if (result["event"] != "close") {
          if (this.bookingEnabled) {

            this.openSlotComponent()


          }
          else if (this.page1 == "/account/wishlist" && result["event"] != "close") {
            this.addWishListItem(this.productArray.product);
          } else if (this.page1 == "/cart" && result["event"] != "close") {
            this.addToCartProduct(
              this.productArray.product,
              this.productArray.type,
              1
            );
          }
          if (!this.bookingEnabled) {
            this.router.navigate([this.page1]);
          }
        }
      });
    }
  }


  getCustomerCount() {

    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled = localStorage.getItem(Constant['GUEST_LOGIN'])
    if (authourized || guestLoginEnabled == "1") {

      if (this.product != undefined) {


        this.cartServices.getCustomerCartProductCount().subscribe(
          (response) => {


            this.values = response;


            this.qty = this.values[this.product["entityId"]];


            if (this.qty == undefined) {
              this.qty = 0;
            }
            let value = localStorage.getItem(Constant.CART_COUNT);
            if (value == null) {
              let encryptedValue = btoa(JSON.stringify(response));

              this.userService.storeProductCartCount(encryptedValue);
            }
          },
          (err) => { }
        );
      }
    }



  }

  whatsapp() {

    let value = "";

    if ((this.configproduct['type'] == "Configurable" ||
      this.configproduct.type == 'Variants Subscription' ||
      this.configproduct['type'] == "Variants")
      && this.configproduct['configurableProductDropdown']
      != null) {

      this.configproduct.configId = this.product.entityId;
      value = this.commonService.retrunProductUrl(this.configproduct)
    } else {
      value = this.commonService.retrunProductUrl(this.configproduct)
    }


    const storeName = this.data['storeName'];
    const phoneNumber = this.data['storePhoneNumber'];
    const productName = this.product['name']
      .replace(/[^a-zA-Z ]/g, "")
      .replace(/ /g, '');

      const productUrl = ` Here is the product link ${this.document.location.origin}/${value}\n`;
      const productIdMessage = `Product Id: ${this.product['productIncrementId']}\n`;
      const message = `Hello ${storeName}! I want to order ${productName}\n ${productIdMessage} 👇 ${productUrl}`;
      const encodedMessage = encodeURIComponent(message);
      const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;

    this.analyticService.customEventEmitter('WA_button_Clicked', 'BUTTON_CLICK',
      'Whats App Click', this.product['name'])
    this.commonService.goToLink(whatsappUrl);
  }


  link(buttonObject) {

    let url = buttonObject.link

    this.commonService.goToLink(url);

    // window.location.href = url;

    this.setConversionTrackingId();

    gtag('event', 'click', {
      'event_category': 'purchase',
      'event_label': buttonObject.name
    });

  }

  tagId: any;
  formGroupId: any;

  setConversionTrackingId() {

    let grandTotal: any = '1.0';
    let value: any = localStorage.getItem('currency');
    this.googleAnalyticsService.event('purchase', grandTotal);
    if (this.tagId != null) {

      this.googleAnalyticsService.eventEmitter
        ('conversion', this.tagId,
          grandTotal,
          value, this.formGroupId);
    }
    else if (this.coversionTrackingIdGa != null) {
      this.googleAnalyticsService.eventEmitter
        ('conversion', this.coversionTrackingIdGa,
          grandTotal,
          value, this.formGroupId);
    }
  }

  isMouseOver: boolean = false;

  onMouseEnter() {
    this.isMouseOver = true;
  }

  onMouseLeave() {
    this.isMouseOver = false;
  }

  notFound() {
    // this.snackBar.open('Subscription is already Added in cart', '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
    this.router.navigate(["/checkout"]);
  }





  goToCourse(product) {

    if (this.product['type'] == "Variants Subscription") {
      product.configId = this.subscribedId;
      product.entityId = this.configproduct.entityId
    }
    this.commonService.goToCourse(product);


  }

  gotoCart() {
    this.router.navigate(['/cart'])
  }


}
