import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'calsoft-booking-search-box',
  templateUrl: './booking-search-box.component.html',
  styleUrls: ['./booking-search-box.component.scss']
})
export class BookingSearchBoxComponent implements OnInit{

  isPopoverOpen: boolean = false;
  selectedAdults:any;
  selectedChildren:any;
  totaluser:any;

  constructor() {
    this.selectedAdults = 1;
    this.selectedChildren =0;
    this.totaluser = (parseInt(this.selectedAdults) + parseInt(this.selectedChildren));
   }


  togglePopover() {
    this.isPopoverOpen = !this.isPopoverOpen;
  }

  closePopover() {
    this.isPopoverOpen = false;
  }

  onAdultChange(){
    this.totaluser = (parseInt(this.selectedAdults) + parseInt(this.selectedChildren));
  }

  onChildChange(){
    this.totaluser = (parseInt(this.selectedAdults) + parseInt(this.selectedChildren));
  }

  ngOnInit() {

  }

}
