<div class="bgimg" [style.backgroundImage]="'url(' + serverPath1+item.customField6 + ')'">

</div>

<div fxLayout="row" fxLayout.xs="column" class="contentdiv">
    <div fxFlex="50" fxFlex.xs="100" fxFlexOrder.lt-md="2">
        <div class="padd1">

            <div *ngFor="let text of item.images; let l = index">
                <h2 class="headercss"><b>
                        <calsoft-editor-text [data]="data" [text]="text.customField4" [align]="item.customField34"
                        [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">
                        </calsoft-editor-text>
                    </b></h2>
                <ng-container *ngFor="let value of text.customField6.split(',')">
                    <div fxLayout="row">
                        <div fxFlex="10">
                            <mat-icon class="maticoncss">check_circle</mat-icon>
                        </div>
                        <div fxFlex="80">
                            <h3 class="font-h3">
                                <calsoft-editor-text [data]="data" [text]="value.trim()" [align]="item.customField34"
                                [style.font-size]="item.customField29" [ngStyle.lt-md]="{ 'font-size': item.customField30 }">
                                </calsoft-editor-text>
                            </h3>
                        </div>
                    </div>
                </ng-container>
            </div>



            <div class="bottomtype1" style="cursor: pointer;">
                <span class="bottomtxt1" (click)="openInNewWindow(item.customField11)">
                    <calsoft-editor-text [data]="data" [text]="item.customField10" [align]="item.customField34"
                    [style.font-size]="item.customField35" [ngStyle.lt-md]="{ 'font-size': item.customField36 }">
                    </calsoft-editor-text></span>
            </div>

            <div class="bottomtype1" style="cursor: pointer;">
                <span class="bottomtxt1" (click)="openInNewWindow(item.customField14)">
                    <calsoft-editor-text [data]="data" [text]="item.customField12" [align]="item.customField34"
                    [style.font-size]="item.customField37" [ngStyle.lt-md]="{ 'font-size': item.customField38 }">
                    </calsoft-editor-text></span>
            </div>


        </div>
    </div>
    <div fxFlex="50" fxFlex.xs="100" fxFlexOrder.lt-md="1">
        <div class="padd1">
            <h2 class="headercss2"><b>
                    <calsoft-editor-text [data]="data" [text]="item.title" [align]="item.customField34"
                    [style.font-size]="item.customField31" [ngStyle.lt-md]="{ 'font-size': item.customField32 }">
                    </calsoft-editor-text>
                </b></h2>
            <h6 class="subtitle"><b>
                    <calsoft-editor-text [data]="data" [text]="item.customField15" [align]="item.customField34"
                    [style.font-size]="item.customField33" [ngStyle.lt-md]="{ 'font-size': item.customField34 }">
                    </calsoft-editor-text>
                </b></h6>

            <div *ngIf="item.customField13==1">
                <div *ngIf="item.layoutSub.length <= 2; else matTabs" fxLayout="row" class="mobbtn">
                    <div fxFlex *ngFor="let button of item.layoutSub; let i=index">
                        <button class="tabbtn" [ngClass]="{'highlighted': i === indexMain}"
                        [style.font-size]="item.customField27" [ngStyle.lt-md]="{ 'font-size': item.customField28 }"
                            (click)="toggleButton(button, i)">{{button.customField1}}</button>
                    </div>
                </div>

                <ng-template #matTabs>
                    <mat-tab-group>
                        <mat-tab *ngFor="let button of item.layoutSub; let i=index">
                            <ng-template mat-tab-label>
                                <span class="mat-tab-label-button"
                                [style.font-size]="item.customField27" [ngStyle.lt-md]="{ 'font-size': item.customField28 }"
                                    (click)="toggleButton(button, i)">{{button.customField1}}</span>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </ng-template>
            </div>



            <div *ngIf="item.customField13==2">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label           [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">{{item.customField4}}</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let button of item.layoutSub; let i=index" [value]="button.customField1">
                            <span (click)="toggleButton(button, i)"
                            [style.font-size]="item.customField27" [ngStyle.lt-md]="{ 'font-size': item.customField28 }">
                                {{button.customField1}}
                            </span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div class="padding-10">

            </div>


            <div *ngIf="init">
                <div class="padding-5" *ngFor="let product of categoryProducts; let k=index">

                    <div>
                        <calsoft-service-product-detail-new [item]="item" [data]="data" [width]="'100'"
                            [imageType]="imageType" [image]="image" [height]="'100'" [heightMobile]="'100'"
                            [widthMobile]="'100'" [showCartIncremented]="showCartIncremented" [product]="product"
                            [index]="k==categoryProducts.length-1" [i]="k">
                        </calsoft-service-product-detail-new>
                    </div>


                </div>
            </div>

        </div>
    </div>
</div>