<div fxLayout="row" style="padding: 5px;" [style.width]="popoverWidth" class="product-container product-elevat" [ngClass]="{
    'left-show':ShowviewClass === '2',
    'top-show':ShowviewClass === '3',
    'bottom-show':ShowviewClass === '4',
    'right-show':ShowviewClass === '1'
    }">
    <div fxFlex>
        <img [src]="image" (click)="productShow(product)" onerror="this.src='';" [style.height]="item.customField25" 
        [ngStyle.lt-md]="{'height': item.customField26}"
         [style.width]="item.customField27" 
        [ngStyle.lt-md]="{'width': item.customField28}" style="margin-left: auto;margin-right: auto;display: block;">
        <calsoft-ng-optimized-image [height]="item.height" [heightMob]="item.heightMob"
                                [navigation]="0" [altImage]="img.img" [catId]="img.catId" [image]="serverPath + img.img"
                                [i]="'0'">
        </calsoft-ng-optimized-image>

        
    </div>
    <div fxFlex>
        <div class="product-details">
            <span class="product-name modernWay">{{ productData?.name   }}</span>
            <h5 class="product-price" >{{ productData?.values.Price | currencyConvert }}</h5>
            <!-- <p class="product-description modernWay" [innerHTML]="productData?.description"></p> -->
        </div>
    </div>

</div>
