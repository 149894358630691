import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DynamicFooterRoutingModule } from './dynamic-footer-routing.module';
import { DynamicFooterComponent } from './dynamic-footer.component';
import { GeneralModule } from '../general/general.module';


@NgModule({
  declarations: [
    DynamicFooterComponent
  ],
  imports: [
    CommonModule,
    DynamicFooterRoutingModule,
    GeneralModule
  ],
  exports:[DynamicFooterComponent]
})
export class DynamicFooterModule { }
