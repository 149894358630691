<div class="container">
  <div class="editSets">

    <div class="padding-10" class="title-head1" fxLayout="row" fxLayoutAlign="space-between center">
      <span class="padding-10"  [innerHTML]="item?.title"></span>
      <button class="padding-10" (click)="addProduct()" mat-icon-button>
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  
  <div fxLayout="row" style="width: 100%;">
    <div *ngIf="showcart" style="width: 100%;">
      <div fxLayout="row" >
        <div style="width: 100%;" id="responsieTable">

          <table class="rwd-table" style="width: 100%;">

            <tr class="backTable">
              <th style="text-align: left; width:20%">Item</th>
              <th>No of Units</th>
              <th>Cost Per Unit</th>
              <th>Total Cost</th>
            </tr>

            <ng-container>
              <tbody>
                <tr style="background: white;" *ngFor="let data of finalData; let i=index">

                  <td data-th="Product" style="text-align: left;">
                    <small>{{data.name| uppercase |slice:0:25}}...</small>

                  </td>

                  <td data-th="No of Units">
                    {{data.quantity}}
                  </td>

                  <td data-th="Cost Per Unit">
                    {{data.price}}
                  </td>


                  <td data-th="Total Cost">
                    {{data.grandTotal}}
                  </td>
                </tr>
              </tbody>
            </ng-container>
          </table>
        </div>
      </div>
    </div>

  </div>
</div>