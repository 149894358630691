import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { SubscriptionPriceService } from 'src/app/services/subscription-price.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-image-checkbox',
  templateUrl: './image-checkbox.component.html',
  styleUrls: ['./image-checkbox.component.scss']
})
export class ImageCheckboxComponent {

 

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() data: any;

  @Input() img: any;
  
  @Input() index: any;

   optionControl = new UntypedFormControl('', []);
  
  customField7: any;
  height: any;
  subscriptionImgName: any;


  constructor(
    private commonService: CommonService,
    private subscriptionService: SubscriptionPriceService ) {}
    


    ngOnInit() {
    
      this.radioChangedeliveryOption(this.img[0].img);


 

    }

  
  imageNavigation(id){


    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  link(url) {
    this.commonService.goToLink(url)
  }


  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      this.commonService.goToLink(url)
    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }

  radioChangedeliveryOption(value) {
    this.optionControl.patchValue(value);

    this.subscriptionService.updatesubscriptionImgName(this.optionControl.value)
    
  }
  
}

