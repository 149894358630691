import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-developmentservices',
  templateUrl: './developmentservices.component.html',
  styleUrls: ['./developmentservices.component.scss']
})
export class DevelopmentservicesComponent {

  @Input() item: any;
  customField15:any;
  customField16:any; 

  public serverPath = environment.commonImageApi + '/home/';

  ngOnInit() {
    
  }


  link(url) {

    window.open(url, '_blank')
  }
  

  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      window.open(fullURL, '_blank');
    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }


}
