import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmiCalculatorRoutingModule } from './emi-calculator-routing.module';
import { EmiCalculatorComponent } from './emi-calculator.component';
import { FormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SliderComponent } from './slider/slider.component';
import { EnquiryFormComponent } from './enquiry-form/enquiry-form.component';


@NgModule({
  declarations: [
    EmiCalculatorComponent,
    SliderComponent,
    EnquiryFormComponent,
    
  ],
  imports: [
    CommonModule,
    EmiCalculatorRoutingModule,
    FormsModule,
    NgxSliderModule,
    CustomFlexLayoutModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule
  
  ],exports:[
    EmiCalculatorComponent
  ]
})
export class EmiCalculatorModule { }
