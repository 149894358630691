import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { AboutService } from 'src/app/services/about.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'calsoft-blog-layout',
  templateUrl: './blog-layout.component.html',
  styleUrls: ['./blog-layout.component.scss']
})
export class BlogLayoutComponent {

  @Input() data: any;

  @Input() blog: any;

  @Input() name: any;

  @Input() width: any;

  @Input() heightMobile: any;

  @Input() widthMobile: any;
  public serverPath = environment.commonImageApi+"blog/";
  image: string;
  @Input() public pageTitle: any
  aboutData: any;
  loaded:any=false;
  layoutype: any=0;
  @Input() public homePageBuilder: any;

  height: any;
  blogNameDisplayLineMob =2;
  blogNameDisplayLine = 2;
  blogOverallHeightMob: any;
  blogOverallHeight: any;
  
  constructor(
    private commonService: CommonService,
    private router: Router,
    public meta: Meta,
    private aboutService: AboutService,

  ) { }
	list:any=[];
  ngOnInit(): void {
   
    
  this.image=this.serverPath+this.blog['content'];
  this.meta.addTags([
    { name: 'author', content: Constant.siteName },
    { name: 'keywords', content: this.blog['metaKeywords'] },
    { name: 'description', content: this.blog['metaDescription'] }
  ]);
  this.height=this.data['blogImageHeight']
  this.width=this.data['blogImageWidth']
  this.widthMobile=this.data['blogImageWidthMob']
  this.heightMobile=this.data['blogImageHeightMob'];
  this.blogNameDisplayLine=this.data['blogNameDisplayLine']
  this.blogNameDisplayLineMob=this.data['blogNameDisplayLineMob']
  this.blogOverallHeight=this.data['blogOverallHeight']
  this.blogOverallHeightMob=this.data['blogOverallHeightMob']
  }

 

  

  goToBlogData(){

    this.router.navigate([`blog/${this.blog['uuid']}`],{ queryParams: { title: this.blog['title'] } })
  }

  }



