import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-service-contact-button',
  templateUrl: './service-contact-button.component.html',
  styleUrls: ['./service-contact-button.component.scss']
})
export class ServiceContactButtonComponent {
  constructor( private commonService: CommonService,) {

  }


  @Input() item: any;

  @Input() img: any;


  
  callPhone(phoneNumber) {
    window.open(`tel:${phoneNumber}`, '_self');
  }


  whatsapp(chat) {
    let message = `Hi`;
    let url = `https://api.whatsapp.com/send?phone= ${encodeURIComponent(chat)}&text=${encodeURIComponent(message)}`;
    this.commonService.goToLink(url)
  }

  composeEmail(smtpUsername) {
    window.location.href = 'mailto:' + smtpUsername;
  }

  
}
