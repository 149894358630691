<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="30px" class="boxcss" *ngFor="let data of pricedetail">
    <div fxFlex="100" fxFlex.gt-sm="50" style="border-radius: 20px;" [style.background-color]="data.column_color">
        <div [ngStyle.gt-md]="{'padding': '70px' }" [ngStyle.lt-md]="{'padding': '50px'}" contentAnim [aniName]="item.customField36">
            <h3 [ngStyle.gt-md]="{'font-size': data.Toptitle_font }"  [ngStyle.lt-md]="{'font-size': data.Toptitle_mobfont}" [style.color]="data.a_button"><b>{{data.title1}}</b></h3>
            <hr />
            <div class="titlecss" ><b [ngStyle.gt-md]="{'font-size': data.title1_font }" [ngStyle.lt-md]="{'font-size': data.title1_mobfont}">{{data.s_price_annualy |currencyConvert}}</b> <span>&#160;&#160;/
                &#160;Monthly</span></div>
            <hr />
            <div class="titlecss"><b [ngStyle.gt-md]="{'font-size': data.title2_font }" [ngStyle.lt-md]="{'font-size': data.title2_mobfont}">{{data.s_price_life |currencyConvert}}</b> <span>&#160;&#160;/
                &#160;Yearly</span></div>
            <hr />

            <div style="margin-top: 15px;margin-bottom: 10px;color: gray;" [ngStyle.gt-md]="{'font-size': data.subtitle_font }" [ngStyle.lt-md]="{'font-size': data.subtitle_mobfont}">
                <mat-icon class="maticon2">add</mat-icon>{{data.s_subtitle}}&#160;<mat-icon
                    class="maticon1">call_made</mat-icon>
            </div>

            <div style="margin-bottom: 10px;padding-top: 5px;">
                <div *ngFor="let subdata of data.list_detail">
                    <div fxLayout="row" fxLayoutAlign="start start" class="innertext">
                        <mat-icon class="maticon3" *ngIf="subdata.option1 == 'yes'">check</mat-icon>
                        <mat-icon class="maticon4" *ngIf="subdata.option1 == 'no'">clear</mat-icon>
                        <h6 style="margin-bottom: 0px;" [ngStyle.gt-md]="{'font-size': data.subtitle_font }" 
                            [ngStyle.lt-md]="{'font-size': data.subtitle_mobfont}">&#160;&#160;{{subdata.starters}}</h6>
                    </div>
                </div>

            </div>

            <button mat-raised-button class="buttcss" [style.background-color]="data.a_button" (click)="openInNewWindow(data.p_button)"
            [ngStyle.gt-md]="{'font-size': data.button_font }" [ngStyle.lt-md]="{'font-size': data.button_mobfont}">{{data.s_button}}</button>
        </div>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="50" style="border-radius: 20px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
        <div [ngStyle.gt-md]="{'padding': '70px' }" [ngStyle.lt-md]="{'padding': '50px'}" contentAnim [aniName]="item.customField37">
            <h3 [ngStyle.gt-md]="{'font-size': data.Toptitle_font }" [ngStyle.lt-md]="{'font-size': data.Toptitle_mobfont}" [style.color]="data.a_button"><b>{{data.title2}}</b></h3>
            <hr />
            <div class="titlecss"><b [ngStyle.gt-md]="{'font-size': data.title1_font }" [ngStyle.lt-md]="{'font-size': data.title1_mobfont}" >{{data.p_price_annualy | currencyConvert}}</b> <span>&#160;&#160;/
                &#160;Monthly</span></div>
            <hr />
            <div class="titlecss"><b [ngStyle.gt-md]="{'font-size': data.title2_font }" [ngStyle.lt-md]="{'font-size': data.title2_mobfont}">{{data.p_price_life | currencyConvert}}</b> <span>&#160;&#160;/
                &#160;Yearly</span></div>
            <hr />

            <div style="margin-top: 15px;margin-bottom: 10px;color: gray;" [ngStyle.gt-md]="{'font-size': data.subtitle_font }" [ngStyle.lt-md]="{'font-size': data.subtitle_mobfont}">
                <mat-icon class="maticon2">add</mat-icon>{{data.p_subtitle}}&#160;<mat-icon
                    class="maticon1">call_made</mat-icon>
            </div>

            <div style="margin-bottom: 10px;padding-top: 5px;">
                <div *ngFor="let subdatas of data.list_detail">
                    <div fxLayout="row" fxLayoutAlign="start start" class="innertext">
                        <mat-icon class="maticon3" *ngIf="subdatas.option2 == 'yes'">check</mat-icon>
                        <mat-icon class="maticon4" *ngIf="subdatas.option2 == 'no'">clear</mat-icon>
                        <h6 style="margin-bottom: 0px;" [ngStyle.gt-md]="{'font-size': data.subtitle_font }" 
                            [ngStyle.lt-md]="{'font-size': data.subtitle_mobfont}">&#160;&#160;{{subdatas.professions}}</h6>
                    </div>
                </div>

            </div>

            <button mat-raised-button class="buttcss" [style.background-color]="data.a_button" (click)="openInNewWindow(data.p_button)"
            [ngStyle.gt-md]="{'font-size': data.button_font }" [ngStyle.lt-md]="{'font-size': data.button_mobfont}">{{data.s_button}}</button>
        </div>

    </div>

</div>