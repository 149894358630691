import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { CurrencyConversionService } from 'src/app/services/currency-conversion.service';
import { PixelService } from 'src/app/services/pixel.service';
import { ShareChatTrackingService } from 'src/app/services/share-chat-tracking.service';
import { UserService } from 'src/app/services/user.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import { CommonSignInComponent } from 'src/app/shared/CommonSignIn/CommonSignIn.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-top-selling-layout-product-child',
  templateUrl: './top-selling-layout-product-child.component.html',
  styleUrls: ['./top-selling-layout-product-child.component.scss']
})
export class TopSellingLayoutProductChildComponent {


  public LogoSliderConfig: any;

  @Input() item: any;

  LogoSlider = {
    ...sliderOpt,
    nav: false,
    dots: false,
    items: 3,
    margin: 20,
    loop: false,
    autoHeight: true,
    autoplay: true,

    responsive: {
      767: {
        items: 5
      },
      576: {
        items: 4
      },
      480: {
        items: 3
      },
      0: {
        items: 2
      }
    }
  }


  @Input() product: any;
  @Input() type: any;
  @Input() showCartIcon: any;
  @Input() data: any;
  @Input() height: any;
  public bigImage = environment.ImageAPI1;
  @Input() name: any;

  @Input() width: any;

  @Input() heightMobile: any;

  @Input() widthMobile: any;

  @Input() showCartIncremented: any;

  @Input() showConfigurableDropdown: any;

  @Input() index: any;

  displayMrpPrice: any = 0;

  @Input() imageType: any;

  public serverPath = environment.ImageAPI;

  public serverPath7 = environment.commonImageApi + "catalog/product/";

  commonImageApi = environment.commonImageApiNew;

  countryCode: string;
  dropDownValue = new UntypedFormControl('');
  configurationDropDownList = new Map<any, any>();
  configurableProductDropdown: any = {};
  configurableProductActive: boolean = false;
  configurableProductId: any = 0;
  configProduct: any = {};
  configProductSelected: any = {};
  price: any;
  specialPrice: any;
  maintainStockIndConfProduct: any;
  manageImageSeperately: any;
  managePriceSeperately: any;
  manageNameSeperately: any;
  productIdAddedInCart: any = {};
  qty: any;
  manageStock: any;
  isInStock: any;
  imageList: any = [];
  averageRating: any;
  calculateAvgRating: any;
  customProductElament: any = [];
  encryptedProductName: string;
  discountPercentage: any;
  public image: any;
  description: any;
  configProductId: any = 0;
  configurableProductObject: any;

  prescriptionRequired: boolean = false;
  originalImage: any;
  showNewConfig: boolean = false;
  units: string;
  showExclusivePrice: any;
  entityId: any;
  config: any;
  variant1Value: any;
  variant1Name: string;
  variant2Value: string;
  variant2Name: string;
  disablePrice: any = 1;
  displayNameFully: boolean = false;
  showAnimation: boolean = false;
  subimage: any;
  b2bPricingEnabled: any;
  productNameCenter: any = 0;
  inclTaxEnabled: any = 0;
  productPriceCenter: boolean = false;
  outofstockEnabled: any = 0;
  detailPageProductHeight: any;
  detailPageProductWeight: any;
  categoryPageProductNameHeight: any;
  categoryPageProductNameMobHeight: any;

  product_image: any;
  public ImageSrc: string;
  imageListArray: any = [];
  productLayoutType: any = 0;
  constructor(
    private commonService: CommonService,
    private router: Router,
    private userService: UserService,
    public cartServices: CartService,
    private dialog: MatDialog,
    private pixelService: PixelService,
    private shareChatTrackingService: ShareChatTrackingService,
    private currencyConversionService: CurrencyConversionService,
    public snackBar: MatSnackBar,
    private wishlistService: WishlistService,
  ) {

  }
  list: any = [];




  ChangeVariantsImage(image) {
    this.ImageSrc = image;
    this.image = image;
    if (this.image.includes('http')) {

      let array = [];
      array = this.image.split('http');

      this.image = "http" + array[1];

    }
    else if (this.image.includes('/medicineimages')) {

      let array = [];
      array = this.image.split('/medicineimages');
      this.image = environment.commonImageApiNew + 'medicineimages/' + array[1]


    }

    else if (this.image.includes('medicineimages')) {

      let array = [];
      array = this.image.split('medicineimages');
      this.image = environment.commonImageApiNew + 'medicineimages/' + array[1]


    }
    else if (this.image.includes('/commonimages')) {

      let array = [];
      array = this.image.split('/commonimages');
      this.image = this.serverPath7 + 'commonimages/' + array[1]


    } else {
      this.image = this.serverPath + this.image

    }
  }

  slickModalConfig: any;

  ngOnInit(): void {

    this.slickModalConfig = {
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    };

    if (this.name == undefined) {
      this.name = '';
    }

    if (this.product['images'] != null) {
      this.imageListArray = this.product['images'];

      this.ImageSrc = this.product['images'][0];
    } else {
      this.imageListArray = [];
    }

    this.configProductSelected = this.product; ``
    this.config = this.product
    this.entityId = this.product['entityId'];
    this.productIdAddedInCart = this.product;
    this.serverPath = this.height == 200 ? environment.ImageAPI : environment.ImageAPI1;
    this.configurableProductObject = this.product;
    this.configProductId = 0;
    this.configurationDropDownList = new Map<any, any>();
    //this.disablePrice=this.data['disablePrice']
    this.configurableProductDropdown = {}
    this.name = this.configurableProductObject.name;
    this.description = this.product['productDescription']
    this.price = this.product['price']
    this.specialPrice = this.product['specialPrice']
    this.showExclusivePrice = this.data['showExclusivePrice']
    this.productNameCenter = this.data['productNameCenter']
    this.inclTaxEnabled = this.data['inclTaxEnabled']
    this.productPriceCenter = this.data['productPriceCenter']
    this.categoryPageProductNameHeight = this.data['categoryPageProductNameHeight']
    this.categoryPageProductNameMobHeight = this.data['categoryPageProductNameMobHeight']
    this.displayMrpPrice = this.data['displayMrpPrice']

    this.height = this.data['productsImageHeight']
    this.width = this.data['productsImageWidth']
    this.heightMobile = this.data['productsImageHeightMob']
    this.widthMobile = this.data['productsImageWidthMob']
    this.productLayoutType = this.data['productLayoutType']

    if (this.data['displayNameFully'] != null) {

      if (this.data['displayNameFully'] == "1") {
        this.displayNameFully = true;
      }

    }

    if (this.data['outofstockEnabled'] != null) {

      if (this.data['outofstockEnabled'] == "1") {
        this.outofstockEnabled = true;
      }

    }

    if (this.data['showAnimation'] != null) {

      if (this.data['showAnimation'] == "1") {
        this.showAnimation = true;
      }

    }
    if (this.showExclusivePrice == 1) {
      if (this.product['isCustomizedValue'] != null) {
        if (this.product['isCustomizedValue']
        ['specialPriceWithoutTaxamount'] != null) {
          this.specialPrice = this.product['isCustomizedValue']
          ['specialPriceWithoutTaxamount'];
        }
        if (this.product['isCustomizedValue']
        ['priceWithoutTaxamount'] != null) {
          this.price = this.product['isCustomizedValue']
          ['priceWithoutTaxamount'];
        }
      }
    }
    this.averageRating = this.product['averageRating'];
    this.calculateAvgRating = this.product['calculateAvgRating'];
    this.image = null;
    this.imageList = [];
    this.imageList = this.product['images'];

    // if(this.imageList[1]!=null){

    // }else{
    //   this.list.splice(1, 1);
    this.discountPercentage = this.product['discountPercentage'];
    if (this.product['isCustomizedValue'] != null) {
      this.b2bPricingEnabled = localStorage.getItem(Constant.B2B_USER) == null ? 0 : localStorage.getItem(Constant.B2B_USER);

      if (this.b2bPricingEnabled == 1) {
        if (this.product['isCustomizedValue']['Whole Sale Price'] != undefined) {

          this.price = this.product['isCustomizedValue']['Whole Sale Price'];


          this.specialPrice = this.product['isCustomizedValue']['Whole Sale Discount Price'];


          if (this.price == 0.0) {
            this.price = this.product['price']
            this.specialPrice = this.product['specialPrice']
            this.discountPercentage = this.product['discountPercentage'];
          } else {
            if (this.product['isCustomizedValue']['Whole Sale Discount Percentage'] != undefined) {
              this.discountPercentage = this.product['isCustomizedValue']['Whole Sale Discount Percentage']
            }
          }
        }

      }

      // console.log(this.name);


    }




    let object = {

      width: '300',
      height: '300',
      url: this.image,
      pivot: {
        related_id: '76',
        upload_file_id: 12321
      }
    }

    this.list.push(object);

    let object1 = {

      width: '300',
      height: '300',
      url: this.subimage,
      pivot: {
        related_id: '76',
        upload_file_id: 12321
      }
    }

    this.list.push(object1);
    if (this.imageList != null) {
      if (this.imageList[0] != null) {

        if (this.imageList[0] != '') {
          this.image = this.imageList[0];


          if (this.image != null) {
            this.image = this.image.replace("///", "/");

            if (this.image.includes('http')) {

              let array = [];
              array = this.image.split('http');

              this.image = "http" + array[1];

            }
            else if (this.image.includes('/medicineimages')) {

              let array = [];
              array = this.image.split('/medicineimages');
              this.image = environment.commonImageApiNew + 'medicineimages/' + array[1]


            }

            else if (this.image.includes('medicineimages')) {

              let array = [];
              array = this.image.split('medicineimages');
              this.image = environment.commonImageApiNew + 'medicineimages/' + array[1]


            }
            else if (this.image.includes('/commonimages')) {

              let array = [];
              array = this.image.split('/commonimages');
              this.image = this.serverPath7 + 'commonimages/' + array[1]


            } else {
              this.image = this.serverPath + this.image

            }
          }
          if (this.imageList[1] != undefined) {
            this.subimage = this.imageList[1];
            if (this.subimage != null) {
              this.subimage = this.subimage.replace("///", "/");

              if (this.subimage.includes('http')) {

                let array = [];
                array = this.subimage.split('http');

                this.subimage = "http" + array[1];

              }
              else if (this.subimage.includes('/medicineimages')) {

                let array = [];
                array = this.subimage.split('/medicineimages');
                this.subimage = environment.commonImageApiNew + 'medicineimages/' + array[1]


              }
              else if (this.image.includes('medicineimages')) {

                let array = [];
                array = this.subimage.split('medicineimages');
                this.subimage = environment.commonImageApiNew + 'medicineimages/' + array[1]


              }
              else if (this.subimage.includes('/commonimages')) {

                let array = [];
                array = this.subimage.split('/commonimages');
                this.subimage = this.serverPath7 + 'commonimages/' + array[1]


              }

              else {
                this.subimage = this.serverPath + this.subimage
              }

            }

          } else {
            this.subimage = this.image;
          }

        } else {
          this.image = "assets/images/products/placeholder.jpg";
          this.subimage = "assets/images/products/placeholder.jpg";
        }

      } else {

        this.image = "assets/images/products/placeholder.jpg";
        this.subimage = "assets/images/products/placeholder.jpg";
      }
    } else {

      this.image = "assets/images/products/placeholder.jpg";
      this.subimage = "assets/images/products/placeholder.jpg";
    }


    this.list[0].url = this.image;

    this.list[1].url = this.subimage;

    this.originalImage = this.product['productImageUrl'];



    this.qty = this.product['qty'];
    this.manageStock = this.product['manageStock'];
    this.isInStock = this.product['isInStock'];


    if (this.product['units'] == 'sqft') {
      this.units = '/sqft'
    }

    if (this.product['isCustomizedValue'] != null) {

      if (this.product['isCustomizedValue']['Prescription Required'] != null && this.product['isCustomizedValue']['Prescription Required'] != undefined && this.product['isCustomizedValue']['Prescription Required'] != '') {

        this.prescriptionRequired = this.product['isCustomizedValue']['Prescription Required'] == 1 ? true : false;
      }

      if (this.product['isCustomizedValue']['Prescription_Required'] != null && this.product['isCustomizedValue']['Prescription_Required']
        != undefined && this.product['isCustomizedValue']['Prescription_Required'] != '') {

        this.prescriptionRequired = this.product['isCustomizedValue']['Prescription_Required'] == 1 ? true : false;
      }


    }
    if ((this.product['type'] == "Configurable" || this.product['type'] == "Variants")

      && this.product['configurableProductDropdown'] != null) {


      this.configurableProductActive = true;
      this.configurableProductDropdown = this.product['configurableProductDropdown'];
      this.maintainStockIndConfProduct = this.product['maintainStockIndConfProduct'];
      this.manageImageSeperately = this.product['maintainImageIndConfProduct'];
      this.managePriceSeperately = this.product['maintainPriceIndConfProduct'];

      if (this.product['isCustomizedValue'] != null) {
        if (this.product['isCustomizedValue']
        ['manageNameSeperately'] != null) {
          this.manageNameSeperately = this.product['isCustomizedValue']['manageNameSeperately'];

        }
      } else {

        this.manageNameSeperately = 0;
      }


      this.configProductId = this.product['entityId']
      let i = 0;


      if (this.configurableProductDropdown['new'] != undefined) {

        this.configurationDropDownList = JSON.parse(this.configurableProductDropdown['new']);
        this.configProduct = this.product['configProduct'];
        this.configurableProductId = this.configurationDropDownList[this.firstIndex]
        ['recursuive'][this.secondIndex].productId;
        this.configProductSelected = JSON.parse(this.configProduct[this.configurableProductId]);
        this.setConfigProductItem();
        this.showNewConfig = true;
      } else {
        delete this.configurableProductDropdown['configAttributeType']

        for (let key of Object.keys(this.configurableProductDropdown)) {
          if (i == 0) {
            let value = this.configurableProductDropdown[key];
            this.configProduct = this.product['configProduct'];
            this.variant1Name = key;
            this.getConfigProductId(value);
            // this.dropDownValue.patchValue(value);
            // this.configurableProductId = value.split("#")[1];

            //

            // this.configProductSelected = JSON.parse(this.product['configProduct'][this.configurableProductId]);

          }

          let valueList = [];
          valueList = this.configurableProductDropdown[key].split(',')


          this.configurationDropDownList.set(key, valueList)
          i++;
        }
      }



    }
    if (this.specialPrice == undefined) {
      this.specialPrice = 0.0;
    }


  }

  // productShow(product) {
  //   if (this.configurableProductObject['type'] == "Configurable"
  //    && this.configurableProductObject['configurableProductDropdown'] != null) {
  //     this.commonService.productNavigation(this.config  )
  //   } else {

  //     if (this.router.url.includes('/product/')) {


  //       this.commonService.productNavigations(product)



  //     } else {
  //       this.commonService.productNavigation(this.config)
  //     }

  //   }

  // }

  productShow(product) {
    if ((this.configurableProductObject['type'] == "Configurable"  ||
    this.configurableProductObject['type'] == "Variants Subscription" ||
      this.configurableProductObject['type'] == "Variants")
      && this.configurableProductObject['configurableProductDropdown']
      != null) {

      this.configurableProductObject.configId = this.configurableProductId;
      this.commonService.productNavigation(this.configurableProductObject)
    } else {
      this.commonService.productNavigation(product)
    }

  }


  indexConfig: any = 0;
  firstIndex: any = 0;
  secondIndex: any = 0;

  getConfigProductIdNew(event, i, value, index) {
    if (event.isUserInput != false) {
      this[index] = i;
      this.configurableProductId = this.configurationDropDownList[this.firstIndex]
      ['recursuive'][this.secondIndex].productId;
      this.configProductSelected = JSON.parse(this.configProduct[this.configurableProductId]);
      this.setConfigProductItem();
    }
  }

  getConfigProductId(event) {

    //  let value = event.split("#")[0];
    if (event.includes(",")) {
      event = event.split(",")[0];
    }
    this.configurableProductId = event.split("#")[1];

    if (this.configurableProductId.includes(",")) {
      this.configurableProductId = this.configurableProductId.split(",")[0];
    }

    this.configProductSelected = JSON.parse(this.configProduct[this.configurableProductId]);

    this.product = this.configProductSelected;
    this.dropDownValue.patchValue(event);

    this.variant1Value = event.split("#")[0];


    this.variant2Value = ''
    this.variant2Name = ''
    this.setConfigProductItem();




  }


  setConfigProductItem() {

    if (this.manageNameSeperately == 1) {
      this.name = this.configProductSelected['name'];
    }


    if (this.managePriceSeperately == 1) {
      this.price = this.configProductSelected['price'];
      this.specialPrice = this.configProductSelected['specialPrice'];
      this.discountPercentage = this.configProductSelected['discountPercentage'];
      if (this.showExclusivePrice == undefined) {
        this.showExclusivePrice = 0;
      }
      if (this.showExclusivePrice == 1) {
        if (this.configProductSelected['isCustomizedValue'] != null) {
          if (this.configProductSelected['isCustomizedValue']
          ['specialPriceWithoutTaxamount'] != null) {
            this.specialPrice = this.configProductSelected['isCustomizedValue']
            ['specialPriceWithoutTaxamount'];
          }
          if (this.configProductSelected['isCustomizedValue']
          ['priceWithoutTaxamount'] != null) {
            this.price = this.configProductSelected['isCustomizedValue']
            ['priceWithoutTaxamount'];
          }
        }
      }
      if (this.configProductSelected['isCustomizedValue'] != null) {
        this.b2bPricingEnabled = localStorage.getItem(Constant.B2B_USER) == null ? 0 : localStorage.getItem(Constant.B2B_USER);
        if (this.b2bPricingEnabled == 0) {
          this.price = this.configProductSelected['price'];
          this.specialPrice = this.configProductSelected['specialPrice'];
        } else {

          if (this.configProductSelected['isCustomizedValue']['Whole Sale Price'] != undefined) {


            this.price = this.configProductSelected['isCustomizedValue']['Whole Sale Price'];
            this.specialPrice = this.configProductSelected['isCustomizedValue']['Whole Sale Discount Price'];
            if (this.price == 0.0) {
              this.price = this.configProductSelected['price'];
              this.specialPrice = this.configProductSelected['specialPrice'];

            } else {

              if (this.configProductSelected['isCustomizedValue']['Whole Sale Discount Percentage'] != null) {
                this.discountPercentage = parseFloat(this.configProductSelected['isCustomizedValue']['Whole Sale Discount Percentage']);
              }
            }
          }
        }
      }
    }

    if (this.product['type'] == "Variants") {
      if (this.configProductSelected['isCustomizedValue'] != null) {

        if (this.configProductSelected['isCustomizedValue']['Prescription Required']
          != null && this.product['isCustomizedValue']['Prescription Required'] != undefined &&
          this.configProductSelected['isCustomizedValue']['Prescription Required'] != '') {

          this.prescriptionRequired = this.configProductSelected['isCustomizedValue']['Prescription Required'] == 1 ? true : false;
        }
      }

    }


    let orginalProduct = this.productIdAddedInCart;
    this.productIdAddedInCart = this.configProductSelected;
    if (this.maintainStockIndConfProduct == 1) {
      this.qty = this.configProductSelected['qty'];
      this.manageStock = this.configProductSelected['manageStock'];
      this.isInStock = this.configProductSelected['isInStock'];
      this.productIdAddedInCart = this.configProductSelected;
      this.averageRating = this.configProductSelected['averageRating'];
      this.calculateAvgRating = this.configProductSelected['calculateAvgRating'];

    } else {
      this.productIdAddedInCart.qty = orginalProduct['qty'];
      this.productIdAddedInCart.manageStock = orginalProduct['manageStock'];
      this.productIdAddedInCart.isInStock = orginalProduct['isInStock'];
      this.productIdAddedInCart = orginalProduct;
      this.productIdAddedInCart.averageRating = orginalProduct['averageRating'];
      this.productIdAddedInCart.calculateAvgRating = orginalProduct['calculateAvgRating'];
    }

    if (this.manageImageSeperately == 1) {


      if (this.configProductSelected['productImageUrl'] != 'no_selection') {
        this.image = this.serverPath + "/" + this.configProductSelected['productImageUrl'];
        this.originalImage = this.configProductSelected['productImageUrl'];
      } else {
        this.image = this.configProductSelected['productImageUrl'];
        this.originalImage = this.configProductSelected['productImageUrl'];

      }
      this.imageListArray = this.configProductSelected['images'];
      this.imageList = this.configProductSelected['images'];


      if (this.imageList[0] != '') {
        this.image = this.imageList[0];
        this.ImageSrc = this.configProductSelected['images'][0];

        if (this.image != null) {
          this.image = this.image.replace("///", "/");

          if (this.image.includes('http')) {

            let array = [];
            array = this.image.split('http');

            this.image = "http" + array[1];

          }
          else if (this.image.includes('/medicineimages')) {

            let array = [];
            array = this.image.split('/medicineimages');
            this.image = environment.commonImageApiNew + 'medicineimages/' + array[1]


          }
          else if (this.image.includes('medicineimages')) {

            let array = [];
            array = this.image.split('medicineimages');
            this.image = environment.commonImageApiNew + 'medicineimages/' + array[1]


          }
          else if (this.image.includes('/commonimages')) {

            let array = [];
            array = this.image.split('/commonimages');
            this.image = this.serverPath7 + 'commonimages/' + array[1]


          } else {
            this.image = this.serverPath + this.image

          }
        }
        if (this.imageList[1] != undefined) {
          this.subimage = this.imageList[1];
          if (this.subimage != null) {
            this.subimage = this.subimage.replace("///", "/");

            if (this.subimage.includes('http')) {

              let array = [];
              array = this.subimage.split('http');

              this.subimage = "http" + array[1];

            }
            else if (this.subimage.includes('/medicineimages')) {

              let array = [];
              array = this.subimage.split('/medicineimages');
              this.subimage = environment.commonImageApiNew + 'medicineimages/' + array[1]


            }
            else if (this.image.includes('medicineimages')) {

              let array = [];
              array = this.subimage.split('medicineimages');
              this.subimage = environment.commonImageApiNew + 'medicineimages/' + array[1]


            }

            else if (this.subimage.includes('/commonimages')) {

              let array = [];
              array = this.subimage.split('/commonimages');
              this.subimage = this.serverPath7 + 'commonimages/' + array[1]


            } else {
              this.subimage = this.serverPath + this.subimage
            }

          }

        } else {
          this.subimage = this.image;
        }

      } else {
        this.image = "assets/images/products/placeholder.jpg";
        this.subimage = "assets/images/products/placeholder.jpg";
      }



      this.list[0].url = this.image;
      this.list[1].url = this.subimage;



    }


  }
  //cart icon

  authourized: string;
  page1: string;
  @Input() configproduct: any = [];
  productArray: any;
  @Input() productOption: any[];
  variantList: any = [];
  spinner: boolean = false;
  public values: any;
  qtyFormControl = new UntypedFormControl(0);
  wishMapDisplay: boolean = false;
  wishMap = this.cartServices.getWishListValue();

  public addToCart(product, type, value) {



    if (this.authourized == "" || this.authourized == undefined) {
      this.authourized = this.userService.getAuthenticatedUser();
    }

    if (this.authourized || this.data['guestLogin'] == 1) {
      this.addToCartProduct(product, type, value,);
    } else {
      this.page1 = "/cart";
      this.loginModel();
      this.productArray = {
        product: product,
        type: type,
      };
    }

  }

  addToCartProduct(product, type, value) {


    let entityId = 0;
    if ((this.configproduct.type == 'Configurable'
      || this.configproduct.type == 'Variants')
      && this.configproduct.configurableProductDropdown != null) {
      if (this.configproduct.entityId != product.entityId) {
        let object = {
          productId: this.configproduct.entityId,
          qty: value,
          operationType: "New",
        }
        this.productOption = [];
        this.variantList.push(object);
      }

    }
    this.spinner = true;
    let currency: any = localStorage.getItem('currency');
    this.pixelService.track('AddToCart', {
      content_type: "product",
      content_ids: [product.sku],  // Item SKUs
      value: product.price,
      content_name: product.name,
      currency: currency
    });

    if (this.data['shareChat'] != null) {
      let eventName = 'Add to cart';
      if (type != 'cart') {
        eventName = 'Initiate checkout';
      }
      const eventType = 'pre_defined';
      const customProps = {
        content_type: "product",
        content_ids: [product.sku],  // Item SKUs
        value: product.price,
        content_name: product.name,
        currency: currency
      }
      this.shareChatTrackingService.sendCustomEvent(eventName, eventType, customProps);
    }

    let currencyFrom = this.currencyConversionService.getCountryCode();
    if (currencyFrom == null) {
      currencyFrom = "INR";
    }
    this.authourized = this.userService.getAuthenticatedUser();
    let cart = {
      customerId: 0,
      itemsQty: 1,
      currencyCode: currencyFrom,
      operationType: "New",
      remoteIp: "192.168.5.99",
      userAuthenticated: "",
      salesFlatQuoteItem: [
        {
          productId: product.entityId,
          qty: value,
          operationType: "New",
          productType: this.configproduct.type,
          subChild: this.variantList,
          manageStockSepConfig: this.configproduct.maintainStockIndConfProduct,
          manageImageSepConfig: this.configproduct.manageImageSepConfig,
          managePriceSepConfig: this.configproduct.maintainPriceIndConfProduct
        },
      ],
    };
    this.cart(cart, type, value, product);
  }


  cart(cart, type, value, product) {
    this.cartServices.addCartItem(cart).subscribe(
      (response) => {
        if (response["status_code"] == Constant.RESPONSE_SUCCESS) {

          localStorage.setItem(Constant['CART_ID'], this.commonService.getEncryptedId(response['data']['entityId']))
          if (type != "buyNow") {
            if (window.innerWidth < 850) {
              this.snackBar.open(response["status_message"], "×", {
                panelClass: "success",
                verticalPosition: "top",
                duration: 3000,
              });
            } else {


              this.snackBar.open(response["status_message"], "×", {
                panelClass: "success",
                verticalPosition: "bottom",
                duration: 3000,
              });

            }

            let authourized = this.userService.getAuthenticatedUser();
            let guestLoginEnabled = localStorage.getItem(Constant['GUEST_LOGIN'])
            if (authourized || guestLoginEnabled == "1") {
              let cartId = localStorage.getItem(Constant['CART_ID']);
              if (cartId != null) {

                if (this.values == undefined) {
                  this.values = this.cartServices.getCartValue();
                }
              }
            }

            else if (this.values.size == 0) {
              this.values = this.cartServices.getCartValue();
            }



            this.values = this.cartServices.getCartValue();
            this.cartServices.responseCache1 = new Map();
            if (this.values == undefined) {

              let object = {
                "qty": value,
                "stockInHand": 0
              }
              // this.values = new Map<any, any>();
              // this.values.set(
              //   this.product['entityId'],
              //   object
              // );
              const convMap = {};

              convMap[this.product['entityId']] = object;
              this.values = convMap;

              this.cartServices.setCartValue(this.values);
              this.qty = this.values[this.product["entityId"]]["qty"];
              this.qtyFormControl.patchValue(this.qty)
            } else {
              if (this.values[this.product['entityId']] == undefined) {

                let data = this.values;
                let object = {
                  "qty": value,
                  "stockInHand": 0
                }
                data[this.product["entityId"]] = object;

                this.values = data;

                let encryptedValue = btoa(JSON.stringify(this.values));

                this.userService.storeProductCartCount(encryptedValue);
              } else {
                this.values[this.product["entityId"]]["qty"] =
                  this.values[this.product["entityId"]]["qty"] + value;
              }


              this.cartServices.setCartValue(this.values);

              this.qty = this.values[this.product["entityId"]]["qty"];





              if (this.qty == undefined) {
                this.qty = 0;
              }
              this.qtyFormControl.patchValue(this.qty)
            }



          } else {
            if (this.values == undefined) {

              let object = {
                "qty": value,
                "stockInHand": 0
              }
              // this.values = new Map<any, any>();
              // this.values.set(
              //   this.product['entityId'],
              //   object
              // );
              const convMap = {};

              convMap[this.product['entityId']] = object;
              this.values = convMap;

              this.cartServices.setCartValue(this.values);
              this.qty = this.values[this.product["entityId"]]["qty"];
              this.qtyFormControl.patchValue(this.qty)
            }
            if (this.values[this.product['entityId']] == undefined) {
              this.values[this.product["entityId"]] = value;
              let encryptedValue = btoa(JSON.stringify(this.values));

              this.userService.storeProductCartCount(encryptedValue);
            } else {
              this.values[this.product["entityId"]] =
                this.values[this.product["entityId"]] + value;
            }


            this.cartServices.setCartValue(this.values);

            this.qty = this.values[this.product["entityId"]];
            localStorage.removeItem(Constant.CART_COUNT)
            this.cartServices.responseCache1 = new Map();

            // if(this.product.)

            if (this.product['isCustomizedValue'] != null) {

              if (this.product['isCustomizedValue']['Prescription Required']
                != null && this.product['isCustomizedValue']['Prescription Required']
                != undefined && this.product['isCustomizedValue']['Prescription Required']
                != '') {

                let value = this.product['isCustomizedValue']['Prescription Required']
                  == 1 ? true : false;



                if (value) {
                  this.router.navigate(['/checkout/type'])
                } else {
                  this.router.navigate(["/checkout"]);
                }

              } else if (this.product['isCustomizedValue']['Prescription_Required']
                != null && this.product['isCustomizedValue']['Prescription_Required']
                != undefined && this.product['isCustomizedValue']['Prescription_Required']
                != '') {

                let value = this.product['isCustomizedValue']['Prescription_Required'] == 1 ? true : false;

                if (value) {
                  this.router.navigate(['/checkout/type'])
                } else {
                  this.router.navigate(["/checkout"]);
                }

              }


              else {
                this.router.navigate(["/checkout"]);
              }




              this.qtyFormControl.patchValue(this.qty)
            } else {
              this.router.navigate(["/checkout"]);
            }
          }

        } else {
          this.snackBar.open(response["status_message"], "×", {
            panelClass: "error",
            verticalPosition: "bottom",
            duration: 3000,
          });
        }
        this.spinner = false;
        this.cartServices.getCount();
      },
      (err) => { this.spinner = false; }
    );

  }


  loginModel() {
    if (window.innerWidth > 768) {
      let dialogRef = this.dialog.open(CommonSignInComponent, {
        width: "790px",
        data: { page: this.page1 },
      });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe((result) => {
        if (result["event"] != "close") {
          if (this.page1 == "/account/wishlist" && result["event"] != "close") {
            this.addWishListItem(this.productArray.product);
          } else if (this.page1 == "/cart" && result["event"] != "close") {
            this.addToCartProduct(
              this.productArray.product,
              this.productArray.type,
              1
            );
          }
          this.router.navigate([this.page1]);
        }
      });
    } else {
      let dialogRef = this.dialog.open(CommonSignInComponent, {
        minWidth: "100vw",
        height: "100vh",
        data: { page: this.page1 },
      });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe((result) => {
        if (result["event"] != "close") {
          if (this.page1 == "/account/wishlist" && result["event"] != "close") {
            this.addWishListItem(this.productArray.product);
          } else if (this.page1 == "/cart" && result["event"] != "close") {
            this.addToCartProduct(
              this.productArray.product,
              this.productArray.type,
              1
            );
          }
          this.router.navigate([this.page1]);
        }
      });
    }
  }

  addWishListItem(product) {
    let currencyFrom = this.currencyConversionService.getCountryCode();
    if (currencyFrom == null) {
      currencyFrom = "INR";
    }
    this.authourized = this.userService.getAuthenticatedUser();
    let wish = {
      customerId: 0,
      itemsQty: 1,
      currencyCode: currencyFrom,
      operationType: "New",
      remoteIp: "192.168.5.99",
      wishlistItem: [
        {
          productId: product.entityId,
          qty: 1.0,
          operationType: "New",
          storeId: 1,
          wishlistItemOption: {
            productId: this.configproduct.entityId,
            code: "info_buyRequest",
            value: this.configproduct.type

          },
        },
      ],
    };

    this.wishlistService.addWishlist(wish).subscribe(
      (response) => {
        if (response["status_code"] == Constant.RESPONSE_SUCCESS) {
          this.snackBar.open(response["status_message"], "×", {
            panelClass: "success",
            verticalPosition: "bottom",
            duration: 3000,
          });


          let values = this.cartServices.getWishListValue();

          if (values == undefined) {

            let object = {
              "productId": this.product['entityId'],
              "stockInHand": 0
            }

            const convMap = {};

            convMap[this.product['entityId']] = object;
            values = convMap;


            this.cartServices.setWishValue(values);
            this.wishMap = this.cartServices.getWishListValue();
          } else {
            if (values[this.product['entityId']] == undefined) {

              let data = values;
              let object = {
                "productId": this.product['entityId'],
                "stockInHand": 0
              }
              data[this.product["entityId"]] = object;

              values = data;

            }

            this.cartServices.setWishValue(values);
            this.wishMap = this.cartServices.getWishListValue();
            this.wishMapDisplay = true;
          }

        } else {
          this.snackBar.open(response["status_message"], "×", {
            panelClass: "error",
            verticalPosition: "bottom",
            duration: 3000,
          });
          this.wishMapDisplay = true;
        }
      },
      (err) => { }
    );
  }




}


export const sliderOpt = {
  items: 1,
  loop: true,
  margin: 0,
  responsiveClass: true,
  nav: true,
  navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
  dots: true,
  smartSpeed: 400,
  autoplay: false,
  autoplayTimeout: 15000
}