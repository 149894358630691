<div fxLayout="column">
  <div fxLayout="column" *ngIf="img.autoplay==0">
    <video controls (click)="playVideo()" loop="loop" [style.height]="item.height" [ngStyle.lt-md]="{
      'padding-top': i > 0 ? item.categoryId6 == 1 ? '10px' : 
      i == 0 ? '10px' : '0px' : '0px',
      'height': item.heightMob
  }" [ngClass]="item.customField9==2 ? '' : 'collection-banner '"
      fxLayoutAlign="space-evenly stretch">
      <source [src]="serverPath+img.img | safe: 'resourceUrl'" [alt]="img.altImage || 'images'" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>

  <div fxLayout="column" *ngIf="img.autoplay==1">
    <video oncanplay="this.play()" onloadedmetadata="this.muted = true" autoplay loop  playsinline="playsinline"
      [style.height]="item.height" [ngStyle.lt-md]="{
        'padding-top': i > 0 ? item.categoryId6 == 1 ? '10px' : (i == 0 ? '10px' : '0px') : '0px',
        'height': item.heightMob
    }" [ngClass]="item.customField9 == 2 ? '' : 'collection-banner '"
      fxLayoutAlign="space-evenly stretch">
      <source [src]="serverPath + img.img | safe: 'resourceUrl'" [alt]="img.altImage || 'images'" type="video/mp4">
    </video>
  </div>

  <div  style="width: 100%;" fxLayout="column" *ngIf="img.autoplay==2">


    <video 
    oncanplay="this.play()" 
    onloadedmetadata="this.muted = true" 
    autoplay muted loop="loop"
    [ngStyle.lt-md]="{
        'padding-top': i > 0 ? (item.categoryId6 == 1 ? '10px' : (i == 0 ? '10px' : '0px')) : '0px',
        'height': item.heightMob,
        'width': '100%'
    }"
    [ngStyle]="{
      'height': item.height,
     
  }"
    [ngClass]="item.customField9 == 2 ? '' : 'collection-banner'"
    fxLayoutAlign="space-evenly stretch">
    <source [src]="serverPath + img.img | safe: 'resourceUrl'" [alt]="img.altImage || 'images'" type="video/mp4">
    Your browser does not support the video tag.
</video>
  </div>

  <div fxLayout="column">
    <span *ngIf="img.customField1 !== null">
      <span *ngIf="img.customField1.length>0" fxLayoutAlign="space-evenly center" fxLayoutGap="5px"
        class="padding-10">
        <p [style.font-size]="item.customField18"
          [ngStyle.lt-md]="{ 'font-size': item.customField17 }">{{ img.customField1 }}
        </p>
      </span>
    </span>
  </div>

  <div fxLayout="column">
    <div *ngIf="img.customField13!== null">
      <div class="customer " *ngIf="img.customField13==0" fxLayoutAlign="start center">
        <button (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
          [style.color]="'#ffffff'" [style.border-radius]="img.customField11" class="primary"
          mat-flat-button>
          {{img.customField12}}
        </button>
      </div>

      <div class="customer" *ngIf="img.customField13==1" fxLayoutAlign="center center">
        <button (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
          [style.color]="'#ffffff'" [style.border-radius]="img.customField11" class="primary"
          mat-flat-button>
          {{img.customField12}}
        </button>
      </div>

      <div class="customer" *ngIf="img.customField13==2">
        <button fxFlex (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
          [style.color]="'#ffffff'" [style.border-radius]="img.customField11" class="primary"
          mat-flat-button>
          {{img.customField12}}
        </button>
      </div>
    </div>
  </div>
</div>