
<div contentAnim [aniName]="item.customField36" class="shipping-info-container" *ngIf="img.customField33==0">

  <div class="icon-container">
    <img  [src]="serverPath+img.img"
      (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [alt]="img.altImage || 'images'" class="icon">
  </div>
  <div class="text-container">
    <h3 [style.color]="img.customField10" [style.font-size]="item.customField18"
      [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text"
      [ngStyle.lt-md]="{ 'font-size': item.customField17 }"  [innerHTML]="img.customField1"></h3>
    <p [style.color]="img.customField15" [ngStyle]="{'font-family': img.customField16 || 'cursive'}" class="text"
      [innerHTML]="img.customField14"></p>
  </div>
</div>

<div contentAnim [aniName]="item.customField36" class="shipping-info-container1" *ngIf="img.customField33==1">

  <div class="icon-container1">
    <img  [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}" [src]="serverPath+img.img"
      (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [alt]="img.altImage || 'images'" class="icon1">
  </div>
  <div class="text-container padding-10">


    <h3 [style.color]="img.customField10" [style.font-size]="item.customField18"
      [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text"
      [ngStyle.lt-md]="{ 'font-size': item.customField17 }" [innerHTML]="img.customField1"></h3>

    <p [style.color]="img.customField15" [ngStyle]="{'font-family': img.customField16 || 'cursive'}" class="text"
      [innerHTML]="img.customField14"></p>

    <br>

    <div style="display: flex;" *ngIf="img.customField34==1" fxLayoutAlign="center center">
      <p (click)="openInNewWindow(img.customField7)" style="color: #f46c0b;text-decoration: underline;cursor: pointer;">
        Read More </p>&#160;<mat-icon style="color:#f46c0b ;" class="arrowicon">arrow_forward</mat-icon>
    </div>

  </div>
</div>

<div contentAnim [aniName]="item.customField36" *ngIf="img.customField33==2">

  <div fxLayout="row">
    <h3 [style.color]="img.customField10" [style.font-size]="item.customField18"
      [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text padding-10"
      [ngStyle.lt-md]="{ 'font-size': item.customField17 }" [innerHTML]="img.customField1" ></h3>
  </div>
  <div fxLayout="row">
    <div class="icon-container ">
      <img [src]="serverPath+img.img"
        (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [alt]="img.altImage || 'images'" class="icon">
    </div>
    <div class="text-container" fxLayoutAlign="center start">
      <p [style.color]="img.customField15" [ngStyle]="{'font-family': img.customField16 || 'cursive'}" class="text1"
        [innerHTML]="img.customField14"></p>
    </div>
  </div>

</div>


<div contentAnim [aniName]="item.customField36" class="shipping-info-container" *ngIf="img.customField33==3" fxLayoutAlign="start start">
  <div class="icon-container-3 ">
    <img [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}" [src]="serverPath+img.img"
      (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [alt]="img.altImage || 'images'">
  </div>

  <div class="text-container" style="text-align: left;" >

    <h3 [style.color]="img.customField10" [style.font-size]="item.customField18"
      [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text"
      [ngStyle.lt-md]="{ 'font-size': item.customField17 }" [innerHTML]="img.customField1"></h3>


    <span class="title">
      <p [style.color]="img.customField15" [ngStyle]="{'font-family': img.customField16 || 'cursive'}" class="text"
        [innerHTML]="img.customField14"></p>
    </span>

    <div style="display: flex;margin-top: 4px;" *ngIf="img.customField34==1">
      <p (click)="openInNewWindow(img.customField7)" style="color: #f46c0b;text-decoration: underline;cursor: pointer;">
        Read More </p>&#160;<mat-icon class="arrowicon" style="color:#f46c0b ;">arrow_forward</mat-icon>
    </div>

  </div>
</div>



<div contentAnim [aniName]="item.customField36" fxLayout="row" class="iconType1" *ngIf="img.customField33==4" 
[ngStyle]="{ 'margin-left.px': (index % 2 === 0) ? '2' : '15px', 'margin-right.px': (index % 2 !== 0) ? '2' : '15px' }">

  <div fxLayout="column" [style.height]="item.customField11" >
    <div class="padding-10" fxLayoutAlign="start center" fxLayout="row">
      <img fxLayoutAlign="start center"[style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}" [src]="serverPath+img.img"
        (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [alt]="img.altImage || 'images'">
  

        <div class="padding-10">
          <h3 [style.color]="img.customField10" [style.font-size]="item.customField18"
          [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text"
          [ngStyle.lt-md]="{ 'font-size': item.customField17 }" [innerHTML]="img.customField1"></h3>
        </div>
     
    </div>


  <div  fxLayout="row">

    <div class="text-container" style="text-align: left;">
   

      <p class="title" [style.color]="img.customField15" [ngStyle]="{'font-family': img.customField16 || 'cursive'}"
        [innerHTML]="img.customField14"></p>
  
      <br>
  
      <div style="display: flex;" *ngIf="img.customField34==1">
        <p (click)="openInNewWindow(img.customField7)"
          style="color: #f46c0b;text-decoration: underline;cursor: pointer;">Read More </p>&#160;<mat-icon
          class="arrowicon" style="color:#f46c0b ;">arrow_forward</mat-icon>
      </div>
    </div>

  </div>

  </div>



</div>


<div contentAnim [aniName]="item.customField36" *ngIf="img.customField33==5">

  <calsoft-mobile-icon2 [data]="data" [item]="item" [img]="img"></calsoft-mobile-icon2>

</div>

<div fxFlex="100" style="padding: 0px 20px;" contentAnim [aniName]="item.customField36" fxLayout="column" *ngIf="img.customField33==6">

  <div class="overlay1" style="width: 100%;" fxFlex="100" fxLayout="row wrap">
  
  
      <div fxLayout="row" fxLayout.xs="column">

  
        <div fxLayout="column" [fxLayoutAlign]="item.customField29" fxLayoutAlign.xs="center center">
  
          
        <div class="padding-10">
  
        </div>
        
          <img onerror="" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}" [src]="serverPath+img.img"
          (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [alt]="img.altImage || 'images'">
  
        </div>
  
        <div fxLayout="column">
          <div class="text-container paragraph" style="text-align: left; padding-left: 20px;">
            <h3 class="text" style="color: black;" [innerHTML]="img.customField1"></h3>
            <span class="title">
              <p class="text" style="justify-content: center;">
                <calsoft-editor-text [text]="img.customField14" [align]="item.customField34">
                </calsoft-editor-text>
              </p>
            </span>
            <div style="display: flex;margin-top: 4px;" *ngIf="img.customField34==1">
              <p (click)="openInNewWindow(img.customField7)" style="color: #f46c0b;text-decoration: underline;cursor: pointer;">
                Read More </p>&#160;<mat-icon class="arrowicon" style="color:#f46c0b ;">arrow_forward</mat-icon>
            </div>
        
          </div>
  
        </div>
  
      </div>
  
      <div class="padding-10">
  
      </div>
  </div>
  </div>

<div class="iconType" contentAnim [aniName]="item.customField36" *ngIf="img.customField33==7">
  
    <div class="icon-container">
      <img  [src]="serverPath+img.img"
        (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [alt]="img.altImage || 'images'" class="icon">
    </div>
    <div class="text-container">
      <h3 [style.color]="img.customField10" [style.font-size]="item.customField18"
        [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text"
        [ngStyle.lt-md]="{ 'font-size': item.customField17 }" [innerHTML]="img.customField1"></h3>
      <p [style.color]="img.customField15" [ngStyle]="{'font-family': img.customField16 || 'cursive'}" class="text"
        [innerHTML]="img.customField14"></p>
    </div>
</div>

<div class="img-container" contentAnim [aniName]="item.customField36" *ngIf="img.customField33==8">

  <div class="icon-container" fxLayoutAlign="center center">
    <img style="border-top-left-radius: 5px; border-bottom-left-radius: 5px;" 
    [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}"
     [src]="serverPath+img.img" fxLayoutAlign="center center"
      (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [alt]="img.altImage || 'images'"
      class="iconNew">
  </div>

  <div fxLayout="column" class="text-placed" (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)">
    <div class="text-placed">
      <div fxLayout="row">
      <h3 [style.color]="img.customField10" [style.font-size]="item.customField18"
        [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text"
        [ngStyle.lt-md]="{ 'font-size': item.customField17 }" [innerHTML]="img.customField1"></h3>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <p [style.color]="img.customField15" [ngStyle]="{'font-family': img.customField16 || 'cursive'}" class="text"
        [innerHTML]="img.customField14">
      </p>
      <mat-icon *ngIf="img.customField14.length > 0" style="font-size: 20px">arrow_forward</mat-icon>
    </div>

    </div>
    
  </div>
</div>

<div class="iconType9" contentAnim [aniName]="item.customField36" *ngIf="img.customField33==9">
  
  <div class="icon-container">
    <img  [src]="serverPath+img.img"
      (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [alt]="img.altImage || 'images'" class="icon">
  </div>
  <div class="padding-10">

  </div>
  <div class="text-container padding-10">
    <h3 [style.color]="img.customField10" [style.font-size]="item.customField18"
      [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text"
      [ngStyle.lt-md]="{ 'font-size': item.customField17 }" [innerHTML]="img.customField1">
    </h3>
    <p [style.color]="img.customField15" [ngStyle]="{'font-family': img.customField16 || 'cursive'}" class="text"
      [innerHTML]="img.customField14"></p>
  </div>
</div>


<div class="shipping-info-container1" contentAnim [aniName]="item.customField36" *ngIf="img.customField33==10">

  <div fxLayoutAlign="center center"  class="icon-container1">
    <span fxLayoutAlign="space-around stretch"  [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}">
      <img    [src]="serverPath+img.img"
      (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [alt]="img.altImage || 'images'" class="icon1">
    </span>
   
  </div>
  <div  class="text-container">

    <span fxLayoutAlign="center center" [style.color]="img.customField10" [style.font-size]="item.customField18"
      [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text"
      [ngStyle.lt-md]="{ 'font-size': item.customField17 }" [innerHTML]="img.customField1">
    </span  >

    <p fxLayout="column"  fxLayoutAlign="center center" [style.color]="img.customField15"
    [ngStyle.lt-md]="{ 'text-align': 'center' }"
    [ngStyle]="{'font-family': img.customField16 || 'cursive', 'text-align': 'center'}" class="text"
      [innerHTML]="img.customField14"></p> 



    <div style="display: flex;" *ngIf="img.customField34==1" fxLayoutAlign="center center">
      <p (click)="openInNewWindow(img.customField7)" style="color: #f46c0b;text-decoration: underline;cursor: pointer;">
        Read More </p>&#160;<mat-icon style="color:#f46c0b ;" class="arrowicon">arrow_forward</mat-icon>
    </div>


  </div>
  
</div>

<div class="shipping-info-container1" contentAnim [aniName]="item.customField36" *ngIf="img.customField33==14" >
  <div  [ngStyle]="{
    'background-image': img.customField36 == 1 ? 'url(' + serverPath + img.customField37 + ')' : null,
    'background-color': img.customField35,
    'padding': img.customField40,
    'border-radius':img.customField32,
     'width':img.customField30 ,
     'height':img.customField26  }" [ngStyle.lt-md]="{ 'background-image': img.customField36 == 1 ? 'url(' + serverPath + img.customField37 + ')' : null,
     'background-color': img.customField35,
     'padding': img.customField40,
     'border-radius':img.customField32, 'width': img.customField34,  'height':img.customField27  }" >
    <div fxLayoutAlign="center center"  class="icon-container1">
      <span fxLayoutAlign="space-around stretch"  [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}" style="margin-bottom: 10px;">
        <img    [src]="serverPath+img.img"
        (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [alt]="img.altImage || 'images'" class="icon1">
      </span>
     
    </div>
    <div  class="text-container">
  
      <!-- <span fxLayoutAlign="center center" [style.color]="img.customField10" [style.font-size]="item.customField18"
        [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text"
        [ngStyle.lt-md]="{ 'font-size': item.customField17 }" [innerHTML]="img.customField1" style="margin-bottom: 10px;">
      </span  > -->
      <span fxLayoutAlign="center center" [style.color]="img.customField10" [style.font-size]="item.customField18"
      [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text"
      [ngStyle.lt-md]="{ 'font-size': item.customField17 }"  style="margin-bottom: 10px;">
        <calsoft-editor-text [data]="data"  [text]="img.customField1">
        </calsoft-editor-text>
      </span>
      
  
      <p fxLayout="column"  fxLayoutAlign="center center" [style.color]="img.customField15"
      [ngStyle.lt-md]="{ 'text-align': 'center','font-size': img.customField39 }" 

      [ngStyle]="{'font-family': img.customField16 || 'cursive', 'text-align': 'center','font-size': img.customField38}" class="text"
        [innerHTML]="img.customField14"></p> 
  
  
  
      <div style="display: flex;" *ngIf="img.customField34==1" fxLayoutAlign="center center">
        <p (click)="openInNewWindow(img.customField7)" style="color: #f46c0b;text-decoration: underline;cursor: pointer;">
          Read More </p>&#160;<mat-icon style="color:#f46c0b ;" class="arrowicon">arrow_forward</mat-icon>
      </div>
  
  
    </div>
    </div>
  </div>

  <div class="shipping-info-container1" contentAnim [aniName]="item.customField36" *ngIf="img.customField33==15" style="margin-bottom: 10px;">
    <div  [ngStyle]="{
      'background-image': img.customField36 == 1 ? 'url(' + serverPath + img.customField37 + ')' : null,
      'background-color': img.customField35,
      'padding': img.customField40,
      'border-radius':img.customField32,
       'width':img.customField30  ,
       'height':img.customField26  ,
       'outline': img.customField28 + ' solid ' + img.customField29 }" [ngStyle.lt-md]="{   'background-image': img.customField36 == 1 ? 'url(' + serverPath + img.customField37 + ')' : null,
       'background-color': img.customField35,
       'padding': img.customField40,
       'border-radius':img.customField32,'width': img.customField34 ,'height':img.customField27  , 'outline': img.customField28 + ' solid ' + img.customField29 }" >
      <div fxLayoutAlign="center center"  class="icon-container1">
        <span fxLayoutAlign="space-around stretch"  [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}"  style="margin-bottom: 10px;">
          <img    [src]="serverPath+img.img"
          (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [alt]="img.altImage || 'images'" class="icon1">
        </span>
       
      </div>
      <div  class="text-container">
    
        <!-- <span fxLayoutAlign="center center" [style.color]="img.customField10" [style.font-size]="item.customField18"  style="margin-bottom: 10px;"
          [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text"
          [ngStyle.lt-md]="{ 'font-size': item.customField17 }" [innerHTML]="img.customField1">
        </span  > -->
        <span fxLayoutAlign="center center" [style.color]="img.customField10" [style.font-size]="item.customField18"  style="margin-bottom: 10px;"
        [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text"
        [ngStyle.lt-md]="{ 'font-size': item.customField17 }">
        <calsoft-editor-text [data]="data" [text]="img.customField1">
        </calsoft-editor-text>
      </span>
    
      <p fxLayout="column"  
      fxLayoutAlign="center center" 
      [style.color]="sanitizeStyle(img.customField15)"
      [ngStyle.lt-md]="{ 'text-align': 'center', 'font-size': sanitizeStyle(img.customField39) }"
      [ngStyle]="{ 'font-family': sanitizeStyle(img.customField16 || 'cursive'), 'text-align': 'center', 'font-size': sanitizeStyle(img.customField38) }"
      class="text"
      [innerHTML]="sanitizeHtml(img.customField14)">
   </p>
    
    
    
        <div style="display: flex;" *ngIf="img.customField34==1" fxLayoutAlign="center center">
          <p (click)="openInNewWindow(img.customField7)" style="color: #f46c0b;text-decoration: underline;cursor: pointer;">
            Read More </p>&#160;<mat-icon style="color:#f46c0b ;" class="arrowicon">arrow_forward</mat-icon>
        </div>
    
    
      </div>
      </div>
    </div>



<div class="iconType9" contentAnim [aniName]="item.customField36" *ngIf="img.customField33==12">
  
  <!-- <div class="icon-container">
    <img  [src]="serverPath+img.img"
      (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [alt]="img.altImage || 'images'" class="icon">
  </div>
  <div class="padding-10">

  </div> -->
  <div fxLayoutAlign="center center" class="text-container padding-10" fxLayout="column" fxLayoutGap="10px">
    <h3 [style.color]="img.customField10" [style.font-size]="item.customField18"
        [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text"
        [ngStyle.lt-md]="{ 'font-size': item.customField17 }" [innerHTML]="img.customField1">
    </h3>
    <p [style.color]="img.customField15" [ngStyle]="{'font-family': img.customField16 || 'cursive'}" class="text"
        [innerHTML]="img.customField14"></p>
  
    <div fxFlex="1 1 auto"></div> <!-- This pushes the button to the bottom -->
    
    <div fxLayoutAlign="center">
      <button calss="button"  [style.background-color]="item.customField33" mat-raised-button color="primary" (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" >
        {{img.customField25}}
      </button>
    </div>
  </div>
  
</div>

<div contentAnim [aniName]="item.customField36"   class="shipping-info-container3" *ngIf="img.customField33==13">
  <div fxLayout="column" fxLayoutAlign="center center" >
    <div  fxLayoutAlign="center center" class="icon-container2">
      <img [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}"  [src]="serverPath+img.img"
        (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [alt]="img.altImage || 'images'" class="icon2">
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="text-container" *ngIf="img.customField14 || img.customField1">
  
      <h3 [style.color]="img.customField10" [style.font-size]="item.customField18"
      [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text"
      [ngStyle.lt-md]="{ 'font-size': item.customField17 }" [innerHTML]="img.customField1 || '&nbsp;'"></h3>

      <p [style.color]="img.customField15" [ngStyle]="{'font-family': img.customField16 || 'cursive'}" class="text"
      [innerHTML]="img.customField14 || '&nbsp;'"></p>
  
      <br>
  
      <div style="display: flex;" *ngIf="img.customField34==1" fxLayoutAlign="center center">
        <p (click)="openInNewWindow(img.customField7)" style="color: #f46c0b;text-decoration: underline;cursor: pointer;">
          Read More </p>&#160;<mat-icon style="color:#f46c0b ;" class="arrowicon">arrow_forward</mat-icon>
      </div>
  
    </div>
  </div>
  <!-- Image covers the entire card-content container -->
  <div class="loader"></div>
</div>

<div class="iconType10" contentAnim  [aniName]="item.customField36" *ngIf="img.customField33==16">
  <!-- <div class="padding-10">
  
    </div> -->
  <div class="transition" fxLayout="column" fxLayoutAlign="center center"  [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}">
    <div fxLayoutAlign="center center">
      <img  [src]="serverPath+img.img" [style.height]="img.customField26" [style.width]="img.customField27"
        (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [alt]="img.altImage || 'images'" class="icon">
    </div>
    <!-- <div class="padding-10">
  
    </div> -->
    <div fxLayoutAlign="center center" class="text-container padding-10" fxLayout="column" fxLayoutGap="10px">
      <!-- <h3 [style.color]="img.customField10" [style.font-size]="item.customField18"
          [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text" style="text-align:center;"
          [ngStyle.lt-md]="{ 'font-size': item.customField17 }" [innerHTML]="img.customField1">
      </h3> -->
      <span [style.color]="img.customField10" [style.font-size]="item.customField18"
      [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text" 
      [ngStyle.lt-md]="{ 'font-size': item.customField17 }" >
        <calsoft-editor-text  [data]="data" [text]="img.customField1">
        </calsoft-editor-text>
      </span>
      
      <p [style.color]="img.customField15"  style="text-align:center;" [ngStyle.lt-md]="{'text-align': 'center'}" [ngStyle]="{'font-family': img.customField16 || 'cursive'}" class="text"
          [innerHTML]="img.customField14"></p>
    
      <div fxFlex="1 1 auto"></div> <!-- This pushes the button to the bottom -->

      <div fxLayoutAlign="center">
        <button calss="button" [style.border-radius]="item.customField34"  [style.background-color]="item.customField33" mat-raised-button color="primary" (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" >
          {{img.customField25}}
        </button>
      </div>
    </div>
  </div>
  
</div>