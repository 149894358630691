
<div class="lookbook" *ngIf="viewtype==0 && type!=='new1' ">
  <div >
    <div class="lookbook-image-container" (mouseleave)="resetActiveDot()">
      <!-- <img [src]="serverPath+img.img" class="img-fluid w-100" [alt]="img.altImage || 'images'" /> -->
      <calsoft-ng-optimized-image class="img-fluid w-100" [altImage]="img.altImage || 'images'" [image]="serverPath+img.img" [i]="'0'"> </calsoft-ng-optimized-image>


      <div *ngFor="let dot of img.subChild" class="lookbook-dot"
       [style.top]="dot.customField3" [style.left]="dot.customField4">
        <div class="dot-marker" (mouseenter)="setActiveDot(dot)">
          <span>{{dot.customField5}}</span>
        </div>

        <div *ngIf="activeDot === dot" [@fadeInUp] [class.fade-in-up]="isDotInfoVisible"
         class="dot-info">
         
           <calsoft-lookback-sub [data]="data" [item]="item" [img]="dot" ></calsoft-lookback-sub>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="lookbook" *ngIf="viewtype==1 && type!=='new1' ">
    <div class="lookbook-image-container" (mouseleave)="resetActiveDot()">
      <!-- <img [src]="serverPath+img.img" class="img-fluid w-100" [alt]="img.altImage || 'images'" /> -->
      <calsoft-ng-optimized-image class="img-fluid w-100" [altImage]="img.altImage || 'images'" [image]="serverPath+img.img" [i]="'0'"> </calsoft-ng-optimized-image>


      <div *ngFor="let dot of img.subChild" class="lookbook-dot"
       [style.top]="dot.customField3" [style.left]="dot.customField4">
        <div class="dot-marker" (mouseenter)="setActiveDot(dot)">
          <div class="pointer">
            <span><mat-icon style="margin-top: 5px;">add</mat-icon></span>
          </div>
        </div>

        <div *ngIf="activeDot === dot" [@fadeInUp] [class.fade-in-up]="isDotInfoVisible"
         class="dot-info1">
         
           <calsoft-loopbacksub1 [data]="data" [item]="item" [img]="dot" ></calsoft-loopbacksub1>
        </div>
      </div>
    </div>
</div>

<div  class="lookbook" *ngIf="viewtype==2 && type!=='new1' ">
  <div class="lookbook-image-container" (mouseleave)="resetActiveDot()">
    <!-- <img [src]="serverPath+img.img" class="img-fluid w-100" [alt]="img.altImage || 'images'" /> -->
    <calsoft-ng-optimized-image  class="img-fluid w-100" [altImage]="img.altImage || 'images'" [image]="serverPath+img.img" [i]="'0'"> </calsoft-ng-optimized-image>


    <div *ngFor="let dot of img.subChild" class="lookbook-dot"
     [style.top]="dot.customField3" [style.left]="dot.customField4">
      <div class="dot-marker1" (mouseenter)="setActiveDot(dot)" [style.background-color]="dot.customField13">
          <div style="display: flex;padding: 7px;color: white;">
            <mat-icon>{{dot.customField12}}</mat-icon>&#160;
            <h6 [style.color]="dot.customField14">{{dot.customField11}}</h6>
          </div>
        <!-- <div class="pointer">
          <span><mat-icon style="margin-top: 5px;">add</mat-icon></span>
        </div> -->
      </div>

      <div *ngIf="activeDot === dot" [@fadeInUp] [class.fade-in-up]="isDotInfoVisible"
       class="dot-info1">
       
         <!-- <calsoft-loopbacksub1 [data]="data" [item]="item" [img]="dot" ></calsoft-loopbacksub1> -->
      </div>
    </div>
  </div>
</div>

<div  class="lookbook" *ngIf="type=='new1'">

  <div class="lookbook-image-container" (mouseleave)="resetActiveDot()">
    <!-- <img [src]="serverPath+img.img" class="img-fluid w-100" [alt]="img.altImage || 'images'" /> -->
    
    <span fxHide fxShow.gt-md>
      <calsoft-ng-optimized-image1  class="img-fluid w-100" [altImage]="img.altImage || 'images'" [image]="serverPath+img[0].img" [i]="'0'" [height]="item.height" [width]="item.customField2" [heightMob]="item.heightMob"> </calsoft-ng-optimized-image1>
    </span>
  

    <div  fxHide fxShow.gt-md  *ngFor="let dot of img[0].subChild" class="lookbook-dot"
     [style.top]="dot.customField3" [style.left]="dot.customField4">
      <div class="dot-marker1" (mouseenter)="setActiveDot(dot)" [style.background-color]="dot.customField13" contentAnim [aniName]="dot.customField16">
          <div style="display: flex;padding: 10px;color: white;" >
            <mat-icon>{{dot.customField12}}</mat-icon>&#160;
            <h6 [style.color]="dot.customField14" style="font-size: 14px;">{{dot.customField11}}</h6>
          </div>
      </div>

      <div *ngIf="activeDot === dot" [@fadeInUp] [class.fade-in-up]="isDotInfoVisible"
       class="dot-info1">
       
         <!-- <calsoft-loopbacksub1 [data]="data" [item]="item" [img]="dot" ></calsoft-loopbacksub1> -->
      </div>
    </div>
  </div>
</div>
