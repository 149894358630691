<div class="brands-carousel">
    <div *ngIf="layoutType == '0'">
        <div class="swiper-container h-100" [swiper]="config">

            <div class="swiper-wrapper h-100">
                <div *ngFor="let brand of categoryArray" class="swiper-slide">
                    <div class="p-0">
                        <a (click)="goToCategory(brand)" class="brand-item">
                            <!-- <img [attr.data-src]="serverPath+brand.image" [alt]="'images'" class="swiper-lazy"> -->
                            <calsoft-ng-optimized-image [altImage]="'images'" [image]="serverPath+brand.image" [i]="'0'">  </calsoft-ng-optimized-image>
                        </a>
                        <div *ngIf="categoryId7==1"><b>{{ brand.categoryName }}</b></div>

                        <div class="swiper-lazy-preloader"></div>
                    </div>
                </div>
            </div>
            <button mat-icon-button class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
            <button mat-icon-button
                class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button>
        </div>
    </div>
    <div *ngIf="layoutType == '1'">
        <div class="swiper-container h-100" [swiper]="config">
            <div class="swiper-wrapper h-100">
                <div *ngFor="let brand of categoryArray" class="swiper-slide">
                    <div class="p-0">
                        <a (click)="goToCategory(brand)" class="brand-item1">
                            <div class="artist-card" [style.border-color]="item.customField8">
                                <div class="image-wrap" [style.border-color]="item.customField8">
                            <img  [attr.data-src]="serverPath+brand.image" [alt]="'images'" class="swiper-lazy imgview">
                            <calsoft-ng-optimized-image [altImage]="'images'" [image]="serverPath+brand.image" [i]="'0'">  </calsoft-ng-optimized-image>

                                </div>
                            </div>
                        </a>
                        <div *ngIf="categoryId7==1" style="margin-top: 10px;"><b>{{ brand.categoryName }}</b></div>

                        <div class="swiper-lazy-preloader"></div>
                    </div>
                </div>
            </div>
            <button mat-icon-button class="swiper-button-prev1 swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
            <button mat-icon-button
                class="swiper-button-next1 swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button>
        </div>
    </div>

</div>