import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { YoutubeVideoRoutingModule } from './youtube-video-routing.module';
import { YoutubeVideoComponent } from './youtube-video.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PipesModule } from 'src/app/theme/pipes/pipes.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    YoutubeVideoComponent
  ],
  imports: [
    CommonModule,
    YoutubeVideoRoutingModule,
    SharedModule,
    PipesModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports:[
    YoutubeVideoComponent
  ]
})
export class YoutubeVideoModule { }
