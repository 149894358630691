<div fxLayoutGap="2px" [ngClass]="categoryId4 === 0 ? 'border' : 'noclass'">
  <div *ngIf="categoryId5==0" style="padding-bottom: 5px"  >
    <calsoft-common-header-carousel [products]="products" [other]="other"
     [type]="type" [item]="item"  [header]="header" [id]="id"></calsoft-common-header-carousel>

  </div>
  <div *ngIf="categoryId5==1" style="padding-bottom: 5px"  >
   
  </div>

  <div *ngIf="categoryId5==2||categoryId5==3" style="padding-bottom: 5px">
    <div  [style.background-color]="item.customField25" class="commonHeader" fxLayout="row" fxLayoutAlign="space-between center">
      <span style="font-weight: 500;"
      [ngStyle.gt-md]="{'font-size': item.customField39}"
      [ngStyle.lt-md]="{'font-size': item.customField40}"
      [innerHTML]="header | uppercase">
</span>
  </div>
  </div>

  <div *ngIf="products" class="background" class="swiper-container padingtb" [swiper]="config">
    <div fxLayout="row wrap" class="swiper-wrapper h-100">
      <div  *ngFor="let product of categoryProducts" class="swiper-slide">

        <calsoft-common-product-details-carousel
        [urlNavigation]="urlNavigation"
        [data]="data"
        [width]="width" 
        [heightMobile]="heightMobile"
        [widthMobile]="widthMobile"
        [imageType]="imageType"  
         [height]="height"  [showConfigurableDropdown]="showConfigurableDropdown" [showCartIcon]="showCartIcon"  [showCartIncremented]="showCartIncremented" [type]="'carousel'" [product]="product"></calsoft-common-product-details-carousel>
      </div>
    </div>
    <button fxShow fxHide.lt-md mat-mini-fab class="swiper-button-prev swipe-arrow">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button mat-mini-fab fxShow fxHide.lt-md class="swiper-button-next swipe-arrow">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>

  <div  *ngIf="categoryId5==3" fxLayout="row" fxLayoutAlign="center center" style="padding-top: 10px; padding-bottom: 10px;">
    <button  class=" btn"
    (click)="goToCategory()" >{{item.customField26}}</button>
  </div>
</div>

