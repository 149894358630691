import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Constant } from '../constants/constant';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ShippingRateService {

  constructor(
    private userService: UserService,
    private http: HttpClient) { }
  url = environment.serverAPI4 + "/api/shippingRate/vendor";

  url2 = environment.serverAPI4 + "/api/ig/shippingRate/vendor";

  url1 = environment.serverAPI4 + "/api/courier/";

  url3 = environment.serverAPI4 + "/api/icarry";


  getShippingRate(rate: any): Observable<any> {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
    return this.http.post<Response[]>(`${this.url}`, rate);
    }else{
      return this.http.post<Response[]>(`${this.url2}`, rate);
    }

  }


  getAromexShippingRate(rate): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url1}/aromex/shippingtablerate`, rate);
  }

  getShippingStatus(tracknumber): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url1}/aromex/TrackShipments`, tracknumber);
  }



  getShipRocketRate(rate: any): Observable<any> {

    return this.http.post<Response[]>(`${this.url1}/shiprocket/shippingtablerate`, rate);

  }

  getShipRocketShippingStatus(shipmentId: number): Observable<Object> {
    return this.http.get(`${this.url1}/shiprocket/TrackShipments/${shipmentId}`);
  }


  getIcarryStatus(shippingId: any): Observable<Object> {
    return this.http.get(`${this.url3}/track/${shippingId}`);
  }



}
