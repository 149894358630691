<div *ngIf="item.customField22 == '1' ">
    <div style="margin-top: 50px;font-size: 20px;margin-bottom: 15px;" fxLayoutAlign="center center">
        Annual &#160;&#160;
        <mat-slide-toggle [(ngModel)]="isChecked" (change)="toggleChanged()" color="primary">Lifetime</mat-slide-toggle>
    </div>

    <div fxLayout="column" fxLayout.gt-sm="row" style="text-align: center;" fxLayoutGap="15px"
        *ngFor="let data of pricedetail">
        <div contentAnim [aniName]="item.customField36" fxFlex="100" fxFlex.gt-sm="33" style="border: 1px solid gray;border-radius: 20px">
            <h3><b>{{data.title1}}</b></h3>
            <p> For growing businesses with a comprehensive set of features</p>

            <div *ngIf="!isChecked">
                <h4><b>₹ {{data.s_price_annualy}} /&#160;&#160;&#160;Yearly</b></h4>
                <h6>{{data.s_charge_year}}</h6>
            </div>
            <div *ngIf="isChecked">
                <h4><b>₹ {{data.s_price_life}}/​ &#160;&#160;&#160;lifetime</b></h4>
                <h6>Annual maintenance charge : ₹ {{data.s_charge_life}}</h6>
            </div>

            <button (click)="openInNewWindow(data.p_button)" mat-raised-button
                color="primary">{{data.s_button}}</button>
            <h5><b>{{data.s_subtitle}}</b></h5>

            <div *ngFor="let subdata of data.list_detail" style="margin-bottom: 10px;">
                <div class="pricelist" *ngIf="subdata.starters">
                    <mat-icon class="maticon3">check_circle</mat-icon>
                    <h6 style="margin-bottom: 0px;">&#160;&#160;{{subdata.starters}}</h6>
                </div>
            </div>
            <!-- <div>
                <div class="pricelist" >
                    <mat-icon class="maticon3">check_circle</mat-icon>
                    <h6 style="margin-bottom: 0px;">&#160;Partial Payment</h6>                
                </div>
            </div> -->
        </div>

        <div contentAnim [aniName]="item.customField37" fxFlex="100" fxFlex.gt-sm="33" [style.background-color]="data.a_button"
            style="border: 1px solid gray;border-radius: 20px;" class="txtcolor">
            <h3><b>{{data.title2}}</b></h3>
            <p>Professional features to grow and manage your online store</p>

            <div *ngIf="!isChecked">
                <h4><b>₹ {{data.p_price_annualy}} /&#160;&#160;&#160;Yearly</b></h4>
                <h6 class="txtcolor">{{data.p_charge_year}}</h6>
            </div>
            <div *ngIf="isChecked">
                <h4><b>₹ {{data.p_price_life}}/​ &#160;&#160;&#160;lifetime</b></h4>
                <h6 class="txtcolor"> Annual maintenance charge : ₹ {{data.p_charge_life}}</h6>
            </div>

            <button (click)="openInNewWindow(data.p_button)" mat-raised-button>{{data.s_button}}</button>
            <h5 class="txtcolor"><b>{{data.p_subtitle}}</b></h5>

            <div *ngFor="let subdatas of data.list_detail" style="margin-bottom: 10px;">
                <div class="pricelist" *ngIf="subdatas.professions">
                    <mat-icon class="maticon3">check_circle</mat-icon>
                    <h6 style="margin-bottom: 0px;" class="txtcolor">&#160;&#160;{{subdatas.professions}}</h6>
                </div>
            </div>

        </div>

        <div contentAnim [aniName]="item.customField38" fxFlex="100" fxFlex.gt-sm="33" style="border: 1px solid gray;border-radius: 20px">
            <h3><b>{{data.title3}}</b></h3>
            <p>Ideal for established businesses with high-volume sales & Multiple Outlets</p>

            <div *ngIf="!isChecked">
                <h4><b>₹ {{data.a_price_annualy}} /&#160;&#160;&#160;Yearly</b></h4>
                <h6>{{data.a_charge_year}}</h6>
            </div>
            <div *ngIf="isChecked">
                <h4><b>₹ {{data.a_price_life}}/​ &#160;&#160;&#160;lifetime</b></h4>
                <h6>Annual maintenance charge : ₹ {{data.a_charge_life}}</h6>
            </div>

            <button (click)="openInNewWindow(data.p_button)" mat-raised-button
                color="primary">{{data.s_button}}</button>
            <h5><b>{{data.a_subtitle}}</b></h5>

            <div *ngFor="let subdataz of data.list_detail" style="margin-bottom: 10px;">
                <div class="pricelist" *ngIf="subdataz.advanceds">
                    <mat-icon class="maticon3">check_circle</mat-icon>
                    <h6 style="margin-bottom: 0px;">&#160;&#160;{{subdataz.advanceds}}</h6>
                </div>
            </div>

        </div>
    </div>

</div>

<!----------- TYpe 2 ------------>


<div *ngIf="item.customField22 == '2' ">

    <div fxLayout="column" fxLayout.gt-sm="row" style="text-align: center;" fxLayoutGap="15px"
        *ngFor="let data of pricedetail">
        <div contentAnim [aniName]="item.customField36" fxFlex="100" fxFlex.gt-sm="33" style="border: 1px solid gray;border-radius: 20px">
            <h3><b>{{data.title1}}</b></h3>

            <div>
                <h4><b>₹ {{data.s_price_annualy}} </b></h4>
                <h6>{{data.s_charge_year}}</h6>
            </div>

            <button (click)="openInNewWindow(data.p_button)" mat-raised-button
                color="primary">{{data.s_button}}</button>
            <h5><b>{{data.s_subtitle}}</b></h5>

            <div *ngFor="let subdata of data.list_detail" style="margin-bottom: 10px;">
                <div class="pricelist" *ngIf="subdata.starters">
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div>
                    <mat-icon class="maticon3">check_circle</mat-icon>
                </div>
                <div>
                    <h6 style="margin-bottom: 0px;font-size: 16px">&#160;{{subdata.starters}}</h6>
                </div>
                    </div>
                </div>
            </div>
        </div>

        <div contentAnim [aniName]="item.customField37" fxFlex="100" fxFlex.gt-sm="33" [style.background-color]="data.a_button"
            style="border: 1px solid gray;border-radius: 20px;" class="txtcolor">
            <h3><b>{{data.title2}}</b></h3>

            <div>
                <h4><b>₹ {{data.p_price_annualy}}</b></h4>
                <h6 class="txtcolor">{{data.p_charge_year}}</h6>
            </div>

            <button (click)="openInNewWindow(data.p_button)" mat-raised-button>{{data.s_button}}</button>
            <h5 class="txtcolor"><b>{{data.p_subtitle}}</b></h5>

            <div *ngFor="let subdatas of data.list_detail" style="margin-bottom: 10px;">
                <div class="pricelist" *ngIf="subdatas.professions">
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div>
                            <div class="circleiconz">
                                <mat-icon class="maticon_3">check_circle</mat-icon>
                            </div>
                        </div>
                        <div>
                    <h6 style="margin-bottom: 0px;padding-left: 10px;font-size: 16px" class="txtcolor">&#160;{{subdatas.professions}}</h6>
                </div>
                    </div>
                </div>
            </div>

        </div>

        <div contentAnim [aniName]="item.customField38" fxFlex="100" fxFlex.gt-sm="33" style="border: 1px solid gray;border-radius: 20px">
            <h3><b>{{data.title3}}</b></h3>

            <h4><b>₹ {{data.a_price_annualy}} </b></h4>
            <h6>{{data.a_charge_year}}</h6>

            <button (click)="openInNewWindow(data.p_button)" mat-raised-button
                color="primary">{{data.s_button}}</button>
            <h5><b>{{data.a_subtitle}}</b></h5>

            <div *ngFor="let subdataz of data.list_detail" style="margin-bottom: 10px;">
                <div class="pricelist" *ngIf="subdataz.advanceds">
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div>
                            <mat-icon class="maticon3">check_circle</mat-icon>
                        </div>
                        <div>
                            <h6 style="margin-bottom: 0px;font-size: 16px;">&#160;{{subdataz.advanceds}}</h6>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>