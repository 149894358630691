import { Component, Input, ViewChild } from '@angular/core';
import { CarouselComponent } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-brandscarousel-type27',
  templateUrl: './brandscarousel-type27.component.html',
  styleUrls: ['./brandscarousel-type27.component.scss']
})
export class BrandscarouselType27Component {

  @Input() item: any;
  @Input() img: any;
  public serverPath = environment.commonImageApi + '/home/';
  public bannerServerPath = environment.commonImageApi + '/commonSlider/';

  @ViewChild('owlCar') owlCarousel: CarouselComponent;

  carouselOptions = {
    loop: true, 
    margin: 10, 
    nav: false, 
    dots: true, 
    autoplay: true, 
    autoplayTimeout: 3000, 
    autoplayHoverPause: true, 
    items: 1, 
    responsive: {
      0: {
        items: 1 
      },
      600: {
        items: 1
      },
      1000: {
        items: 1 
      }
    },
    // slideBy: 1, // Slides one item at a time
    // smartSpeed: 800, // Controls the speed of the slide transition
    // rewind: true, // Rewinds to the first slide when the last slide is reached
    // animateOut: 'fadeOut', // Animation effect when a slide goes out
    // animateIn: 'fadeIn' // Animation effect when a slide comes in
  };
 
  // slides = [
  //   {
  //     title: 'Slide 1',
  //     description: 'Description for slide 1',
  //     image: 'assets/images/google-meet-people.jpg' // Update with actual image paths
  //   },
  //   {
  //     title: 'Slide 2',
  //     description: 'Description for slide 2',
  //     image: 'assets/images/seller.jpg'
  //   },
  //   {
  //     title: 'Slide 3',
  //     description: 'Description for slide 3',
  //     image: 'assets/images/Supplier-Portal-1.jpg'
  //   }
  // ];
  
 

}