<div fxLayout="row" style="background-color: #f5f5f5;" fxLayout.xs="column" >
    <div fxFlex="50">
        <div fxLayout="row" >
            <div fxFlex="50">
                <img [src]="serverPath+datas[0].img" style="width: 100%;" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}"/>
            </div>
            <div fxFlex="50" >
                <div class="padcss">
                    <h3 [style.color]="datas[0].customField10" [style.font-size]="item.customField18"
                    [ngStyle]="{'font-family': datas[0].customField11}" 
                    [ngStyle.lt-md]="{ 'font-size': item.customField17 }">{{datas[0].customField1}}</h3>
                    <p [style.color]="datas[0].customField15" [innerHTML]="datas[0].customField14"></p>
                    <div class="details" *ngIf="datas[0].customField34==1" (click)="openInNewWindow(datas[0].customField7)">
                        VIEW DETAILS &#160;
                        <mat-icon>arrow_right_alt</mat-icon>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div fxFlex="50" fxHide.lt-md>
        <div fxLayout="row" >
            <div fxFlex="50">
                <img [src]="serverPath+datas[1].img" style="width: 100%;" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}" />
            </div>
            <div fxFlex="50" >
                <div class="padcss">
                    <h3 [style.color]="datas[1].customField10" [style.font-size]="item.customField18"
                    [ngStyle]="{'font-family': datas[1].customField11}" 
                    [ngStyle.lt-md]="{ 'font-size': item.customField17 }">{{datas[1].customField1}}</h3>
                    <p [style.color]="datas[1].customField15" [innerHTML]="datas[1].customField14"></p>
                    <div class="details" *ngIf="datas[1].customField34==1" (click)="openInNewWindow(datas[1].customField7)">
                        VIEW DETAILS &#160;
                        <mat-icon>arrow_right_alt</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div fxFlex="50" fxHide fxShow.lt-md>
        <div fxLayout="row" >
            <div fxFlex="50" >
                <div class="padcss">
                    <h3 [style.color]="datas[1].customField10" [style.font-size]="item.customField18"
                    [ngStyle]="{'font-family': datas[1].customField11}" 
                    [ngStyle.lt-md]="{ 'font-size': item.customField17 }">{{datas[1].customField1}}</h3>
                    <p [style.color]="datas[1].customField15" [innerHTML]="datas[1].customField14"></p>
                    <div class="details" *ngIf="datas[1].customField34==1" (click)="openInNewWindow(datas[1].customField7)">
                        VIEW DETAILS &#160;
                        <mat-icon>arrow_right_alt</mat-icon>
                    </div>
                </div>
            </div>
            <div fxFlex="50">
                <img [src]="serverPath+datas[1].img" style="width: 100%;" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}"/>
            </div>

        </div>
    </div>


</div>

<div fxLayout="row" style="background-color: #f5f5f5;" fxLayout.xs="column">
    <div fxFlex="50" fxHide.lt-md>
        <div fxLayout="row">
            <div fxFlex="50">
                <div class="padcss" >
                    <h3 [style.color]="datas[2].customField10" [style.font-size]="item.customField18"
                    [ngStyle]="{'font-family': datas[2].customField11}" 
                    [ngStyle.lt-md]="{ 'font-size': item.customField17 }">{{datas[2].customField1}}</h3>
                    <p [style.color]="datas[2].customField15" [innerHTML]="datas[2].customField14"></p>
                    <div class="details" *ngIf="datas[2].customField34==1" (click)="openInNewWindow(datas[2].customField7)">
                        VIEW DETAILS &#160;
                        <mat-icon>arrow_right_alt</mat-icon>
                    </div>
                </div>
            </div>
            <div fxFlex="50" >
                <img [src]="serverPath+datas[2].img" style="width: 100%;" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}"/>
            </div>
        </div>
    </div>

    <div fxFlex="50" fxHide fxShow.lt-md>
        <div fxLayout="row">
            <div fxFlex="50" >
                <img [src]="serverPath+datas[2].img" style="width: 100%;" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}"/>
            </div>

            <div fxFlex="50">
                <div class="padcss" >
                    <h3 [style.color]="datas[2].customField10" [style.font-size]="item.customField18"
                    [ngStyle]="{'font-family': datas[2].customField11}" 
                    [ngStyle.lt-md]="{ 'font-size': item.customField17 }">{{datas[2].customField1}}</h3>
                    <p [style.color]="datas[2].customField15" [innerHTML]="datas[2].customField14"></p>
                    <div class="details" *ngIf="datas[2].customField34==1" (click)="openInNewWindow(datas[2].customField7)">
                        VIEW DETAILS &#160;
                        <mat-icon>arrow_right_alt</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    <div fxFlex="50">
        <div fxLayout="row">
            <div fxFlex="50">
                <div class="padcss" >
                    <h3 [style.color]="datas[3].customField10" [style.font-size]="item.customField18"
                    [ngStyle]="{'font-family': datas[3].customField11}" 
                    [ngStyle.lt-md]="{ 'font-size': item.customField17 }">{{datas[3].customField1}}</h3>
                    <p [style.color]="datas[3].customField15" [innerHTML]="datas[3].customField14"></p>
                    <div class="details" *ngIf="datas[3].customField34==1" (click)="openInNewWindow(datas[3].customField7)">
                        VIEW DETAILS &#160;
                        <mat-icon>arrow_right_alt</mat-icon>
                    </div>
                </div>
            </div>
            <div fxFlex="50" >
                <img [src]="serverPath+datas[3].img" style="width: 100%;" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}"/>
            </div>
        </div>
    </div>


</div>
