<div fxLayout="row" fxLayout.xs="column">
  <div fxFlex="25"
    *ngIf="item.customField1!='type3' && item.customField1 !== 'type4' && item.customField1 !== 'type5' && item.customField1 !== 'type6' || item.customField1 == 'type7'"
    fxHide fxShow.gt-sm fxFlex.xs="100">
    <calsoft-new-menu></calsoft-new-menu>
  </div>

  <div [fxFlex]="item.gapBetweenImage">

  </div>

  <div *ngIf="item.customField1!='type6'"
    [fxFlex]="(item.customField1 !== 'type3' && item.customField1 !== 'type4') ? (item.customField1 === 'type5' ? 60 : (item.customField1 === 'type7' ? 75 : 50)) : 75"
    fxFlex.xs="100">

    <div *ngIf="item.customField1=='type7'"  fxLayout="row" fxHide fxShow.gt-sm fxFlex.xs="100">
      <div fxFlex="70">
        <calsoft-search-layout></calsoft-search-layout>
      </div>

      <div fxFlex="25" fxLayoutAlign="end center" class="shipping-info-container">
        <div class="icon-container">
          <mat-icon class="callicon" style="margin-right: 5px;">call</mat-icon>
        </div>
        <div class="text-container">
          <h3 class="text" style="font-weight: 700;"> {{data.storePhoneNumber}}</h3>
          <p class="text"> {{data.storeHoursOfOperation}}</p>
        </div>
      </div>
    </div>

    <div *ngIf="item.customField1=='type7'" [style.margin-top]="item.gapBetweenImage"></div>

    <owl-carousel-o [options]="introSlider">
      <ng-container *ngFor="let  img of item.images; let l = index">
        <ng-template carouselSlide>

          <div style="width:100%" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob
                         }" class="intro-slide" [style.backgroundImage]="'url(' + serverPath +img.img + ')'">

            <div *ngIf="item.customField13 == 0" fxLayoutAlign="start center" style="width:100%"
              [style.text-align]="item.customField13" class="container intro-content" contentAnim
              [aniName]="item.customField7">
              <span [ngStyle]="{'text-align': 'start'}">
                <h3 [style.color]="item.customField22" [style.font-size]="item.customField31"
                  [ngStyle.lt-md]="{ 'font-size': item.customField32 }" class="intro-subtitle text-white">
                  {{img.customField4}}</h3>
                <h1 [style.color]="item.customField22" [style.font-size]="item.customField39"
                  [ngStyle.lt-md]="{ 'font-size': item.customField40 }" class="intro-title text-white"
                  [innerHTML]="img.customField5"></h1>

                <ng-container style="cursor: pointer;" *ngIf="img.customField3.length !== 0; else noCustomField3">
                  <button [style.border-radius]="item.customField34" [style.background-color]="item.customField33" (click)="link(img.customField6)" class="btn btn-outline-white-4" target="_blank">
                    <span style="align-items: center;">{{img.customField3}}</span>
                  </button>
                </ng-container>


                <ng-template #noCustomField3>
                  <span>{{img.customField3}}</span>
                </ng-template>

              </span>

            </div>

            <div *ngIf="item.customField13 == 1" fxLayoutAlign="center center" style="width:100%"
              [style.text-align]="item.customField13" class="container intro-content" contentAnim
              [aniName]="item.customField7">
              <span [ngStyle]="{'text-align': 'center'}">
                <h3 [style.color]="item.customField22" [style.font-size]="item.customField31"
                [ngStyle.lt-md]="{ 'font-size': item.customField32 }" class="intro-subtitle text-white">
                  {{img.customField4}}</h3>
                <h1 [style.color]="item.customField22" [style.font-size]="item.customField39"
                [ngStyle.lt-md]="{ 'font-size': item.customField40 }" class="intro-title text-white"
                  [innerHTML]="img.customField5"></h1>

                <ng-container style="cursor: pointer;" *ngIf="img.customField3.length !== 0; else noCustomField3">
                  <button [style.border-radius]="item.customField34" [style.background-color]="item.customField33" (click)="link(img.customField6)" class="btn btn-outline-white-4" target="_blank">
                    <span style="align-items: center;">{{img.customField3}}</span>
                  </button>
                </ng-container>


                <ng-template #noCustomField3>
                  <span>{{img.customField3}}</span>
                </ng-template>

              </span>
            </div>

            <div *ngIf="item.customField13 == 2" fxLayoutAlign="end center" style="width:100%"
              [style.text-align]="item.customField13" class="container intro-content" contentAnim
              [aniName]="item.customField7">
              <span [ngStyle]="{'text-align': 'center'}">
                <h3 [style.color]="item.customField22" [style.font-size]="item.customField31"
                [ngStyle.lt-md]="{ 'font-size': item.customField32 }" class="intro-subtitle text-white">
                  {{img.customField4}}</h3>
                <h1 [style.color]="item.customField22" [style.font-size]="item.customField39"
                [ngStyle.lt-md]="{ 'font-size': item.customField40 }" class="intro-title text-white"
                  [innerHTML]="img.customField5"></h1>

                <ng-container style="cursor: pointer;" *ngIf="img.customField3.length !== 0; else noCustomField3">
                  <a [style.border-radius]="item.customField34" [style.background-color]="item.customField33" (click)="link(img.customField6)" class="btn btn-outline-white-4" target="_blank">
                    <span>{{img.customField3}}</span>
                  </a>
                </ng-container>

                <ng-template #noCustomField3>
                  <span>{{img.customField3}}</span>
                </ng-template>

              </span>





            </div>
          </div>


        </ng-template>
      </ng-container>

    </owl-carousel-o>
  </div>

  <div *ngIf="item.customField1!='type6'" [fxFlex]="item.gapBetweenImage">

  </div>

  <div *ngIf="item.customField1!='type6'&& item.customField1 != 'type7'"
    [fxFlex]="(item.customField1 !== 'type5') ? 25 : 40">
    <calsoft-categorywithimage2 [item]="item" [img]="item.images"></calsoft-categorywithimage2>
  </div>


  <!-- type6 -->

  <div *ngIf="item.customField1=='type6'" [fxFlex]="(item.customField1 !== 'type5') ? 25 : 40">
    <calsoft-categorywithimage2 [item]="item" [img]="item.images"></calsoft-categorywithimage2>
  </div>

  <div *ngIf="item.customField1=='type6'" [fxFlex]="item.gapBetweenImage">

  </div>

  <div *ngIf="item.customField1=='type6'"
    [fxFlex]="(item.customField1 !== 'type3' && item.customField1 !== 'type4' && item.customField1 !== 'type6') ? (item.customField1 === 'type5' ? 60 : 50) : 75"
    fxFlex.xs="100">
    <owl-carousel-o [options]="introSlider">
      <ng-container *ngFor="let  img of item.images; let l = index">
        <ng-template carouselSlide>

          <div style="width:100%" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob
                         }" class="intro-slide" [style.backgroundImage]="'url(' + serverPath +img.img + ')'">
            <div *ngIf="item.customField13 == 0" fxLayoutAlign="start center" style="width:100%"
              [style.text-align]="item.customField13" class="container intro-content" contentAnim
              [aniName]="item.customField7">
              <span [ngStyle]="{'text-align': 'start'}">
                <h3 [style.color]="item.customField22" [style.font-size]="item.customField31"
                [ngStyle.lt-md]="{ 'font-size': item.customField32 }" class="intro-subtitle text-white">
                  {{img.customField4}}</h3>
                <h1 [style.color]="item.customField22" [style.font-size]="item.customField39"
                [ngStyle.lt-md]="{ 'font-size': item.customField40 }" class="intro-title text-white"
                  [innerHTML]="img.customField5"></h1>

                <ng-container style="cursor: pointer;" *ngIf="img.customField3.length !== 0; else noCustomField3">
                  <button [style.border-radius]="item.customField34" [style.background-color]="item.customField33" (click)="link(img.customField6)" class="btn btn-outline-white-4" target="_blank">
                    <span style="align-items: center;">{{img.customField3}}</span>
                  </button>
                </ng-container>


                <ng-template #noCustomField3>
                  <span>{{img.customField3}}</span>
                </ng-template>

              </span>

            </div>

            <div *ngIf="item.customField13 == 1" fxLayoutAlign="center center" style="width:100%"
              [style.text-align]="item.customField13" class="container intro-content" contentAnim
              [aniName]="item.customField7">
              <span [ngStyle]="{'text-align': 'center'}">
                <h3 [style.color]="item.customField22" [style.font-size]="item.customField31"
                [ngStyle.lt-md]="{ 'font-size': item.customField32 }" class="intro-subtitle text-white">
                  {{img.customField4}}</h3>
                <h1 [style.color]="item.customField22" [style.font-size]="item.customField39"
                [ngStyle.lt-md]="{ 'font-size': item.customField40 }" class="intro-title text-white"
                  [innerHTML]="img.customField5"></h1>

                <ng-container style="cursor: pointer;" *ngIf="img.customField3.length !== 0; else noCustomField3">
                  <button [style.border-radius]="item.customField34" [style.background-color]="item.customField33" (click)="link(img.customField6)" class="btn btn-outline-white-4" target="_blank">
                    <span style="align-items: center;">{{img.customField3}}</span>
                  </button>
                </ng-container>


                <ng-template #noCustomField3>
                  <span>{{img.customField3}}</span>
                </ng-template>

              </span>
            </div>

            <div *ngIf="item.customField13 == 2" fxLayoutAlign="end center" style="width:100%"
              [style.text-align]="item.customField13" class="container intro-content" contentAnim
              [aniName]="item.customField7">
              <span [ngStyle]="{'text-align': 'center'}">
                <h3 [style.color]="item.customField22" [style.font-size]="item.customField31"
                [ngStyle.lt-md]="{ 'font-size': item.customField32 }" class="intro-subtitle text-white">
                  {{img.customField4}}</h3>
                <h1 [style.color]="item.customField22" [style.font-size]="item.customField39"
                [ngStyle.lt-md]="{ 'font-size': item.customField40 }" class="intro-title text-white"
                  [innerHTML]="img.customField5"></h1>

                <ng-container style="cursor: pointer;" *ngIf="img.customField3.length !== 0; else noCustomField3">
                  <a  [style.border-radius]="item.customField34" [style.background-color]="item.customField33" (click)="link(img.customField6)" class="btn btn-outline-white-4" target="_blank">
                    <span>{{img.customField3}}</span>
                  </a>
                </ng-container>

                <ng-template #noCustomField3>
                  <span>{{img.customField3}}</span>
                </ng-template>

              </span>





            </div>
          </div>


        </ng-template>
      </ng-container>

    </owl-carousel-o>
  </div>

</div>