import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { AboutService } from 'src/app/services/about.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { PartnerDataService  } from 'src/app/services/partner-data.service';
import { MatDialog } from '@angular/material/dialog';
import { PartnerDetailComponent } from 'src/app/pages/layout/partner-detail/partner-detail.component';

@Component({
  selector: 'calsoft-partner-layout',
  templateUrl: './partner-layout.component.html',
  styleUrls: ['./partner-layout.component.scss']
})
export class PartnerLayoutComponent {

  @Input() data: any;

  @Input() blog: any;

  @Input() name: any;

  @Input() width: any;

  @Input() heightMobile: any;

  @Input() widthMobile: any;

  public serverPath = environment.commonImageApi + '/partnerLogo/';
  
  public serverPath1 = environment.commonImageApi + '/partnerLogo1/';

  image: string;
  @Input() public pageTitle: any
  aboutData: any;
  loaded:any=false;
  layoutype: any=0;
  @Input() public homePageBuilder: any;

  height: any;
  blogNameDisplayLineMob =2;
  blogNameDisplayLine = 2;
  blogOverallHeightMob: any;
  blogOverallHeight: any;
  imageurl: string;
  
  constructor(
    private commonService: CommonService,
    private router: Router,
    public meta: Meta,
    private aboutService: AboutService,
    private partnerDataService: PartnerDataService,
    private dialog: MatDialog,
  ) { }
	list:any=[];
  ngOnInit(): void {
   
    
  this.image=this.serverPath+this.blog['content'];
  this.imageurl=this.serverPath1+this.blog['customField9'];  
    console.log(this.imageurl);
  this.meta.addTags([
    { name: 'author', content: Constant.siteName },
    { name: 'keywords', content: this.blog['metaKeywords'] },
    { name: 'description', content: this.blog['metaDescription'] }
  ]);
  this.height=this.data['blogImageHeight']
  this.width=this.data['blogImageWidth']
  this.widthMobile=this.data['blogImageWidthMob']
  this.heightMobile=this.data['blogImageHeightMob'];
  this.blogNameDisplayLine=this.data['blogNameDisplayLine']
  this.blogNameDisplayLineMob=this.data['blogNameDisplayLineMob']
  this.blogOverallHeight=this.data['blogOverallHeight']
  this.blogOverallHeightMob=this.data['blogOverallHeightMob']
  }

 

  

  goToBlogData(data){
   // this.router.navigate([`blog/${this.blog['uuid']}`],{ queryParams: { title: this.blog['title'] } })

   if (window.innerWidth > 768) {

    let dialogRef = this.dialog.open(PartnerDetailComponent, {
      width: '990px',
      height:'500px',
      data: { data: data, }
    });

    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();


    });

  }
  else {
    let dialogRef = this.dialog.open(PartnerDetailComponent, {
      minWidth: '100vw', height: '100vh',
      data: { data: data, }
    });

    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();


    });
  }

  }


}
