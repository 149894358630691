  <div class="custom-carousel">
    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">    
      <div fxFlex="100">
        <owl-carousel-o [options]="LogoSlider" #owlCar class="no-arrow">
          <ng-container *ngFor="let  brand of brands; let l = index">
            <ng-template carouselSlide>
              <div class="profile-card">
                <!-- <img fxFlex="100" (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
                  [alt]="brand.altImage" [src]="bannerServerPath+brand.img" [ngStyle.lt-md]="{'height': item.heightMob}"
                  [style.height]="item.height" class="brand-image"> -->
                  <calsoft-ng-optimized-image fxFlex="100" class="brand-image" [height]="item.height" [heightMob]="item.heightMob" [navigation]="0" [altImage]="brand.altImage" [image]="bannerServerPath+brand.img" [i]="'0'"> </calsoft-ng-optimized-image>
                <div class="image-title" fxLayoutAlign="center center">
                  <span class="titleText" [innerHTML]="brand.customField21"></span>
                </div>
    
                <div class="hover-area alignment-center">
                  <div class="profile-body">
                    <h3 class="profile-title" [innerHTML]="brand.customField21">
                    </h3>
                    <p class="profile-designation">
                    </p>
                    <p class="profile-content">
                      {{brand.customField20}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    
    </div>
    
    </div>