<div class="container" fxLayout="column">
    <div>
        <!-- <div class="top-container dark-background">
            <div class="background">
                <div class="main">
                    <div class="home-page">
                        <div class="home-page2">
                            <br>
                            <br>
                            <a class="heading" href="/courses/development/">Development</a>
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <a class="heading" href="/courses/development/web-development/">Web Development</a>
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <a class="heading" href="/topic/javascript/">JavaScript</a>
                            <br>
                            <br>
                            <br>
                            <h1 style="color: white;">Javascript For Beginners</h1>
                            <p style="font-size: 20px; font-weight: bolder; color: white;">Learn javascript online and
                                supercharge your web design with this Javascript for<br>beginners training course.</p>
                            <br>
                            <span style="font-size: larger; color: coral;">4.4</span>
                            <h3 style="color: white;">Created by</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>


    <!-- <div class="component-margin">
        <span id="objective" class="in-page-offset-anchor"></span>
        <div data-purpose="course-curriculum">
            <h2 class="ud-heading-xl curriculum--curriculum-header--vxTrz" data-purpose="curriculum-header">Course
                content
            </h2>
            <div class="curriculum--curriculum-sub-header--QqY6d">
                <div class="ud-text-sm" data-purpose="curriculum-stats">
                    <span class="curriculum--content-length--V3vIz">14 sections • 72 lectures •
                        <span><span>7h&nbsp;49m</span> total length</span>
                    </span>
                    <a style="font-weight: bold; color: blue;" fxFlex="29" fxLayoutAlign="end center" class="heading" href="">Expand all
                        sections</a>

                </div>
            </div>
        </div>
    </div> -->


    <div *ngFor="let section of productData[0].course; let i = index" class="course-container">
        <div fxLayout="column">
            <div (click)="toggleAccordion(i)" fxLayout="row" class="cat pointer" fxFlex="50">
                <div fxFlex="5" fxLayoutAlign="center center" (click)="toggleAccordion(i)" style="cursor: pointer;">
                    <button *ngIf="accordionOpenIndex !== i" mat-icon-button>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <button *ngIf="accordionOpenIndex === i" mat-icon-button>
                        <mat-icon>keyboard_arrow_up</mat-icon>
                    </button>
                </div>
                <div fxFlex fxFlex.xs="100" fxLayoutAlign="center center">
                    <p style="font-weight: bolder;">{{section.title}}</p>
                </div>
                <div fxFlex="10" fxFlex.xs="50" fxLayoutAlign="center center">
                    <span> {{ section.courseItem.length }} lecture{{ section.courseItem.length !== 1 ? 's' : '' }}</span> 
                </div>
                <div fxFlex="10"  fxFlex.xs="50" class="timeming" fxLayoutAlign="center center">
                    <!-- <span>1 mins</span> -->
                </div>
            </div>

            <div fxLayout="column" *ngIf="accordionOpenIndex === i" style="border: 1px solid lightgray; padding: 20px;">
                <div *ngFor="let subsection of section.courseItem; let j = index" fxLayout="column"
                    class="subsection-container">
                    <div fxLayout="row" fxLayoutAlign="start center" class="subsection-header">
                        <div fxFlex="5" fxFlex.xs="100">
                            <mat-icon>insert_drive_file</mat-icon>
                        </div>
                        <div (click)="subtoggleAccordion(i, j)" fxFlex="40" fxFlex.xs="100" class="subsection-content pointer">
                            <span>{{subsection.title}}</span>

                        </div>
                        <div fxFlex="35"  fxFlex.xs="100" fxLayoutAlign="end center">
                            <span (click)="subtoggleAccordion(i, j)" style="cursor: pointer;">
                                <button *ngIf="subaccordionOpenIndex[i] !== j" mat-icon-button>
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                </button>
                                <button *ngIf="subaccordionOpenIndex[i] === j" mat-icon-button>
                                    <mat-icon>keyboard_arrow_up</mat-icon>
                                </button>
                            </span>
                        </div>
                        <div fxFlex="15" fxLayoutAlign="end center">
                            <span class="duration">{{subsection.customField2}}</span>
                        </div>
                    </div>
                    <div *ngIf="subaccordionOpenIndex[i] === j" class="subsection-details">
                        <div fxLayout="row">
                            <div fxFlex="5">

                            </div>

                            <div fxFlex="50">
                                <span>{{subsection.customField1}}</span>
                            </div>
                            <div fxFlex fxLayoutAlign="end center">
                                <a (click)="ViewVedio()" mat-flat-button>
                                    Preview
                                </a>
                            </div>

                            <div fxFlex="10">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #vedio>
    <div class="video-modal">
        <iframe class="mfp-iframe" [src]="''" frameBorder="0" allowFullScreen=""
        allow="autoplay" title="youtube"></iframe>
        <button type="button" class="close" (click)="closeModal()" autofocus=false><span><mat-icon>close</mat-icon></span></button>
    </div>
</ng-template>