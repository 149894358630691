import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-imagescrolling',
  templateUrl: './imagescrolling.component.html',
  styleUrls: ['./imagescrolling.component.scss']
})
export class ImagescrollingComponent {


  @Input() item: any;

  @Input() img: any;

  @Input() data: any;

  list1: any;

  list2: any;

  public bannerServerPath = environment.commonImageApi + '/commonSlider/';

  cards = [
    { id: 1, imgSrc: 'https://static.wixstatic.com/media/535075_834e3c77a9074a379488eef68c3bee55~mv2.jpg/v1/fill/w_672,h_1303,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/16.jpg', alt: 'Image description', cssClass: '' },
    { id: 2, imgSrc: 'https://static.wixstatic.com/media/535075_c35db8056cb349779873504818fc11d7~mv2.jpg/v1/fill/w_693,h_1130,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/5.jpg', alt: 'Image rotated', cssClass: 'rotate-image' },
    { id: 3, imgSrc: 'https://static.wixstatic.com/media/535075_c166ff23a4334afbb2055866dfdf4dfa~mv2.jpg/v1/fill/w_716,h_1060,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/6.jpg', alt: 'Image rotated', cssClass: 'rotate-image2' },
    { id: 4, imgSrc: 'https://static.wixstatic.com/media/535075_e0c246ea091e41d390e5a9dfdae9f350~mv2.jpg/v1/fill/w_716,h_1273,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/9.jpg', alt: 'Image description', cssClass: '' },
    { id: 5, imgSrc: 'https://static.wixstatic.com/media/535075_51b8a4cd90884f78bf279fbedb2bac0b~mv2.jpg/v1/fill/w_619,h_1100,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2.jpg', alt: 'Image description', cssClass: '' },
    { id: 6, imgSrc: 'https://static.wixstatic.com/media/535075_ede5d843c7534ea981955e4a8a580ba5~mv2.jpg/v1/fill/w_760,h_1152,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/4.jpg', alt: 'Image rotated', cssClass: 'rotate-image' },
    { id: 7, imgSrc: 'https://static.wixstatic.com/media/535075_3cc0d109e7844035af99a12511254154~mv2.jpg/v1/fill/w_716,h_1182,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/7.jpg', alt: 'Image rotated', cssClass: 'rotate-image2' },
    { id: 8, imgSrc: 'https://static.wixstatic.com/media/535075_989f7f1412bc4af09744df5cb812f625~mv2.jpg/v1/fill/w_716,h_1209,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11.jpg', alt: 'Image description', cssClass: '' }
  ];
  constructor(private commonService: CommonService,){

  }


  ngOnInit() {
    
    const middleIndex = Math.ceil(this.item.images.length / 2);
    this.list1 = this.item.images.slice(0, middleIndex);
    this.list2 = this.item.images.slice(middleIndex);
    

  }
  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }
  

  link(url) {

    this.commonService.goToLink(url)
  }

}
