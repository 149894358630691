import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit {

  trustedHtmlContent: SafeHtml;
  
  @Input() text: any;

  @Input() align: any;

  constructor(
   private sanitizer: DomSanitizer,
   

  ) {
    
  }

  ngOnInit() {
   
    this.trustedHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.text);
  }

}


