<div fxLayout="row" fxLayoutAlign="center center">
  <button style="height: 45px;" [innerHTML]="item.title" 
  (click)="(item.customField6 == 0) ? link(item.customField11) 
  : (item.customField6 == 1) ? dynamicFormPopup(item.customField5) 
  : (item.customField6 == 2) ? scrollDown(item.customField35): null"
    [ngStyle.gt-md]="{'font-size': '16px' }" [ngStyle.lt-md]="{
  
  'font-size': '10px'
}" fxLayout="row" fxLayoutAlign="center center" class="price-breakup-button">
  </button>
  <span   (click)="(item.customField6 == 0) ? link(item.customField11) 
  : (item.customField6 == 1) ? dynamicFormPopup(item.customField5) 
  : (item.customField6 == 2) ? scrollDown(item.customField35): null" class="arrow1" [style.background-color]="item.customField20"
    fxLayoutAlign="center center"><mat-icon>arrow_right_alt</mat-icon></span>
</div>