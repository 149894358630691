import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'calsoft-step-form-main',
  templateUrl: './step-form-main.component.html',
  styleUrls: ['./step-form-main.component.scss']
})
export class StepFormMainComponent implements OnInit {
  @Input() item: any;
  @Input() layout: any = 2;
  @Input() data: any;
  @Input() img: any;
  type: any;
  firstFormGroup: UntypedFormGroup;
  regConfig: any[] = [];
  init: boolean = false;
  gaId: any;
  formGroupId: any;
  isLinear = true;
  formIdIndex: number = 0;
  finalList: any[] = [];
  @ViewChild('stepper') stepper: MatStepper;

  ngOnInit(): void {}

  submitForm(event, i) {
    let submitValue = {};
    console.log(event);

    let object = {
      index: i,
      value: event
    };
    this.finalList.push(object);

    let totalLength = this.img.length - 1;

    if (i == totalLength) {
      console.log(this.finalList);

      let i = 0;
      this.finalList.forEach(element => {
        if (i == 0) {
          submitValue = element['value'];
        } else {
          const mergedObj = { ...submitValue, ...element['value'] };
          submitValue = mergedObj;
        }
        i++;
      });

      this.stepper.next();
    } else {
      this.stepper.next();
    }
    console.log(submitValue);
  }

  finalSubmit() {
    let finalData = {};
    this.finalList.forEach(item => {
      finalData = { ...finalData, ...item.value };
    });

    console.log('Final Submitted Data: ', finalData);

  }
}
