import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-image-text-row',
  templateUrl: './image-text-row.component.html',
  styleUrls: ['./image-text-row.component.scss']
})
export class ImageTextRowComponent {

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() img: any;

  @Input() data: any;
  

  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      window.open(fullURL, '_blank');
    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }

  
}
