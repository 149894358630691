import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/models/app.models';

import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-common-header-carousel',
  templateUrl: './common-header-carousel.component.html',
  styleUrls: ['./common-header-carousel.component.scss']
})
export class CommonHeaderCarouselComponent implements OnInit {
  @Input() header: any;
  @Input('products') products: Array<Product> = [];
  @Input() id: any
  @Input() other:any;
  @Input() type;
  displayHeader: boolean=true;

  @Input() item: any;
  customField8: any = 'white';
  categoryId4: any=0;
  customField25:any = 'white';
  categoryId5: any=0;
  customField39: any;
  customField40: any;

  constructor(
    private commonService: CommonService,
    private router:Router,
  ) { }

  ngOnInit(): void {

    if(this.router.url.includes('/product/')){
      this.displayHeader=false;

    }

    if(this.item!=undefined){
      if(this.customField8!=undefined){
        this.customField8=this.item.customField8;
      }

      if(this.categoryId4!=undefined){
        this.categoryId4=this.item.categoryId4;
      }

      if(this.customField25!=undefined){
        this.customField25=this.item.customField25;
      }

      if(this.categoryId5!=undefined){
        this.categoryId5=this.item.categoryId5;
      }

      if(this.customField39!=undefined){
        this.customField39=this.item.customField39;
      }

      if(this.customField40!=undefined){
        this.customField40=this.item.customField40;
      }


    }

  }

  goToCategory() {


    if(this.type=='product'){
      this.commonService.goToCategoryForOtherTypes(this.products,this.header);
    }
    else if(this.other=="productScope"){
      this.commonService.customSearch(this.id,this.header);
    }
    else if(this.other!="other"){
      this.commonService.goToCategory(1, parseInt(this.id));
    }
    
    else{
      let value = 1;
      let search = "isInStock:" + '*' + value + '*'
        + "' AND featured:" + '*' + value + '*' + "' "
      this.commonService.productSearch(search);
    }

  }

}
