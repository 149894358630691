import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-imagewidget',
  templateUrl: './imagewidget.component.html',
  styleUrls: ['./imagewidget.component.scss']
})
export class ImagewidgetComponent {

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  @Input() item: any;
  customField16:any;
  customField17:any;
  customField18:any;
  @Input() img: any;
  image: any;
  secondImageHeight: any;
  secondMobileHeight: any;

  constructor(
    private commonService: CommonService,
  ) {

  }
 
  ngOnInit(): void {
    

    this.image=this.img[0].img

   this.secondImageHeight=this.item.customField25;
   this.secondMobileHeight=this.item.customField26;

   
    
  }

  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }


  link(url) {

    this.commonService.goToLink(url)
  }
  
  
  imageNavigation(id){
  
  
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }
  
  }


  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      this.commonService.goToLink(url)
    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }

}
