import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'calsoft-lookback',
  templateUrl: './lookback.component.html',
  styleUrls: ['./lookback.component.scss'],
  animations: [
    trigger('fadeInUp', [
      state('void', style({ opacity: 0, transform: 'translateY(50px)' })),
      transition('void => *', animate('400ms ease-in-out')),
    ]),
  ],
})
export class LookbackComponent {

  public serverPath = environment.commonImageApi + '/home/';
  
  @Input() item: any;

  @Input() data: any;

  @Input() img: any;
  
  @Input() index: any;
  @Input() type: any;

  roundedtop:any;
  roundedleft:any;
  dots:any;
  @ViewChild('dialogContent') dialogContent: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;
  productData: any;
  viewtype:any;
  bigImage: any;
  ngOnInit(): void {

    if(this.img.customField33 == undefined || this.img.customField33 == null || this.img.customField33 == ''){
      this.viewtype = 0;
    }
    else{
    this.viewtype = this.img.customField33;
    }
    this.bigImage = environment.ImageAPI;
    //this.productData = JSON.parse(this.dot.customField12);
  }

  constructor(
    public dialog: MatDialog,
  ) {

    this.dots = [
      { class: 'dot', top: '50%', left: '12%', num:1, img:"https://angular.pixelstrap.com/multikart/assets/images/product/fashion/1.jpg", title:"tee", price:"$200" },
      { class: 'dot', top: '80%', left: '27%', num:2, img:"https://angular.pixelstrap.com/multikart/assets/images/product/fashion/2.jpg", title:"tee1", price:"$100" },
      { class: 'dot', top: '28%', left: '30%', num:3, img:"https://angular.pixelstrap.com/multikart/assets/images/product/fashion/1.jpg", title:"tee", price:"$20" },
    ];


  }


  onDoubleClick(event){
    var imageContainer = document.getElementById('image-container');
      
    // Calculate the position of the click relative to the image
    var x = (event.clientX - imageContainer.getBoundingClientRect().left) / imageContainer.clientWidth * 100;
      var y = (event.clientY - imageContainer.getBoundingClientRect().top) / imageContainer.clientHeight * 100;
    console.log(x);
    console.log(y);
    this.roundedleft = Math.round(x);
    this.roundedtop = Math.round(y);
  
    this.dialogRef = this.dialog.open(this.dialogContent, { data: {} });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // User clicked 'Yes' or confirmed the action
        console.log('Action confirmed!');
        // Perform your action here
      } else {
        // User clicked 'No' or canceled the action
        console.log('Action canceled.');
      }
    });

  }

  onNoClick() {
    console.log('no');
    this.dialogRef.close();
  }

  onYesClick() {
    // Handle 'Yes' button click
    this.dots.push({class:'dot',top:this.roundedtop+'%', left:this.roundedleft+'%', num:this.dots.length + 1});
    this.roundedleft = '';
    this.roundedtop = '';    

    this.dialogRef.close();
  }

  public activeDot: any | null = null;

  public setActiveDot(dot: any): void {
    this.activeDot = dot;
  }

  public resetActiveDot(): void {
    this.activeDot = null;
  }

}
