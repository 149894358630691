<div class="pointer product-item padding-10 product-elevat ext-center">
  <div fxLayout="row" class="" fxLayoutAlign="center center">
    <img loading="lazy"
    [alt]="name"
    (click)="goToBlogData(blog)"
    [src]="imageurl"
    [style.height]="height"
    [style.width]="width"
    [ngStyle.lt-md]="{'height': heightMobile, 'width': widthMobile}"
    onerror="this.src='assets/images/products/noImage/no-image.png';" />

  </div>
  <div class="productPrice padding-rl10" fxLayout="row" [style.height]="data.blogOverallHeight"
    [ngStyle.lt-md]="{ 'height': blogOverallHeightMob}" fxLayoutAlign="center center">

    <div fxFlex="100" class="f16 text123444" style="cursor: pointer;" (click)="goToBlogData(blog)">
      <b class="modernWay1" [style.-webkit-line-clamp]="blogNameDisplayLine" [style.line-clamp]="blogNameDisplayLine"
        [ngStyle.lt-md]="{ '-webkit-line-clamp': blogNameDisplayLineMob, 'line-clamp': blogNameDisplayLineMob }">{{blog.title}}</b>
    </div>


  </div>

  <div class="productPrice padding-rl10" fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center" style="cursor: pointer;" (click)="goToBlogData(blog)">
      <b class="modernWay1" [style.-webkit-line-clamp]="blogNameDisplayLine" [style.line-clamp]="blogNameDisplayLine"
        [ngStyle.lt-md]="{ '-webkit-line-clamp': blogNameDisplayLineMob, 'line-clamp': blogNameDisplayLineMob }">
        {{blog.rootTemplate}}</b>
    </div>
  </div>


  <div fxLayout="row" class="padding-10 ">
    <div fxFlex="100" class="description" style="cursor: pointer;" (click)="goToBlogData(blog)">
      <b class="modernWay1" [style.-webkit-line-clamp]="blogNameDisplayLine" [style.line-clamp]="blogNameDisplayLine"
        [ngStyle.lt-md]="{ '-webkit-line-clamp': blogNameDisplayLineMob, 'line-clamp': blogNameDisplayLineMob }">{{blog.customField2}}</b>
    </div>
  </div>

  <div fxLayout="row" class="padding-10">
    <button class="primary" fxFlex (click)="goToBlogData(blog)" style="color: white" mat-flat-button>
      <span>Read More</span>
    </button>
  </div>


</div>