import { Component } from '@angular/core';

@Component({
  selector: 'calsoft-imageslideranimation',
  templateUrl: './imageslideranimation.component.html',
  styleUrls: ['./imageslideranimation.component.scss']
})
export class ImageslideranimationComponent {

}
