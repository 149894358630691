<div class="container" *ngIf="item.customField25=='1'">
    <div fxFlex="50">
        <owl-carousel-o [options]="LogoSlider" #owlCar class="no-arrow">
            <ng-container *ngFor="let img of item.images; let l = index">

                <ng-template carouselSlide>
                    <div>
                        <!-- <img fxFlex="100" (click)="img.navigation==0?goToCategories(img.catId):link(img.navigatedUrl)"
                            [alt]="img.altImage" onerror="this.src='';" [src]="serverPath+img.img" 
                            [ngStyle.lt-md]="{'height': item.heightMob}"
                            [style.height]="item.height"> -->
                        <calsoft-ng-optimized-image fxFlex="100" [height]="item.height" [heightMob]="item.heightMob"
                                [navigation]="0" [altImage]="img.altImage" [image]="serverPath+img.img"
                                [i]="'0'">
                        </calsoft-ng-optimized-image>


                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
    <div fxFlex="50">

        <div fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="center center" class="title">
                <h2 class="textwithImage">
                    <calsoft-editor-text [data]="data" [text]="item?.title" [align]="item.customField34"
                    [style.font-size]="item.customField33" [ngStyle.lt-md]="{ 'font-size': item.customField34 }">
                    </calsoft-editor-text>
                </h2>
            </div>
            <div fxLayout="row"  fxLayoutAlign="center center" class="description">
                <calsoft-editor-text [data]="data" [text]="item?.customField15" [align]="item.customField34"
                [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">
                </calsoft-editor-text>
            </div>

        </div>
    </div>
    </div>

    <div  class="container" *ngIf="item.customField25=='2'" [fxLayout.gt-md]="item.customField26=='0'?'row':'row-reverse'" fxShow fxHide.lt-md>
        <div fxFlex="50">
            <div class="image-slider-container">
            <div id="image-slider">
                <ul >
                  <li *ngFor="let img of item.images; let l = index" style="width: 100%;"><img [src]="serverPath+img.img"></li>                  
                </ul>
            </div>

            <div class="controls">
                <button mat-icon-button (click)="moveSlideDown()" title="previous-image" class="btnicons"  [ngStyle]="{ 'border': '1px solid ' + item.customField27 }" >
                    <mat-icon>keyboard_arrow_up</mat-icon>
                  </button>
                  
                  <button mat-icon-button (click)="moveSlideUp()" title="next-image" class="btnicons" [ngStyle]="{ 'border': '1px solid ' + item.customField27 }">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
            </div>
            </div>
        </div>
        <div fxFlex="50">    
            <div fxLayout="column">
                <!-- <div fxLayout="row" fxLayoutAlign="start center" class="title">
                    <h2 class="textwithImage">
                        <calsoft-editor-text [data]="data" [text]="item?.title" [align]="item.customField34">
                        </calsoft-editor-text>
                    </h2>
                </div> -->
                <div fxLayout="row"  fxLayoutAlign="start center" class="description" style="margin-top: 50px;">
                    <calsoft-editor-text [data]="data" [text]="item?.customField15" [align]="item.customField34"
                    [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">
                    </calsoft-editor-text>
                </div>
                <div fxLayoutAlign="start center">
                    <button mat-button class="btncss" [style.background-color]="item.customField27" (click)="openInNewWindow(item.customField29)">{{item.customField28}}<mat-icon>chevron_right</mat-icon></button>
                </div>
            </div>
        </div>
    </div>

    <div class="container" *ngIf="item.customField25=='2'" fxShow fxHide.gt-sm>
        <div fxLayout="column">
            <!-- <div fxLayout="row" fxLayoutAlign="start center" class="title">
                <h2 class="textwithImage">
                    <calsoft-editor-text [data]="data" [text]="item?.title" [align]="item.customField34">
                    </calsoft-editor-text>
                </h2>
            </div> -->
            <div fxLayout="row"  fxLayoutAlign="center center" class="description" style="margin-top: 5px;margin-bottom: 15px;">
                <calsoft-editor-text [data]="data" [text]="item?.customField15" [align]="item.customField34"
                [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">
                </calsoft-editor-text>
            </div>

            <owl-carousel-o [options]="LogoSlider1" #owlCar class="no-arrow">
                <ng-container *ngFor="let img of item.images; let l = index">
                    <ng-template carouselSlide>
                        <div>
                            <!-- <img fxFlex="100" (click)="img.navigation==0?goToCategories(img.catId):link(img.navigatedUrl)"
                                [alt]="img.altImage" onerror="this.src='';" [src]="serverPath+img.img" [ngStyle.lt-md]="{'height': item.heightMob}"
                                [style.height]="item.height" style="border-radius: 8px;"> -->
                            <calsoft-ng-optimized-image fxFlex="100" style="border-radius: 8px;" [height]="item.height" [heightMob]="item.heightMob"
                                [navigation]="0" [altImage]="img.altImage" [image]="serverPath+img.img"
                                [i]="'0'">
                            </calsoft-ng-optimized-image>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>

            <div fxLayoutAlign="center center">
                <button mat-button class="btncss" [style.background-color]="item.customField27" (click)="openInNewWindow(item.customField29)">{{item.customField28}}<mat-icon>chevron_right</mat-icon></button>
            </div>
        </div>
    </div>