import { Component, Input, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import Swiper from 'swiper';

@Component({
  selector: 'calsoft-image-type10',
  templateUrl: './image-type10.component.html',
  styleUrls: ['./image-type10.component.scss']
})
export class ImageType10Component {

  public serverPath = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() data: any;

  @Input() img: any;

  imageUrlSuffix = '?w=1600&auto=format&dpr=2'; 


  hoverIndex: number = 0;

  image:any;

  showInfo(index) {
    this.hoverIndex =index;
    this.image= this.serverPath+this.item.images[this.hoverIndex].img
  }


  ngOnInit(): void {

    this.image= this.serverPath+this.item.images[this.hoverIndex].img

  }

  @ViewChild('swiper', { static: true }) swiperContainer: any;
  
  ngAfterViewInit() {
    new Swiper(this.swiperContainer.nativeElement, {
      effect: 'coverflow',
     // grabCursor:true,
      centeredSlides: true,
      slidesPerView: 'auto',
      loopedSlides: 1,
      loop:true,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 300,
        modifier: 1,
        slideShadows: true,
      },
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 40
        }
      }
    });
    }
  
  
    openlink(url: string) {
      console.log(url);
      if (url) {
        const fullURL = this.prependProtocol(url);
        window.open(fullURL, '_blank');
      }
    }
    
    prependProtocol(url: string): string {
      if (!url.includes('http://') && !url.includes('https://')) {
        return 'https://' + url;
      }
      return url;
    }

    
}
