<div class="container" >
    <div class="carousel-container" [style.background-color]="item.customField8" [style.backgroundImage]="'url(' +serverPath+item.customField6 + ')'">
      <owl-carousel-o [options]="carouselOptions" #owlCar >
        <ng-container *ngFor="let image of item.images">
          <ng-template carouselSlide>
            <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="carousel-slide" >
              <h2 [innerHTML]="image.customField20" [style.color]="item.customField5" ></h2>
              <p [innerHTML]="image.customField21"  [style.color]="item.customField5"  [ngStyle.gt-md]="{'font-size': item.customField34 }"
              [ngStyle.lt-md]="{'font-size': item.customField35}"></p>
              <img (click)="image.navigation==0?goToCategories(image.catId):link(image.navigatedUrl)" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob }"  
              [src]="bannerServerPath +image.img" [alt]="image.altImage"  class="carousel-image" />
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
  
      <!-- Custom Navigation -->
      <div class="custom-nav" fxShow fxHide.lt-md>
        <div class="owl-prev" (click)="owlCarousel.prev()">
          <mat-icon class="custom-icon">keyboard_arrow_left</mat-icon>
        </div>
        <div class="owl-next" (click)="owlCarousel.next()">
          <mat-icon class="custom-icon">keyboard_arrow_right</mat-icon>
        </div>
      </div>
    </div>
  </div>
  