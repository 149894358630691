import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, Inject, ViewChild, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatIconRegistry } from '@angular/material/icon';
import { Router } from "@angular/router";
import { CartService } from "src/app/services/cart.service";
import { CurrencyConversionService } from "src/app/services/currency-conversion.service";
import { UntypedFormControl, Validators } from "@angular/forms";
import { CommonService } from "src/app/services/common.service";
import { DOCUMENT } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { ConfigService } from "src/app/services/config.service";
import { Constant } from "src/app/constants/constant";

@Component({
  selector: 'calsoft-controls-cart',
  templateUrl: './controls-cart.component.html',
  styleUrls: ['./controls-cart.component.scss']
})
export class ControlsCartComponent {

  messageControl = new UntypedFormControl('', [Validators.required]);

  @Input() userId: any;
  @Input() userName: any;
  @Input() product: any;
  @Input() data: any;
  @Input() type: any;
  @Input() type1: string;
  @Input() disableControl: any;
  @Input() productCustomOption: any;
  @Input() productOption: any[];
  //  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onQuantityStockChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() addCart: EventEmitter<any> = new EventEmitter<any>();

  @Output() getProductIdCount: EventEmitter<any> = new EventEmitter<any>();
  public count: number = 1;
  public align = "center center";
  cartCount: any;
  page1: string;
  productArray: any;
  authourized: string;
  qtyInput = new UntypedFormControl('0');

  stockInHandInput = new UntypedFormControl('0');
  map = this.cartServices.getCartValue();

  qty = 0;
  stockInHandQty = 0;

  stockInFormControl = new UntypedFormControl(0);

  qtyFormControl = new UntypedFormControl(0);

  inc: number = 0;
  spinner: boolean = false;

  @Input() showCartIcon: any;

  @Input() showCartIncremented: any;


  @Input() buttonArray: any = [];

  @Input() subscription: any = [];


  @Input() configproduct: any = [];
  @Input() variant1Name: any;
  @Input() variant2Name: any;
  @Input() variant1Value: any;
  @Input() variant2Value: any;

  variantList: any = [];
  wishMapDisplay: boolean = false;
  addToCartType: any;
  addToCartSymbolType: any;

  bookingEnabled: boolean = false;
  text: string;
  cartUpdated: boolean=false;


  constructor(
    public cartServices: CartService,
    private configService: ConfigService,
    // private userService: UsersService,
    private dialog: MatDialog,
    private currencyConversionService: CurrencyConversionService,
    private router: Router,
    public snackBar: MatSnackBar,
    private commonService: CommonService,

    @Inject(DOCUMENT) private document: Document,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,

  ) {

    this.matIconRegistry.addSvgIcon(
      "whatsapp",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/whatsapp.svg")
    );

  }
  public values: any;
  ngOnInit() {


    localStorage.setItem("cartUpdate","0");

    if (this.product) {

      if (this.product.cartCount > 0) {
        this.count = this.product.cartCount;
      }

    }

    if (this.product.type == 'Booking') {
      this.bookingEnabled = true;
      this.text = "BOOK NOW"
    } else {
      this.text = "ADD TO CART"
    }


    this.authourized = this.userName;
    this.layoutAlign();

    if(this.product.entityId ! == null){
    this.setCount(this.product.entityId);
    }

    if (this.type=='wish') {
 
      this.qtyInput.patchValue(this.product.controls.qty.value)

      this.stockInHandInput.patchValue(this.product.controls.stockInHand.value)

      this.previousQty = this.product.controls.qty.value;

      this.previousStockInHand = this.product.controls.stockInHand.value;
    

    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes != undefined) {

      if (changes.product != undefined) {
        let objectLength: any = Object.keys(changes.product.currentValue).length != 0;
        if (objectLength) {
          let productId = changes.product.currentValue.entityId
          this.inc = 0
          if(productId ! == null){
          this.setCount(productId);
          }
        } else if (changes.productOption != undefined) {

          this.productOption = changes.productOption.currentValue
        }

      }

    }

  }


  setCount(productId) {


    if (this.inc == 0 && this.product != undefined) {
      if (this.router.url.includes("/pr?sid=")) {
        this.inc = 1;

        this.qty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["qty"] == undefined ? 0 : this.map[productId]["qty"];
        this.stockInHandQty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["stockInHand"] == undefined ? 0 : this.map[productId]["stockInHand"];
        //this.getCustomerCount();
      } else if (this.router.url.includes('/home') || this.router.url == '/') {
        this.inc = 1;
        this.qty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["qty"] == undefined ? 0 : this.map[productId]["qty"];
        this.stockInHandQty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["stockInHand"] == undefined ? 0 : this.map[productId]["stockInHand"];
      }
      else if (this.router.url.includes('/product')) {
        this.inc = 1;
        this.qty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["qty"] == undefined ? 0 : this.map[productId]["qty"];
        this.stockInHandQty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["stockInHand"] == undefined ? 0 : this.map[productId]["stockInHand"];
      } else if (this.router.url.includes('/search')) {
        this.inc = 1;
        this.qty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["qty"] == undefined ? 0 : this.map[productId]["qty"];
        this.stockInHandQty = this.map == undefined ? 0 : this.map[productId] == undefined ? 0 : this.map[productId]["stockInHand"] == undefined ? 0 : this.map[productId]["stockInHand"];
      }



      this.qtyFormControl.patchValue(this.qty)


      this.emitQtySubscription.emit(this.qty)

    }
  }

  @Output() emitQtySubscription: EventEmitter<any> = new EventEmitter<any>();

  public layoutAlign() {
    if (this.type == "all") {
      this.align = "start center";
    } else if (this.type == "wish") {
      this.align = "start center";
    } else {
      this.align = "start center";
    }
  }

  public increment(qty, type) {

    qty++;
    localStorage.removeItem(Constant.CART_COUNT)
    this.cartServices.responseCache1 = new Map();



    if (type == 'all') {
      this.changeQuantity(qty);
    } else {
      this.changeStockInHand(qty);
    }

  }
  previousQty: any = 0;

  previousStockInHand: any = 0;
  updateQty(value, event, type) {

    if (event.code == 'Enter') {
      event.stopImmediatePropagation();
    } else {
      event.stopImmediatePropagation();
      event.preventDefault();
    }


    if (type == 'all') {
      if (this.previousQty != value) {
        this.emit(value);
      } else {

        this.previousQty = value

      }

    } else {
      if (this.previousStockInHand != value) {
        this.changeStockInHand(value);
      } else {

        this.previousStockInHand = value

      }
    }
  }

  emit(value) {

    this.onQuantityChange.emit(value);
  }



  public decrementass(qty) {
    if (qty < 0) {
      qty = 0
    }
    localStorage.removeItem(Constant.CART_COUNT)
    this.cartServices.responseCache1 = new Map();
    qty--;
    if (qty < 0) {
      qty = 0
    }
    if (this.type == 'all' || this.type == 'wish') {
      this.changeQuantity(qty);
    } else {
      this.changeStockInHand(qty);
    }
  }

  public addToCart(product, type, value) {



 
    if (this.bookingEnabled) {
     
       // this.openSlotComponent()
      

    } else {

      this.addToCartProduct(product, type, value,);
    
    }
  }


  // openSlotComponent() {
  //   if (window.innerWidth > 768) {
  //     let dialogRef = this.dialog.open(SlotDetailComponent, {
  //       disableClose: true,
  //       width: '900px',
  //       data: {
  //         id: this.product.entityId,
  //         productData: this.product,
  //         data: this.data
  //       }
  //     });
  //     dialogRef.afterClosed().subscribe((result) => { });
  //   } else {
  //     let dialogRef = this.dialog.open(SlotDetailComponent, {
  //       minWidth: '100vw',
  //       height: '100vh',
  //       data: {
  //         id: this.product.entityId,
  //         productData: this.product,
  //       }
  //     });
  //     dialogRef.afterClosed().subscribe((result) => { });
  //   }
  // }


  addToCartProduct(product, type, value) {


    let entityId = 0;
    if ((this.configproduct.type == 'Configurable'
      || this.configproduct.type == 'Variants')
      && this.configproduct.configurableProductDropdown != null) {
      if (this.configproduct.entityId != product.entityId) {
        let object = {
          productId: this.configproduct.entityId,
          qty: value,
          operationType: "New",
        }
        this.productOption = [];
        this.variantList.push(object);
      }

    }

    let subscription = null;

    if (this.type == 'subscription') {
      subscription = this.subscription;
    }
    this.spinner = true;
    let currency: any = localStorage.getItem('currency');
   



    let currencyFrom = this.currencyConversionService.getCountryCode();
    if (currencyFrom == null) {
      currencyFrom = "INR";
    }
    this.authourized = this.userName;
    let cart = {
      customerId: this.userId,
      itemsQty: 1,
      currencyCode: currencyFrom,
      operationType: "New",
      remoteIp: "192.168.5.99",
      userAuthenticated: "",
      salesFlatQuoteItem: [
        {
          productId: product.entityId,
          qty: value,
          operationType: "New",
          productType: this.configproduct.type,
          subChild: this.variantList,
          manageStockSepConfig: this.configproduct.maintainStockIndConfProduct,
          manageImageSepConfig: this.configproduct.manageImageSepConfig,
          managePriceSepConfig: this.configproduct.maintainPriceIndConfProduct,
          subscription: subscription,
          productCustomOption: this.productCustomOption,
          salesFlatQuoteItemOption: this.productOption

        },
      ],
    };
    this.cart(cart, type, value, product);
  }


  cart(cart, type, value, product) {
    this.cartServices.addCartItem(cart).subscribe(
      (response) => {
        if (response["status_code"] == Constant.RESPONSE_SUCCESS) {

          this.cartUpdated=true;
         
          localStorage.setItem("cartUpdate","1");
          if (type != "buyNow") {
            if (window.innerWidth < 850) {
              this.snackBar.open(response["status_message"], "×", {
                panelClass: "success",
                verticalPosition: "top",
                duration: 3000,
              });
            } else {


              this.snackBar.open(response["status_message"], "×", {
                panelClass: "success",
                verticalPosition: "bottom",
                duration: 3000,
              });

            }

            this.values = this.cartServices.getCartValue();
            this.cartServices.responseCache1 = new Map();
            if (this.values == undefined) {

              let object = {
                "qty": value,
                "stockInHand": 0
              }
              // this.values = new Map<any, any>();
              // this.values.set(
              //   this.product['entityId'],
              //   object
              // );
              const convMap = {};

              convMap[this.product['entityId']] = object;
              this.values = convMap;

              this.cartServices.setCartValue(this.values);
              this.qty = this.values[this.product["entityId"]]["qty"];
              this.qtyFormControl.patchValue(this.qty)
            } else {
              if (this.values[this.product['entityId']] == undefined) {

                let data = this.values;
                let object = {
                  "qty": value,
                  "stockInHand": 0
                }
                data[this.product["entityId"]] = object;

                this.values = data;

                let encryptedValue = btoa(JSON.stringify(this.values));

                // this.userService.storeProductCartCount(encryptedValue);
              } else {
                this.values[this.product["entityId"]]["qty"] =
                  this.values[this.product["entityId"]]["qty"] + value;
              }


              this.cartServices.setCartValue(this.values);

              this.qty = this.values[this.product["entityId"]]["qty"];





              if (this.qty == undefined) {
                this.qty = 0;
              }
              this.qtyFormControl.patchValue(this.qty)
            }



          } else {
            if (this.values == undefined) {

              let object = {
                "qty": value,
                "stockInHand": 0
              }
              // this.values = new Map<any, any>();
              // this.values.set(
              //   this.product['entityId'],
              //   object
              // );
              const convMap = {};

              convMap[this.product['entityId']] = object;
              this.values = convMap;

              this.cartServices.setCartValue(this.values);
              this.qty = this.values[this.product["entityId"]]["qty"];
              this.qtyFormControl.patchValue(this.qty)
            }
            if (this.values[this.product['entityId']] == undefined) {
              this.values[this.product["entityId"]] = value;
              let encryptedValue = btoa(JSON.stringify(this.values));

              // this.userService.storeProductCartCount(encryptedValue);
            } else {
              this.values[this.product["entityId"]] =
                this.values[this.product["entityId"]] + value;
            }


            this.cartServices.setCartValue(this.values);

            this.qty = this.values[this.product["entityId"]];
            localStorage.removeItem(Constant.CART_COUNT)
            this.cartServices.responseCache1 = new Map();

            // if(this.product.)

            if (this.product['isCustomizedValue'] != null) {

              if (this.product['isCustomizedValue']['Prescription Required']
                != null && this.product['isCustomizedValue']['Prescription Required']
                != undefined && this.product['isCustomizedValue']['Prescription Required']
                != '') {

                let value = this.product['isCustomizedValue']['Prescription Required']
                  == 1 ? true : false;



                if (value) {
                  this.router.navigate(['/checkout/type'])
                } else {
                  this.router.navigate(["/checkout"]);
                }

              } else if (this.product['isCustomizedValue']['Prescription_Required']
                != null && this.product['isCustomizedValue']['Prescription_Required']
                != undefined && this.product['isCustomizedValue']['Prescription_Required']
                != '') {

                let value = this.product['isCustomizedValue']['Prescription_Required'] == 1 ? true : false;

                if (value) {
                  this.router.navigate(['/checkout/type'])
                } else {
                  this.router.navigate(["/checkout"]);
                }

              }


              else {
                this.router.navigate(["/checkout"]);
              }




              this.qtyFormControl.patchValue(this.qty)
            } else {
              this.router.navigate(["/checkout"]);
            }
          }

        } else {
          this.snackBar.open(response["status_message"], "×", {
            panelClass: "error",
            verticalPosition: "bottom",
            duration: 3000,
          });
        }
        this.spinner = false;
       
      },
      (err) => { this.spinner = false; }
    );

  }

  public changeQuantity(value) {
    this.onQuantityChange.emit(value);
  }

  public changeStockInHand(value) {
    this.onQuantityStockChange.emit(value);
  }





  // getCustomerCount() {

  //   let authourized = this.userService.getAuthenticatedUser();
  //   let guestLoginEnabled = localStorage.getItem(Constant['GUEST_LOGIN'])
  //   if (authourized || guestLoginEnabled == "1") {

  //     if (this.product != undefined) {


  //       this.cartServices.getCustomerCartProductCount().subscribe(
  //         (response) => {


  //           this.values = response;


  //           this.qty = this.values[this.product["entityId"]];


  //           if (this.qty == undefined) {
  //             this.qty = 0;
  //           }
  //           let value = localStorage.getItem(Constant.CART_COUNT);
  //           if (value == null) {
  //             let encryptedValue = btoa(JSON.stringify(response));

  //             this.userService.storeProductCartCount(encryptedValue);
  //           }
  //         },
  //         (err) => { }
  //       );
  //     }
  //   }



  // }



  link(buttonObject) {

    let url = buttonObject.link
    window.location.href = url;
  }

  isMouseOver: boolean = false;

  onMouseEnter() {
    this.isMouseOver = true;
  }

  onMouseLeave() {
    this.isMouseOver = false;
  }

  notFound() {
    // this.snackBar.open('Subscription is already Added in cart', '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
    this.router.navigate(["/checkout"]);
  }


  whatsapp() {

    let value = "";

    if ((this.configproduct['type'] == "Configurable" ||
      this.configproduct['type'] == "Variants")
      && this.configproduct['configurableProductDropdown']
      != null) {

      this.configproduct.configId = this.product.entityId;
    //  value = this.commonService.retrunProductUrl(this.configproduct)
    } else {
     // value = this.commonService.retrunProductUrl(this.configproduct)
    }

    let url1 = this.data['storeName'];
    let fullUrl = "Hello " + url1 + "! I want to order 1 " + this.product['name'].replace(/[^a-zA-Z ]/g, "").replace(/ /g, '') + "%F0%9F%91%89" + this.document.location.origin + "/" + value

    let url = `https://api.whatsapp.com/send?phone=${this.data['storePhoneNumber']}&text=${fullUrl}`

    window.open(url, '_blank');
  }


  addToWishlistText: string = "ADD TO WISHLIST";
  public addToWishList(product) {
 
  }



}

