import { Component, Input,OnInit } from '@angular/core';

@Component({
  selector: 'calsoft-price-chart-view',
  templateUrl: './price-chart-view.component.html',
  styleUrls: ['./price-chart-view.component.scss']
})
export class PriceChartViewComponent implements OnInit{
  @Input() item: any;
  @Input() data: any;
  tabledata: any[] = [{  }];
  items:any;
  constructor() {

  }


  ngOnInit() {   
    this.items = this.item;      
   // console.log(JSON.parse(this.item.customField34));
    this.tabledata = JSON.parse(this.item.customField34);
    this.tableHeaders=Object.keys(this.tabledata[0]);

  }

  tableHeaders: string[] = Object.keys(this.tabledata[0]);


}
