
<div fxHide.lt-md>
    <div class="custom-carousel" style=" padding: 40px;" [style.background-color]="item.customField8"  >

        <h2 fxLayoutAlign="center center" [innerHTML]="item.title"></h2>
        <owl-carousel-o [options]="LogoSlider" #owlCar class="no-arrow">
            <ng-container *ngFor="let img of item.images; let i = index">
    
                <ng-template carouselSlide>
    
                    <div fxFlex="70">
                        <p class="testimonial-text">
                            <span [innerHTML]="img.customField21"></span>
                        </p>
                        <p class="testimonial-author" [innerHTML]="img.customField20">
    
                        </p>
                        <!-- <div class="rating-input">
                        <label for="ratingCount">Enter Rating (e.g., 3/5):</label>
                        <input type="text" id="ratingCount" [(ngModel)]="ratingInput" (ngModelChange)="updateRating()" />
                      </div> -->
                        <calsoft-rate-star [rating]="img.customField9"></calsoft-rate-star>
                        <!-- <p>Your rating: {{ rating }} / {{ count }}</p> -->
                    </div>
                    <div fxFlex="30" class="testimonial-image" contentAnim [aniName]="img.customField7">
                        <img [src]="serverPath+img.img" [alt]="img.altImage">
                    </div>
    
    
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    
    </div>  
</div>

<div fxHide fxShow.lt-md>
    <div class="custom-carousel" style=" padding: 40px;" [style.background-color]="item.customField8">

        <h2 fxLayoutAlign="center center" [innerHTML]="item.title"></h2>
        <owl-carousel-o [options]="LogoSlider" #owlCar class="no-arrow">
            <ng-container *ngFor="let img of item.images; let i = index">
    
                <ng-template carouselSlide >
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div fxFlex="100">
                            <p class="testimonial-text">
                                <span [innerHTML]="img.customField21"></span>
                            </p>
                            <p class="testimonial-author" [innerHTML]="img.customField20">
        
                            </p>
                            <!-- <div class="rating-input">
                            <label for="ratingCount">Enter Rating (e.g., 3/5):</label>
                            <input type="text" id="ratingCount" [(ngModel)]="ratingInput" (ngModelChange)="updateRating()" />
                          </div> -->
                            <calsoft-rate-star [rating]="img.customField9"></calsoft-rate-star>
                            <!-- <p>Your rating: {{ rating }} / {{ count }}</p> -->
                        </div>
                        <div fxFlex="100" class="testimonial-image">
                            <img [src]="serverPath+img.img" [alt]="img.altImage">
                        </div>
        
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    
    </div>
</div>

