<span [ngClass]="{'top-left': img.customField29 === '1', 'top-right': img.customField29 === '2', 'centered': img.customField29 === '3',
    'bottom-left': img.customField29 === '4','bottom-right': img.customField29 === '5' }">

    <div>
        <h3 [style.color]="item.customField22"  [style.font-size]="item.customField31"  [ngStyle.lt-md]="{ 'font-size': item.customField32}" 
        class="intro-subtitle text-white">{{img.customField4}}</h3>
        <h1 [style.color]="item.customField22" [style.font-size]="item.customField39"  [ngStyle.lt-md]="{ 'font-size': item.customField40}"
         class="intro-title text-white" [innerHTML]="img.customField5"></h1>

        <ng-container *ngIf="img.customField3.length !== 0; else noCustomField3">
            <button (click)="link(img.customField6)" [style.border-color]="item.customField33" [style.color]="item.customField33" [style.background-color]="item.customField34" class="btn btn-outline-white-4 btnview" target="_blank"
            (mouseover)="handleMouseOver($event.target, item)"
            (mouseout)="handleMouseOut($event.target, item)"
            >{{img.customField3}}
            <!-- <span style="align-items: center;" >{{img.customField3}}</span> -->
        </button>
        </ng-container>    
        
        <ng-template #noCustomField3>
            <span>{{img.customField3}}</span>
        </ng-template>
    </div>

  </span>
