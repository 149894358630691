import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-new-menu',
  templateUrl: './new-menu.component.html',
  styleUrls: ['./new-menu.component.scss']
})
export class NewMenuComponent implements OnInit{
  showMegamenu = false;
  categoryArray:any;
  autoColumns: number;
  megamenuPosition: string = 'bottom'; // Default position

  constructor(
    private categoryService: CategoryService, private commonService: CommonService,
  ){

  }
  ngOnInit() {
    this.getCategories();
  }

  goToCategory(menu) {

    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);

  }


  getCategories() {
    this.categoryService.categoryList().subscribe(
       result => {
          this.categoryArray = result['data'];

       },
       error => {
       })
  }

  calculateMegamenuPosition(categoryElement: HTMLElement): { [key: string]: string } {
    const windowHeight = window.innerHeight;
    const megamenuElement = categoryElement.querySelector('.megamenu') as HTMLElement;

    if (megamenuElement) {
      const megamenuWidth = megamenuElement.offsetWidth;
      const megamenuHeight = megamenuElement.offsetHeight;

      // Check if there is enough space below, otherwise adjust the position
      const isBelowSpaceEnough = windowHeight - (categoryElement.offsetTop + megamenuHeight) > 0;
      const topPosition = isBelowSpaceEnough
        ? (categoryElement.offsetTop + 10).toString() // Add a small offset at the top
        : (windowHeight - megamenuHeight - 10).toString(); // Add a small offset from the bottom

      const parentCategoryWidth = categoryElement.offsetWidth;
      const leftPosition = (categoryElement.offsetLeft + parentCategoryWidth).toString();

      return { 'top.px': topPosition, 'left.px': leftPosition };
    }

    return {};
  }

  calculateFlexValue(auto: any[], maxColumns: number): string {
    const totalItems = auto.length;
    const minColumns = Math.min(totalItems, maxColumns);
    
    if (minColumns > 0) {
      return `calc(100% / ${minColumns})`;
    } else {
      // Fallback value if there are no items
      return '100%';
    }
  }

  calculateWidth(categoryElement: HTMLElement): number {
    const megaMenuHeight = this.calculateMegaMenuHeight(categoryElement);
    
    // You can adjust this multiplier based on your requirements
    const widthMultiplier = 1.5; // Adjust this value as needed
  
    // Calculate width based on mega menu height
    return megaMenuHeight * widthMultiplier;
  }

  calculateMegaMenuHeight(categoryElement: HTMLElement): number {
  
    const megaMenu = categoryElement.querySelector('.megamenu') as HTMLElement;

    if (megaMenu) {
      const megaMenuStyles = getComputedStyle(megaMenu);
      const megaMenuHeight = megaMenu.offsetHeight + 
        parseInt(megaMenuStyles.marginTop) + 
        parseInt(megaMenuStyles.marginBottom);
      
      return megaMenuHeight;
    }
    
    return 0; // Return a default value if mega menu is not found
  }

  calculateColumnWidth(subcategories: any[]): string {
  
    const totalItems = subcategories.length;
    const autoColumns = Math.min(totalItems);
    return `calc(100% / ${autoColumns})`;
  }

  calculateColumnStyle(subcategories: any[]): any {
    const maxColumns = 4; // Set your maximum number of columns
    const totalItems = subcategories.length;
    const autoColumns = Math.min(totalItems, maxColumns);
    const columnHeight = 400; // Set your desired height for each column
    const maxRows = Math.ceil(totalItems / autoColumns);
    const maxContainerHeight = maxRows * columnHeight;
  
    return {
      'flex-basis': `calc(100% / ${autoColumns})`,
      'max-height': `${maxContainerHeight}px`,
      'overflow-y': 'auto',
    };
  }


  onHover(menuItem: any) {
    const unsetElement = document.getElementById('unset');
  
    if (window.innerWidth > 1200 && unsetElement) {
      unsetElement.classList.add('sidebar-unset');
    } else if (unsetElement) {
      unsetElement.classList.remove('sidebar-unset');
    }
  
    this.setMegamenuPosition();
  }
  
  setMegamenuPosition() {
    // Adjust the breakpoint as needed
    this.megamenuPosition = window.innerWidth > 768 ? 'bottom' : 'top';
  }


}
