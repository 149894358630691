import { Component } from '@angular/core';

@Component({
  selector: 'app-card-slide-popup',
  templateUrl: './card-slide-popup.component.html',
  styleUrls: ['./card-slide-popup.component.scss']
})
export class CardSlidePopupComponent {
  services = [
    { 
      imgUrl: 'https://www.rmscloud.com/hubfs/Agoda_op-1.jpg',
      altText: 'Agoda',
      title: 'Agoda',
      description: 'Expand your reach with Agoda\'s global network and diverse offerings, simplified through a user-friendly platform.',
      popupId: 1,
      imageUrl: 'https://www.rmscloud.com/hubfs/RMS%20Partner%20Directory%20Images/Product%20Images/Agoda_01_op.jpg',
      partnerName: 'Agoda',
      category: 'Channel Managers, Online Travel Agents',
      status: 'Active'
    },
    {
      imgUrl: 'https://www.rmscloud.com/hubfs/AirBNB_op-1.jpg',
      altText: 'Airbnb',
      title: 'Airbnb',
      description: 'Integrate Airbnb with RMS for global exposure, easy listing management and enhanced property visibility.',
      popupId: 2,
      imageUrl: 'https://www.rmscloud.com/hubfs/RMS%20Partner%20Directory%20Images/Product%20Images/AirBNB_01_op.jpg',
      partnerName: 'Airbnb',
      category: 'Online Travel Agents',
      status: 'Active'
    },
    {
      imgUrl: 'https://www.rmscloud.com/hubfs/RMS%20Partner%20Directory%20Images/Logos/Hostel%20World_op.jpg',
      altText: 'Hostelworld',
      title: 'Hostelworld',
      description: 'Hostelworld Group is a leading Online Travel Agent focused on the hostelling category, with a well-known trusted brand.',
      popupId: 3,
      imageUrl: 'https://www.rmscloud.com/hubfs/RMS%20Partner%20Directory%20Images/Product%20Images/booking-com_01_op.jpg',
      partnerName: 'Hostelworld',
      category: 'Online Travel Agents',
      status: 'Active'
    },
    {
      imgUrl: 'https://www.rmscloud.com/hubfs/RMS%20Partner%20Directory%20Images/Logos/booking.com_op.jpg',
      altText: 'Booking.com',
      title: 'Booking.com',
      description: 'Maximize bookings with Booking.com global reach and diverse accommodation options, user-friendly for all.',
      popupId: 4,
      imageUrl: 'https://www.rmscloud.com/hubfs/RMS%20Partner%20Directory%20Images/Product%20Images/Expedia_01_op.jpg',
      partnerName: 'Booking.com',
      category: 'Online Travel Agents',
      status: 'Active'
    },
    {
      imgUrl: 'https://www.rmscloud.com/hubfs/RMS%20Partner%20Directory%20Images/Logos/Expedia_op.jpg',
      altText: 'Expedia',
      title: 'Expedia',
      description: 'Expedia Group is one of the world\'s largest travel platforms. With unrivaled industry knowledge and advanced tech.',
      popupId: 5,
      imageUrl: 'https://www.rmscloud.com/hubfs/RMS%20Partner%20Directory%20Images/Product%20Images/Expedia_01_op.jpg',
      partnerName: 'Expedia',
      category: 'Online Travel Agents',
      status: 'Active'
    },
    {
      imgUrl: 'https://www.rmscloud.com/hubfs/Google%20Hotel%20Ads_op.jpg',
      altText: 'Google Hotel Ads',
      title: 'Google Hotel Ads',
      description: 'Help your next guest find you on Google. From bed and breakfasts to resorts, hundreds of millions of travelers around the world search for hotels on Google. Connect with them using our free and paid products, and watch bookings grow',
      popupId: 6,
      imageUrl: 'https://www.rmscloud.com/hubfs/RMS%20Partner%20Directory%20Images/Product%20Images/Google%20Hotel%20Ads_01_op.jpg',
      partnerName: 'Google Hotel Ads',
      category: 'Advertising',
      status: 'Active'
    }
    // Add more services as needed
  ];

  selectedService: any = null;
  isPopupVisible = false;

  openPopup(service: any) {
    this.selectedService = service;
    this.isPopupVisible = true;
  }

  closePopup() {
    this.isPopupVisible = false;
  }
}
