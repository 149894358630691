import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Constant } from '../constants/constant';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private http: HttpClient,
    private userService: UserService
    
    ) { }

  url = environment.serverAPI3 + "/api/order";

  url3 = environment.serverAPI4 + "/api/order";

  url2 = environment.serverAPI3 + "/api/ig/order";

  getOrder(Page: number, size: number, sort: any): Observable<Object> {

    return this.http.get(`${this.url}/details?page=${Page}&size=${size}&sort=${sort}`);
  }

  getCourse(Page: number, size: number, sort: any): Observable<Object> {

    return this.http.get(`${this.url}/course/?page=${Page}&size=${size}&sort=${sort}`);
  }

  getOrders(): Observable<Object> {
    return this.http.get(`${this.url}/details`);

  }
  getOrderById(entityId: number): Observable<Object> {
    let authourized = this.userService.getAuthenticatedUser();
let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if (authourized==null||guestLoginEnabled=="1") {
    return this.http.get(`${this.url2}/details/${entityId}`);
    }else{
      return this.http.get(`${this.url}/details/${entityId}`);
    }

  }

  downloadInvoice(entityId): Observable<any> {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
        // if (authourized==null||guestLoginEnabled=="1") {
           return this.http.get(`${this.url2}/invoice/${entityId}`, { responseType: 'arraybuffer', observe: 'response' });
        // }else{
        //  return this.http.get(`${this.url}/invoice/${entityId}`, { responseType: 'arraybuffer', observe: 'response' });
 ///       }
   


  }
  shippingForEachItem(orderId: number, productId: number): Observable<Object> {
    return this.http.get(`${this.url}/trackShipment/${orderId}/${productId}`);
  }
  createOrder(order: any): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url3}`, order);
  }

}
