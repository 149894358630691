import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarCodePutDirective } from './bar-code-put.directive';



@NgModule({
  declarations: [BarCodePutDirective],
  imports: [
    CommonModule
  ],exports:[
    BarCodePutDirective

  ]
})
export class BarcodePutModule { }
