import { Component, Input } from '@angular/core';

@Component({
  selector: 'calsoft-price-plan',
  templateUrl: './price-plan.component.html',
  styleUrls: ['./price-plan.component.scss']
})
export class PricePlanComponent {
  @Input() item: any;
  @Input() data: any;
  @Input() img: any;

  constructor() {

  }


  ngOnInit() {  

  }

}
