import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProductService } from 'src/app/services/product.service';
import { view } from '../../util/calsoft-validators';

@Component({
  selector: 'calsoft-pdf-banner',
  templateUrl: './pdf-banner.component.html',
  styleUrls: ['./pdf-banner.component.scss']
})
export class PdfBannerComponent implements OnInit,OnDestroy  {

  @Input() fileName: any;

  @Input() item: any;

  @Input() path: any = '/home/';

  url: string;
  urlExist: boolean =false;
  pdfUrl: any;

  constructor( private productService: ProductService,private sanitizer: DomSanitizer){

  }
  ngOnInit(): void {
   
    this.downloadPdf();
  }


  downloadPdf() {
    if(this.fileName!=undefined){
      let smallImage = this.path + this.fileName;
      this.productService.downloadPdf(smallImage).subscribe(
        data => {
        this.url=  view(data,this.fileName);

        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

        console.log( this.url);

        this.urlExist=true;
        
        },
      )
    }
   
  }

  ngOnDestroy(): void {
    if (this.pdfUrl) {
      // Cast to string to revoke the object URL
      const url = (this.pdfUrl as any).changingThisBreaksApplicationSecurity;
      URL.revokeObjectURL(url);
    }
  }

}
