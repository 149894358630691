<div class="container">
    <div fxLayout="row" class="table-align" [style.background-color]="item.customField20" [style.color]="item.customField21">
        <div fxLayout="column"  fxLayoutAlign="center center" [fxFlex]="item.customField19 == '' ? 33.33 : 25" class="cell">
            <h4><b>{{item.customField16}}</b></h4>
        </div>
    
        <div fxLayout="column"  fxLayoutAlign="center center" [fxFlex]="item.customField19 == '' ? 33.33 : 25" class="cell">
            <h4><b>{{item.customField17}}</b></h4>
        </div>
    
        <div fxLayout="column"  fxLayoutAlign="center center" [fxFlex]="item.customField19 == '' ? 33.33 : 25" class="cell">
            <h4><b>{{item.customField18}}</b></h4>
        </div>

        <div fxLayout="column"  fxLayoutAlign="center center" [fxFlex]="item.customField19 == '' ? 33.33 : 25" class="cell">
            <h4><b>{{item.customField19}}</b></h4>
        </div>

        <div fxLayout="column"  fxLayoutAlign="center center" [fxFlex]="item.customField19 == '' ? 33.33 : 25" class="cell">
            <h4><b>{{item.customField24}}</b></h4>
            <div fxLayoutAlign="space-evenly center"  fxLayout="row"   class="cellrow" >
                <div fxFlex="50" fxFlex.xs="100"  fxLayout="column" class="cell">
                    <h4><b>{{item.customField25}}</b></h4>
                </div>
        
                <div fxFlex="50" fxFlex.xs="100"  fxLayout="column" class="cellrow1">
                    <h4><b>{{item.customField26}}</b></h4>
                </div>
            </div>
        </div>

        
    </div>
    
    <div *ngFor="let data of pricedetail">
        <div fxLayout="row" fxLayoutAlign="center center" class="subTitle" *ngIf="data.title">
            <p><b>{{data.title}}</b></p>
        </div>

        <div *ngFor="let item of data.list_detail">
            <div fxLayout="row" class="rowtype">
                <div [fxFlex]="item.customField19 == '' ? 33.33 : 25" class="celltype">
                    <p>{{item.sub_title}}</p>
                </div>
                <div [fxFlex]="item.customField19 == '' ? 33.33 : 25" class="celltype">
                    <p style="text-align: center;" >
                        {{item.sub_title1}}
                    </p>
                    
                </div>
                <div [fxFlex]="item.customField19 == '' ? 33.33 : 25" class="celltype">
                    <p style="text-align: center;" >
                        {{item.sub_title2}}
                    </p>
                    
                </div>
                <div [fxFlex]="item.customField19 == '' ? 33.33 : 25"class="celltype">
                    <p style="text-align: center;">
                        {{item.sub_title3}}
                    </p>
                   
                </div>
                <div  fxLayout="row"  [fxFlex]="item.customField19 == '' ? 33.33 : 25">
                    <div  fxFlex="50"  fxFlex.xs="50"  class="celltype">
                        <p style="text-align: center;" >
                            {{item.sub_title4}}
                        </p>
                       
                    </div>
                    <div fxFlex="50" fxFlex.xs="50"  class="celltype">
                        <p style="text-align: center;">
                            {{item.sub_title5}}
                        </p>
                       
                    </div>
                </div>
               
                <!-- <div  [fxFlex]="item.customField19 == '' ? 33.33 : 25" class="celltype">
                    <p style="text-align: center;" >
                        {{item.sub_title6}}
                    </p>
                   
                </div> -->
            </div>
        </div>
    </div>
</div>