<div fxLayout="row" fxLayout.xs="column"  fxLayoutAlign="center center" class="container-img">
    <div fxFlex="20" class="logo" fxLayout="column"  fxlayoutAlign="center center">
      <!-- Logo placeholder -->
      <img fxlayoutAlign="center center" [src]="bannerServerPath + item.images[0].img" [alt]="item?.images?.[0]?.altImage" class="logo-img">
      <p style="font-style: italic;">{{item?.images?.[0]?.customField20}} </p>
    </div>
    <div ngClass.gt-sm="vl" ></div>
  
    <div fxFlex="80" fxLayout="row"  fxLayout.xs="column" 
     fxLayoutWrap fxLayoutAlign="space-between center" class="features-container">
      <div   fxFlex="30" fxLayout="column"  fxLayoutAlign="center center" class="feature-item">
            <img [src]="bannerServerPath + (item?.images?.[1]?.img)" [alt]="item?.images?.[1]?.altImage"  class="icon-img">
            <p>{{item?.images?.[1]?.customField20}} </p>
            <button *ngIf="(item.images[1].customField9.length >0)" (click)="item.images[1].navigation==0?goToCategories(item.images[1].catId):link(item.images[1].navigatedUrl)" mat-button class="know-more">{{ item?.images?.[1]?.customField9 }}</button>
      </div>
      <div ngClass.gt-sm="vl"></div>
      
      <div fxFlex="30" class="feature-item">
         <div fxLayout="row" fxLayoutAlign="start start" class="feature-item" fxLayoutGap="5px">
            <img [src]="bannerServerPath + (item?.images?.[2]?.img)" [alt]="item?.images?.[2]?.altImage" class="icon-img">
            <p>{{item?.images?.[2]?.customField20}} </p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" class="feature-item" fxLayoutGap="5px">
            <img [src]="bannerServerPath + (item?.images?.[3]?.img)" [alt]="item?.images?.[3]?.altImage" class="icon-img">
            <p>{{item?.images?.[3]?.customField20 }} </p>
        </div>
        <button *ngIf="(item.images[3].customField9.length >0)" (click)="item.images[3].navigation==0?goToCategories(item.images[3].catId):link(item.images[3].navigatedUrl)" mat-button class="know-more">{{ item?.images?.[3]?.customField9 }}</button>
      </div>
      <div fxFlex="30" fxLayout="column" fxLayoutAlign="center center" class="feature-item">
        <div fxLayout="row" fxLayoutAlign="start start" class="feature-item" fxLayoutGap="5px">
            <img [src]="bannerServerPath + (item?.images?.[4]?.img)" [alt]="item?.images?.[4]?.altImage" class="icon-img">
            <p>{{item?.images?.[4]?.customField20}} </p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" class="feature-item" fxLayoutGap="5px">
            <img [src]="bannerServerPath + (item?.images?.[5]?.img)"  [alt]="item?.images?.[5]?.altImage" class="icon-img">
            <p>{{item?.images?.[5]?.customField20}} </p>
        </div>
        <button *ngIf="(item?.images?.[5]?.customField9.length >0)" (click)="item?.images?.[5]?.navigation==0?goToCategories(item?.images?.[5]?.catId):link(item?.images?.[5].navigatedUrl)" mat-button class="know-more">{{ item?.images?.[5]?.customField9 }}</button>
      </div>
      <div fxFlex="30" fxLayout="column" fxLayoutAlign="center center" class="feature-item">
        <div fxLayout="row" fxLayoutAlign="start start" class="feature-item" fxLayoutGap="5px">
            <img [src]="bannerServerPath + (item?.images?.[6]?.img)" [alt]="item?.images?.[6]?.altImage" class="icon-img">
            <p>{{item?.images?.[6]?.customField20 }}</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" class="feature-item" fxLayoutGap="5px">
            <img [src]="bannerServerPath + (item?.images?.[7]?.img)"  [alt]="item?.images?.[7]?.altImage" class="icon-img">
            <p>{{item?.images?.[7]?.customField20}} </p>
        </div>
        <button *ngIf="(item?.images?.[7]?.customField9.length >0)" (click)="item?.images?.[7]?.navigation==0?goToCategories(item?.images?.[7]?.catId):link(item?.images?.[7]?.navigatedUrl)" mat-button class="know-more">{{ item?.images?.[7]?.customField9 }}</button>
      </div>
    </div>
  </div>