import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-faciliteis-image',
  templateUrl: './faciliteis-image.component.html',
  styleUrls: ['./faciliteis-image.component.scss']
})
export class FaciliteisImageComponent implements OnInit{


  @Input() item: any;
  @Input() img: any;
  @Input() data: any;

  public bannerServerPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';
  commonService: any;


  ngOnInit() {


    
  }
  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }
  link(url) {
    window.open(url, '_blank');
  }




}
