import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-category',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  public serverPath = environment.commonImageApi + 'category/image/150x150/';
  categoryArray: any;
  public viewCol: number = 25;

  constructor(
    private categoryService: CategoryService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this.categoryService.categoryList().subscribe(
      result => {
        this.categoryArray = result['data'];
      },
      error => {
      }
    )
  }

  goToCategory(menu) {
    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);

  }




}
