import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import Swiper from 'swiper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-brands-carousel-type26',
  templateUrl: './brands-carousel-type26.component.html',
  styleUrls: ['./brands-carousel-type26.component.scss']
})
export class BrandsCarouselType26Component implements AfterViewInit {
  @Input() item: any;
  @Input() img: any;
  public serverPath = environment.commonImageApi + '/home/';
  public bannerServerPath = environment.commonImageApi + '/commonSlider/';

  @ViewChild('swiper', { static: true }) swiperContainer: any;

  dynamicSlides:any;

  constructor(
    
  ) {
  
  }

  ngAfterViewInit() {
  new Swiper(this.swiperContainer.nativeElement, {
    effect: 'coverflow',
    centeredSlides: true,
    slidesPerView: 'auto',
    loopedSlides: 1,
    loop:true,
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 300,
      modifier: 1,
      slideShadows: true,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 40
      }
    }
    
  });
  }


  private swiper!: Swiper;
  
  navigateSwiper(direction: 'prev' | 'next'): void {
    if (this.swiper) {
      if (direction === 'prev') {
        this.swiper.slidePrev();
      } else {
        this.swiper.slideNext();
      }
    }
  }

  openlink(url: string) {
    console.log(url);
    if (url) {
      const fullURL = this.prependProtocol(url);
      window.open(fullURL, '_blank');


    }
  }
  
  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }



}
