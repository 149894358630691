<div *ngIf="loaded" class="product-item product-elevation backg">
  <div fxLayout="row" class="zIndex pointer">
    <div  fxLayout="row" fxLayoutAlign="space-between center">

      <calsoft-controls [product]="configProductSelected" [data]="data" [variant1Name]="variant1Name"
        [variant2Name]="variant2Name" [variant1Value]="variant1Value" [variant2Value]="variant2Value"
        [configproduct]="config " style="position: absolute; top: 0; left: 0" [type]="'wishonly'">
      </calsoft-controls>

    </div>
 
    <div fxLayout="column" fxFlex="50" class="p-2">
      <div fxLayout="row" fxLayoutAlign="center center" class="image-container">
          <calsoft-image-lazy-loading
              [ngClass]="{'overlayProduct': (qty <= 0 || isInStock == 0) && (manageStock == 1 || isInStock == 0), 'padding-10': true }"
              class="pointer" (click)="productShow(product)" [width]="width" [imageType]="imageType"
              [heightMobile]="heightMobile" [widthMobile]="widthMobile" [height]="height" [type]="'grid'"
              [name]="name" [image]="image">
          </calsoft-image-lazy-loading>          
          <div *ngIf="(qty <= 0 || isInStock == 0) && (manageStock == 1 || isInStock == 0)" class="outof">
              <small fxLayoutAlign="center center" class="colorRed">OUT OF STOCK</small>
          </div>
      </div>
  </div>
  

    <div class="pointer" fxFlex="50" fxLayout="column" class="p-2">


      <div fxLayout="row" [ngClass]="{'overlayProduct':(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)}"
        (click)="productShow(product)" *ngIf="name.length>0">

        <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex="80">
          <calsoft-translator fxFlex [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>
        </div>

        <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
          <calsoft-translator fxFlex [translateValue]="name" [data]="data" [type]="'productsNew'"></calsoft-translator>
        </div>

      </div>



      <div style="padding-bottom: 5px;" *ngIf="prescriptionRequired" fxLayout="row" fxLayoutAlign="start center">
        <img height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
          alt="prescriptionRequired">
        <span style="padding-left: 10px;
          font-weight: 500;"> Prescription Required</span>

      </div>

      <div class="padding-top1"
        [ngClass]="{'overlayProduct':(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)}"
        (click)="productShow(product)" *ngIf="averageRating >=1  " fxLayout="row" fxLayoutAlign="start center"
        fxLayoutGap="10px">
        <div class="review-Rating E_uFuv">
          {{calculateAvgRating | number : '1.1-1'}}
          <span class="material-icons f11">
            star_rate
          </span>
        </div>
        <div>
          &#40;<span>{{averageRating}}</span>&#41;
        </div>
      </div>
      <div class="pointer" [ngClass]="{'overlayProduct':(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)}"
        *ngIf="averageRating == 0" style="padding-bottom: 20px">

      </div>

      <div *ngIf="this.data.disablePrice==0">
        <div class="padding-top"
          [ngClass]="{'overlayProduct':(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)}">

          <div *ngIf="inclTaxEnabled"
            [ngClass]="{'padinf1': !(configurableProductObject['type'] == 'Configurable' && configurableProductObject['configurableProductDropdown'] != null)}"
            (click)="productShow(product)" class=" pointer" fxLayout="row" 
            fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price">
                <b *ngIf="displayMrpPrice">MRP :</b>
                <span class="old-price text-strike text-muted font-16px">
                  {{ price | currencyConvert }}{{units}}
                </span>
              </span>
            </span>

            <span class="new-price123 price-label font-166" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{price | currencyConvert }}{{units}}</b>
            </span>

            <span class="new-price123 price-label font-166" *ngIf="specialPrice > 0">
              <b> {{specialPrice | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span *ngIf="!displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price"
                class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>

          </div>

          <div *ngIf="!inclTaxEnabled"
            [ngClass]="{'padinf1': !(configurableProductObject['type'] == 'Configurable' && configurableProductObject['configurableProductDropdown'] != null)}"
            (click)="productShow(product)" class=" pointer" fxLayout="row" 
            fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="displayMrpPrice">
              <span  style="margin-right: 10px;" *ngIf="specialPrice > 0 && specialPrice != price">
                <b *ngIf="displayMrpPrice">MRP :</b>
                <span class="old-price text-strike text-muted font-16px">
                  {{ price | currencyConvert }}{{units}}
                </span>
              </span>
            </span>

            <span class="new-price123 price-label font-166" *ngIf="specialPrice <= 0">
              <span  style="margin-right: 10px;" *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{price | currencyConvert }}{{units}}</b>
            </span>

            <div class="tax-info" *ngIf="specialPrice > 0">
              <span class="new-price123 price-label font-166" fxLayoutGap="10px">
                <b> {{specialPrice | currencyConvert }}{{units}}</b>
              </span>
              <span *ngIf="displayMrpPrice">
                <b>(incl. of tax)</b>
              </span>
            </div>

            <span *ngIf="!displayMrpPrice">
              <span fxLayoutGap="10px" *ngIf="specialPrice > 0 && specialPrice != price"
                class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>
          </div>

          <span fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}"
            class="product-Price price-label font-166 ">
            <span class="success cursor-text" style="cursor: text;"
              *ngIf="discountPercentage > 0&&discountPercentage < 100">
              ({{discountPercentage | dynamicRound }}<small>%</small> OFF)
            </span>
          </span>
        </div>
      </div>

      <div *ngIf="showConfigurableDropdown == 1">
        <ng-container *ngIf="!showNewConfig; else newconfig">
          <div style="padding-right: 5px; padding-top: 5px;" class=" A" fxLayout="row"
            *ngFor="let item of configurationDropDownList | keyvalue">
            <mat-form-field style="font-size: 10px; height: 54px; padding: 0px;margin: 0px; padding-bottom: 0PX;"
              appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px;">
                {{item.key.replace('_', ' ') }}
              </mat-label>
              <mat-select style="padding-bottom: 0.34375em;padding-top: 5px;"
                (selectionChange)="getConfigProductId($event.value)" [disableOptionCentering]="true"
                [formControl]="dropDownValue">
                <mat-option style="font-size: 14px;" [value]="object" *ngFor="let object of item.value">
                  {{object |splitbyHash}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-template #newconfig>
          <div style="padding-right: 5px; padding-top: 5px;" class=" A" fxLayout="row">
            <mat-form-field style="font-size: 10px; 
              height: 54px; padding: 0px;margin: 0px; padding-bottom: 0PX;" appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px;">
                {{configurationDropDownList[0].key}}
              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,i,item,'firstIndex')" [value]="item.value"
                  *ngFor="let item of configurationDropDownList;let i=index;">
                  {{item.value}}
                </mat-option>
              </mat-select>

            </mat-form-field>
          </div>

          <div style="padding-right: 5px; padding-top: 5px;" class=" A" fxLayout="row">
            <mat-form-field style="font-size: 10px; 
              height: 54px; padding: 0px;margin: 0px; padding-bottom: 0PX;" appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px;">
                {{configurationDropDownList[0]
                ['recursuive'][0].key}}

              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex]
              .recursuive[secondIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,j,item1,'secondIndex')"
                  [value]="item1.value" *ngFor="let item1 of configurationDropDownList[firstIndex]
       ['recursuive'];let j=index;">
                  {{item1.value}}
                </mat-option>
              </mat-select>


            </mat-form-field>
          </div>


        </ng-template>


      </div>
      <div class="padding-tb10"
        [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
        <calsoft-controls [product]="configProductSelected" [data]="data" [variant1Name]="variant1Name"
          [variant2Name]="variant2Name" [variant1Value]="variant1Value" [variant2Value]="variant2Value"
          [configproduct]="config " [showCartIcon]="showCartIcon" [showCartIncremented]="showCartIncremented">
        </calsoft-controls>
      </div>
    </div>
  </div>
</div>