import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-sliderauto',
  templateUrl: './sliderauto.component.html',
  styleUrls: ['./sliderauto.component.scss']
})
export class SliderautoComponent implements OnInit {
  @Input() img: any;

  @Input() item: any;

  @Input() data: any;

  public serverPath1 = environment.commonImageApi + '/commonSlider/';
  customOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplayHoverPause: true,
    items: 1,
    dots: true,
    nav: false
  };

  constructor() {}

  ngOnInit() {

    
  }

}
