import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-home-layouts',
  templateUrl: './home-layouts.component.html',
  styleUrls: ['./home-layouts.component.scss']
})
export class HomeLayoutsComponent implements OnInit {
  @Input() slides:any=[];
  public serverPath = environment.commonImageApi + '/bannerImage/';
  constructor() { }

  ngOnInit(): void {
 
  }

}
