import { Component, Input, OnInit } from '@angular/core';
import { AddpurchaseComponent } from './addpurchase/addpurchase.component';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { Constant } from 'src/app/constants/constant';
import { OrderService } from 'src/app/services/order.service';
import { SalesFlatQuoteItem } from 'src/app/models/cart-item';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EcryptServiceService } from 'src/app/services/ecrypt-service.service';
import { ShippingRateService } from 'src/app/services/shipping-rate.service';
import { CurrencyConversionService } from 'src/app/services/currency-conversion.service';
import { isNumeric } from '../utils/calsoft-validators';
import { SubscriptionPriceService } from 'src/app/services/subscription-price.service';

@Component({
  selector: 'calsoft-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {

  @Input() item: any;
  @Input() category: any;
  
  visible: boolean;
  cartList: any;
  userId: any;
  configData: Object;
  cartEnabled: boolean = false;
  additionData: string;
  shipmentVendor: any;
  disableCoupoun: any;
  shippingEnabled: any;
  showGst: any;
  freeShippingAppliedAmount: any;
  ownerPostCode: any;
  ownerCountryID: any;
  enableStorePickup: any;
  checkoutTimerEnabled: any;
  checkoutTimerSeconds: any;
  calculateShippingRateBasedOnVendors: any;
  storeName: any;
  additionalDeliveryAmount: any;
  additionalDeliveryChargeInPercent: any;
  paritialPaymentEnabled: any;
  paritialPaymentAmount: any;
  paritialPaymentInfo: any;
  calculateParitialAmountPercent: any;
  editParitialAmount: any;
  conversionId: any;
  distanceSelection: any;
  codPaymentCharge: any;
  codPaymentMessage: any;
  additionalPaymentText: any;
  taxName: any;
  checkoutPageType: number;
  shareChatId: any;
  priceDetailType: any;
  enableReferalCustomerDiscount: any;
  vendorEnabled: any;
  cartReinit: boolean=true;
  cartData: any;
  displayUserGrid: boolean;
  title: any;
  text: string;
  firstFormGroup: UntypedFormGroup;
  cartItemList: SalesFlatQuoteItem[];
  userName: any;
  selectedAddres: any;
  checkOutAddress: any;
  countryId: any;
  cartItems: any;
  defalutValue: any;
  totalWeigth: number;
  grandTotalWithoutShippingCharge: any;
  grandTotal: any = 0;
  delieveryCharge: any = 0.0;
  salesflatOrder: any;
  operationType: string;
  addressEmpty: any;
  comment = new UntypedFormControl('', []);
  salesFlatOrderPayment: any;
  payment: string;
  resourcePrivilege: any;
  consultationFees: any = 0;
  taxAmount: any = 0.0;
  salesFlatOrderItemOption: any = [];
  couponResult: any;
  helpDescription: any;
  showPricing: boolean=false;
  loop: number;
  userNameEnabled: any;
  customerData: any;
  paymentOptions: any[];
  contact: {};
  prescriptionRequired: boolean;
  mrp: any;
  deliveryOptionControl: any;
  loopEnabled: boolean;
  medicalDeliveryOptionControl = new UntypedFormControl();
  deliveryOptionMedicalObject = new UntypedFormControl();
  paritialPaymentAmountControl = new UntypedFormControl(0.0, [Validators.required]);
  index: number;
  discountEnabled: any;
  state: any;
  postCode: any;
  latitude: any;
  longitude: any;
  distance: any;
  mediaGallery: any = [];
  partialPaymentControl = new UntypedFormControl('Full Payment', []);

  paymentModes: any = [
    {
      "name": "Cash On Delivery",
      "type": "cashon"
    }];


  showcart:boolean=false;

  totalGrandTotal: number;

  finalData: any[] = [];

  
  ngOnInit() {
    this.getConfigData();


    const storedFinalData = localStorage.getItem('finalData');
    if (storedFinalData) {
      this.finalData = JSON.parse(storedFinalData);
    }

    this.calculateAndUpdateTotalGrandTotal();

    this.subscriptionService.GrandTotalAmount$.subscribe(totalGrandTotal => {
      this.totalGrandTotal = totalGrandTotal;
    });

  }

  constructor(
    public cartService: CartService,
    public formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private commonService: CommonService,
    private configService: ConfigServiceService,
    private orderService: OrderService,
    private snackBar: MatSnackBar,
    private ecryptService:EcryptServiceService,
    private shippingRateService: ShippingRateService,
    private currencyConversionService:CurrencyConversionService,
    private subscriptionService : SubscriptionPriceService,

  ) { }

  
  addProduct() {
    this.showcart = false;
    if (window.innerWidth > 768) {
      this.visible = false;
  
      const dialogRef = this.dialog.open(AddpurchaseComponent, {
        width: '980px',
        disableClose: true,
        data: {
          cartData: this.cartList, 
          userId: this.userId,
          configData: this.configData,
          finalData: this.finalData,
          category: this.category
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        this.visible = true;
        if (result && result.event === 'close' && result.data) {
          this.finalData = result.data;
  
          // localStorage.setItem('finalData', JSON.stringify(this.finalData));

          this.calculateAndUpdateTotalGrandTotal();
  
          this.showcart = true;

        }
      });
    } else {
      this.visible = false;
      const dialogRef = this.dialog.open(AddpurchaseComponent, {
        minWidth: '100vw', height: '100vh',
        disableClose: true,
        data: {
          cartData: this.cartList, 
          userId: this.userId, 
          configData: this.configData,  
          finalData: this.finalData,
          category: this.category
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        this.visible = true;
        if (result && result.event === 'close' && result.data) {
          this.finalData = result.data;
  
          // const totalGrandTotal = this.finalData.reduce((sum, item) => sum + item.grandTotal, 0);
        
          // this.subscriptionService.updateGrandTotal(totalGrandTotal);
  
          this.calculateAndUpdateTotalGrandTotal();
          this.showcart = true;
  
        }
      });
    }
  }
  
  currentMap: Map<string, any> = new Map();

  calculateAndUpdateTotalGrandTotal() {
    if (this.finalData && this.finalData.length) {
    //  const totalGrandTotal = this.finalData.reduce((sum, item) => sum + item.grandTotal, 0);
 
    
      
    
    this.subscriptionService.updateValue(this.item.layoutConfigId, this.finalData);


    }
  }

  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {

          localStorage.setItem(Constant['GUEST_LOGIN'], data['guestLogin'])
          this.commonService.setCurrencyValue(data['currencyBase']);
          this.shipmentVendor = data['shippingVendor'];
          this.configData = data;
          this.disableCoupoun = data['disableCoupoun'];
          this.shippingEnabled = data['shippingChargesApplied']
          this.showGst = data['showGstCartPage'];
          this.freeShippingAppliedAmount = data['freeShippingAppliedAmount'];
          this.ownerPostCode = data['zipPostCode'];
          this.ownerCountryID = data['country'];
          this.enableStorePickup = data['enableStorePickup'];
          this.checkoutTimerEnabled = data['checkoutTimerEnabled']
          this.checkoutTimerSeconds = data['checkoutTimerSeconds']
          this.calculateShippingRateBasedOnVendors = data['calculateShippingRateBasedOnVendors']
          this.storeName = data['storeName'];
          this.additionalDeliveryAmount = data['additionalDeliveryCharge'];
          this.additionalDeliveryChargeInPercent = data['additionalDeliveryChargeInPercent'];

          this.paritialPaymentEnabled = data['partialPayment'];

          this.paritialPaymentAmount = data['partialPaymentMinimumAmount'];

          this.paritialPaymentAmount = parseFloat(data['partialPaymentMinimumAmount']);

          this.paritialPaymentInfo = data['partialPaymentInfo'];

          this.calculateParitialAmountPercent = data['calculateParitialAmountPercent'];

          this.editParitialAmount = data['editParitialAmount'];

          //this.paritialPaymentAmountControl.patchValue(this.paritialPaymentAmount.toFixed(2))

          this.conversionId = data['coversionTrackingIdGa'];

          this.distanceSelection = data['googleMapsEnabled'];

          this.codPaymentCharge = data['codPaymentCharge'];

          this.codPaymentMessage = data['codPaymentMessage'];

          this.additionalPaymentText = data['additionalPaymentText'];

          this.taxName = data['taxName'];

          this.checkoutPageType = 2;

          this.shareChatId = data['shareChat'];

          this.priceDetailType = data['priceDetailType'];

          this.enableReferalCustomerDiscount = data['enableReferalCustomerDiscount'];

          this.vendorEnabled = data['vendorEnabled'];

        }, error => {

        }
      );
  }



}
