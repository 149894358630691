import { Component, Input } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-imagecol-hover',
  templateUrl: './imagecol-hover.component.html',
  styleUrls: ['./imagecol-hover.component.scss']
})
export class ImagecolHoverComponent {
  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() img: any;

  @Input() data: any;

  isHovered: boolean = false;
  hoverState: { [key: number]: boolean } = {};


  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      window.open(fullURL, '_blank');
    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }

  getDynamicBackground(index: number): string {

    const baseColor  = this.item.customField8;
    const color = this.hoverState[index] ? baseColor  : baseColor;
    return this.hoverState[index] ? `linear-gradient(90deg, ${color} 50%, ${color} 50%)` : `linear-gradient(270deg, #FFFFFF 50%, ${color} 38%)`;
  }

  onMouseEnter(index: number): void {
    this.hoverState[index] = true;
  }

  onMouseLeave(index: number): void {
    this.hoverState[index] = false;
  }

}
