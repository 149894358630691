import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-service-layout',
  templateUrl: './service-layout.component.html',
  styleUrls: ['./service-layout.component.scss']
})
export class ServiceLayoutComponent {
  constructor( private commonService: CommonService,) {
  }

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() img: any;

  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      this.commonService.goToLink(url)
    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }

}
