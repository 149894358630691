
  
  <div class="container" fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column">
    <div fxFlex="33" class="section" fxLayout.xs="column">
      <div class="silver-heading">{{title1}}
        <div class="border"></div>
      </div>

          <div class="silver-heading-dics">{{pricedetail[0].a_subtitle}} </div>
          <div *ngFor="let data of pricedetail[0].list_detail">
            <div class="trow" fxLayout="row" *ngIf="data.types == '2'">
              <div fxFlex="50" style="padding-left: 5px;"><b>{{data.sub_title}}</b><span style="color: red;" *ngIf="data.require == '1'">*</span></div>
              <div fxFlex="50">{{data.starters}}</div>
            </div>
            <div *ngIf="data.types == '1'" class="imageview">
                <img [src]="serverPath1+data.value" class="imgcss">
            </div>
        </div>
        <hr class="new2">
        <div style="color: gray;background-color: white;" class="padding-20" *ngIf="pricedetail[0].s_charge_life || pricedetail[0].s_charge_year">
          <div>{{pricedetail[0].s_charge_life}}</div>    
          <div><span style="color: red;">*</span>{{pricedetail[0].s_charge_year}}</div>    
        </div>
        <div class="padding-20" style="text-align: left;" *ngIf="item.customField4">
          <div [innerHTML]="item.customField4"></div>    
        </div>
        
    </div>

 
  <div fxFlex="33" class="section" fxLayout.xs="column">
    <div class="gold-heading">{{title2}}
      <div class="border"></div>
    </div>
      <div class="gold-heading-dics">{{pricedetail[0].p_subtitle}}</div>
      <div *ngFor="let data of pricedetail[0].list_detail">
        <div class="trow" fxLayout="row" *ngIf="data.types == '2'">
          <div fxFlex="50" style="padding-left: 5px;"><b>{{data.sub_title}}</b><span style="color: red;" *ngIf="data.require == '1'">*</span></div>
          <div fxFlex="50">{{data.professions}}</div>
        </div>
        <div *ngIf="data.types == '1'" class="imageview">
            <img [src]="serverPath1+data.value" class="imgcss">
        </div>
    </div>
    <hr class="new2">
    <div style="color: gray;background-color: white" class="padding-20"  *ngIf="pricedetail[0].p_charge_life || pricedetail[0].p_charge_year">
      <div>{{pricedetail[0].p_charge_life}}</div>    
      <div><span style="color: red;">*</span>{{pricedetail[0].p_charge_year}}</div>    
  </div>
  <div class="padding-20" style="text-align: left;" *ngIf="item.customField5">
    <div [innerHTML]="item.customField5"></div>    
  </div>


  </div>


  <div fxFlex="33" class="section" fxLayout.xs="column">
    <div class="platinum-heading">{{title3}}
      <div class="border"></div>
    </div>
    <div class="platinum-heading-dics">{{pricedetail[0].s_subtitle}}</div>

    <div *ngFor="let data of pricedetail[0].list_detail">
        <div class="trow" fxLayout="row" *ngIf="data.types == '2'">
          <div fxFlex="50" style="padding-left: 5px;"><b>{{data.sub_title}}</b><span style="color: red;" *ngIf="data.require == '1'">*</span></div>
          <div fxFlex="50">{{data.advanceds}}</div>
        </div>

        <div *ngIf="data.types == '1'" class="imageview">
            <img [src]="serverPath1+data.value" class="imgcss">
        </div>
    </div>
    <hr class="new2">
    <div style="color: gray;background-color: white" class="padding-20"  *ngIf="pricedetail[0].a_charge_life || pricedetail[0].a_charge_year">
      <div>{{pricedetail[0].a_charge_life}}</div>    
      <div><span style="color: red;">*</span>{{pricedetail[0].a_charge_year}}</div>    
  </div>
  <div class="padding-20" style="text-align: left;" *ngIf="item.customField6">
    <div [innerHTML]="item.customField6"></div>    
  </div>


  </div>
</div>
