<div class="main-banner-area-two">
    <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="50">

            <div class="main-banner-content">
                <h1>{{item.title}}</h1>
                <p>{{item.customField27}}</p>
                <div fxLayout="row" style="margin-top: 35px;"  fxLayoutAlign.gt-sm="start start" 
                fxLayoutAlign.lt-md="center center" >
                    <button mat-button class="btn1" *ngIf="item.customField25" (click)="openInNewWindow(item.customField23)">{{item.customField25}}</button>
                    <button mat-button class="btn2" *ngIf="item.customField26" (click)="openInNewWindow(item.customField24)">{{item.customField26}}</button>
                </div>
            </div>
        </div>

        <div fxFlex="50">

            <div class="banner-image" >
                <div >
                    <img *ngFor="let itemz of item.images" [src]="imageUrl+itemz.img" 
                    class="wow animate__animated {{itemz.customField5}} " data-wow-delay="0.6s" alt="image">
            </div>
        </div>
        </div>
    </div>
    <div class="banner-shape" *ngIf="item.customField6"><img [src]="serverPath+item.customField6"
            alt="image"></div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/5.svg"
                alt="image"></div>
        <div class="shape-3"><img src="assets/img/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/8.png" alt="image"></div>
    </div>

</div>