import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from 'ngx-owl-carousel-o';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-image-item-list',
  templateUrl: './image-item-list.component.html',
  styleUrls: ['./image-item-list.component.scss']
})
export class ImageItemListComponent implements OnInit {
 

  @Input() item: any;

  @Input() data: any;

  @Input() img: any;

  public LogoSliderConfig: any;

  constructor(private commonService: CommonService,) { 

  }

  @ViewChild('owlCar') owlCarousel: CarouselComponent;
  
  public bannerServerPath = environment.commonImageApi + '/commonSlider/';

  ngOnInit() {

    if ( this.item.gapBetweenImage.endsWith('px')) {
      this.item.gapBetweenImage= this.item.gapBetweenImage.slice(0, -2); 
    }
    
    this.LogoSlider.autoplayTimeout = this.item.customField21;

    this.LogoSlider.margin=this.item.gapBetweenImage;
    this.LogoSliderConfig=this.LogoSlider;

    this.LogoSlider.responsive[480].items = this.item.customField14;
    this.LogoSlider.responsive[0].items = this.item.customField14;
    
  }


  goToCategory(menu) {
    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);

  }

  
  imageNavigation(id){

    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  catId:any;
 
  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }


  link(url: string) {
    window.open(url, '_self');
  }

  LogoSlider = {
    ...sliderOpt,
    nav: false,
    dots: false,
    items: 3,
    margin: 20,
    loop: true,
    autoHeight: true,
    autoplay: true,
    autoplayTimeout: 3000,
    
    responsive: {
        767: {
            items: 2
        },
        576: {
            items: 2
        },
        480: {
            items: 2
        },
        0: {
            items: 2
        }
    }
}

}

export const sliderOpt = {
  items: 1,
  loop: true,
  margin: 0,
  responsiveClass: true,
  nav: true,
  navText: [ '<i class="icon-angle-left">', '<i class="icon-angle-right">' ],
  dots: true,
  smartSpeed: 400,
  autoplay: false,
  autoplayTimeout: 15000
}