import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CarouselComponent } from 'ngx-owl-carousel-o';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-mp4',
  templateUrl: './mp4.component.html',
  styleUrls: ['./mp4.component.scss']
})
export class Mp4Component {

  public serverPath = environment.commonImageApi + '/commonSlider/';

  @Input() item: any;
  
  @Input() img: any;

  public youtubeUrl = environment.youtubeUrl;

  width:any;
  height:any;
  gapBetweenImage:any;
  customField12:any;
  customField11:any;
  customField31:any;
  customField32:any;
  type:any=0;

  autoplay:any;

  url: string;

  public LogoSliderConfig: any;
  
  constructor(  private commonService: CommonService,
    private categoryService: CategoryService) { }

    LogoSlider = {
      ...sliderOpt,
      nav: false,
      dots: false,
      items: 3,
      margin: 20,
      loop: false,
      autoHeight: true,
      autoplay: true,
      
      responsive: {
          767: {
              items: 5
          },
          576: {
              items: 4
          },
          480: {
              items: 3
          },
          0: {
              items: 2
          }
      }
  }
  
  ngOnInit(): void {
        

    if ( this.item.gapBetweenImage.endsWith('px')) {
      this.item.gapBetweenImage= this.item.gapBetweenImage.slice(0, -2); 
    }

    if(this.customField31==undefined){
      this.customField31=this.item.customField31;
    }

    if(this.customField32==undefined){
      this.customField32=this.item.customField32;
    }
  
    
    this.LogoSlider.margin=this.item.gapBetweenImage;

    this.LogoSlider.responsive[767].items = this.customField31;

    this.LogoSlider.responsive[576].items = this.customField31;

    this.LogoSlider.responsive[480].items = this.customField32;

    this.LogoSlider.responsive[0].items = this.customField32;

    this.LogoSliderConfig=this.LogoSlider;
    
  }


  goToCategory(menu) {
    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);

  }

  
  imageNavigation(id){

    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  catId:any;
 
  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  @ViewChild('owlCar') owlCarousel: CarouselComponent;

 
  LogoSlider1 = {
    ...sliderOpt,
    nav: false,
    dots: false,
    items: 1,
    margin: 20,
    loop: false,
    autoHeight: true,
    autoplay: true,
    
    responsive: {
        767: {
            items: 1
        },
        576: {
            items: 1
        },
        480: {
            items: 1
        },
        0: {
            items: 1
        }
    }
}

}
export const sliderOpt = {
  items: 1,
  loop: true,
  margin: 0,
  responsiveClass: true,
  nav: true,
  navText: [ '<i class="icon-angle-left">', '<i class="icon-angle-right">' ],
  dots: true,
  smartSpeed: 400,
  autoplay: false,
  autoplayTimeout: 15000
}
  

