import { Component, AfterViewInit, Input } from '@angular/core';
import { Options, LabelType } from '@angular-slider/ngx-slider';
import { ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnquiryFormComponent } from './enquiry-form/enquiry-form.component';

@Component({
  selector: 'calsoft-emi-calculator',
  templateUrl: './emi-calculator.component.html',
  styleUrls: ['./emi-calculator.component.scss']
})
export class EmiCalculatorComponent implements AfterViewInit {
  filters: any;
  @Input()item : any;

  pemi = {
    value: "25"
  }
  remi = {
    value: "8.5"
  }
  temi = {
    value: "20"
  }
  memi = {
    value: "240"
  }

  query = {
    amount: "",
    interest: "",
    tenureYr: "",
    tenureMo: ""
  }

  result = {
    emi: "",
    interest: "",
    total: "",
    loanAmount:""
  }
  yrToggel: boolean;
  poptions: Options = {
    floor: 1,
    ceil: 200,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + '<b>L</b>';
        case LabelType.High:
          return value + '<b>L</b>';
        default:
          return value + '<b>L</b>';
      }
    }
  };
  roptions: Options = {
    floor: 5,
    ceil: 20,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + '<b>%</b>';
        case LabelType.High:
          return value + '<b>%</b>';
        default:
          return value + '<b>%</b>';
      }
    }
  };
  toptions: Options = {
    floor: 1,
    ceil: 30,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + '<b>Yr</b>';
        case LabelType.High:
          return value + '<b>Yr</b>';
        default:
          return value + '<b>Yr</b>';
      }
    }
  };
  moptions: Options = {
    floor: 1,
    ceil: 360,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + '<b>Mo</b>';
        case LabelType.High:
          return value + '<b>Mo</b>';
        default:
          return value + '<b>Mo</b>';
      }
    }
  };
  constructor(private cdRef:ChangeDetectorRef, private dialog: MatDialog) {
    this.calculateEMI();
    }

  ngAfterViewInit() {
     this.yrToggel = this.item.customField23 !== '2';
    this.update();
  }

  tbupdate(id) {
    if (id == 0) {
      this.pemi.value = (Number(this.query.amount) / 100000).toString();
    }
    else if (id == 1) {
      this.remi.value = this.query.interest;
    }
    else if (id == 2) {
      this.temi.value = this.query.tenureYr;
    }
    else if (id == 3) {
      this.memi.value = this.query.tenureMo;
    }
    this.update();
  }

  update() {

    var loanAmount = Number(this.pemi.value) * 100000;
    var numberOfMonths = (this.yrToggel) ? (Number(this.temi.value) * 12) : Number(this.memi.value);
    var rateOfInterest = Number(this.remi.value);
    var monthlyInterestRatio = (rateOfInterest / 100) / 12;

    this.query.amount = loanAmount.toString();
    this.query.interest = rateOfInterest.toString();
    if (this.yrToggel) {
      this.query.tenureYr = this.temi.value.toString();
    }
    else {
      this.query.tenureMo = this.memi.value.toString();
    }

    var top = Math.pow((1 + monthlyInterestRatio), numberOfMonths);
    var bottom = top - 1;
    var sp = top / bottom;
    var emi = ((loanAmount * monthlyInterestRatio) * sp);
    var full = numberOfMonths * emi;
    var interest = full - loanAmount;
    var int_pge = (interest / full) * 100;

    this.result.emi = emi.toFixed(0).toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    var loanAmount_str = loanAmount.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.result.total = full.toFixed(0).toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.result.interest = interest.toFixed(0).toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.result.loanAmount = loanAmount.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  handleSliderChange(event: any, type: string) {
    // switch (type) {
    //   case 'amount':
    //     this.query.amount = event;
    //     break;
    //   case 'interest':
    //     this.query.interest = event;
    //     break;
    //   case 'tenureYr':
    //     this.query.tenureYr = event;
    //     break;
    //   case 'tenureMo':
    //     this.query.tenureMo = event;
    //     break;
    // }
   
    this.result=event;
    console.log(this .result);
  }

  goToForm(){

    if (window.innerWidth > 768) {

      let dialogRef = this.dialog.open(EnquiryFormComponent, {
        width: '790px',
        height: '500px',
      //  data: { data: data, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {


      });

    }
    else {
      let dialogRef = this.dialog.open(EnquiryFormComponent, {
        minWidth: '100vw', height: '100vh',
      //  data: { data: data, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }

 // Initial values
 loanAmount: number = 100000; // default to 5 lakhs
 interestRate: number = 10; // default to 10%
 loanTenure: number = 12; // default to 120 months (10 years)

 // Result fields
 emiResult: number = 0;
 totalInterest: number = 0;
 totalPayment: number = 0;

 // Slider options
 amountSliderOptions: Options = {
   floor: 100000,
   ceil: 10000000,
   step: 10000,
   translate: (value: number): string => {
     return '₹' + value;
   }
 };

 interestSliderOptions: Options = {
   floor: 1,
   ceil: 30,
   step: 0.1,
   translate: (value: number): string => {
     return value + '%';
   }
 };

 tenureSliderOptions: Options = {
   floor: 6,
   ceil: 360,
   step: 1,
   translate: (value: number): string => {
     return value + ' months';
   }
 };

 // Method to calculate EMI
 calculateEMI() {
  const P = this.loanAmount; // Principal
  const r = this.interestRate / (12 * 100); // Monthly interest rate
  const n = this.loanTenure; // Loan tenure in months

  if (r !== 0) {
    const emi = (P * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
    this.emiResult = parseFloat(emi.toFixed(2));
    this.totalPayment = parseFloat((emi * n).toFixed(2));
    this.totalInterest = parseFloat((this.totalPayment - P).toFixed(2));
  } else {
    this.emiResult = P / n;
    this.totalPayment = P;
    this.totalInterest = 0;
  }
}

// Update based on input change
onInputChange() {
  this.calculateEMI();
}

// Update based on slider change
onSliderChange() {
  this.calculateEMI();
}

}
