<div fxHide.lt-md>
  <div class="container" fxLayoutAlign="center center" fxLayout="row wrap" fxLayoutGap="10px">
    <div *ngFor="let images of img" fxFlex="23" class="feature-box"  fxLayout="column" fxLayoutAlign="center center">
      <div class="feature-title"  [style.background-color]="images.customField17" fxLayoutAlign="center center">
        <!-- <h2  [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">{{images.customField15}}</h2> -->
        <h2> <calsoft-editor-text [fontSize]="item.customField39" [MobfontSize]="item.customField40" [data]="data"
          [text]="images.customField15" >
        </calsoft-editor-text></h2>
      </div>
      <div fxLayout="row" class="feature-description" [style.background-color]="images.customField18" fxLayoutAlign="center center">
        <!-- <p class="rotating-icon"> <span [style.font-size]="item.customField37" [ngStyle.lt-md]="{ 'font-size': item.customField38 }"><mat-icon style="height: 20px;">arrow_forward</mat-icon></span>{{images.customField16}}</p> -->
        <p class="rotating-icon" [fontSize]="item.customField37" [MobfontSize]="item.customField38"><span><mat-icon style="height: 20px;">arrow_forward</mat-icon></span></p>
        <p ><calsoft-editor-text [fontSize]="item.customField37" [MobfontSize]="item.customField38" [data]="data"
          [text]="images.customField16" >
       </calsoft-editor-text></p>
      </div>
    </div>
</div>
</div>
<div fxHide fxShow.lt-md>
  <div class="container" fxLayoutGap="10px">
    <div *ngFor="let images of img"  class="feature-box"  fxLayout="column" fxLayoutAlign="center center">
      <div class="feature-title"   [style.background-color]="images.customField17" fxLayoutAlign="center center">
        <!-- <h2  [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">{{images.customField15}}</h2> -->
        <h2> <calsoft-editor-text [fontSize]="item.customField39" [MobfontSize]="item.customField40" [data]="data"
          [text]="images.customField15" >
        </calsoft-editor-text></h2>
      </div>
      <div class="feature-description" [style.background-color]="images.customField18" fxLayoutAlign="center center">
        <p class="rotating-icon" [fontSize]="item.customField37" [MobfontSize]="item.customField38"><span><mat-icon style="height: 20px;">arrow_forward</mat-icon></span></p>
        <p ><calsoft-editor-text [fontSize]="item.customField37" [MobfontSize]="item.customField38" [data]="data"
          [text]="images.customField16" >
       </calsoft-editor-text></p>
      </div>
    </div>
</div>
</div>
