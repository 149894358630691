
<div class="main-container " >

  <div  [ngClass.lt-md]="'container'"  #containerDiv class="newForm left-column"  [ngStyle]="{'background-color': item.customField24}"   [style.backgroundImage]="'url(' + serverPath+item.customField22 + ')'"
    fxLayout="column" fxLayoutAlign="start"    [ngStyle.lt-md]="{ 'padding': '20px','height':'auto'}"
    [ngStyle.gt-md]="{ 'padding': '50px' }">
    <div fxFlex="30"  fxFlex.lt-md="20">

    </div>
    <div class="title">
      <span [style.color]="item.customField17" [id]="item.customField35">

        <calsoft-editor-text [data]="data" [text]="item.customField6" [align]="item.customField34"
          [style.font-size]="item.customField37" [ngStyle.lt-md]="{ 'font-size': item.customField38 }">
        </calsoft-editor-text>

      </span>
    </div>
    <div class="descriptions">
      <span [style.color]="item.customField18">

        <calsoft-editor-text [data]="data" [text]="item.customField21" [align]="item.customField34"
          [style.font-size]="item.customField31" [ngStyle.lt-md]="{ 'font-size': item.customField34 }">
        </calsoft-editor-text>

      </span>
    </div>
  </div>


  <div  [ngClass.lt-md]="'container'"  #secondElement  class="newForm right-column"  [ngStyle]="{'background-color': item.customField25 }"   [style.backgroundImage]="'url(' + serverPath+item.customField23 + ')'"
    fxLayout="column" fxLayoutAlign="start" [ngStyle.lt-md]="{ 'padding': '20px','height':'auto'}"
    [ngStyle.gt-md]="{ 'padding': '50px' }">
    <!-- <div fxFlex="20" 
    >

    </div> -->
    <div class="title" >
      <span [style.color]="item.customField19" >
        <calsoft-editor-text [data]="data" [text]="item.customField14" [align]="item.customField34"
          [style.font-size]="item.customField37" [ngStyle.lt-md]="{ 'font-size': item.customField38 }">
        </calsoft-editor-text>
      </span>
    </div>
    <div class="descriptions">
      <span [style.color]="item.customField20">

        <calsoft-editor-text [data]="data" [text]="item.customField15" [align]="item.customField34"
          [style.font-size]="item.customField31" [ngStyle.lt-md]="{ 'font-size': item.customField34 }">
        </calsoft-editor-text>

      </span>
    </div>
  </div>

<div >
  <div  [ngClass.lt-md]="'container'" [ngClass.md]="'form-container'"    [ngClass.gt-md]="'form-container'" #positionedElement  
  
  [ngStyle.gt-md]="{'margin-top': item.gapBetweenImage, 'top': item.gapBetweenImage}" >



    <div fxLayout="row"   style="width:100% ;">

      <calsoft-contact-form [noPadding]="true"   style="width:100% ;"   [data]="data" [item]="item" [input]="item.title">
      </calsoft-contact-form>

    </div>

  </div>

</div>
  
</div>
<!-- <div>
  <div fxLayout="row">
      <div fxFlex="50">
        <div fxLayout="column">
          <div fxFlex="50">
            
          </div>
          <div fxFlex="50">

          </div>

        </div>

      </div>
      <div fxFlex="50">
        <div>
          
        </div>

      </div>
  </div>
</div> -->