import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CategoriesRoutingModule } from './categories-routing.module';
import { CategoriesComponent } from './categories.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { PipesModule } from 'src/app/theme/pipes/pipes.module';


@NgModule({
  declarations: [CategoriesComponent],
  imports: [
    CommonModule,
    CategoriesRoutingModule,
    MatButtonModule,
    MatIconModule,
    CustomFlexLayoutModule,
    PipesModule
  ],
  exports:[CategoriesComponent]
})
export class CategoriesModule { }
