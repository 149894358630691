<div class="container">
    <div class="root">
        <div class="board">
            <div class="board-wrapper">
                <div class="board-columns"  >

                    <div cdkDropList cdkDropListOrientation="horizontal" class="board-columns" fxLAyoutGap="10px"
                        (cdkDropListDropped)="dropGrid($event)" >
                        <div fxFlex="33%"   dkDragLockAxis="x" class="board-column"  *ngFor="let images of item.images let d=index"
                            cdkDrag>
                            <div class="column-title" cdkDragHandle           [style.font-size]="item.customField33" [ngStyle.lt-md]="{ 'font-size': item.customField34 }">
                                {{images.customField4}}
                            </div>
                            <div class="tasks-container" id="{{images.customField6}}"
                                [cdkDropListConnectedTo]="connectedDropLists" cdkDropList
                                [cdkDropListData]="images.subChild" (cdkDropListDropped)="drop($event)">
                                <div class="task"*ngFor="let subLayout of images.subChild let d=index" cdkDrag>
                                    {{subLayout. customField1}}
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>

</div>