    <div class="sidesticky">

    <div class="row" fxFlex>
        <div class="appLogo pointer gap" fxFlex="100" fxLayoutAlign="center center">
            <a>       
              <img  [alt]="'fast'"  class="isa" fxFlex="100" fxLayoutAlign="center center"
              [defaultImage]="defaultImage"
              [lazyLoad]="serverPath+'logo.png'"   onerror="this.src='assets/images/error/logoerror1.png';this.style.width='80px';" />      
          </a>
      </div>
  
      <!-- <div class="vertical-navigation">
        <ul>
          <li><a routerLink="/">Home</a></li>
          <li class="has-submenu show-submenu">
            <a routerLink="/about">About</a>
            <aside class="sub-menu ">
                <li><a routerLink="/about/history">History</a></li>
                <li><a routerLink="/about/team">Team</a></li>
                <li><a routerLink="/about/history">History</a></li>
                <li><a routerLink="/about/team">Team</a></li>
                <li><a routerLink="/about/history">History</a></li>
                <li><a routerLink="/about/team">Team</a></li>
                <li><a routerLink="/about/history">History</a></li>
                <li><a routerLink="/about/team">Team</a></li>
                <li><a routerLink="/about/history">History</a></li>
                <li><a routerLink="/about/team">Team</a></li>
                <li><a routerLink="/about/team">Team</a></li>
                <li><a routerLink="/about/history">History</a></li>
                <li><a routerLink="/about/team">Team</a></li>
                <li><a routerLink="/about/history">History</a></li>
                <li><a routerLink="/about/team">Team</a></li>
            </aside>
          </li>
          <li><a routerLink="/services">Services</a></li>
          <li><a routerLink="/contact">Contact</a></li>
  
          <li><a routerLink="/">Home</a></li>
          <li class="has-submenu show-submenu">
            <a routerLink="/about">About</a>
            <aside class="sub-menu">
              <ul>
                <li><a routerLink="/about/history">History</a></li>
                <li><a routerLink="/about/team">Team</a></li>
              </ul>
            </aside>
          </li>
          <li><a routerLink="/services">Services</a></li>
          <li><a routerLink="/contact">Contact</a></li>
          <li><a routerLink="/">Home</a></li>
          <li class="has-submenu">
            <a routerLink="/about">About</a>
            <aside class="sub-menu">
              <ul>
                <li><a routerLink="/about/history">History</a></li>
                <li><a routerLink="/about/team">Team</a></li>
              </ul>
            </aside>
          </li>
          <li><a routerLink="/services">Services</a></li>
          <li><a routerLink="/contact">Contact</a></li>
  
        </ul>
      </div> -->
  
         <div  class="d-none d-xl-block">
            <nav class="side-nav">
                <ul class="menu-vertical sf-arrows" *ngFor="let menu of categoryArray; " #categoryElement>
                    <li class="megamenu-container" (mouseover)="onHover(menu.children ? true : false)">
                        <a style="cursor: pointer;font-weight: bold;" (click)="goToCategory(menu)" [ngClass]="{'sf-with-ul': menu.subcategories.length > 0}">
                            <span *ngIf="menu.subcategories.length > 0">{{menu.categoryName | uppercase}}</span>
                            <span (click)="goToCategory(menu)" *ngIf="menu.subcategories.length == 0">{{menu.categoryName | uppercase}}</span>
                        </a>
    
                        <div [ngStyle]="calculateMegamenuPosition(categoryElement)"  class="megamenu megamenu-sm" *ngIf="menu.subcategories.length > 0">
                                  <div class="row" >
    
                                      <div class="col-xl-4" [style.width.%]="submenu.subcategories.length > 0 ? '15' : '100'" *ngFor="let submenu of menu.subcategories; let i = index">
                                      <div *ngIf="submenu.subcategories.length > 0">
                                        <div class="menu-title" (click)="goToCategory(submenu)">{{submenu.categoryName | uppercase}}</div>
    
                                        <calsoft-new-menu-sub [category]="submenu.subcategories"></calsoft-new-menu-sub>
                                      </div>
                                      <div *ngIf="submenu.subcategories.length === 0">
                                        <div class="menu-title1" (click)="goToCategory(submenu)">{{submenu.categoryName | uppercase}}</div>
                                      </div>
                                    </div>
    
                                  </div>
                          </div>  
    
                          
                    </li>
                </ul>
            </nav>
        </div> 
    </div>
</div>