<div class="container">
    <h2 [style.font-size]="item.customField18"  [ngStyle.lt-md]="{ 'font-size': item.customField17}" class="text-center" [innerHTML]="item.title"></h2>
</div>
<div class="video-banner video-banner-bg bg-image text-center container" [lazyLoad]="image" >
    <div class="container" *ngFor="let slide of item.images">
        <h3 class="video-banner-title h1 text-white"><span  [style.font-size]="item.customField21"  [ngStyle.lt-md]="{ 'font-size': item.customField22}">{{slide.customField15}}</span>
            <strong  [style.font-size]="item.customField23"  [ngStyle.lt-md]="{ 'font-size': item.customField24}">{{slide.customField16}}</strong>
        </h3>
        <p class="btn-video btn-iframe"
            (click)="showModal($event)"><mat-icon>play_arrow</mat-icon></p>
    </div>
</div>

<ng-template #vedio>
    <div class="video-modal" *ngFor="let slide of item.images">
        <iframe class="mfp-iframe" [src]="youtubeUrl+slide.customField17 + '?autoplay=1' | safe: 'resourceUrl'" frameBorder="0" allowFullScreen=""
        allow="autoplay"   title="youtube"></iframe>
        <button type="button" class="close" (click)="closeModal()" autofocus=false><span><mat-icon>close</mat-icon></span></button>
    </div>
  </ng-template>