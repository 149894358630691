import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-bannertextwithimage',
  templateUrl: './bannertextwithimage.component.html',
  styleUrls: ['./bannertextwithimage.component.scss']
})
export class BannertextwithimageComponent {
  @Input() item: any;
  @Input() img: any;
  @Input() data : any;
  @Input() categoryId6: any;

  @Input() navigation: any;
  @Input() catId: any;

  @Input() navigatedUrl: any;

  public serverPath = environment.commonImageApi + '/home/';

  public serverPath1 = environment.commonImageApi + '/commonSlider/';
  
  constructor( private commonService: CommonService,) { }

  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }
  

  link(url: any) {

    this.commonService.goToLink(url)
  }

  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      this.commonService.goToLink(url)
    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }


}

