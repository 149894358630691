import { Component, OnInit } from '@angular/core';
import { Constant } from 'src/app/constants/constant';

@Component({
  selector: 'calsoft-payment-policy',
  templateUrl: './payment-policy.component.html',
  styleUrls: ['./payment-policy.component.scss']
})
export class PaymentPolicyComponent implements OnInit {
  public PageTitle = Constant.payment_policy
  constructor() { }

  ngOnInit(): void {
  }

}
