import { Component, ElementRef, Input, ViewChild, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-vedio-banner-search',
  templateUrl: './vedio-banner-search.component.html',
  styleUrls: ['./vedio-banner-search.component.scss']
})
export class VedioBannerSearchComponent{
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  public serverPath = environment.commonImageApi + '/commonSlider/';
  public serverPath1 = environment.commonImageApi + '/home/';
  @Input() item: any;
  
  @Input() img: any;

  @Input() data: any;

  public youtubeUrl = environment.youtubeUrl;
  
  image: any;

  constructor(private sanitizer: DomSanitizer) {}
  

  ngOnInit() {
    
    this.image= this.serverPath+this.img[0].img;  
    
    console.log(this.image);
        
  }


  searchPage(){
    
  }
  
}
