<div class="row" fxFlex>
    <div  class="d-none d-xl-block">
        <nav class="side-nav">
            <div class="sidenav-title primary">Browse Categories</div>
            <ul class="menu-vertical sf-arrows" *ngFor="let menu of categoryArray; " #categoryElement>
                <li class="megamenu-container" (mouseover)="onHover(menu.children ? true : false)">
                    <a style="cursor: pointer;" (click)="goToCategory(menu)" [ngClass]="{'sf-with-ul': menu.subcategories.length > 0}">
                        <span *ngIf="menu.subcategories.length > 0">{{menu.categoryName | uppercase}}</span>
                        <span (click)="goToCategory(menu)" *ngIf="menu.subcategories.length == 0">{{menu.categoryName | uppercase}}</span>
                    </a>

                    <div [ngStyle]="calculateMegamenuPosition(categoryElement)"  class="megamenu megamenu-sm" *ngIf="menu.subcategories.length > 0">
                              <div class="row" >

                                  <div class="col-xl-4" [style.width.%]="submenu.subcategories.length > 0 ? '15' : '100'" *ngFor="let submenu of menu.subcategories; let i = index">
                                  <div *ngIf="submenu.subcategories.length > 0">
                                    <div class="menu-title" (click)="goToCategory(submenu)">{{submenu.categoryName | uppercase}}</div>

                                    <calsoft-new-menu-sub [category]="submenu.subcategories"></calsoft-new-menu-sub>
                                    <!-- <ul>
                                      <li *ngFor="let submenu1 of submenu.subcategories;">
                                        <a routerLink="" (click)="goToCategory(submenu1)">{{submenu1.categoryName}}</a>
                                      </li>
                                    </ul> -->
                                  </div>
                                  <div *ngIf="submenu.subcategories.length === 0">
                                    <div class="menu-title1" (click)="goToCategory(submenu)">{{submenu.categoryName | uppercase}}</div>
                                  </div>
                                </div>

                              </div>
                      </div>  

                      
                </li>
            </ul>
        </nav>
    </div>
</div>