<!-- button type1 -->
<div fxLayout="row">
    <div fxFlex="20%"></div>
  
    <div fxFlex class="buttoncontainer" >
        <div fxLayout="column" fxLayoutAlign="start center">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="btn">
                    <div fxLayout="row" fxLayoutAlign="center center" style="padding: 3px; margin-top: 5px;">
                        <div class="toggle-container">
                            <button (click)="toggle('buy')" [ngClass]="{'active': selectedOption === 'buy'}">Buy car</button>
                            <button (click)="toggle('sell')" [ngClass]="{'active': selectedOption === 'sell'}">Sell car</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn1">
                <div *ngIf="selectedOption === 'buy'">
                    <h2>spinny offers buy cars</h2>
                    <p>nsbnvchvbgvbn jsbvkn s,m</p>
                </div>
                <div *ngIf="selectedOption === 'sell'">
                    <h2>spinny offers sell cars</h2>
                    <p>njbhfvkmfjndnklvmsmjfowhndlm</p>
                </div>
            </div>
        </div>
    </div>

    <div fxFlex="20%"></div>
</div>



<!-- button type2 -->
<div fxLayout="row">
    <div fxFlex="20%"></div>
  
    <div fxFlex class="buttoncontainer" >
        <div fxLayout="column" fxLayoutAlign="start center">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="btn2">
                    <div fxLayout="row" fxLayoutAlign="center center" style="padding: 3px; margin-top: 5px;">
                        <div class="toggle-container2">
                            <button (click)="toggle1('Best')" [ngClass]="{'active': selectedOption1 === 'Best'}">Best buy for you</button>
                            <button (click)="toggle1('Newly')" [ngClass]="{'active': selectedOption1 === 'Newly'}">Newly Added</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

    <div fxFlex="20%"></div>
</div>



<!-- button type3 -->
<div fxLayout="row">
    <div fxFlex="20%"></div>
  
    <div fxFlex  class="buttoncontainer" >
        <div fxLayout="column" fxLayoutAlign="start center">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="btn3">
                    <div fxLayout="row" fxLayoutAlign="center center" style="padding: 3px; margin-top: 5px;">
                        <div class="toggle-container3">
                           <ul fxlayout="row" fxLayoutAlign="space-evenly center" >
                            <li>
                                <button (click)="toggle2('hatchback')" [ngClass]="{'active': selectedOption2 === 'hatchback'}">
                                    <img src="assets/img/car1.jpg" alt="car1">
                                    <h4>Hatchback</h4>
                                </button>
                            </li>
                            <li>
                                <button (click)="toggle2('sedan')" [ngClass]="{'active': selectedOption2 === 'sedan'}">
                                    <img src="assets/img/car2.jpg" alt="car2">
                                    <h4>Sedan</h4>
                                </button>
                            </li>
                            <li>
                                <button (click)="toggle2('suv')" [ngClass]="{'active': selectedOption2 === 'suv'}">
                                    <img src="assets/img/car3.jpg" alt="car3">
                                    <h4>Suv</h4>
                                </button>
                            </li>
                            <li>
                                <button (click)="toggle2('muv')" [ngClass]="{'active': selectedOption2 === 'muv'}">
                                    <img src="assets/img/car4.jpg" alt="car4">
                                    <h4>Muv</h4>
                                </button>
                            </li>
                           </ul> 
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

    <div fxFlex="20%"></div>
</div>
