import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-image-lazy-loading',
  templateUrl: './image-lazy-loading.component.html',
  styleUrls: ['./image-lazy-loading.component.scss']
})
export class ImageLazyLoadingComponent implements OnInit {
  @Input() loader: string = 'https://media.giphy.com/media/y1ZBcOGOOtlpC/200.gif';
  @Input() height: any;
  @Input() width: number;
  @Input() heightMobile: any;

  @Input() widthMobile: any;

  @Input() image: any;
  @Input() name: any;
  @Input() type: any="'carousel'";

  @Input() imageType: any=1;
  @Input() radius: any;
  public serverPath7 = environment.commonImageApi+"catalog/product/";

  public serverPath8 = environment.commonImageApi+"catalog/product";

  isLoading: boolean;
  actualImage: any;
  constructor(private sanitizer: DomSanitizer ) {
    this.isLoading = false;
    
  }

  hideLoader() {
    this.isLoading = false;
  }
  ngOnInit() {
    this.height=this.height

    if( this.image!=null){
      this.image=this.image.replace("///","/");
      
    if(this.image.includes('/http')){
     
      let array=[];
      array=this.image.split('/http');

      this.image="http"+array[1];

    }
   else if(this.image.includes('/medicineimages')){

      let array=[];
      array=this.image.split('/medicineimages');
      this.image=environment.commonImageApiNew+'medicineimages/'+array[1]
      
      
    }
    else if(this.image.includes('/commonimages')){

      let array=[];
      array=this.image.split('/commonimages');
      
      this.image=this.serverPath7+'commonimages/'+array[1]
      
      
    }

    else if(this.image.includes('/commonimages')){

      let array=[];
      array=this.image.split('/commonimages');
      
      this.image=this.serverPath8+'commonimages/'+array[1]
      
      
    }

    }
   


     
  
     
    if( this.type!='carousel'){
      this.type="product"
    }
    

   
  }



  

}
