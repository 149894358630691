<div *ngIf="item.layout == '0' || item.layout == '1' ">
    <div *ngIf="item.customField6 && item.customField6.length>0"  fxLayout="row">

        <div fxLayout="column" class="title">
            <h1 [ngStyle]="{'font-family': data?.websiteFontFamily}" [style.font-size]="item.customField25" [ngStyle.lt-md]="{ 'font-size': item.customField31 }"><b>
                <calsoft-editor-text [data]="data" [text]="item.customField6" [align]="item.customField34">
                </calsoft-editor-text></b></h1>
        </div>
    </div>

    <div *ngIf="item.customField7 && item.customField7.length>0"  fxLayout="row" class="descrption">
        <p [style.font-size]="item.customField22" [ngStyle.lt-md]="{ 'font-size': item.customField32 }">
            <calsoft-editor-text [data]="data" [text]="item.customField7" [align]="item.customField34">
            </calsoft-editor-text>
        </p>
    </div>


    <div  class="image">
        <div *ngIf="item.customField21" style="padding:10px ;" >
            <!-- <img [src]="serverPath1+item.customField21" style="width: 100%;" [alt]="item.altImage || 'images'" /> -->
            <calsoft-ng-optimized-image [height]="isMobileView() ? '100%' : (item.customField38 || '100%')"  [altImage]="item.altImage || 'images'" [image]="serverPath1+item.customField21" [i]="'0'">  </calsoft-ng-optimized-image>


        </div>
    </div>
    <!-- <hr /> -->
        <p [style.font-size]="item.customField26" [ngStyle.lt-md]="{ 'font-size': item.customField35 }">
        <calsoft-editor-text [data]="data" [text]="item.customField8"
        [align]="item.customField34">
       </calsoft-editor-text>
        </p>
</div>


<div [style.backgroundImage]="'url(' + serverPath1+item.customField21 + ')'" class="bgcss" *ngIf="item.layout == '2' ">
    <div class="subcontent">
        <div class="padcss">
            <div>
                <h3 [style.font-size]="item.customField25" [ngStyle.lt-md]="{ 'font-size': item.customField31 }" style="color: white;"
                [innerHTML]="item.customField6">
                    <b></b></h3>
            </div>

            <p [style.font-size]="item.customField22" [ngStyle.lt-md]="{ 'font-size': item.customField32 }" style="color: white;">
                <calsoft-editor-text [data]="data" [text]="item.customField7" [align]="item.customField34">
                </calsoft-editor-text>
            </p>
        </div>
    </div>
</div>