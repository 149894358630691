

<div class="new-container">
  <div fxLayout="row" fxLayoutGap="10px">
    <div fxFlex>
      <mat-form-field style="width: 100%;" appearance="outline">
        <mat-label>Hotels</mat-label>
        <mat-select formControlName="name"
            [disableOptionCentering]="true" #singleSelect
            autocomplete="off">
            <mat-option>
                <ngx-mat-select-search name="Product Search"
                    ngDefaultControl [formControl]="typeYFilterControl"
                    placeholderLabel="Product Search"
                    [preventHomeEndKeyPropagation]="true"
                    noEntriesFoundLabel="No data found">
                </ngx-mat-select-search>
            </mat-option>
            <mat-option (onSelectionChange)="onProductChange($event,opt.name)" *ngFor="let opt of filteredProductdropdownTypeList; let i=index;"
                [value]="opt.name">
                {{opt.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    </div>
    <div fxFlex>

      <mat-form-field appearance="outline" fxFlex="100" style="font-size: 16px;">
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Check-in Date" [(ngModel)]="startDate">
          <input matEndDate placeholder="Check-out Date" [(ngModel)]="endDate">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

    </div>
    <div fxFlex>

      <div fxLayout="row" (click)="toggleMenu()" style="margin-top: 20px;text-align: center;font-size: 20px;">
        <input type="text" matInput placeholder="1 Room - 1 Guests" readonly style="border: none;">
      </div>


      <div class="dropdown-menu" [@dropdownAnimation]="isOpen ? 'open' : 'closed'">

        <div *ngFor="let room of bookingInfo; let i = index">

          <div fxLayout="column" fxLayoutAlign="center center" style="background-color: #F6F5F5;">

            <div class="padding-10">

              <span>Room {{i+1}}</span>

              <button style="cursor: pointer;" mat-icon-button (click)="removeRoom(i)" *ngIf="bookingInfo.length > 1">
                <mat-icon>close</mat-icon>
              </button>
  
            </div>

            <div fxLayoutAlign="center center"  fxFlex="100">
              <span class="label" style="color: gray;">Adult</span>

              <button mat-icon-button (click)="decrementAdult(room)">
                <mat-icon>remove</mat-icon>
              </button>

              <input type="number" [(ngModel)]="room.adults" style="width: 20%;border: none;text-align: end;" readonly>
              <button mat-icon-button (click)="incrementAdult(room)">
                <mat-icon>add</mat-icon>
              </button>
            </div>
            

            <div fxLayoutAlign="center center" fxFlex="100">
              <span class="label" style="color: gray;">Child</span>
              <button mat-icon-button (click)="decrementChildren(room)">
                <mat-icon>remove</mat-icon>
              </button>
              <input type="number" [(ngModel)]="room.children" style="width: 20%;border: none;text-align: end" readonly>
              <button mat-icon-button (click)="incrementChildren(room)">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <a mat-button (click)="addRoom()" class="morebtn">ADD MORE ROOMS</a>
      </div>
    </div>

    <div fxFlex>

      <!-- <mat-form-field appearance="outline" style="font-size: 16px;">
        <mat-label>Special Code</mat-label>
        <mat-select [(ngModel)]="specialcat">
          <mat-option value="0">None</mat-option>
          <mat-option value="1">Corporate Access Code</mat-option>
          <mat-option value="2">Travel Agency code</mat-option>
          <mat-option value="3">Business Connect code</mat-option>
          <mat-option value="4">Group code</mat-option>
        </mat-select>
      </mat-form-field> -->

      <div fxFlex style="height: 65px;">
        <button (click)="navigate()" fxFlex="100" class="dropdown-toggle" color="primary"  style="background-color: #AD8B3A;color: white; font-size: 25px;">
          Enquire</button>
      </div>

      <div style="display: flex;" *ngIf="specialcat == '1' || specialcat == '2'">
        <input matInput type="text" style="width: 60%;border-bottom: 1px solid gray;">
        <button mat-button style="background-color: black;color: white;">Add</button>
      </div>


    </div>
    <div fxFlex style="height: 65px;">
      <button fxFlex="100" class="dropdown-toggle" color="primary"  style="background-color: #AD8B3A;color: white; font-size: 25px;">
        Book Now </button>
    </div>
  </div>
</div>