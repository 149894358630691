<div>
    <div fxFlex fxLayout.xs="column"  fxLayoutAlign.gt-sm="center center">
        <span fxFlex *ngIf="item.customField7.length>0">
            <button (click)="callPhone(item.customField15)" fxFlex  [style.background-color]="item.customField8" class="btn">
                <mat-icon style="margin-right: 5px;">phone_iphone</mat-icon>
                 <span   [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">{{item.customField7}}</span> 
                </button>
        </span>
     
        <span fxFlex *ngIf="item.customField12.length>0">
            <button (click)="whatsapp(item.customField14)" fxFlex  [style.background-color]="item.customField8" class="btn">
                <img style="height: 24px; margin-right: 5px;"  src="assets/icons/whatsapp.svg" alt="whatsapp">
                <span           [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">{{item.customField12}}</span>
            </button>
        </span>
      
        <span fxFlex  *ngIf="item.customField10.length>0"> 
            <button (click)="composeEmail(item.customField11)" fxFlex  [style.background-color]="item.customField8" class="btn">
                <mat-icon style="margin-right: 5px;">mail_outline</mat-icon>
               <span           [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }"> {{item.customField10}}</span>
            </button>
        </span>
       
    </div>
</div>

