

<div *ngFor="let value of arry" fxLayoutGap="10px" fxLayout="row" style="padding-top:5px" fxLayoutAlign="start start" >
    <div fxLayout="column" style="width:100%"   fxLayoutGap="10px">
  
      <div fxLayout="row" fxLayoutGap="10px"  >
  
        <mat-icon *ngIf="icon"   class="maticoncss">{{ matIcon }}</mat-icon>
      
        <calsoft-editor-text-child style="width: 100%" fxLayoutAlign="start center"
        [data]="data"
        [text]="value"
        [id]="id"
        [align]="align"
        [fontSize]="fontSize"
        [data]="data"
        [MobfontSize]="MobfontSize"
        [fontFamily]="data?.websiteFontFamily">
      </calsoft-editor-text-child>
  
        </div>
  
    </div>
  
  </div>
  
  
  
  
  <!-- 
  <div  fxLayoutGap="10px" fxLayout="row" style="padding-top:5px" fxLayoutAlign="start start" >
    <div fxLayout="column" style="width:100%"   fxLayoutGap="10px">
  
      <div fxLayout="row" fxLayoutGap="10px"  >
  
        <mat-icon *ngIf="icon"   class="maticoncss">{{ matIcon }}</mat-icon>
  
        <calsoft-editor-text-child style="width: 100%" fxLayoutAlign="start center"
        [data]="data"
        [text]="text"
        [id]="id"
        [align]="align"
        [fontSize]="fontSize"
        [data]="data"
        [MobfontSize]="MobfontSize"
        [fontFamily]="data?.websiteFontFamily">
      </calsoft-editor-text-child>
  
        </div>
  
    </div>
  
  </div> -->