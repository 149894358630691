import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-youtube-video',
  templateUrl: './youtube-video.component.html',
  styleUrls: ['./youtube-video.component.scss']
})
export class YoutubeVideoComponent  implements OnInit {

  autoplay:any;

  @Input() img: any;

  public youtubeUrl = environment.youtubeUrl;
  url: string;

  ngOnInit() {

    this.url=this.youtubeUrl+this.img.img +'?autoplay='+this.img.autoplay    
  }

}
