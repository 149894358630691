import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-imagecircleanimate',
  templateUrl: './imagecircleanimate.component.html',
  styleUrls: ['./imagecircleanimate.component.scss']
})
export class ImagecircleanimateComponent {

  

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() img: any;

  @Input() data: any;

  constructor(
    private commonService: CommonService,
  ) {

  }


  imageNavigation(id){


    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }
  
  }

  link(url) {

    this.commonService.goToLink(url)
  }
  
  isClicked = false;

  triggerClickEffect() {
    this.isClicked = true;

    // Reset the effect after the animation completes
    setTimeout(() => {
      this.isClicked = false;
    }, 400);
  }
}
