import { Component, ElementRef, Input } from '@angular/core';
import { FormPopupComponent } from '../form-popup/form-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-button-row-header',
  templateUrl: './button-row-header.component.html',
  styleUrls: ['./button-row-header.component.scss']
})
export class ButtonRowHeaderComponent {
  @Input() item: any;
  @Input() data: any;
  @Input() value: string;
  ngOnInit() {
    
  }

  constructor(
    public dialog: MatDialog,
    private elementRef: ElementRef,
    private commonService:CommonService
  ){

  }




callToFunction(type,value){

 this.commonService.navigateButton(type,value)
  // this.commonService.goToLink('contact-us')

}
active: string;
scrollDown(value){
  this.active =value;
  this.ScrollIntoView(this.active);
}

ScrollIntoView(elem: string) {
  this.active = "#" + elem;
  const ele = document.querySelector(this.active) as HTMLElement;
  
  if (ele) {
    const offsetTop = ele.getBoundingClientRect().top + window.pageYOffset;
    const paddingTop = 96;
    window.scrollTo({
    top: offsetTop - paddingTop,
    behavior: 'smooth'
    });
  } else {
    console.error('Element not found:', this.active);
  }
  }


  link(url) {

   this.commonService.goToLink(url)
  
 }

 dynamicFormPopup(formGroupId:any) {
   if (window.innerWidth > 768) {

      let dialogRef = this.dialog.open(FormPopupComponent, {
         width: '790px',
         data: { formId: formGroupId}
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {

      });

   }
   else {
      let dialogRef = this.dialog.open(FormPopupComponent, {
         minWidth: '100vw', height: '100vh',
         data: { formId: formGroupId}
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {

      });
   }

}

}
