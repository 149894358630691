<div fxHide fxShow.gt-sm class="carousel-container" (mouseenter)="stopAutoplay()" (mouseleave)="startAutoplay()">
  <div class="carousel-inner" [style.transform]="getTransform()">
    <div class="carousel-item" *ngFor="let slide of item.images; let i = index" [class.active]="i === currentIndex"
      [style.background-color]="slide.customField4">
      <div class="main-banner-area-two">
        <div class="slide-content" fxLayout="row">
          <ng-container *ngIf="showImages">
            <div class="text-content" fxFlex="50" fxFlex.xs="100">
              <!-- <div class="special-offer" class="wow animate__animated animate__fadeInDown" data-wow-delay="1.5s">
                <img onerror="this.src='';" [src]="serverPath1 + slide.subChild[currentIndex].customField13" style="width: 100px;" />
              </div> -->
              <h1 class="wow animate__animated animate__fadeInDown" data-wow-delay="1.5s"
                [ngStyle]="{'font-family': item.fontFamily}">{{ slide.customField5 }}</h1>
              <p class="wow animate__animated animate__fadeInUp" data-wow-delay="1.5s"
                [ngStyle]="{'font-family': item.fontFamily}">{{ slide.customField6 }}</p>
              <button mat-button class="shop-now-btn buttonz wow animate__animated animate__fadeInUp"
                data-wow-delay="1.5s" *ngIf="slide.customField7"
                (click)="openInNewWindow(slide.customField8)">{{slide.customField7}}<mat-icon>chevron_right</mat-icon></button>

            </div>
            <div class="image-content" fxFlex="50" fxFlex.xs="100">

              <img *ngFor="let subChild of slide.subChild;trackBy: trackByFn"
                [ngStyle.lt-md]="{'height': subChild.customField12 , 'left' : subChild.customField10  , 'top': subChild.customField11}"
                [style.height]="subChild.customField9" [style.left]="subChild.customField7"
                [style.top]="subChild.customField8" [src]="bannerServerPath + subChild.customField6"
                [alt]="subChild.customField6" class="wow animate__animated {{subChild.customField5}}"
                data-wow-delay="0.7s">

            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <button fxHide fxShow.gt-sm class="carousel-control-prev" (click)="prevSlide()">❮</button>
  <button fxHide fxShow.gt-sm class="carousel-control-next" (click)="nextSlide()">❯</button>
</div>

<div fxHide fxShow.lt-sm class="carousel-container" (mouseenter)="stopAutoplay()" (mouseleave)="startAutoplay()">
  <div class="carousel-inner" [style.transform]="getTransform()">
    <div class="carousel-item" *ngFor="let slide of item.images; let i = index" [class.active]="i === currentIndex"
      [style.background-color]="slide.customField4">
      <div class="main-banner-area-two">
        <div class="slide-content" style="height: 100%;" fxLayout="column">
          <ng-container *ngIf="showImages">

            <div fxLayout="row" style="height: 100%; width: 100%;" fxFlex="100" fxLayoutAlign="center start">
              <div class="image-content" fxFlex="50" fxFlex.xs="100">
                <img *ngFor="let subChild of slide.subChild; trackBy: trackByFn"
                  [src]="bannerServerPath + subChild.customField6" [alt]="subChild.customField6"
                  [style.height]="subChild.customField12" [style.left]="subChild.customField10"
                  [style.top]="subChild.customField11" class="wow animate__animated {{subChild.customField5}}"
                  data-wow-delay="0.7s">
              </div>

            </div>

            <div fxLayout="row">
              <div class="mobile-text-content" fxFlex="100">
                <div fxLayout="column">
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <h1 class="wow animate__animated animate__fadeInDown" data-wow-delay="1.5s"
                      [ngStyle]="{'font-family': item.fontFamily}">{{ slide.customField5 }}</h1>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <p class="wow animate__animated animate__fadeInUp padding-10" data-wow-delay="1.5s"
                    [ngStyle]="{'font-family': item.fontFamily}">{{ slide.customField6 }}</p>
                  </div>

                  <div fxLayout="row" fxLayoutAlign="center center">
                    <button mat-button class="shop-now-btn buttonz wow animate__animated animate__fadeInUp"
                      data-wow-delay="1.5s" *ngIf="slide.customField7" (click)="openInNewWindow(slide.customField8)">
                      {{slide.customField7}}<mat-icon>chevron_right</mat-icon>
                    </button>
                  </div>

                </div>


                <div fxLayout="row" style="padding-top: 40px; background: none;" fxLayoutAlign="center center">

                  <span (click)="prevSlide()"><mat-icon style="font-size: 14px; cursor: pointer;"
                      (click)="prevSlide()">lens</mat-icon></span>
                  <span (click)="nextSlide()"><mat-icon style="font-size: 14px; cursor: pointer;"
                      (click)="nextSlide()">lens</mat-icon></span>

                </div>
                
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>