<div *ngIf="item.images.length == 3">
  <div fxLayout="row" style="margin-left: 15px; margin-right: 15px;" fxLayout.xs="column">
    <!-- Left side image -->
    <div fxFlex="50" class="padd15">
      <div *ngIf="item.images && item.images.length > 0">
        <div style="height: 100%; display: flex; flex-direction: column; justify-content: space-between;">

          <img [src]="serverPath1 + item.images[0].customField6">
          <div>
            <h3 style="font-size: 19px; margin-top: 10px;">{{ item.images[0].customField4 }}</h3>
            <p style="font-size: 14px;"><b>{{ item.images[0].customField17 }}</b></p>
            <p style="font-size: 14px;">{{ item.images[0].customField16 }}</p>
          </div>
          <div class="details" *ngIf="item.images[0].customField5"
            (click)="openInNewWindow(item.images[0].customField7)">
            {{ item.images[0].customField5 }}
            <mat-icon>chevron_right</mat-icon>
          </div>
        </div>
      </div>
    </div>

    <!-- Right side images -->
    <div fxFlex="50" fxLayout="column">
      <div *ngFor="let image of item.images.slice(1)" style="margin-bottom: 15px; display: flex; ">
        <div fxLayout="row" fxLayout.xs="column">
          <div style="width: 50%; margin-right: 10px;" [ngStyle.lt-md]="{ 'width': '100%' }">
            <div style="height: 250px;" [ngStyle.lt-md]="{ 'height': 'unset' }">
              <img [src]="serverPath1 + image.customField6" style="height: 100%; width: auto;"
                [ngStyle.lt-md]="{ 'width': '100%' }">
            </div>
          </div>
          <div class="csswidth">
            <h3 style="font-size: 19px; margin-top: 0px;">{{ image.customField4 }}</h3>
            <p style="font-size: 14px;"><b>{{ image.customField17 }}</b></p>
            <p style="font-size: 14px;">{{ image.customField16 }}</p>
            <div class="details" *ngIf="image.customField5" (click)="openInNewWindow(image.customField7)">
              {{ image.customField5 }}
              <mat-icon>chevron_right</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 4 images -->


<div *ngIf="item.images.length == 4">
  <div fxLayout="row" style="margin-left: 15px; margin-right: 15px;" fxLayout.xs="column">
    <!-- Left side image -->
    <div fxFlex="50" class="padd15">
      <div *ngIf="item.images && item.images.length > 0">
        <div style="height: 100%; display: flex; flex-direction: column; justify-content: space-between;">

          <img [src]="serverPath1 + item.images[0].customField6">
          <div>
            <h3 style="font-size: 19px; margin-top: 10px;">{{ item.images[0].customField4 }}</h3>
            <p style="font-size: 14px;"><b>{{ item.images[0].customField17 }}</b></p>
            <p style="font-size: 14px;">{{ item.images[0].customField16 }}</p>
          </div>
          <div class="details" *ngIf="item.images[0].customField5"
            (click)="openInNewWindow(item.images[0].customField7)">
            {{ item.images[0].customField5 }}
            <mat-icon>chevron_right</mat-icon>
          </div>
        </div>
      </div>
    </div>

    <!-- Right side images -->
    <div fxFlex="50" fxLayout="column">
      <div *ngFor="let image of item.images.slice(1)" style="margin-bottom: 15px; display: flex; ">
        <div fxLayout="row" fxLayout.xs="column">
          <div style="margin-right: 10px;" [ngStyle.lt-md]="{ 'width': '100%' }">
            <div style="height: 200px;" [ngStyle.lt-md]="{ 'height': 'unset' }">
              <img [src]="serverPath1 + image.customField6" style="height: 100%; width: auto;"
                [ngStyle.lt-md]="{ 'width': '100%' }">
            </div>
          </div>
          <div class="csswidth">
            <h3 style="font-size: 19px; margin-top: 0px;">{{ image.customField4 }}</h3>
            <p style="font-size: 14px;"><b>{{ image.customField17 }}</b></p>
            <p style="font-size: 14px;">{{ image.customField16 }}</p>
            <div class="details" *ngIf="image.customField5" (click)="openInNewWindow(image.customField7)">
              {{ image.customField5 }}
              <mat-icon>chevron_right</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

