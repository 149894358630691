import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'calsoft-course-content',
  templateUrl: './course-content.component.html',
  styleUrls: ['./course-content.component.scss']
})
export class CourseContentComponent {

  
  constructor(
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {

  }
  

  productData = [
    {
      values: {
        short_description: null,
        featured: 0,
        MapLink: null,
        WholeSalePrice: 0.0,
        discountPrice: 0.0,
        description: "Angular js",
        units: 0,
        ScentType: "",
        Name: "Angular js",
        WholeSaleDiscountPrice: 0.0,
        price: 1200.0,
        supplier: null,
        sku: "Angular js",
        MaterialCost: 0.0,
        gallery: null,
        image: null,
        thumbnail: null,
        meta_title: null,
        visibility: 4,
        isCustomized: null,
        media_gallery: null,
        tax_class_id: 59,
        CustomProductResource: null,
        weight: 1000.0,
        meta_description: null,
        country_of_manufacture: null,
        special_price: 0.0,
        meta_keyword: null,
        product_seo_name: null,
        video_id: null
      },
      valueIds: {
        short_description: "1091408",
        featured: "436365",
        MapLink: "374299",
        WholeSalePrice: "214011",
        discountPrice: "214013",
        description: "1091407",
        units: "436369",
        ScentType: "",
        Name: "374296",
        WholeSaleDiscountPrice: "214012",
        price: "214009",
        supplier: "436366",
        sku: "0",
        MaterialCost: "214014",
        gallery: "374306",
        image: "374303",
        thumbnail: "374304",
        meta_title: "374301",
        visibility: "436364",
        isCustomized: "436367",
        media_gallery: "374305",
        tax_class_id: "436368",
        CustomProductResource: "374300",
        weight: "214008",
        meta_description: "374302",
        country_of_manufacture: "374297",
        special_price: "214010",
        meta_keyword: "1091410",
        product_seo_name: "1091409",
        video_id: "374298"
      },
      typeId: "Course",
      catalogInventoryStockItem: {
        manageStock: 1,
        itemId: 92193,
        enableQtyIncrements: 0,
        qty: 1.0,
        stockId: 1,
        isQtyDecimal: 1,
        isInStock: 1.0,
        maxQty: 0.0,
        notifyStockQty: 1.0,
        minQty: 0.0,
        stockStatusChangedAuto: 1
      },
      catalogInventoryStockStatus: {
        websiteId: 1,
        qty: 1.0,
        stockId: 1,
        stockStatus: 1
      },
      productEntityMediaGallery: [],
      catalogCategoryProduct: [
        {
          categoryId: 1187,
          position: 0,
          productId: 95824,
          minPrice: null,
          maxPrice: null
        }
      ],
      catalogProductSuperAttribute: [],
      catalogProductSuperLink: [],
      catalogProductLink: [],
      varientProductItems: [],
      variant: null,
      slots: [],
      course: [
        {
          courseId: 2,
          title: "Introduction",
          productId: 95824,
          active: 1,
          customField1: "",
          customField2: "",
          customField3: "",
          customField4: "",
          customField5: "",
          customField6: "",
          customField7: "",
          customField8: "",
          customField9: "",
          customField10: "",
          courseItem: [
            {
              courseItemId: 2,
              courseId: 2,
              title: "JavaScript Development Workbook",
              type: 0,
              active: 1,
              freePreview: "",
              view: "",
              customField1: "",
              customField2: "",
              customField3: "",
              customField4: "",
              customField5: "",
              customField6: "",
              customField7: "",
              customField8: "",
              customField9: "",
              customField10: ""
            },
            {
              courseItemId: 3,
              courseId: 2,
              title: "Variables in JavaScript",
              type: 0,
              active: 1,
              freePreview: "",
              view: "",
              customField1: "",
              customField2: "",
              customField3: "",
              customField4: "",
              customField5: "",
              customField6: "",
              customField7: "",
              customField8: "",
              customField9: "",
              customField10: ""
            }
          ]
        }
      ]
    }
  ];


  accordionOpenIndex: number | null = null;
  subaccordionOpenIndex: { [key: number]: number | null } = {};

  toggleAccordion(index: number) {
    this.accordionOpenIndex = this.accordionOpenIndex === index ? null : index;
  }

  subtoggleAccordion(parentIndex: number, subIndex: number) {
    if (this.subaccordionOpenIndex[parentIndex] === undefined) {
      this.subaccordionOpenIndex[parentIndex] = null;
    }
    this.subaccordionOpenIndex[parentIndex] = this.subaccordionOpenIndex[parentIndex] === subIndex ? null : subIndex;
  }


  @ViewChild('vedio') vedio: TemplateRef<any>;
  
  ViewVedio(event: Event) {
    if (window.innerWidth > 768) {

      let dialogRef = this.dialog.open(this.vedio, {
        width: '790px',
        height:'500px'
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
  
      });

    }
    else {
      let dialogRef = this.dialog.open(this.vedio, {
        minWidth: '100vw', height: '100vh',
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
  
      });
    }
	}

  closeModal(){
    this.dialog.closeAll();
  }

}

