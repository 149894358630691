import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-image-text',
  templateUrl: './image-text.component.html',
  styleUrls: ['./image-text.component.scss']
})
export class ImageTextComponent {

  public serverPath = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() data: any;

  @Input() img: any;
  
  @Input() index: any;


  constructor(
    private commonService: CommonService ) {}
  
  imageNavigation(id){


    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  link(url) {

    window.open(url, '_blank')
  }
  
}

