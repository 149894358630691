<div *ngIf="type=='layouts'">
    <mat-carousel timings="250ms ease-in" 
     [maintainAspectRatio]="maintainAspectRatio" [height]="height" 
     [autoplay]="true" interval="5000" class="primary" maxWidth="auto"
        proportion="25" slides="5" [loop]="true"[hideArrows]="layout.length==1?true:false" [hideIndicators]="false" [useKeyboard]="true"
        [useMouseWheel]="false" orientation="ltr">
        <mat-carousel-slide #matCarouselSlide 
        *ngFor="let slide of layout; let i = index" [data]="slide"
        [categoryId]="slide.category" [image]="imageUrl+slide.value"
            overlayColor="#00000040" [hideOverlay]="true">

        </mat-carousel-slide>
    </mat-carousel>
</div>

<div *ngIf="type=='common'">
    <mat-carousel timings="250ms ease-in"  [autoplay]="true" 
     [maintainAspectRatio]="maintainAspectRatio" [height]="height"
      interval="5000" class="primary" maxWidth="auto"
        proportion="25" slides="5" [loop]="true" 
        [hideArrows]="layout.length==1?true:false" 
        [hideIndicators]="false" [useKeyboard]="true"
        [useMouseWheel]="false" orientation="ltr" *ngIf="layout.length">
        <mat-carousel-slide #matCarouselSlide 
        *ngFor="let slide of layout; let i = index"
         [categoryId]="slide.catId" [image]="imageUrl+slide.img"
            overlayColor="#00000040" 
            [data]="slide"
            [hideOverlay]="true">

            <div [ngClass]="{
                'top-left': slide.customField29 === '1',
                'top-right': slide.customField29 === '2',
                'centered': slide.customField29 === '3',
                'bottom-left': slide.customField29 === '4',
                'bottom-right': slide.customField29 === '5'
            }">
                <div fxHide fxShow.gt-sm>
                    <div style="font-size: 55px; text-transform: uppercase;" *ngIf="slide.customField30"    [ngStyle.gt-md]="{'font-size': item.customField34 }"
                    [ngStyle.lt-md]="{
                        
                        'font-size': item.customField35
                    }" >{{slide.customField30}}</div>
                    <div style="font-size: 40px;" *ngIf="slide.customField31"  [ngStyle.gt-md]="{'font-size': item.customField36 }"
                    [ngStyle.lt-md]="{
                        
                        'font-size': item.customField37
                    }" ><i>{{slide.customField31}}</i></div>
                    <button mat-raised-button *ngIf="slide.customField32" [style.background-color]="slide.customField33" class="btn_css">{{slide.customField32}}</button>
                </div>
                <div fxShow fxHide.gt-sm >
                    <div style="font-size: 18px; text-transform: uppercase;" *ngIf="slide.customField30"   [ngStyle.gt-md]="{'font-size': item.customField34 }"
                    [ngStyle.lt-md]="{
                        
                        'font-size': item.customField35
                    }">{{slide.customField30}}</div>
                    <div style="font-size: 14px;" *ngIf="slide.customField31"  [ngStyle.gt-md]="{'font-size': item.customField36 }"
                    [ngStyle.lt-md]="{
                        
                        'font-size': item.customField37
                    }"><i>{{slide.customField31}}</i></div>
                    <button mat-raised-button *ngIf="slide.customField32" class="btnview" [style.background-color]="slide.customField33" class="btn_css_mob">{{slide.customField32}}</button>
                </div>
            </div>    
            

        </mat-carousel-slide>
    </mat-carousel>
</div>

<div *ngIf="type=='headsearch'">
    <mat-carousel timings="250ms ease-in"  [autoplay]="true" 
     [maintainAspectRatio]="maintainAspectRatio" [height]="height"
      interval="3000" class="primary" maxWidth="auto"
        proportion="25" slides="6" [loop]="true" 
        [hideArrows]="layout.length==1?true:true" 
        [hideIndicators]="true" [useKeyboard]="true"
        [useMouseWheel]="false" orientation="ltr" *ngIf="layout.length">
        <mat-carousel-slide #matCarouselSlide 
        *ngFor="let slide of layout; let i = index"
         [categoryId]="slide.catId" [image]="imageUrl+slide.img"
            overlayColor="#00000040" 
            [data]="slide"
            [hideOverlay]="true">

            <div fxHide fxShow.gt-sm>
                <div class="top-left1">
                    <div style="font-size: 35px; text-transform: uppercase;" *ngIf="slide.customField30"   [ngStyle.gt-md]="{'font-size': item.customField34 }"
                    [ngStyle.lt-md]="{
                        
                        'font-size': item.customField35
                    }"><b><i>{{slide.customField30}}</i></b></div>
                </div>
                <div class="bottom-right1">
                    <div style="font-size: 35px;text-transform: uppercase;" *ngIf="slide.customField31"  [ngStyle.gt-md]="{'font-size': item.customField36 }"
                    [ngStyle.lt-md]="{
                        
                        'font-size': item.customField37
                    }"><b><i>{{slide.customField31}}</i></b></div>
                </div>
            </div>

            <div fxShow fxHide.gt-sm >
                <div>
                    <div style="font-size: 25px; text-transform: uppercase;margin-top: 22px;" *ngIf="slide.customField30"  [ngStyle.gt-md]="{'font-size': item.customField34 }"
                    [ngStyle.lt-md]="{
                        
                        'font-size': item.customField35
                    }"><b><i>{{slide.customField30}}</i></b></div>
                </div>
                <div class="bottom-right2">
                    <div style="font-size: 25px;text-transform: uppercase;" *ngIf="slide.customField31"  [ngStyle.gt-md]="{'font-size': item.customField36 }"
                    [ngStyle.lt-md]="{
                        
                        'font-size': item.customField37
                    }"><b><i>{{slide.customField31}}</i></b></div>
                </div>
            </div>            
          
        </mat-carousel-slide>
    </mat-carousel>
</div>

