import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { CommonService } from 'src/app/services/common.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { RestaurantsService } from 'src/app/services/restaurants.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  
  public serverPath = environment.commonImageApi + "logo/";

  page: number;
  size: number;
  sortParam: string;
  partnerList: any;
  distance: any;
  @Input() data: any;
  showCartIcon: any;
  enableEnquire: any;
  b2bPricingEnabled: any;
  decimalDigit: any;
  showCartIncremented: any;
  deliveryDoorStepIcon: boolean;
  deliveryTime: any;
  showTimeIcon: boolean;
  showGenuineProductIcon: boolean;
  genuineProductText: any;
  deliveryDoorStepText: any;
  showConfigurableDropdown: any;
  productDetailPage: any;
  crossSellProductsText: any;
  relatedProductsText: any;
  recentlyViewedProductsText: any;
  upSellProductsText: any;
  productImageHeight: any;
  productImageWidth: any;
  productImageType: any;
  productDetailImageWidth: any;
  productzoom: any;
  productImageWidthMob: any;
  productImageHeightMob: any;
  productDetailHeight: any;
  productDetailWidth: any;
  productDetailHeightMobile: any;
  productDetailWidthMobile: any;
  showExclusivePrice: any;
  productDetailPageSubimageHeight: any;

  pageSize: any;
  spinner: boolean;
  
  constructor(
    public router: Router,
    private restaurantsService: RestaurantsService,
    private configService: ConfigServiceService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {

    this.getConfigData();
    

 
  }



  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {
          localStorage.setItem(Constant['GUEST_LOGIN'], data['guestLogin'])

          let url = data['baseUrl'] + this.router.url;


          this.commonService.setCurrencyValue(data['currencyBase'])

          this.showCartIcon = data['showCartProductPage'];

          this.enableEnquire = data['enableEnquire'];

          this.b2bPricingEnabled = data['b2bPricingEnabled'];

          this.decimalDigit=data['decimalDigit'];
          
          this.showCartIncremented = data['showCartIncremented']

          this.deliveryDoorStepIcon = data['deliveryDoorStepIcon'] == 1 ? true : false

          this.deliveryTime = data['deliveryTime']

          this.showTimeIcon = data['showTimeIcon'] == 1 ? true : false

          this.showGenuineProductIcon = data['showGenuineProductIcon'] == 1 ? true : false;

          this.genuineProductText = data['genuineProductText']

          this.deliveryDoorStepText = data['deliveryDoorStepText'];

          this.showConfigurableDropdown = data['showConfigurableDropdownProductPage'];

          this.productDetailPage = data['productDetailPage'];

          this.crossSellProductsText = data['crossSellProductsText']

          this.relatedProductsText = data['relatedProductsText'];

          this.recentlyViewedProductsText = data['recentlyViewedProductsText']

          this.upSellProductsText = data['upSellProductsText']

          this.productImageHeight = data['productsImageHeight']

          this.productImageWidth = data['productsImageWidth']

          this.productImageType = data['productsImageType']

          this.productDetailImageWidth = data['productImageWidth']

          this.productzoom = data['productzoom']

          this.data = data;

          this.productImageWidthMob = data['productsImageWidthMob']

          this.productImageHeightMob = data['productsImageHeightMob'];


          this.productDetailHeight = data['productImageHeight'];
          this.productDetailWidth = data['producImageWidth'];

          this.productDetailHeightMobile = data['productMImageHeight'];
          this.productDetailWidthMobile = data['productMImageWidth'];

          this.showExclusivePrice = data['showExclusivePrice']

          this.productDetailPageSubimageHeight = data['productDetailPageSubimageHeight'];

          this.distance=data['deliveryRange'];

          if (this.distance !== null && this.distance !== undefined) {
            this.getAllPartner();
          }
          

      

        }, error => {

        }
      );
  }

  getAllPartner() {
    this.page = 0;
    this.size = 10;
    this.sortParam = "id,DESC";

    let lat = localStorage.getItem('latitude');
    let long = localStorage.getItem('longitude');

    this.restaurantsService.getAllPartner(this.page, this.size, this.sortParam, lat, long, this.distance).subscribe(
      res => {
        this.partnerList = res['content'];
        
      },
      error => {
      }
    )
  }

  onScroll(){
    if (this.page < this.pageSize) {

      let lat = localStorage.getItem('latitude');
      let long = localStorage.getItem('longitude');
      this.spinner = true;
      this.page++;
      this.restaurantsService.getAllPartner(this.page, this.size, this.sortParam, lat, long, this.distance)
        .subscribe(
          res => {
            for (const partners of res['content']) {
              this.partnerList.push(partners);
              this.spinner = false;
            }
            setTimeout(() => {

              if (this.partnerList.length < 1) {

              }
            }, 500);
          });

    }
    else {
      this.spinner = false;
    }
  }

  viewPartner(secondaryTenantId,storeName) {
    this.router.navigate([`/restaurants/${secondaryTenantId}/${storeName}`]);
  }
  

}

