<div class="container">
  <div class="container">
    <div *ngFor="let rowIndex of rows" class="feature-row"
      [ngClass]="{'slide-right': rowIndex % 2 === 0, 'slide-left': rowIndex % 2 !== 0}">
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
        <div *ngFor="let feature of img.slice(rowIndex * 3, rowIndex * 3 + 3); let i = index" [fxFlex]="calcFlex()"
          class="feature-box" [ngClass]="getStyleClass(i)">
          <div [style.background-color]="feature.customField17" class="feature-title">
            <calsoft-editor-text [fontSize]="item.customField39" [MobfontSize]="item.customField40" [data]="data"
              [text]="feature.customField15">
            </calsoft-editor-text>
          </div>
          <div [style.background-color]="feature.customField18" class="feature-description">
            <calsoft-editor-text [fontSize]="item.customField37" [MobfontSize]="item.customField38" [data]="data"
              [text]="feature.customField16">
            </calsoft-editor-text>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>