import { ChangeDetectorRef, Component, DoCheck, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent  {

  @Input() item: any;

  @Input() faq: any;

  
  @Input() data: any;
  
  panelOpenState: boolean = false;
  item_visible:boolean = true;

  customField15:any;
  customField16:any;
  customField17:any;
  customField18:any;

  public serverPath = environment.commonImageApi + '/commonSlider/';
  public serverPath2 = environment.commonImageApi + '/home/';

  index: number=0;
  
  constructor(
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
  ) {
   
  }

  ngOnInit() {
    this.index=0;       
  }

  openedPanelTitle: string;

  expandedIndex:any;
  onPanelOpen(title: string, index: number) {
    if (this.expandedIndex !== -1 && this.expandedIndex !== index) {
      this.expandedIndex = -1;
      this.cdr.detectChanges(); 
    }
    this.expandedIndex = index;
    this.openedPanelTitle = title;
    this.panelOpenState = true;
    this.cdr.detectChanges(); 
  }
  
  onPanelClose(index: number) {
    if (this.expandedIndex === index) {
      this.expandedIndex = -1;
      this.openedPanelTitle = null;
      this.panelOpenState = false;
      this.cdr.detectChanges(); 
    }
  }
  
toggleSection(){
  this.item_visible = !this.item_visible;
}

}
