<div *ngIf="align>0 ; else noAlign" >
    <div [fxFlex]="align">
    
    
    </div>
    <div [innerHTML]="trustedHtmlContent"></div> 
    
    <div [fxFlex]="align">
    
    
    </div>
    </div>

    <ng-template #noAlign>

        
    <div [innerHTML]="trustedHtmlContent"></div> 

   </ng-template>

