<div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column">
    <div fxFlex="50" fxFlex.sm="100" *ngFor="let slide of item.images; let i = index">
        <div class="container-image"  [style.margin-right]="(i % 2 !== 0 && i !== slide.length - 1) ? 'item.gapBetweenImage' : '0'" [style.background-color]="slide.customField11"
            [style.backgroundImage]="'url(' + serverPath + slide.img + ')'">
            <div class="background-image" [style.backgroundImage]="'url(' + serverPath1 + slide.customField6 + ')'"
                style="background-repeat: no-repeat; background-position: center; width: 100%; height: 100%; position: absolute; top: 0; left: 0;">
            </div>
            <div fxLayout="column" fxLayoutAlign="start start" style="padding: 30px; height: 300px;">
                <h3>{{slide.customField20}}</h3>
                <h1 style="color: #fd8d4e;">{{slide.customField8}}</h1>
                <p>Foods They Love</p>
                <div>
                    <button (click)="slide.navigation==0?imageNavigation(slide.catId):link(slide.navigatedUrl)" class="a">
                        <span class="button-content" fxLayoutAlign="center center">{{slide.customField9}}</span>
                        <mat-icon class="icon">keyboard_arrow_right</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>