<div class="container">
    <owl-carousel-o [options]="customOptions">
      <ng-container *ngFor="let slide of item.images">
        <ng-template carouselSlide>
          <div class="slide" fxLayout="column" fxLayoutAlign="center center">
            <img style="width:100%" [style.height]="item.height"
            [ngStyle.lt-md]="{'height': item.heightMob}"  [src]="serverPath1 + slide.img" alt="slide.img">
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>