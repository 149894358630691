<div class="shipping-info-container">
    <div class="icon-container">
        <mat-icon class="icon" [style.font-size]="item.height" [ngStyle.lt-md]="{'font-size': item.heightMob}" (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)">{{img.img}}</mat-icon>
    </div>
    <div class="text-container">
      <h3 [style.color]="img.customField10" [style.font-size]="item.customField18" [ngStyle]="{'font-family': img.customField11 || 'cursive'}"
       class="text" [ngStyle.lt-md]="{ 'font-size': item.customField17 }">{{img.customField1}}</h3>
      <p  [style.color]="img.customField15"  [ngStyle]="{'font-family': img.customField16 || 'cursive'}"  class="text">{{img.customField14}}</p>
    </div>
</div>
  