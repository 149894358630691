import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-stepflow-layout',
  templateUrl: './stepflow-layout.component.html',
  styleUrls: ['./stepflow-layout.component.scss']
})
export class StepflowLayoutComponent {

  public serverPath1 = environment.commonImageApi + '/commonSlider/';

  public serverPath = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() img: any;

  @Input() data: any;


  stepType:any=1

  constructor() { }


  ngOnInit() {
  
    
  }

  
}
