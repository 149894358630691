<div class="container ">
    <div class="heading gap">
        <div class="gap" fxLayout="row" fxLayoutAlign="space-between center">
            <span  [style.font-size]="item.customField13"  [ngStyle.lt-md]="{ 'font-size': item.customField15}" style=" font-size: 1.3rem;
            font-weight: 600;" [innerHTML]="item.title"></span>

            <a class="primary-icon" style="cursor: pointer;" (click)="goToCategory()">
                VIEW MORE PRODUCTS </a>
        </div>
    </div>
    <div [fxLayout.gt-md]="item.customField26=='1'?'row-reverse':'row'" fxLayout.xs="column">
        <div fxFlex="40" fxFlex.xs="100">
            <owl-carousel-o [options]="LogoSlider">
                <ng-container *ngFor="let  img of item.images; let l = index">
                    <ng-template carouselSlide>
                        <div class="slide">
                            <!-- <img (click)="goToCategories(img.catId)" width="100%"
                                [ngStyle.lt-md]="{'height': item.heightMob}" [style.height]="item.height"
                                [src]="serverPath + img.img" [alt]="img.img"> -->

                            <calsoft-ng-optimized-image [height]="item.height" [heightMob]="item.heightMob"
                                [navigation]="0" [altImage]="img.img" [catId]="img.catId" [image]="serverPath + img.img"
                                [i]="'0'">
                            </calsoft-ng-optimized-image>

                            <div style="text-align: center;" class="content-right">
                                <div class="banner-content text-center">
                                    <h4 [style.color]="item.customField22" class="banner-subtitle12">
                                        {{img.customField5}}</h4>
                                    <h4 [style.color]="item.customField22" class="banner-price12">{{img.customField8}}
                                    </h4>
                                    <button mat-raised-button (click)="goToCategories(img.catId)" class="btn1">
                                        <span style="align-items: center;">{{img.customField10}}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>

        <div [fxFlex]="item.gapBetweenImage">

        </div>

        <div fxFlex="60" fxFlex.xs="100">

            <div fxLayoutAlign="center center"  fxFlex="100" fxLayout="row wrap" *ngIf="item.layout=='type-3'">
             
               
                    <div class="br5" fxFlex="100">
                        <div  ngClass.gt-md="carousel-gap" style="border: 2px solid #eee;">
                            <calsoft-product-carousel  [slidesPerView]="3" [data]="data" [item]="item" [heightMobile]="productImageHeightMob"
                            [widthMobile]="productImageWidthMob" [height]="productImageHeight" [width]="productImageWidth"
                            [imageType]="productImageType" [showConfigurableDropdown]="showConfigurableDropdown"
                            [showCartIcon]="showCartIcon" [showCartIncremented]="showCartIncremented"
                            [other]="item.type=='carouselwithProduct'?'productScope':'other'" [id]="item.customField1"
                            [sortOrder]="item.sortOrder" [header]="item.title" [item]="item"
                            [img]="item.images"></calsoft-product-carousel>

                        </div>

                    </div>
                
            </div>

            <div fxLayout="row wrap" *ngIf="item.layout=='type-2'">

            

                <ng-container *ngFor="let product of categoryProducts; let i=index">
                    <div class="br5" fxFlex.xs="50" fxFlex.lt-md="33.33" fxLayoutGap="10px" fxFlex="33.33">
                        <div ngClass.gt-md="carousel-gap" style="border: 2px solid #eee;">
                            <calsoft-common-product-details-carousel [data]="data" [width]="width"
                                [imageType]="imageType" [height]="height" [heightMobile]="heightMobile"
                                [widthMobile]="widthMobile" [showCartIcon]="showCartIcon"
                                [showCartIncremented]="showCartIncremented"
                                [product]="product"></calsoft-common-product-details-carousel>

                        </div>

                    </div>
                </ng-container>


            </div>
        </div>
    </div>
</div>