<div *ngIf="type==1">

    <div fxLayout="column" fxLayout.gt-sm="row" style="text-align: center;" fxLayoutGap="15px"
        *ngFor="let data of pricedetailnew">
        <div contentAnim [aniName]="item.customField36" fxFlex="100" fxFlex.gt-sm="33" style="border: 1px solid gray;border-radius: 20px">
            <h3><b>{{data.title1}}</b></h3>
            <!-- <p> For growing businesses with a comprehensive set of features</p> -->
            <h5><b>{{data.s_subtitle}}</b></h5>

            <div *ngFor="let subdata of data.list_detail" style="margin-bottom: 10px;">
                <div class="pricelist" *ngIf="subdata.starters">
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div>
                            <mat-icon class="maticon3">check_circle</mat-icon>
                        </div>
                        <div>
                            <h6 style="margin-bottom: 0px;">&#160;{{subdata.starters}}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <h4><b>₹ {{data.s_price_annualy}}</b></h4>
            <h6>{{data.s_charge_year}}</h6>


        </div>

        <div contentAnim [aniName]="item.customField37" fxFlex="100" fxFlex.gt-sm="33" [style.background-color]="data.a_button"
            style="border: 1px solid gray;border-radius: 20px;" class="txtcolor">
            <h3><b>{{data.title2}}</b></h3>
            <h5 class="txtcolor"><b>{{data.p_subtitle}}</b></h5>

            <div *ngFor="let subdatas of data.list_detail" style="margin-bottom: 10px;">
                <div class="pricelist" *ngIf="subdatas.professions">

                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div>
                            <div class="circleiconz">
                                <mat-icon class="maticon_3">check_circle</mat-icon>
                            </div>
                        </div>
                        <div>
                            <h6 style="margin-bottom: 0px;padding-left: 10px;" class="txtcolor">
                                &#160;{{subdatas.professions}}</h6>
                        </div>
                    </div>
                </div>
            </div>

            <h4><b>₹ {{data.p_price_annualy}}</b></h4>
            <h6 class="txtcolor">{{data.p_charge_year}}</h6>

        </div>

        <div contentAnim [aniName]="item.customField38" fxFlex="100" fxFlex.gt-sm="33" style="border: 1px solid gray;border-radius: 20px">
            <h3><b>{{data.title3}}</b></h3>
            <h5><b>{{data.a_subtitle}}</b></h5>

            <div *ngFor="let subdataz of data.list_detail" style="margin-bottom: 10px;">
                <div class="pricelist" *ngIf="subdataz.advanceds">
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div>
                            <mat-icon class="maticon3">check_circle</mat-icon>
                        </div>
                        <div>
                            <h6 style="margin-bottom: 0px;">&#160;{{subdataz.advanceds}}</h6>
                        </div>

                    </div>

                </div>
            </div>

            <h4><b>₹ {{data.a_price_annualy}} </b></h4>
            <h6>{{data.a_charge_year}}</h6>

        </div>
    </div>

</div>

<div *ngIf="type==2">


    <div fxLayout="column" fxLayout.gt-sm="row" style="text-align: center;" fxLayoutGap="15px"
        *ngFor="let data of pricedetailnew">
        <div contentAnim [aniName]="item.customField36" fxFlex="100" fxFlex.gt-sm="33" style="border: 1px solid gray;border-radius: 20px"
            [style.background-color]="selectedPrice === data.s_price_annualy ? data.a_button : 'transparent'"
            (click)="selectSubscription(data.s_price_annualy , data.title1)"
            [ngClass]="{'white-font': selectedPrice === data.s_price_annualy}">
            <h3><b>{{data.title1}}</b></h3>
            <h5><b>{{data.s_subtitle}}</b></h5>

            <div *ngFor="let subdata of data.list_detail" style="margin-bottom: 10px;">
                <div class="pricelist1" *ngIf="subdata.starters">
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div>
                            <div class="circleiconz">
                                <mat-icon class="maticon_3">check_circle</mat-icon>
                            </div>
                        </div>

                        <div>
                            <h6 [ngClass]="{'white-font': selectedPrice === data.s_price_annualy}"
                                style="margin-bottom: 0px;padding-left: 10px;">
                                &#160;{{subdata.starters}}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <h4><b>₹ {{data.s_price_annualy}}</b></h4>
            <h6 [ngClass]="{'white-font': selectedPrice === data.s_price_annualy}">{{data.s_charge_year}}</h6>


        </div>

        <div contentAnim [aniName]="item.customField37" fxFlex="100" fxFlex.gt-sm="33"
            [style.background-color]="selectedPrice === data.p_price_annualy ? data.a_button : 'transparent'"
            style="border: 1px solid gray;border-radius: 20px;"
            (click)="selectSubscription(data.p_price_annualy, data.title2)"
            [ngClass]="{'white-font': selectedPrice === data.p_price_annualy}">
            <h3><b>{{data.title2}}</b></h3>
            <h5><b>{{data.p_subtitle}}</b></h5>

            <div *ngFor="let subdatas of data.list_detail" style="margin-bottom: 10px;">
                <div class="pricelist1" *ngIf="subdatas.professions">

                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div>
                            <div class="circleiconz">
                                <mat-icon class="maticon_3">check_circle</mat-icon>
                            </div>
                        </div>
                        <div>
                            <h6 [ngClass]="{'white-font': selectedPrice === data.p_price_annualy}"
                                style="margin-bottom: 0px;padding-left: 10px;">
                                &#160;{{subdatas.professions}}</h6>
                        </div>
                    </div>
                </div>
            </div>

            <h4><b>₹ {{data.p_price_annualy}}</b></h4>
            <h6 [ngClass]="{'white-font': selectedPrice === data.p_price_annualy}">{{data.p_charge_year}}</h6>

        </div>

        <div contentAnim [aniName]="item.customField38" fxFlex="100" fxFlex.gt-sm="33" style="border: 1px solid gray;border-radius: 20px"
            (click)="selectSubscription(data.a_price_annualy , data.title3)"
            [ngClass]="{'white-font': selectedPrice === data.a_price_annualy}"
            [style.background-color]="selectedPrice === data.a_price_annualy ? data.a_button : 'transparent'">
            <h3><b>{{data.title3}}</b></h3>
            <h5><b>{{data.a_subtitle}}</b></h5>

            <div *ngFor="let subdataz of data.list_detail" style="margin-bottom: 10px;">
                <div class="pricelist1" *ngIf="subdataz.advanceds">
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div class="circleiconz">
                            <mat-icon class="maticon_3">check_circle</mat-icon>
                        </div>
                        <div>
                            <h6 [ngClass]="{'white-font': selectedPrice === data.a_price_annualy}"
                                style="margin-bottom: 0px;padding-left: 10px;">
                                &#160;{{subdataz.advanceds}}</h6>
                        </div>

                    </div>

                </div>
            </div>

            <h4><b>₹ {{data.a_price_annualy}} </b></h4>
            <h6 [ngClass]="{'white-font': selectedPrice === data.a_price_annualy}">{{data.a_charge_year}}</h6>

        </div>
    </div>

</div>

<div *ngIf="type==3">


    <div fxLayout="column" fxLayout.gt-sm="row" style="text-align: center;margin-left: 10px;" fxLayoutGap="15px"
        *ngFor="let data of pricedetailnew">
        <div contentAnim [aniName]="item.customField36" class="card-container" fxFlex="100" fxFlex.gt-sm="33" >
            <div class="subscription-card">
                <!-- Card Header -->
                <div class="card-header" fxLayout="column" fxLayoutAlign="start start">
                    <h4>{{data.title1}}</h4>
                    <p>{{data.s_subtitle}}</p>
                </div>

                <!-- Card Price -->
                <div class="card-price" fxLayout="column" fxLayoutAlign="start start">
                    <!-- Left-aligned Section -->
                    <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center">
                        <span class="discounted-price">{{ data.s_price_annualy | currency }}</span><span
                            class="discount">*</span>
                    </div>

                    <!-- Right-aligned Section -->
                    <div fxFlex="auto" fxLayoutAlign="end center">
                        <h3><span class="original-price-tag">{{ data.s_charge_year }}</span></h3>
                    </div>
                </div>

                <!-- Card Features -->
                <div class="card-features">
                    <ng-container *ngFor="let subdatas of data.list_detail">

                        <div fxLayout="row" fxLayoutAlign="start center" style="margin:10px 0px;">
                            <mat-icon class="maticon_3">check_circle</mat-icon>
                            <span>{{subdatas.professions}}</span>
                        </div>

                    </ng-container>
                </div>

                <!-- Card Actions -->
                <div class="card-actions" fxLayout="row" fxLayoutAlign="center center">
                    <div fxFlex="auto">
                        <a href="#" class="btn-order">Order Now</a>
                    </div>
                    <div fxFlex="auto" style="margin-left: 10px;">
                        <a href="#" class="btn-chat-gradient">
                            <i class="fa-regular fa-comment"></i>
                            <div><span>Click here to</span> Live Chat</div>
                        </a>
                    </div>
                </div>

            </div>
        </div>

    

   
        <div contentAnim [aniName]="item.customField37" class="card-container" fxFlex="100" fxFlex.gt-sm="33" >
            <div class="subscription-card">
                <!-- Card Header -->
                <div class="card-header" fxLayout="column" fxLayoutAlign="start start">
                    <h4>{{data.title2}}</h4>
                    <p>{{data.p_subtitle}}</p>
                </div>

                <!-- Card Price -->
                <div class="card-price" fxLayout="column" fxLayoutAlign="start start">
                    <!-- Left-aligned Section -->
                    <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center">
                        <span class="discounted-price">{{ data.p_price_annualy | currency }}</span><span
                            class="discount">*</span>
                    </div>

                    <!-- Right-aligned Section -->
                    <div fxFlex="auto" fxLayoutAlign="end center">
                        <h3><span class="original-price-tag">{{ data.p_charge_year }}</span></h3>
                    </div>
                </div>

                <!-- Card Features -->
                <div class="card-features">
                    <ng-container *ngFor="let subdatas of data.list_detail">

                        <div fxLayout="row" fxLayoutAlign="start center" style="margin:10px 0px;">
                            <mat-icon class="maticon_3">check_circle</mat-icon>
                            <span>{{subdatas.professions}}</span>
                        </div>

                    </ng-container>
                </div>

                <!-- Card Actions -->
                <div class="card-actions" fxLayout="row" fxLayoutAlign="center center">
                    <div fxFlex="auto">
                        <a href="#" class="btn-order">Order Now</a>
                    </div>
                    <div fxFlex="auto" style="margin-left: 10px;">
                        <a href="#" class="btn-chat-gradient">
                            <i class="fa-regular fa-comment"></i>
                            <div><span>Click here to</span> Live Chat</div>
                        </a>
                    </div>
                </div>

            </div>
        </div>

    

    
        <div contentAnim [aniName]="item.customField38" class="card-container" fxFlex="100" fxFlex.gt-sm="33" >
            <div class="subscription-card">
                <!-- Card Header -->
                <div class="card-header" fxLayout="column" fxLayoutAlign="start start">
                    <h4>{{data.title3}}</h4>
                    <p>{{data.a_subtitle}}</p>
                </div>

                <!-- Card Price -->
                <div class="card-price" fxLayout="column" fxLayoutAlign="start start">
                    <!-- Left-aligned Section -->
                    <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center">
                        <span class="discounted-price">{{ data.a_price_annualy | currency }}</span><span
                            class="discount">*</span>
                    </div>

                    <!-- Right-aligned Section -->
                    <div fxFlex="auto" fxLayoutAlign="end center">
                        <h3><span class="original-price-tag">{{ data.a_charge_year }}</span></h3>
                    </div>
                </div>

                <!-- Card Features -->
                <div class="card-features">
                    <ng-container *ngFor="let subdatas of data.list_detail">

                        <div fxLayout="row" fxLayoutAlign="start center" style="margin:10px 0px;">
                            <mat-icon class="maticon_3">check_circle</mat-icon>
                            <span>{{subdatas.professions}}</span>
                        </div>

                    </ng-container>
                </div>

                <!-- Card Actions -->
                <div class="card-actions" fxLayout="row" fxLayoutAlign="center center">
                    <div fxFlex="auto">
                        <a href="#" class="btn-order">Order Now</a>
                    </div>
                    <div fxFlex="auto" style="margin-left: 10px;">
                        <a href="#" class="btn-chat-gradient">
                            <i class="fa-regular fa-comment"></i>
                            <div><span>Click here to</span> Live Chat</div>
                        </a>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <!-- 
        <div fxFlex="100" fxFlex.gt-sm="33" class="subscription-card"
            [style.background-color]="selectedPrice === data.p_price_annualy ? data.a_button : 'transparent'"
            (click)="selectSubscription(data.p_price_annualy, data.title2)"
            [ngClass]="{'white-font': selectedPrice === data.p_price_annualy}">

   
            <div class="card-header">
                <h4><b>{{data.title2}}</b></h4>
                <p>{{data.p_subtitle}}</p>
            </div>

            <div class="card-price" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center">
                    <span class="discounted-price">₹ {{data.p_price_annualy}}</span>
                    <span class="discount">*</span>
                </div>
                <div fxFlex="auto" fxLayoutAlign="end center">
                    <h3><span class="original-price-tag">{{data.p_charge_year}}</span></h3>
                </div>
            </div>

            <div class="card-features">
                <ul>
                    <ng-container *ngFor="let subdatas of data.list_detail">
                        <li *ngIf="subdatas.professions">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon class="maticon_3">check_circle</mat-icon>
                                <span>{{subdatas.professions}}</span>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>

            <div class="card-actions" fxLayout="row" fxLayoutAlign="center center">
                <div fxFlex="auto">
                    <a href="#" class="btn-order">Order Now</a>
                </div>
                <div fxFlex="auto" style="margin-left: 10px;">
                    <a href="#" class="btn-chat">
                        <i class="fa-regular fa-comment"></i>
                        <div><span>Click here to</span> Live Chat</div>
                    </a>
                </div>
            </div>

        </div>


        <div fxFlex="100" fxFlex.gt-sm="33" class="subscription-card"
            [style.background-color]="selectedPrice === data.a_price_annualy ? data.a_button : 'transparent'"
            (click)="selectSubscription(data.a_price_annualy , data.title3)"
            [ngClass]="{'white-font': selectedPrice === data.a_price_annualy}">

            <div class="card-header">
                <h4><b>{{data.title3}}</b></h4>
                <p>{{data.a_subtitle}}</p>
            </div>

            <div class="card-price" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center">
                    <span class="discounted-price">₹ {{data.a_price_annualy}}</span>
                    <span class="discount">*</span>
                </div>
                <div fxFlex="auto" fxLayoutAlign="end center">
                    <h3><span class="original-price-tag">{{data.a_charge_year}}</span></h3>
                </div>
            </div>

            <div class="card-features">
                <ul>
                    <ng-container *ngFor="let subdataz of data.list_detail">
                        <li *ngIf="subdataz.advanceds">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon class="maticon_3">check_circle</mat-icon>
                                <span>{{subdataz.advanceds}}</span>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>

            <div class="card-actions" fxLayout="row" fxLayoutAlign="center center">
                <div fxFlex="auto">
                    <a href="#" class="btn-order">Order Now</a>
                </div>
                <div fxFlex="auto" style="margin-left: 10px;">
                    <a href="#" class="btn-chat">
                        <i class="fa-regular fa-comment"></i>
                        <div><span>Click here to</span> Live Chat</div>
                    </a>
                </div>
            </div>

        </div> -->



</div>