<div class="product-container product-elevat">
    <img [src]="image" (click)="productShow(product)" onerror="this.src='';" [style.height]="item.customField25" 
    [ngStyle.lt-md]="{'height': item.customField26}"
     [style.width]="item.customField27" 
    [ngStyle.lt-md]="{'width': item.customField28}">
    <div class="product-details">
        <span class="product-name modernWay">{{ productData?.name   }}</span>
        <h5 class="product-price" >{{ productData?.values.Price | currencyConvert }}</h5>
        <!-- <p class="product-description modernWay" [innerHTML]="productData?.description"></p> -->
    </div>
</div>
