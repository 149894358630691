import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogSectionService } from 'src/app/services/blog-section.service';

@Component({
  selector: 'calsoft-blog-section',
  templateUrl: './blog-section.component.html',
  styleUrls: ['./blog-section.component.scss']
})
export class BlogSectionComponent {
  isEmpty:any =false;
  public categoryProducts: any=[];
  public count: any;
  spinnerMain:boolean=false;
  otherProductType: boolean=false;
  @Input() data: any;
  list: any=[];
  public viewCol: number = 25;
  counts: any = [12];
  public page: number = 0;
  mobileSize: number = 4;
  size: any = this.counts[0];

  spinner: boolean;
  search: any = "";

  public sortings = [

    { value: "pageId,DESC", viewValue: "Newest First" },
  ];
    filterMap = new Map<any, any>();
  paths: any = [];
  sortParam: any=this.sortings[0].value;
  mobileScreen: boolean=false;

  public sort: string = this.sortings[0].viewValue;
  blogSize: any;
  previousPage: any=0;
  noProductValue: boolean;


  constructor( private blogSectionService: BlogSectionService, 
    private activatedRoute: ActivatedRoute,  
    @Inject(PLATFORM_ID) private platformId: Object
    ) { }
    
    ngOnInit() {
      this.blogSize=this.data['blogSize']
      
      this.viewCol=100/this.blogSize;

      this.getBlogPage();
      if (window.innerWidth < 1000) {
      
        this.mobileScreen=true;
  
      }else{
        this.mobileScreen=false;
      }

      
      
        
    }


    pageSize: any;
    config: any={};
    getBlogPage(){
      this.blogSectionService.getBlogPage(this.page, this.size, this.sortParam).subscribe(
        res => {
          this.list = res['content'];
          this.count = res["totalElements"];
          this.isEmpty = res["empty"];
          this.pageSize = res["totalPages"];

     

             (this.config.currentPage = this.page),
            (this.config.totalItems = this.count),
            (this.config.itemsPerPage = this.size);
            this.getcount();
        },
        error => {
        }
        )
    }

    
  getcount() {

    let value1=  localStorage.getItem('pgs');
      
    }

 

public onPageChanged(event) {

  this.page = event-1;
  this.getBlogPage();
  

  this.page=event
  this.config = {
    itemsPerPage: this.size,
    currentPage: this.page,
    totalItems: this.count,
  };

    this.previousPage=event;
   
  

  }

  public onScroll() {
    if (window.innerWidth<850 && this.page<this.pageSize) {
      this.page++;

      this.spinner = true;
      if (this.list.length <this.count ) {

          this.blogSectionService.getBlogPage(
              this.page,
              this.mobileSize,
              this.sortParam
            )
            .subscribe((res) => {
              this.spinner = false;
              this.list = [ ...this.list, ...res['content']];

            });
        
      } else {
        this.spinner = false;
        //this.noProduct();
        this.noProductValue=true;
      }
    }
  }




}
