import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { FormPopupComponent } from '../form-popup/form-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'calsoft-button-type6',
  templateUrl: './button-type6.component.html',
  styleUrls: ['./button-type6.component.scss']
})
export class ButtonType6Component {

  constructor(

    private commonService: CommonService,
    public dialog: MatDialog,
  ) {
  }

  @Input() item: any;

  active: string;
 
   scrollDown(value){
     this.active =value;
     this.ScrollIntoView(this.active);
   }
  
   ScrollIntoView(elem: string) {
     this.active = "#" + elem;
     const ele = document.querySelector(this.active) as HTMLElement;
     
     if (ele) {
       const offsetTop = ele.getBoundingClientRect().top + window.pageYOffset;
       const paddingTop = 96;
       window.scrollTo({
       top: offsetTop - paddingTop,
       behavior: 'smooth'
       });
     } else {
       console.error('Element not found:', this.active);
     }
     }


     link(url) {

      this.commonService.goToLink(url)
     
    }

    dynamicFormPopup(formGroupId:any) {
      if (window.innerWidth > 768) {
  
         let dialogRef = this.dialog.open(FormPopupComponent, {
            width: '790px',
            data: { formId: formGroupId}
         });
  
         dialogRef.disableClose = true;
         dialogRef.afterClosed().subscribe(result => {
  
         });
  
      }
      else {
         let dialogRef = this.dialog.open(FormPopupComponent, {
            minWidth: '100vw', height: '100vh',
            data: { formId: formGroupId}
         });
  
         dialogRef.disableClose = true;
         dialogRef.afterClosed().subscribe(result => {
  
         });
      }
  
   }

}
