<table>
    <tr>
        <ng-container *ngFor="let header of tableHeaders">
        <th *ngIf="header !== 'editing'">{{ header }}</th>
        </ng-container>
      </tr>
      <tr *ngFor="let row of tabledata; let rowIndex = index">
        <ng-container *ngFor="let header of tableHeaders">
          <td  *ngIf="header !== 'editing'" >{{ row[header] }}</td>
         
        </ng-container>
      </tr>
   </table>
