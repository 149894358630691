import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-image-with-text-type',
  templateUrl: './image-with-text-type.component.html',
  styleUrls: ['./image-with-text-type.component.scss']
})
export class ImageWithTextTypeComponent {

  public serverPath = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() data: any;

  @Input() img: any;
  
  @Input() index: any;

}
