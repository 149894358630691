import { Component, Input, OnInit } from '@angular/core';
import { Constant } from 'src/app/constants/constant';
import { CommonService } from 'src/app/services/common.service';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-service-plan',
  templateUrl: './service-plan.component.html',
  styleUrls: ['./service-plan.component.scss']
})
export class ServicePlanComponent implements OnInit{

  @Input() item: any;

  @Input() img: any;

  @Input() data: any;

  @Input() layoutSub: any;

  @Input() categoryId: any;
  @Input() header: any;
  @Input() id: any;
  @Input() other: any;
  @Input() sortOrder: 'price,DESC';
  @Input() showCartIcon: any;
  @Input() showConfigurableDropdown: any;
  @Input() productImageHeight: any;
  @Input() height: any;
  @Input() width: any;
  @Input() imageType: any;
  @Input() showCartIncremented: any;
  @Input() heightMobile: any;
  @Input() index =0;
  @Input() widthMobile: any;
  categoryProducts: any;
  count: any;
  isEmpty: any;
  pageSize: any;
  public page: any = 0;
  size: number = 5;
  mobileSize: number = 4;
  sortParam: any = 'entityId' + "," + Constant.Asc;

  private serverPath = environment.ImageAPI1;

  public serverPath1 = environment.commonImageApi + '/home/';

  countryCode: string;
  categoryId5: any = 0;
  categoryId4: any = 0;

  customField25: any = 'white';
  customField26: any = 'View All';
  customField27: any;
  customField28: any;



  accordionOpenIndex: number | null = null;
  init: boolean=false;

  toggleAccordion(index: number) {
    if (this.accordionOpenIndex === index) {
        this.accordionOpenIndex = null;
    } else {
        this.accordionOpenIndex = index;
    }
}
  

  constructor(
    private commonService: CommonService,
    private productService: ProductService,
  ) { }

  ngOnInit() {

    this.productsByCategory();    
    
  }

  indexMain:any=0;
  public productsByCategory() {

   this.init=false;
    let methodName = "getProductBySearch";
    let search = ''
    if (this.other == 'productScope') {
      methodName = "getProductByCustomProductId";
      search = this.layoutSub[this.indexMain].customField20
    } else {
      let value = 1;
      search = "isInStock:" + '*' + value + '*'
        + "' AND featured:" + '*' + value + '*' + "' "
    }


    this.productService.getProductByCustomProductId
    (search, this.page, this.size, this.sortOrder).subscribe(
      res => {
        if (res) {
          this.init=true;
          this.categoryProducts = res['content'];
          this.count = res['totalElements'];
          this.isEmpty = res['empty'];
          this.pageSize = res['totalPages'];
          this.init = true;
        } else {
          console.error('Response is undefined or null');
          // Handle the error appropriately, such as showing an error message to the user or logging it.
        }
      },
      error => {
        console.error('Error occurred:', error);
        // Handle the error appropriately, such as showing an error message to the user or logging it.
      }
    );
  }

  toggleButton(button,index) {
    this.init=false;
    this.indexMain=index;
    this.productsByCategory();
  }

  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      this.commonService.goToLink(url)
    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }

}

