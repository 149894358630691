<div fxLayout="column" fxFlex="100" class="flip-container" (mouseenter)="flipCard(true)" (mouseleave)="flipCard(false)">
  <div fxLayout="column" class="flipper" [class.flipped]="isFlipped" fxFlex>
    <div fxLayout="column">
      <img style="width: 100%;" [style.height]="item.height" [ngStyle.lt-md]="{
        'padding-top': i > 0 ? item.categoryId6 == 1 ? '10px' : i == 0 ? '10px' : '0px' : '0px',
        'height': item.heightMob
      }" [ngClass]="item.customField9 == 2 ? '' : 'collection-banner '" fxLayoutAlign="space-evenly stretch"
        (click)="img.navigation == 0 ? imageNavigation(img.catId) : link(img.navigatedUrl)" [src]="serverPath+img.img"
        [alt]="img.altImage || 'images'" loading="lazy">

      <!-- <calsoft-ng-optimized-image [height]="item.height" [heightMob]="item.heightMob" [navigation]="0"
        [altImage]="img.altImage || 'images'" loading="lazy" [catId]="img.catId" [navigatedUrl]="img.navigation == 0 ? imageNavigation(img.catId) : link(img.navigatedUrl)" [customField9]="''"
        [image]="serverPath+img.img" [i]="'0'">

      </calsoft-ng-optimized-image> -->
    </div>

    <div fxLayout="column">
      <span *ngIf="img.customField1 !== null">
        <span *ngIf="img.customField1.length>0" fxLayoutAlign="space-evenly center" fxLayoutGap="5px">
          <h2  (click)="img.navigation == 0 ? imageNavigation(img.catId) : link(img.navigatedUrl)"  [style.font-size]="item.customField18" [ngStyle.lt-md]="{ 'font-size': item.customField17 }">{{
            img.customField1 }}
          </h2>
        </span>
      </span>
    </div>
    <!-- Content for the back of the card -->
    <div (click)="img.navigation == 0 ? imageNavigation(img.catId) : link(img.navigatedUrl)"   class="back" fxFlex="100" fxLayoutAlign="start start"  fxLayout="column" [innerHTML]="img.customField21">
    </div>
  </div>
</div>