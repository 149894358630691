<div *ngFor="let datas of datas; ">
    <div [ngClass]="{
        'top-left': datas.customField29 === '1',
        'top-right': datas.customField29 === '2',
        'centered': datas.customField29 === '3',
        'bottom-left': datas.customField29 === '4',
        'bottom-right': datas.customField29 === '5'
    }">
        <div fxHide fxShow.gt-sm>
            <div style="font-size: 55px; text-transform: uppercase;" *ngIf="datas.customField30">{{datas.customField30}}</div>
            <div style="font-size: 40px;" *ngIf="datas.customField31"><i>{{datas.customField31}}</i></div>
            <button mat-raised-button *ngIf="datas.customField32" [style.border-color]="item.customField33"
             [style.color]="datas.customField33"  [style.background-color]="datas.customField34"
             (mouseover)="handleMouseOver($event.target, item)"
             (mouseout)="handleMouseOut($event.target, item)"
             >{{datas.customField32}}</button>
        </div>
        <div fxShow fxHide.gt-sm >
            <div style="font-size: 18px; text-transform: uppercase;" *ngIf="datas.customField30">{{datas.customField30}}</div>
            <div style="font-size: 14px;" *ngIf="datas.customField31"><i>{{datas.customField31}}</i></div>
            <button mat-raised-button *ngIf="datas.customField32" [style.border-color]="item.customField33" [style.color]="datas.customField33"  [style.background-color]="datas.customField34" class="btnview">{{datas.customField32}}</button>
        </div>
    </div>    
</div>

