import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-bannertext',
  templateUrl: './bannertext.component.html',
  styleUrls: ['./bannertext.component.scss']
})
export class BannertextComponent {
  @Input() item: any;
  customField1:any;
  customField11:any;
  customField12:any;
  customField14:any;
  customField15:any;
  customField16:any;
  customField17:any;
  customField4:any;
  customField13: any;
  customField18: any;
  customField20:any;
  customField21:any;
  customField8:any;
  fontFamily: string ; 

  public serverPath = environment.commonImageApi + '/home/';
  
  ngOnInit() {
    
  }

  constructor(
    private commonService: CommonService,
  ) {

  }
  

}
