<div class="product product-11 text-center">

        <calsoft-image-lazy-mouse-over [name]="name" [width]="width" [imageType]="imageType"
                [heightMobile]="heightMobile" [widthMobile]="widthMobile" [type]="type" [height]="height"
                [src]="list[0]?.url" [alt]="name" class="product-image">
        </calsoft-image-lazy-mouse-over>
        <calsoft-image-lazy-mouse-over [name]="name" [width]="width" [imageType]="imageType"
                [heightMobile]="heightMobile" [widthMobile]="widthMobile" [type]="type" [height]="height"
                [src]="list[1]?.url" [alt]="name" class="product-image-hover" *ngIf="list[1]">
        </calsoft-image-lazy-mouse-over>

</div>