import { Component, Input, OnChanges } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'calsoft-image-zigzag-text',
  templateUrl: './image-zigzag-text.component.html',
  styleUrls: ['./image-zigzag-text.component.scss']
})
export class ImageZigzagTextComponent implements OnChanges {

  public serverPath = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() data: any;

  @Input() img: any;
  
  @Input() index: any;
  customField7: any;
  height: any;
  datas:any;

  constructor(
    private commonService: CommonService ) {}


    ngOnInit() {
    

    }

    ngOnChanges(){

      this.datas = this.item.images;
      console.log(this.datas)
    }
  
  imageNavigation(id){


    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  link(url) {

    window.open(url, '_blank')
  }


  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      window.open(fullURL, '_blank');
    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }


}
