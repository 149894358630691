import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-translator',
  templateUrl: './translator.component.html',
  styleUrls: ['./translator.component.scss']
})
export class TranslatorComponent implements OnInit, OnDestroy {
  @Input() translateValue: any;
  @Input() type: any;
  @Input() data:any;
  result: any = [];
  finalValue: any;
  resultStatus: boolean;
  productNameCenter: any=0;

  private _unsubscribeAll: Subject<any>;
  categoryPageProductNameDisplayLineMobHeight =2;
  categoryPageProductNameDisplayLine = 2;

  constructor(private commonService: CommonService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    
    let country = this.commonService.getTranslateCountry();
    if (country == 'en') {
      this.translateValue = this.translateValue
    } else {
      this.getTranslatedValue(this.translateValue);
    }

    if(this.data!=null){

      this.productNameCenter=this.data['productNameCenter']
      
    }

    if(this.data!=null){

      this.categoryPageProductNameDisplayLine=this.data['categoryPageProductNameDisplayLine']
      
    }

    if(this.data!=null){

      this.categoryPageProductNameDisplayLineMobHeight=this.data['categoryPageProductNameDisplayLineMobHeight']
      
    }
  }


  getTranslatedValue(i) {

    let page = 0;
    let size = 1;
    let sortParam = "keyId,DESC";
    this.resultStatus = false;
    this.resultStatus = false;
    let locale = this.commonService.getTranslateCountry();

    if(locale!="en"&&locale!=null){


    this.commonService
      .getTransaltedValue(this.translateValue, locale, page, size, sortParam)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.result = res["content"];
        if (this.result.length > 0) {
          this.translateValue = res["content"][0]["translate"];


        } else {
          {
            this.translateValue = this.translateValue;
          }
        }
      });
    }
  }

  /**
 * On destroy
 */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }



}
