import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-text-with-text',
  templateUrl: './text-with-text.component.html',
  styleUrls: ['./text-with-text.component.scss']
})
export class TextWithTextComponent {

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() img: any;

  @Input() data: any;

}
