import { Component } from '@angular/core';
import {  Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-common-images',
  templateUrl: './common-images.component.html',
  styleUrls: ['./common-images.component.scss']
})
export class CommonImagesComponent {
  @Input() height: any;

  @Input() i: any;

  @Input() heightMob: any;

  @Input() categoryId6: any;

  @Input() navigation: any;

  @Input() altImage: any;

  @Input() catId: any;

  @Input() navigatedUrl: any;

  @Input() customField9: any;

  @Input() image: any;

  constructor( private commonService: CommonService,) { }




  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }
  

  link(url) {

    this.commonService.goToLink(url)
  }
}
