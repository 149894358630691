import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-layout-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  public serverPath = environment.commonImageApi + '/home/';
  @Input() image: any;
  @Input() id: any;
  @Input() layout: any;
  @Input() imageHeight: any;
  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit(): void {


  }


  goToCate() {

    if (this.id > 0) {
      this.commonService.goToCategory(1, parseInt(this.id))
    }

  }


}
