import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-youtube-video',
  templateUrl: './youtube-video.component.html',
  styleUrls: ['./youtube-video.component.scss']
})
export class YoutubeVideoComponent {
  data: any;
  VideoUrl: any;

  public youtubeUrl = environment.youtubeUrl;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data,

  ) {

    this.data = data
  }

  
  ngOnInit(): void {


    console.log(this.data);
    
    this.VideoUrl = this.data.data;

  }

  closeModal(){
    this.dialog.closeAll();
  }

}
