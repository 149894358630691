import { Component, Input } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-categorywithimage2',
  templateUrl: './categorywithimage2.component.html',
  styleUrls: ['./categorywithimage2.component.scss']
})
export class Categorywithimage2Component {

  constructor(  private commonService: CommonService,
    private categoryService: CategoryService) { }

  @Input() img: any;

  @Input() item: any;

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  link(url) {

    window.open(url, '_blank')
  }
  

  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

}
