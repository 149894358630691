<div fxLayout.gt-md="row" *ngIf="slides[0].type=='type1'">
    <div fxFlex>
        <calsoft-carousel [order]="'first'" [type]="'layouts'" [layout]="slides[0].images"></calsoft-carousel>

    </div>
</div>

<div fxLayout.gt-md="row" *ngIf="slides[0].type=='type2'">
    <div fxFlex>
        <calsoft-carousel [order]="'first'" [type]="'layouts'" [layout]="slides[0].images"></calsoft-carousel>

    </div>
</div>

<div *ngIf="slides[0].type=='type3'" fxLayout="row" fxLayoutGap="5px">
    <div fxLayout="column" fxFlex="65" fxLayoutGap="5px">
        <div fxFlex>
            <calsoft-carousel [order]="'second'" [bannerLayout]="'bannerLayout'" [type]="'layouts'"
                [layout]="slides[0].images">
            </calsoft-carousel>
        </div>
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
            <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                <calsoft-layout-image fxLayout="column"
                 fxLayoutAlign="start stretch"
                    [image]="serverPath+slides[0].image1">
                </calsoft-layout-image>
            </div>
            <div fxFlex class="images" fxLayout="column" fxLayoutAlign="start stretch">

                <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch"
                    [image]="serverPath+slides[0].image2">
                </calsoft-layout-image>
            </div>
        </div>
    </div>
    <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
        <calsoft-layout-image fxLayout="row" fxLayoutAlign="start stretch" [layout]="'row'"
            [image]="serverPath+slides[0].image3">
        </calsoft-layout-image>
    </div>
</div>
<div *ngIf="slides[0].type=='type4'" fxLayout="row" fxLayoutGap="5px">

    <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
        <calsoft-layout-image fxLayout="row" fxLayoutAlign="start stretch" [layout]="'row'"
            [image]="serverPath+slides[0].image3">
        </calsoft-layout-image>
    </div>
    <div fxLayout="column" fxFlex="65" fxLayoutGap="5px">
        <div fxFlex>
            <calsoft-carousel [order]="'second'" [bannerLayout]="'bannerLayout'" [type]="'layouts'"
                [layout]="slides[0].images">
            </calsoft-carousel>
        </div>
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
            <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch"
                    [image]="serverPath+slides[0].image1">
                </calsoft-layout-image>
            </div>
            <div fxFlex class="images" fxLayout="column" fxLayoutAlign="start stretch">

                <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch"
                    [image]="serverPath+slides[0].image2">
                </calsoft-layout-image>
            </div>
        </div>
    </div>
</div>
<div *ngIf="slides[0].type=='type5'" fxLayout="row" fxLayoutGap="5px">

    <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
        <calsoft-layout-image fxLayout="row" fxLayoutAlign="start stretch" [layout]="'row'"
            [image]="serverPath+slides[0].image3">
        </calsoft-layout-image>
    </div>
    <div fxLayout="column" fxFlex="65" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
            <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch"
                    [image]="serverPath+slides[0].image1">
                </calsoft-layout-image>
            </div>
            <div fxFlex class="images" fxLayout="column" fxLayoutAlign="start stretch">

                <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch"
                    [image]="serverPath+slides[0].image2">
                </calsoft-layout-image>
            </div>
        </div>
        <div fxFlex>
            <calsoft-carousel [bannerLayout]="'bannerLayout'" [type]="'layouts'" [layout]="slides[0].images">
            </calsoft-carousel>
        </div>

    </div>
</div>
<div *ngIf="slides[0].type=='type6'" fxLayout="row" fxLayoutGap="5px">
    <div fxLayout="column" fxFlex="65" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
            <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch"
                    [image]="serverPath+slides[0].image1">
                </calsoft-layout-image>
            </div>
            <div fxFlex class="images" fxLayout="column" fxLayoutAlign="start stretch">

                <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch"
                    [image]="serverPath+slides[0].image2">
                </calsoft-layout-image>
            </div>
        </div>
        <div fxFlex>
            <calsoft-carousel [bannerLayout]="'bannerLayout'" [type]="'layouts'" [layout]="slides[0].images">
            </calsoft-carousel>
        </div>

    </div>
    <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
        <calsoft-layout-image fxLayout="row" fxLayoutAlign="start stretch" [layout]="'row'"
            [image]="serverPath+slides[0].image3">
        </calsoft-layout-image>
    </div>
</div>
<!--  -->
<div *ngIf="slides[0].type=='type7'" fxLayout="row" fxLayoutGap="5px">
    <div fxLayout="column" fxFlex="65" fxLayoutGap="5px">
        <div fxFlex>
            <calsoft-carousel [bannerLayout]="'bannerLayout'" [type]="'layouts'" [layout]="slides[0].images">
            </calsoft-carousel>
        </div>
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
            <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch"
                    [image]="serverPath+slides[0].image1">
                </calsoft-layout-image>
            </div>
        </div>
    </div>
    <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
        <calsoft-layout-image fxLayout="row" fxLayoutAlign="start stretch" [layout]="'row'"
            [image]="serverPath+slides[0].image2">
        </calsoft-layout-image>
    </div>
</div>
<div *ngIf="slides[0].type=='ftype8'" fxLayout="row" fxLayoutGap="5px">

    <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
        <calsoft-layout-image fxLayout="row" fxLayoutAlign="start stretch" [layout]="'row'"
            [image]="serverPath+slides[0].image2">
        </calsoft-layout-image>
    </div>
    <div fxLayout="column" fxFlex="65" fxLayoutGap="5px">
        <div fxFlex>
            <calsoft-carousel [bannerLayout]="'bannerLayout'" [type]="'layouts'" [layout]="slides[0].images">
            </calsoft-carousel>
        </div>
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
            <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch"
                    [image]="serverPath+slides[0].image1">
                </calsoft-layout-image>
            </div>
        </div>
    </div>
</div>
<div *ngIf="slides[0].type=='type9'" fxLayout="row" fxLayoutGap="5px">

    <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
        <calsoft-layout-image fxLayout="row" fxLayoutAlign="start stretch" [layout]="'row'"
            [image]="serverPath+slides[0].image2">
        </calsoft-layout-image>
    </div>
    <div fxLayout="column" fxFlex="65" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
            <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch"
                    [image]="serverPath+slides[0].image1">
                </calsoft-layout-image>
            </div>
        </div>
        <div fxFlex>
            <calsoft-carousel [bannerLayout]="'bannerLayout'" [type]="'layouts'" [layout]="slides[0].images">
            </calsoft-carousel>
        </div>

    </div>
</div>
<div *ngIf="slides[0].type=='type10'" fxLayout="row" fxLayoutGap="5px">
    <div fxLayout="column" fxFlex="65" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
            <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch"
                    [image]="serverPath+slides[0].image1">
                </calsoft-layout-image>
            </div>
        </div>
        <div fxFlex>
            <calsoft-carousel [bannerLayout]="'bannerLayout'" [type]="'layouts'" [layout]="slides[0].images">
            </calsoft-carousel>
        </div>

    </div>
    <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
        <calsoft-layout-image fxLayout="row" fxLayoutAlign="start stretch" [layout]="'row'"
            [image]="serverPath+slides[0].image2">
        </calsoft-layout-image>
    </div>
</div>