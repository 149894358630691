import { Component } from '@angular/core';

@Component({
  selector: 'calsoft-leftmenu1',
  templateUrl: './leftmenu1.component.html',
  styleUrls: ['./leftmenu1.component.scss']
})
export class Leftmenu1Component{
  menuItems = [
    {
      title: 'Products',
      submenu: [
        {
          title: 'Product 1',
          submenu: [
            { title: 'Feature 1', description: 'This is the description for Feature 1.' },
            { title: 'Feature 2', description: 'This is the description for Feature 2.' },
            { title: 'Feature 3', description: 'This is the description for Feature 3.' }
          ],
          isExpanded: false
        },
        {
          title: 'Product 2',
          submenu: [ 
          { title: 'Feature 1', description: 'This is the description for Feature 1.' },
          { title: 'Feature 2', description: 'This is the description for Feature 2.' },],
          isExpanded: false
        }
      ],
      isExpanded: false
    },
    {
      title: 'Platform',
      submenu: [
        {
          title: 'Platform 1',
          submenu: [
          { title: 'Feature 1', description: 'This is the description for Feature 1.' },
          { title: 'Feature 2', description: 'This is the description for Feature 2.' },
        ],
          isExpanded: false
        },
        {
          title: 'Platform 2',
          submenu: [
            { title: 'Feature 1', description: 'This is the description for Feature 1.' },
            { title: 'Feature 2', description: 'This is the description for Feature 2.' },
          ],
          isExpanded: false
        }
      ],
      isExpanded: false
    },
    {
      title: 'Resources',
      submenu: [
        {
          title: 'Blog',
          submenu: [
            { title: 'Feature 1', description: 'This is the description shdcshdhchsgdchsdjshdcsvhcfsgdvchsavcsvdchsdfcysvdchgvshcshvdhdv for Feature 1.' },
            { title: 'Feature 2', description: 'This is the description for Feature 2.' },
          ],
          isExpanded: false
        },
        {
          title: 'Documentation',
          submenu: [
            { title: 'Feature 1', description: 'This is the description for Feature 1.' },
            { title: 'Feature 2', description: 'This is the description for Feature 2.' },
          ],
          isExpanded: false
        }
      ],
      isExpanded: false
    }
  ];

  toggleSubmenu(item: any): void {
    item.isExpanded = !item.isExpanded;
  }

  toggleSubSubmenu(sub: any): void {
    sub.isExpanded = !sub.isExpanded;
  }
}