<!-- <div class="calculatorborder">
  <h2>{{ calculatorData.title }}</h2>
  <form [formGroup]="calculatorForm" (ngSubmit)="onSubmit()" style="width:100%">
    <div *ngFor="let field of calculatorData.fields">
      <mat-form-field appearance="fill" style="display: grid;">
        <mat-label>{{ field.label }}</mat-label>
        <input
          matInput
          [type]="field.type"
          [formControlName]="field.name"
          [placeholder]="field.placeholder"
        />
      </mat-form-field>
    </div>
       Dynamic formula input 
    <mat-form-field appearance="fill" style="display: grid;">
      <mat-label>Enter Formula</mat-label>
      <textarea
        matInput
        placeholder="Enter formula (e.g., income + deductions, income * taxRate / 100)"
        [(ngModel)]="dynamicFormula"
        rows="2"
      ></textarea>
    </mat-form-field>

    <button mat-raised-button color="primary" type="submit">{{ calculatorData.submitText }}</button>
  </form>

  <div *ngIf="calculatedResult !== null">
    <h3>Calculated Result: {{ calculatedResult }}</h3>
  </div>
</div>  -->


<div class="calculatorborder">
  <h2 style="font-size: 24px; font-weight: bold;">{{ calculatorData.title }}</h2>
  <form [formGroup]="calculatorForm" (ngSubmit)="onSubmit()" style="width: 100%;">
    <div *ngFor="let field of calculatorData.fields">
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>{{ field.label }}</mat-label>
        <input
          matInput
          [type]="field.type"
          [formControlName]="field.name"
          [placeholder]="field.placeholder"
          required
        />
      </mat-form-field>
    </div>

    <!-- Dynamic formula input -->
    <!-- <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>Enter Formula</mat-label>
      <textarea
        matInput
        placeholder="Enter formula (e.g., income + deductions, income * taxRate / 100)"
        [(ngModel)]="dynamicFormula"
        rows="2"
        required
      ></textarea>
    </mat-form-field> -->

    <button mat-raised-button type="submit" class="taxbutton">
      {{ calculatorData.submitText }}
    </button>
  </form>

  <div *ngIf="calculatedResult !== null">
    <h3>Calculated Result: {{ calculatedResult }}</h3>
  </div>
</div>
