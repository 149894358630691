import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionPriceService } from 'src/app/services/subscription-price.service';

@Component({
  selector: 'calsoft-subscription-price',
  templateUrl: './subscription-price.component.html',
  styleUrls: ['./subscription-price.component.scss']
})
export class SubscriptionPriceComponent implements OnInit {


  @Input() type: any = 1;

  @Input() item: any;

  subscriptionCost: any = 0.0;
  taxPercentage: any = 0.0;
  totalCost: any = 0.0;
  taxAmount: any;
  tax: boolean;


  constructor(private subscriptionService : SubscriptionPriceService,) { }

  ngOnInit() {

    if(this.item.customField22=='Exclusive'){
      this.tax = true;
    }else{
      this.tax = false
    }


    this.subscriptionService.subscriptionPrice$.subscribe(price => {
      this.subscriptionCost = price;
    });

    this.subscriptionService.subscriptionTaxPercentage$.subscribe(taxPercentage => {
      this.taxPercentage = taxPercentage;
    });

    this.subscriptionService.subscriptionTotalCost$.subscribe(totalCost => {
      this.totalCost = totalCost;
    });

    this.subscriptionService.subscriptionTotalTaxAmount$.subscribe(taxAmount => {
      this.taxAmount = taxAmount;
    });
  }


}
