<div  *ngIf="item.customField25==0">
    <div fxShow fxHide.lt-md>    
        <div class="bgimage" [style.backgroundImage]="'url(' + serverPath+item.customField13 + ')'"  contentAnim [aniName]="img.customField36">
        <h2 style="margin-left: 30%;margin-right: 30%;" [style.font-size]="item.customField22"  [ngStyle.lt-md]="{ 'font-size': item.customField23}"><b>{{item.title}} </b></h2>
    
        <div style="width: 60%;" *ngFor="let  img of item.images; let l = index" [ngStyle]="{ 'margin-left': l % 2 !== 0 ? 'auto' : '', 'margin-right': l % 2 !== 0 ? '3%' : '' }">
            <div fxLayout="row" fxLayoutAlign="space-around center" [ngClass]="{'even-item': l % 2 === 0, 'odd-item': l % 2 !== 0}" [style.background-color]="img.customField5" >
                <div fxFlex="50%" contentAnim [aniName]="img.customField36">
                    <img [src]="serverPath+img.customField10" >
                </div>
                <div fxFlex="50%" style="padding: 30px;"  contentAnim [aniName]="img.customField36" >
                    <p class="para1" [style.color]="img.customField7">Step {{l+1}}</p>
                    <p class="para2" [style.color]="img.customField7" [style.font-size]="item.customField34"  [ngStyle.lt-md]="{ 'font-size': item.customField35}">{{img.customField4}}</p>
                    <p class="para3" [style.color]="img.customField7" [style.font-size]="item.customField39"  [ngStyle.lt-md]="{ 'font-size': item.customField40}">{{img.customField16}}</p>
                </div>
            </div> 
        </div>
    
    
    </div>
    </div>
    
     <div fxShow fxHide.gt-sm  >
        <h2 style="font-size: 24px;margin-left: 15%;margin-right: 15%;"  contentAnim [aniName]="img.customField36" [style.font-size]="item.customField22"  [ngStyle.lt-md]="{ 'font-size': item.customField23}"><b>{{item.title}}</b></h2> 
    
        <div *ngFor="let  img of item.images; let l = index"   contentAnim [aniName]="img.customField36" >
        <p style="font-size: 32px;text-align: center;color: #4b4f58;"><b>Step {{l+1}}</b></p>
        <p style="font-size: 25px; text-align: center;font-weight: 700;font-family: Montserrat,Sans-serif;color: #4b4f58;" [style.font-size]="item.customField34"  [ngStyle.lt-md]="{ 'font-size': item.customField35}">{{img.customField4}}</p>
        <p style="font-size: 14px;text-align: center;color: #4b4f58;padding-left: 10px;
        padding-right: 10px " [style.font-size]="item.customField39"  [ngStyle.lt-md]="{ 'font-size': item.customField40}">{{img.customField16}}</p>
        <div style="padding: 10px;padding-top: 20px;" contentAnim [aniName]="img.customField36">

<img [src]="serverPath+img.customField10" >
        </div>
        
        <div style="text-align: center;" *ngIf="l < 2">
            <mat-icon style="font-size: 30px;color: blue;">arrow_downward</mat-icon>
        </div>
        </div>
    
    </div>
</div>



<div *ngIf="item.customField25==1" class="container">
    <div fxLayout="row" fxLayoutAlign="center center">

        <div class="title"  contentAnim [aniName]="img.customField36" >

            <calsoft-editor-text [data]="data" [text]="item.title" [align]="item.customField34">
            </calsoft-editor-text>

        </div>
    </div>

    <div class="StepFlow" *ngFor="let img of item.images; let l = index">

        <div class="image" [fxLayout.gt-md]="img.customField11=='Start'?'row':'row-reverse'">
            <div fxFlex="45" contentAnim [aniName]="img.customField36">
                <!-- <img width="100%" src="assets/images/map/map.jpg"
                [style.height]="item.height"
                [ngStyle.lt-md]="{'height': item.heightMob}"> -->

                <!-- <img width="100%" [src]="serverPath+img.customField10"
                [style.height]="item.height"
                [ngStyle.lt-md]="{'height': item.heightMob}"> -->
                <calsoft-ng-optimized-image [height]="item.height" [heightMob]="item.heightMob"
                                [altImage]="'images'"  [image]="serverPath+img.customField10" width="100%"
                                [i]="'0'">
                </calsoft-ng-optimized-image>

            </div>
            <div fxFlex="10">
                <!-- <span style="border-right: 1px solid lightgray; padding: 25px "></span> -->
            </div>
            <div fxFlex="45">
                <div class="title">
                    <h2 [style.font-size]="item.customField34"  [ngStyle.lt-md]="{ 'font-size': item.customField35}">
                        {{img.customField4}}
                    </h2>
                    <span style="text-align: left;" [style.font-size]="item.customField39"  [ngStyle.lt-md]="{ 'font-size': item.customField40}">
                        {{img.customField16}}
                    </span>

                </div>

            </div>

        </div>

    </div>

</div>