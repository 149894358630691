import { Component, Input, OnInit } from '@angular/core';
import { Thumbs } from 'swiper';

@Component({
  selector: 'calsoft-maps-component',
  templateUrl: './maps-component.component.html',
  styleUrls: ['./maps-component.component.scss']
})
export class MapsComponentComponent implements OnInit {
height:any;
  @Input() input:any;
  positionMap = {
    street: "Brookline",
    num: "123",
    city: "NewYork"
  };

  mapsURL = null;
  width: number;
  constructor() { }

  ngOnInit(): void {
  this.height=200;
  this.width=200;
 let value=this.input.split('q=');
    let size=value[1].split('z=')
       size=size[1].split('&amp;')[0]
    value=value[1].split('&amp')[0]
    let height=this.input.split('height=')
    height=height[1].split('&')[0]

    let width=this.input.split('width=')
    width=width[1].split('&')[0]
    this.width=width;
this.height=height;


    this.mapsURL = `https://maps.google.com/maps?q=${value}&t=&z=${size}&ie=UTF8&iwloc=&output=embed`;

    // let value=this.input.split('hl=en&amp;')[1];

    // console.log(value)

    // this.mapsURL = `https://maps.google.com/maps?${value}`;

    
  }

}
