import { Component } from '@angular/core';
import { Constant } from 'src/app/constants/constant';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  public PageTitle ='contact-us'
  constructor() { }

  ngOnInit() {
  }
}
