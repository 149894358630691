import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'calsoft-animated-text',
  templateUrl: './animated-text.component.html',
  styleUrls: ['./animated-text.component.scss']
})
export class AnimatedTextComponent {

  @Input() item: any;
  @Input() img: any;
  @Input() data: any;


  rows: number[] = []; // Array to hold row indices

  ngOnInit(): void {
    console.log(this.img);

    this.calculateRows();
  }

  // Method to calculate the number of rows needed
  calculateRows() {
    const itemsPerRow = 3; // Number of items per row
    const totalRows = Math.ceil(this.img.length / itemsPerRow);

    // Fill rows array with row indices
    this.rows = Array.from({ length: totalRows }, (_, i) => i);
  }




  features = [
    { title: 'FEATURE 1', description: 'PRODUCT MANAGEMENT' },
    { title: 'FEATURE 2', description: 'SHOPPING CART' },
    { title: 'FEATURE 3', description: 'CHECKOUT PROCESS' },
    { title: 'FEATURE 4', description: 'ORDER MANAGEMENT' },
    { title: 'FEATURE 5', description: 'USERS ACCOUNTS' },
    { title: 'FEATURE 6', description: 'SECURITY' },
    { title: 'FEATURE 7', description: 'RESPONSIVE DESIGN' },
    { title: 'FEATURE 8', description: 'SEO AND MARKETING TOOLS' },
    { title: 'FEATURE 9', description: 'ANALYTICS AND REPORTING' },
    { title: 'FEATURE 10', description: 'ANALYTICS AND REPORTING' },
    { title: 'FEATURE 11', description: 'ANALYTICS AND REPORTING' },
    { title: 'FEATURE 12', description: 'ANALYTICS AND REPORTING' },
    { title: 'FEATURE 13', description: 'ANALYTICS AND REPORTING' },
    { title: 'FEATURE 14', description: 'ANALYTICS AND REPORTING' },
    { title: 'FEATURE 15', description: 'ANALYTICS AND REPORTING' },
    { title: 'FEATURE 16', description: 'ANALYTICS AND REPORTING' },
    { title: 'FEATURE 17', description: 'ANALYTICS AND REPORTING' },
    { title: 'FEATURE 18', description: 'ANALYTICS AND REPORTING' }
  ];


  getStyleClass(index: number): string {
    const row = Math.floor(index / 3); // Determine the row number
    const col = index % 3; // Determine the column within the row

    // If the column is odd (1 or 3 in zero-based index), apply alternate style
    return (col % 2 === 1) ? 'alt-style' : '';
  }

  calcFlex(): string {
    return 'calc(33.33% - 20px)';
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {

    const elements = document.querySelectorAll('.feature-row');
    elements.forEach((el: any) => {
      const rect = el.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
        el.classList.add('slide-in');

      } else {
        el.classList.remove('slide-in');

      }
    });
  }


}

