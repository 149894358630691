import { Component, OnInit, Input } from '@angular/core';

import { Constant } from 'src/app/constants/constant';
import { Product } from 'src/app/models/app.models';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { CurrencyConversionService } from 'src/app/services/currency-conversion.service';
import { ProductService } from 'src/app/services/product.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-LighteningDeals',
  templateUrl: './LighteningDeals.component.html',
  styleUrls: ['./LighteningDeals.component.scss']
})
export class LighteningDealsComponent implements OnInit {
  @Input()  showCartIcon : any;
  @Input()  showCartIncremented : any;
  @Input() categoryId: any;
  @Input() header: any;
  @Input() showConfigurableDropdown: any;
  @Input() productImageHeight:any;
  @Input() data: any;
  
  @Input() sortOrder: any;
  @Input() id: any;
  @Input() other: any;
  categoryProducts: any;
  @Input() height: any;
  @Input() heightMobile: any;
  @Input() item: any;

  @Input() widthMobile: any;
  @Input() width: any;

 @Input() imageType: any;
  count: any;
  isEmpty: any;
  pageSize: any;
  public page: any = 0;
  size: number = 5;
  sortParam: any = 'entityId' + "," + Constant.Dsc;
  private serverPath = environment.ImageAPI;
  countryCode: string;
  mobileSize: number = 4;
  @Input('products') products: Array<Product> = [];
  constructor(
    private commonService: CommonService,
    private productService: ProductService,
    private currencyConversionService: CurrencyConversionService,
    private userService: UserService,
    private cartServices: CartService,
  ) { }

  ngOnInit() {
    if (window.innerWidth> 770&&window.innerWidth< 1024){
      this.size=5;
   }
   else if (window.innerWidth> 650&&window.innerWidth< 770) {
      this.size=6
   }else if(window.innerWidth< 650){
this.size=this.mobileSize
   }	else{
    this.size=this.size
   }


    this.productsByCategory();
    let countryCode = this.currencyConversionService.getCountryCode();
    if (countryCode == null) {
      this.countryCode = "INR";
    }
    else {
      this.countryCode = countryCode;
    }
  }

  public productsByCategory() {


    if (this.other == 'category') {
      this.categoryId = this.commonService.getEncryptedId(parseInt(this.id))
      this.sortParam = this.sortOrder;
      this.productService.productsByCategoryIdx(decodeURIComponent(this.categoryId), this.page, this.size, this.sortOrder).subscribe(
        res => {

          if(res!=null){
          this.categoryProducts = res['content'];
          this.count = res['totalElements'];
          this.isEmpty = res['empty'];
          this.pageSize = res['totalPages']
          }else{
            this.categoryProducts = [];
            this.count = 0;
            this.isEmpty = true;
            this.pageSize = 0
          }
        }

      )
    } else {

      let methodName="getProductBySearch";
      let search=''
      if(this.other=='productScope'){
        methodName="getProductByCustomProductId";
        search=this.id
      }else{

         let value = 1;
       search = "isInStock:" + '*' + value + '*'
            + "' AND featured:" + '*' + value + '*' + "' "
      }
      this.productService[methodName](search, this.page, this.size, this.sortOrder).subscribe(
        res => {
          this.categoryProducts = res['content'];
          this.count = res['totalElements'];
          this.isEmpty = res['empty'];
          this.pageSize = res['totalPages']
        })
    }
  }

  productShow(product) {


    this.commonService.productNavigation(product)


  }

  goToCategory() {


    if(this.other!="other"){
      this.commonService.goToCategory(1, parseInt(this.id));
    }else{
      let value = 1;
      let search = "isInStock:" + '*' + value + '*'
        + "' AND featured:" + '*' + value + '*' + "' "
      this.commonService.productSearch(search);
    }

  }
  values: any = "*values*";
  getCustomerCount() {

    let authourized = this.userService.getAuthenticatedUser();
  if (authourized) {


    this.cartServices.geWishListProduct();

    this.cartServices.getCustomerCartProductCount().subscribe(
      (response) => {


        this.values = response;

        let value = localStorage.getItem(Constant.CART_COUNT);
        if (value == null) {
          let encryptedValue = btoa(JSON.stringify(response));

          this.userService.storeProductCartCount(encryptedValue);
        }
      },
      (err) => {}
    );
  }
}
}
