<div [style.backgroundImage]="'url(' +serverPath+item.customField13 + ')'" class="background">
  <div class="section section--no-pb jarallax item_64d76c6e6c76a snipcss-ypR9p">
    <div
      class="containermain snipcss0-0-0-1 tether-target-attached-top tether-element-attached-top tether-element-attached-center tether-target-attached-center">
      <div class="section-heading section-heading--center section-heading--white aos-init aos-animate snipcss0-1-1-2"
        data-aos="fade">
        <h2 [style.font-size]="item.customField18" [ngStyle.lt-md]="{ 'font-size': item.customField17 }"
          class="__title snipcss0-2-2-3" [innerHTML]="item.title">
         
          <span class="snipcss0-3-3-4">
            {{item.customField1}}
          </span>
        </h2>
        <p [style.color]="item.customField8" [ngStyle]="{'font-family': item.fontFamily || 'cursive'}"
          [innerHTML]="item.customField14"              [style.font-size]="item.customField35" [ngStyle.lt-md]="{ 'font-size': item.customField36 }">
        </p>
      </div>
      <div class="simple-text-block simple-text-block--no-pb snipcss0-0-0-1 snipcss0-1-1-6">
        <div class="row justify-content-md-center snipcss0-1-1-2 snipcss0-2-6-7">
          <div class="col-12 col-md-11 snipcss0-2-2-3 snipcss0-3-7-8">
            <div class="row justify-content-lg-between no-gutters snipcss0-3-3-4 snipcss0-4-8-9">
              <div class="col-12 col-lg-5 snipcss0-4-4-5 snipcss0-5-9-10">
                <!-- <img [src]="serverPath+item.customField21" onerror="this.src='';" [alt]="'images'" class="swiper-lazy"> -->

                <calsoft-ng-optimized-image [altImage]="'images'" [image]="serverPath+item.customField21" [i]="'0'">
                </calsoft-ng-optimized-image>
              </div>
              <div class="col-12 my-3 d-lg-none snipcss0-4-4-7 snipcss0-5-9-12">
              </div>
              <div class="col-12 col-lg-6 snipcss0-4-4-8 snipcss0-5-9-13">
                <div class="js-slick slick_64d76c6e6c76c slick-initialized slick-slider snipcss0-5-8-9 snipcss0-6-13-14"
                  data-slick="{&quot;autoplay&quot;: false, &quot;arrows&quot;: false, &quot;dots&quot;: false, &quot;pauseOnHover&quot;: false, &quot;speed&quot;: 1000}">
                  <div class="slick-list draggable snipcss0-6-9-10 snipcss0-7-14-15 style-IhUWw scrollable-element" id="style-IhUWw">
                    <calsoft-banner-content [item]="item" [array]="item.images"></calsoft-banner-content>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>