import { Component, Input } from "@angular/core";


@Component({
  selector: 'calsoft-purchase-cart',
  templateUrl: './purchase-cart.component.html',
  styleUrls: ['./purchase-cart.component.scss']
})
export class PurchaseCartComponent {

  @Input() finalData: any;

  @Input() showcart: any;


  constructor(
   
  ) {

  }

  ngOnInit() {

     console.log("final data" , this.finalData);
        

  }



}
