import { Component, Input } from '@angular/core';

@Component({
  selector: 'calsoft-textanimation2',
  templateUrl: './textanimation2.component.html',
  styleUrls: ['./textanimation2.component.scss']
})
export class Textanimation2Component {

  

  @Input() item : any;
  @Input() img : any;
  @Input() data : any ;

     card=[
      {title:'-75%',content:'ORDER VALUE'},
      {title:'-75%',content:'ORDER VALUE'},
      {title:'-75%',content:'ORDER VALUE'},
      {title:'-75%',content:'ORDER VALUE'},
      {title:'-75%',content:'ORDER VALUE'},
      {title:'-75%',content:'ORDER VALUE'},
      {title:'-75%',content:'ORDER VALUE'},
      {title:'-75%',content:'ORDER VALUE'},
     ]
}
