
<div *ngIf="item.customField23=='0'" class="emicalculator" >
    <div fxLayout="column" fxLayoutGap="30px" class="spacer">
        <div class="bgcolor">
            <div class="well">

                <div class="Mbottom">
                    <div class="margin-bottom">
                        <span class="left-title">
                        Loan Amount
                        </span>
                        <input type="text" class="contentfloat" (blur)="tbupdate(0)"
                        [(ngModel)]="query.amount" placeholder="Loan Amount" name="pemi.highValue" />
                    </div>
                    <ngx-slider [(value)]="pemi.value" [options]="poptions" (userChangeEnd)="update()">
                    </ngx-slider>
                    <!-- <calsoft-slider (userChangeEnd)="handleSliderChange($event)" [Datavalue]="pemi.value"  [poptions]="poptions" >

                    </calsoft-slider> -->
                </div>
    
                <hr />
    
                <div class="Mbottom">
                    <div class="margin-bottom">
                        <span class="left-title">
                        Interest Rate
                        </span>
                        <input type="text" class="contentfloat"  (blur)="tbupdate(1)"
                        [(ngModel)]="query.interest" placeholder="Interest Rate" name="remi.highValue" />
                    </div>
                    <ngx-slider [(value)]="remi.value" [options]="roptions" (userChangeEnd)="update()">
                    </ngx-slider>
                </div>
    
                <hr />
    
                <div class="Mbottom">
                    <div class="margin-bottom">
                        <span class="left-title">Loan Tenure</span>
                        <input type="text" *ngIf="yrToggel" class="contentfloat" (blur)="tbupdate(2)"
                        placeholder="Loan Tenure in Yr" [(ngModel)]="query.tenureYr" >
                        <input type="text" *ngIf="!yrToggel" class="contentfloat" (blur)="tbupdate(3)"
                        placeholder="Loan Tenure in Mo" [(ngModel)]="query.tenureMo" >
                    </div>
                    <div class="margin-bottom">
                        <div>
                            <button class="formtype" mat-button
                            class="btn-outline-secondary" [ngClass]="{'active':yrToggel}"
                                (click)="yrToggel=true;update()">YR
                            </button>
                            <button class="formtype" mat-button class="btn-outline-secondary" [ngClass]="{'active':!yrToggel}"
                                (click)="yrToggel=false;update()">MO
                            </button>
                        </div>
                    </div>
                    <ngx-slider *ngIf="yrToggel" [(value)]="temi.value" [options]="toptions" (userChangeEnd)="update()">
                    </ngx-slider>
                    <ngx-slider *ngIf="!yrToggel" [(value)]="memi.value" [options]="moptions" (userChangeEnd)="update()" class="custom-slider">
                    </ngx-slider>
                </div>


            </div>

        </div>
        <div class="bgcolor"  >
            <div fxLayout="column" fxLayoutGap="10px" class="well">

                <!-- Principal Amount -->
                <div class="Mbottom">
                    <h5>
                        Principal Amount (Loan Amount)
                    </h5>
                    <span class="result">
                        {{result.loanAmount || "--"}}
                    </span>
                </div>
            
                <hr />
            
                <div class="Mbottom">
                    <h5>
                        Loan EMI
                    </h5>  
                    <span class="result">
                        {{result.emi || "--"}}
                    </span>
                </div>
            
                <hr />
            
                <div class="Mbottom">
                    <h5>
                        Total Interest Payable
                    </h5>
                    <span class="result">
                        {{result.interest || "--"}}
                    </span>
                </div>
            
                <hr />
                <div class="Mbottom">
                    <h5>
                        Total Payment
                    </h5>
                    <h6>
                        (Principal + Interest)
                    </h6>
                    <span class="result">
                        {{result.total || "--"}}
                    </span>
                </div>
            
            </div>
            
        </div>
    </div>
    
</div>

<div *ngIf="item.customField23=='1'" >
    <div fxHide.lt-md style="width:100% ;" >
        <div fxLayout="row" fxLayoutGap="30px" class="spacer">
            <div fxFlex="25" class="bgcolor"  >
                <div class="well">
    
                    <div class="Mbottom"  >
                        <div class="margin-bottom">
                            <span class="left-title">
                            Loan Amount
                            </span>
                            <input type="text" class="contentfloat" (blur)="tbupdate(0)"
                            [(ngModel)]="query.amount" placeholder="Loan Amount" name="pemi.highValue" />
                        </div>
                        <calsoft-slider   (userChangeEnd)="handleSliderChange($event)" [Datavalue]="pemi.value"  [poptions]="poptions" >

                        </calsoft-slider>
                    </div>
        
                    <hr />
        
                    <div class="Mbottom" >
                        <div class="margin-bottom">             
                            <span class="left-title">
                            Interest Rate
                            </span>
                            <input type="text" class="contentfloat"  (blur)="tbupdate(1)"
                            [(ngModel)]="query.interest" placeholder="Interest Rate" name="remi.highValue" />
                        </div>
                        <!-- <ngx-slider style="width:100% ;" [(value)]="remi.value" [options]="roptions" (userChangeEnd)="update()">
                        </ngx-slider> -->
                        <calsoft-slider   (userChangeEnd)="handleSliderChange($event)" [Datavalue]="remi.value"  [poptions]="roptions" >

                        </calsoft-slider>
                    </div>
        
                    <hr />
        
                    <div class="Mbottom" >
                        <div class="margin-bottom">
                            <span class="left-title">Loan Tenure</span>
                            <input type="text" *ngIf="yrToggel" class="contentfloat" (blur)="tbupdate(2)"
                            placeholder="Loan Tenure in Yr" [(ngModel)]="query.tenureYr" >
                            <input type="text" *ngIf="!yrToggel" class="contentfloat" (blur)="tbupdate(3)"
                            placeholder="Loan Tenure in Mo" [(ngModel)]="query.tenureMo" >
                        </div>
                        <div class="margin-bottom">
                            <div>
                                <button class="formtype" mat-button
                                class="btn-outline-secondary" [ngClass]="{'active':yrToggel}"
                                    (click)="yrToggel=true;update()">YR
                                </button>
                                <button class="formtype" mat-button class="btn-outline-secondary" [ngClass]="{'active':!yrToggel}"
                                    (click)="yrToggel=false;update()">MO
                                </button>
                            </div>
                        </div>
                        <!-- <ngx-slider style="width:100% ;" *ngIf="yrToggel" [(value)]="temi.value" [options]="toptions" (userChangeEnd)="update()">
                        </ngx-slider> -->
                        <calsoft-slider *ngIf="yrToggel"    (userChangeEnd)="handleSliderChange($event)" [Datavalue]="temi.value"  [poptions]="toptions" >

                        </calsoft-slider>
                        <!-- <ngx-slider style="width:100% ;" *ngIf="!yrToggel" [(value)]="memi.value" [options]="moptions" (userChangeEnd)="update()" class="custom-slider">
                        </ngx-slider> -->
                        <calsoft-slider *ngIf="!yrToggel"    (userChangeEnd)="handleSliderChange($event)" [Datavalue]="memi.value"  [poptions]="moptions" >

                        </calsoft-slider>

                    </div>
    
    
                </div>
    
            </div>
            <div fxFlex="70"  class="bgcolor" >
                <div [innerHTML]="item.customField19"></div>
            </div>
            <div fxFlex="25"  class="bgcolor"  >
                <div fxLayout="column" fxLayoutGap="10px" class="well">

                    <!-- Principal Amount -->
                    <div class="Mbottom">
                        <h5>
                            Principal Amount (Loan Amount)
                        </h5>
                        <span class="result">
                            {{result.loanAmount || "--"}}
                        </span>
                    </div>
                
                    <hr />
                
                    <div class="Mbottom">
                        <h5>
                            Loan EMI
                        </h5>  
                        <span class="result">
                            {{result.emi || "--"}}
                        </span>
                    </div>
                
                    <hr />
                
                    <div class="Mbottom">
                        <h5>
                            Total Interest Payable
                        </h5>
                        <span class="result">
                            {{result.interest || "--"}}
                        </span>
                    </div>
                
                    <hr />
                    <div class="Mbottom">
                        <h5>
                            Total Payment
                        </h5>
                        <h6>
                            (Principal + Interest)
                        </h6>
                        <span class="result">
                            {{result.total || "--"}}
                        </span>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
    <div fxHide fxShow.lt-md style="width:100% ;" >
        <div fxLayout="column" fxLayoutGap="30px" class="spacer">
            <div fxFlex="100" class="bgcolor" >
                <div class="well">
    
                    <div class="Mbottom">
                        <div class="margin-bottom">
                            <span class="left-title">
                            Loan Amount
                            </span>
                            <input type="text" class="contentfloat" (blur)="tbupdate(0)"
                            [(ngModel)]="query.amount" placeholder="Loan Amount" name="pemi.highValue" />
                        </div>
                        <calsoft-slider   (userChangeEnd)="handleSliderChange($event)" [Datavalue]="pemi.value"  [poptions]="poptions" >

                        </calsoft-slider>
                    </div>
        
                    <hr />
        
                    <div class="Mbottom">
                        <div class="margin-bottom">
                            <span class="left-title">
                            Interest Rate
                            </span>
                            <input type="text" class="contentfloat"  (blur)="tbupdate(1)"
                            [(ngModel)]="query.interest" placeholder="Interest Rate" name="remi.highValue" />
                        </div>
                        <calsoft-slider   (userChangeEnd)="handleSliderChange($event)" [Datavalue]="remi.value"  [poptions]="roptions" >

                        </calsoft-slider>
                    </div>
                    </div>
        
                    <hr />
        
                    <div class="Mbottom">
                         <div class="margin-bottom">
                            <span class="left-title">Loan Tenure</span>
                            <input type="text" *ngIf="yrToggel" class="contentfloat" (blur)="tbupdate(2)"
                            placeholder="Loan Tenure in Yr" [(ngModel)]="query.tenureYr" >
                            <input type="text" *ngIf="!yrToggel" class="contentfloat" (blur)="tbupdate(3)"
                            placeholder="Loan Tenure in Mo" [(ngModel)]="query.tenureMo" >
                        </div> 
                     
                          
                        <div class="margin-bottom">
                            <div>
                                <button class="formtype" mat-button
                                class="btn-outline-secondary" [ngClass]="{'active':yrToggel}"
                                    (click)="yrToggel=true;update()">YR
                                </button>
                                <button class="formtype" mat-button class="btn-outline-secondary" [ngClass]="{'active':!yrToggel}"
                                    (click)="yrToggel=false;update()">MO
                                </button>
                            </div>
                        </div>
                       <!-- <ngx-slider style="width:100% ;" *ngIf="yrToggel" [(value)]="temi.value" [options]="toptions" (userChangeEnd)="update()">
                        </ngx-slider> -->
                        <calsoft-slider *ngIf="yrToggel"    (userChangeEnd)="handleSliderChange($event)" [Datavalue]="temi.value"  [poptions]="toptions" >

                        </calsoft-slider>
                        <!-- <ngx-slider style="width:100% ;" *ngIf="!yrToggel" [(value)]="memi.value" [options]="moptions" (userChangeEnd)="update()" class="custom-slider">
                        </ngx-slider> -->
                        <calsoft-slider *ngIf="!yrToggel"    (userChangeEnd)="handleSliderChange($event)" [Datavalue]="memi.value"  [poptions]="moptions" >

                        </calsoft-slider>
                    </div>
    
    
                </div>
    
            </div>
            <div fxFlex="100"   class="bgcolor" [style.background-color]="item.customField20" >
                <div style="margin:15px ;"  [innerHTML]="item.customField19"></div>
            </div>
            <div fxFlex="100"  class="bgcolor" [style.background-color]="item.customField20">
                <div fxLayout="column" fxLayoutGap="10px" class="well">

                    <!-- Principal Amount -->
                    <div class="Mbottom">
                        <h5>
                            Principal Amount
                        </h5>
                        <span class="result">
                            {{result.principal || "--"}}
                        </span>
                    </div>
                
                    <hr />
                
                    <div class="Mbottom">
                        <h5>
                            Loan EMI
                        </h5>  
                        <span class="result">
                            {{result.emi || "--"}}
                        </span>
                    </div>
                
                    <hr />
                
                    <div class="Mbottom">
                        <h5>
                            Total Interest Payable
                        </h5>
                        <span class="result">
                            {{result.interest || "--"}}
                        </span>
                    </div>
                
                    <hr />
                    <div class="Mbottom">
                        <h5>
                            Total Payment
                        </h5>
                        <h6>
                            (Principal + Interest)
                        </h6>
                        <span class="result">
                            {{result.total || "--"}}
                        </span>
                    </div>
                
                </div>
            </div>
        </div>
</div>
    
<div *ngIf="item.customField23=='2'" >
    <h2 style="text-align: center;">Loan EMI Calculator</h2>

    <div fxLayout="row" fxLayoutGap="10px" class="padding-20" fxLayout.xs="column">

        <div fxFlex="35" class="bgcolor padding-20">

            <div fxLayout="column"  fxLayoutGap="20px">
                <!-- Loan Amount Input -->
                <div fxFlex="100">
                  <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Loan Amount (₹)</mat-label>
                    <input matInput type="number" [(ngModel)]="loanAmount" (change)="onInputChange()" (keyup)="onInputChange()">
                  </mat-form-field>
                  <ngx-slider [(value)]="loanAmount" [options]="amountSliderOptions" (userChangeEnd)="onSliderChange()"></ngx-slider>
                </div>
            
                <!-- Interest Rate Input -->
                <div fxFlex="100">
                  <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Interest Rate (%)</mat-label>
                    <input matInput type="number" [(ngModel)]="interestRate" step="0.1" (change)="onInputChange()" (keyup)="onInputChange()">
                  </mat-form-field>
                  <ngx-slider [(value)]="interestRate" [options]="interestSliderOptions" (userChangeEnd)="onSliderChange()"></ngx-slider>
                </div>
            
                <!-- Loan Tenure Input -->
                <div fxFlex="100">
                  <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Loan Tenure (Months)</mat-label>
                    <input matInput type="number" [(ngModel)]="loanTenure" (change)="onInputChange()" (keyup)="onInputChange()">
                  </mat-form-field>
                  <ngx-slider [(value)]="loanTenure" [options]="tenureSliderOptions" (userChangeEnd)="onSliderChange()"></ngx-slider>
                </div>
              </div>      

        </div>
        <div fxFlex="30" class="bgcolor">
            <div [innerHTML]="item.customField19"></div>
            <div class="button-container">
                <button mat-flat-button class="primary" style="color: white;" (click)="goToForm()">ENQUIRY</button>
            </div>

        </div>
        <div fxFlex="35" class="bgcolor padding-20">
            <div fxFlex="100" class="emi-result">

                <div class="Mbottom"><h5>Principal Amount</h5>
                    <span class="resultnew">
                        ₹ {{ loanAmount | number }}
                    </span>
                </div>        
                <hr />
                <!-- <div class="Mbottom"><h5>EMI Amount</h5>
                    <span class="result">
                        ₹{{ emiResult | number }}
                    </span>
                </div>        
                <hr /> -->
                <div class="Mbottom"><h5>Total Interest Payable</h5>
                    <span class="resultnew">
                        ₹ {{ totalInterest | number }}
                    </span>
                </div>        
                <hr />
                <div class="Mbottom"><h5>Total Payment (Principal + Interest)</h5>
                    <span class="resultnew">
                        ₹ {{ totalPayment | number }}
                    </span>
                </div>        
                <hr />

              </div>
    
        </div>
    </div>

      

</div>




