<div fxLayout="row" id="responsieTable" class="container" >
    <div fxFlex>

        <table class="rwd-table">

            <tr class="backTable">
                <th style="text-align: left; width:20%">Item</th>
                <th>No of Units</th>
                <th>Cost Per Unit</th>
                <th>Total Cost</th>
            </tr>

            <ng-container>
                <tbody>
                    <tr style="background: white;"
                        *ngFor="let data of finalData; let i=index">

                            <td data-th="Product" style="text-align: left;" fxHide fxShow.sm fxShow.xs>
                                <small>{{data.name
                                    | uppercase |
                                    slice:0:25}}...</small>

                            </td>

                            <td data-th="No of Units">
                                {{data.quantity
                                }}
                            </td>

                            <td data-th="Cost Per Unit">
                                {{data.price * data.quantity
                                }}
                            </td>


                            <td data-th="Total Cost">
                                {{data.price
                                }}
                            </td>
                    </tr>
                </tbody>
            </ng-container>
        </table>
    </div>
</div>