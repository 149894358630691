<div fxHide fxShow.gt-sm [style.background-color]="item.customField8" class="image padding-10 bg">
    
    <!-- Position h1 above the images -->
    

    <div class="backgroundImg" [style.max-height]="item.height">
        <h1 style="color: #D6DCDC;
        text-decoration: none;
        text-align: center;
      
        letter-spacing: 0.09em;
        line-height: normal;
        margin-bottom: 20px; /* Adjusted for gap */
        text-transform: uppercase;"   [innerHTML]="item.title" ></h1>
        <ul id="cards">
            <li *ngFor="let card of list1" [id]="'card_' + card.id" class="card">
                <figure>
                    <img width="100%" onerror="this.src='';" [src]="bannerServerPath+card.img"
                        [alt]="card.altImage || 'images'" [ngClass]="card.customField10" style="cursor:pointer;" (click)="navigation==0?goToCategories(catId):link(card.navigatedUrl)">
                </figure>
            </li>
        </ul>
    </div>
    
    <div class="backgroundImg" [style.max-height]="item.height">
        <ul id="cards">
            <li *ngFor="let card of list2" [id]="'card_' + card.id" class="card">
                <figure>
                   
                    <img width="100%" onerror="this.src='';" [src]="bannerServerPath+card.img" style="cursor:pointer;"
                        [alt]="card.altImage || 'images'" [ngClass]="card.customField10" (click)="navigation==0?goToCategories(catId):link(card.navigatedUrl)">
                </figure>
            </li>
        </ul>
    </div>

</div>


<div fxHide fxShow.lt-sm   [style.background-color]="item.customField8" class="image padding-10 bg">
    <div class="backgroundImg">
        <ul id="cards">
            <li *ngFor="let card of item.images" [id]="'card_' + card.id" class="card">
                <figure>
                    <img onerror="this.src='';" width="100%" [src]="bannerServerPath + card.img" [alt]="card.altImage || 'images'" [ngClass]="card.customField10" style="cursor:pointer;"  (click)="navigation==0?goToCategories(catId):link(card.navigatedUrl)">
                </figure>
            </li>
        </ul>
    </div>
</div>
  
  