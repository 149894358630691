import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-textwithform-popup',
  templateUrl: './textwithform-popup.component.html',
  styleUrls: ['./textwithform-popup.component.scss']
})
export class TextwithformPopupComponent implements OnInit {
  data: any;
  item: any;
  
  public serverPath1 = environment.commonImageApi + '/home/';
  constructor(private dialogRef: MatDialogRef<TextwithformPopupComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
  ) {

    this.data = data;

  }
  close() {
    this.dialogRef.close({ event: 'close' })
  }
  ngOnInit() {
    console.log(this.data);

    this.item = this.data.item

  
    
    
  }

}
