<div *ngIf="item.length==0; else imgMissing" fxLayoutAlign="center center">
</div>

<ng-template #imgMissing >
    <div fxLayout="column" fxLayoutAlign="center center" [ngStyle]="{'font-family': item.fontFamily || 'Lato, sans-serif'}" contentAnim
    [aniName]="item.customField7" >
        <h3 [style.font-size]="item.customField18"
        [ngStyle.lt-md]="{ 'font-size': item.customField17 }" [innerHTML]="item.title"></h3>
   </div>

  <owl-carousel-o  fxLayoutAlign="center center" [options]="slideConfig">
    <ng-container *ngFor="let slide of item.images">
      <ng-template carouselSlide [id]="slide.id">

        <div fxLayout="column" fxLayoutAlign="center center" [ngStyle]="{'font-family': item.fontFamily || 'Lato, sans-serif'}" contentAnim
        [aniName]="item.customField7">

        <calsoft-editor-text  
        [ngStyle.gt-md]="{'font-size': item.customField39 }"
        [ngStyle.lt-md]="{
            
            'font-size': item.customField40
        }" [data]="data" [text]="slide.customField15" [align]="item.customField34">
        </calsoft-editor-text>
          
       </div>
          
      </ng-template>
    </ng-container>
  </owl-carousel-o>
  </ng-template>
