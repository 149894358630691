import { Component, Input, ViewChild } from '@angular/core';
import { CarouselComponent } from 'ngx-owl-carousel-o';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-scroll-image',
  templateUrl: './scroll-image.component.html',
  styleUrls: ['./scroll-image.component.scss']
})
export class ScrollImageComponent {

  @ViewChild('owlCar') owlCarousel: CarouselComponent;

  public serverPath = environment.commonImageApi + '/home/';

  public serverPath1 = environment.commonImageApi + '/commonSlider/';

  @Input() item: any;
  
  @Input() img: any;

  @Input() i: any;

  @Input() data: any;

  width:any;

  height:any;


  constructor(
    private commonService: CommonService,
    private configService :ConfigServiceService,    
  ) {

  }


    LogoSlider = {
      ...sliderOpt,
      nav: false,
      dots: false,
      items: 1,
      margin: 20,
      loop: false,
      autoHeight: true,
      autoplay: true,
      
      responsive: {
          767: {
              items: 1
          },
          576: {
              items: 1
          },
          480: {
              items: 1
          },
          0: {
              items: 1
          }
      }
  }
  


  imageNavigation(id){


    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }
  
  }

  link(url) {

    this.commonService.goToLink(url)
  }

  
}


export const sliderOpt = {
  items: 1,
  loop: true,
  margin: 0,
  responsiveClass: true,
  nav: true,
  navText: [ '<i class="icon-angle-left">', '<i class="icon-angle-right">' ],
  dots: true,
  smartSpeed: 400,
  autoplay: false,
  autoplayTimeout: 15000
}