import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'calsoft-button-type2',
  templateUrl: './button-type2.component.html',
  styleUrls: ['./button-type2.component.scss']
})
export class ButtonType2Component {
  @Input() item: any;
  customField1:any;
  fontFamily: string ; 

  public serverPath = environment.commonImageApi + '/home/';

  ngOnInit() {
    
  }

  openInNewWindow1(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      window.open(fullURL, '_blank');

    }
  }

  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      window.open(fullURL, '_blank');

    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }


}
