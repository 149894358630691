import { Component, Input, ViewChild } from '@angular/core';
import { CarouselComponent } from 'ngx-owl-carousel-o';
import { Constant } from 'src/app/constants/constant';
import { Product } from 'src/app/models/app.models';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-carousel-with-product',
  templateUrl: './carousel-with-product.component.html',
  styleUrls: ['./carousel-with-product.component.scss']
})
export class CarouselWithProductComponent {

  public serverPath = environment.commonImageApi + '/commonSlider/';

  @Input() item: any;

  @Input() img: any;

  @Input() categoryId: any;
  @Input() header: any;
  @Input() id: any;
  @Input() other: any;
  @Input() sortOrder: any;
  @Input()  showCartIcon : any;
  @Input() showConfigurableDropdown: any;
  @Input() productImageHeight:any;
  @Input() height: any;
  @Input() width: any;
  @Input() imageType: any;
  @Input() data: any;

  @Input()  showCartIncremented : any;
  @Input() heightMobile: any;

  @Input() widthMobile: any;
  
  productImageType: any;
  productImageWidthMob: any;
  productImageWidth: any;
  productImageHeightMob: any;

  public LogoSliderConfig: any;

  pageSize: any;
  public page: any = 0;
  size: number = 6;
  mobileSize:number=4;
  categoryProducts: any;
  sortParam: any = 'entityId' + "," + Constant.Asc;

  count: any;
  isEmpty: any;
 disablePrice:any=1;
 @Input() type;
 @Input('products') products: Array<Product> = [];
  
  constructor(  private commonService: CommonService,
    private productService: ProductService,
    private categoryService: CategoryService) { }

    LogoSlider = {
      ...sliderOpt,
      nav: false,
      dots: true,
      items: 1,
      margin: 20,
      loop: false,
      autoHeight: true,
      autoplay: true,
      
      responsive: {
          767: {
              items: 1
          },
          576: {
              items: 1
          },
          480: {
              items: 1
          },
          0: {
              items: 1
          }
      }
  }
  
  ngOnInit(): void {

    this.productsByCategory();
    

    if ( this.item.gapBetweenImage.endsWith('px')) {
      this.item.gapBetweenImage= this.item.gapBetweenImage.slice(0, -2); 
    }
  
    
    this.LogoSlider.margin=this.item.gapBetweenImage;
    this.LogoSliderConfig=this.LogoSlider;
    
  }




  productShow(product) {


    this.commonService.productNavigation(product)


 }

  
  imageNavigation(id){

    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  catId:any;
 
  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  @ViewChild('owlCar') owlCarousel: CarouselComponent;

  public productsByCategory() {


    if (this.other == 'category') {
        this.categoryId = this.commonService.getEncryptedId(parseInt(this.id))
       this.sortParam = this.sortOrder;
       this.productService.productsByCategoryIdx
       (decodeURIComponent(this.categoryId), this.page, this.size, this.sortParam).subscribe(
          res => {
   
             if(res!=null){
                this.categoryProducts = res['content'];
                this.count = res['totalElements'];
                this.isEmpty = res['empty'];
                this.pageSize = res['totalPages']
                }else{
                  this.categoryProducts = [];
                  this.count = 0;
                  this.isEmpty = true;
                  this.pageSize = 0
                }

          }

       )
    } else {

       let methodName="getProductBySearch";
       let search=''
       if(this.other=='productScope'){
         methodName="getProductByCustomProductId";
         search=this.id
       }else{

          let value = 1;
        search = "isInStock:" + '*' + value + '*'
             + "' AND featured:" + '*' + value + '*' + "' "
       }
     

       this.productService[methodName](search, this.page, this.size, this.sortOrder).subscribe(
          res => {
             this.categoryProducts = res['content'];
             this.count = res['totalElements'];
             this.isEmpty = res['empty'];
             this.pageSize = res['totalPages']
          })
    }

 }

 
 goToCategory() {


  if(this.type=='product'){
    this.commonService.goToCategoryForOtherTypes(this.products,this.header);
  }
  else if(this.other=="productScope"){
    this.commonService.customSearch(this.id,this.header);
  }
  else if(this.other!="other"){
    this.commonService.goToCategory(1, parseInt(this.id));
  }
  
  else{
    let value = 1;
    let search = "isInStock:" + '*' + value + '*'
      + "' AND featured:" + '*' + value + '*' + "' "
    this.commonService.productSearch(search);
  }

}
  

}


export const sliderOpt = {
  items: 1,
  loop: true,
  margin: 0,
  responsiveClass: true,
  nav: true,
  navText: [ '<i class="icon-angle-left">', '<i class="icon-angle-right">' ],
  dots: true,
  smartSpeed: 400,
  autoplay: false,
  autoplayTimeout: 15000
}
