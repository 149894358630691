import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'calsoft-bannerimage-text',
  templateUrl: './bannerimage-text.component.html',
  styleUrls: ['./bannerimage-text.component.scss']
})
export class BannerimageTextComponent implements OnInit{
  @Input() datas: any;

  ngOnInit(): void {
    console.log(this.datas);
  }

  handleMouseOver(button: HTMLButtonElement, item: any): void {
    button.style.backgroundColor = '#000';
    button.style.color = '#fff';
    button.style.borderColor = '#000';
  }

  handleMouseOut(button: HTMLButtonElement, item: any): void {
    button.style.backgroundColor = item.customField34;
    button.style.color = item.customField33;
    button.style.borderColor = item.customField33;
  }


}
