import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-banner-anime',
  templateUrl: './banner-anime.component.html',
  styleUrls: ['./banner-anime.component.scss']
})
export class BannerAnimeComponent implements OnInit{
  
  @Input() item: any;
  @Input() img: any;
  @Input() data: any;
  
  public bannerServerPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  currentIndex = 0;
  autoplayInterval: any;
  showImages = true;
  ngOnInit() {
    this.startAutoplay();
  }

  ngOnDestroy() {
    this.stopAutoplay();
  }

  prevSlide() {
    this.currentIndex = (this.currentIndex === 0) ? this.item.images.length - 1 : this.currentIndex - 1;
    this.reloadImages();
  }

  nextSlide() {
    this.currentIndex = (this.currentIndex === this.item.images.length - 1) ? 0 : this.currentIndex + 1;
    this.reloadImages();
  }

  getTransform() {
    return `translateX(-${this.currentIndex * 100}%)`;
  }

  startAutoplay() {
    this.autoplayInterval = setInterval(() => {
      this.nextSlide();
    }, 55000); // Change slide every 5 seconds
  }

  stopAutoplay() {
    clearInterval(this.autoplayInterval);
  }

  openInNewWindow(url: string) {
    window.open(url, '_self');
  }

  reloadImages() {
    this.showImages = false;
    setTimeout(() => {
      this.showImages = true;
    }, 0);
  }

  trackByFn(index: number, item: any) {
    return index;
  }

 
}