<div #carouselContainer class="carousel" tabindex="0" [style.max-width]="maxWidth"
  [style.height]="!maintainAspectRatio ? height : 'auto'">
  <ul #carouselList class="carousel-list" role="listbox" [attr.aria-label]="ariaLabel"
    [style.flex-direction]="orientation === 'rtl' ? 'row-reverse' : 'row'"
    [style.height]="!maintainAspectRatio ? height: 'auto'">
    <ul #carouselList class="carousel-list" role="listbox"
    [style.flex-direction]="orientation === 'rtl' ? 'row-reverse' : 'row'"
    [style.height]="!maintainAspectRatio ? height : 'auto'">
    <li #carouselSlide *ngFor="let slide of slidesList" 
        class="carousel-slide no-touch-action " role="option"
        [style.padding-bottom]="maintainAspectRatio && proportion ? proportion + '%': '0px'"
        [style.height]="!maintainAspectRatio && slideHeight ? slideHeight : '0px'"
        (swipe)="onSwipe($event)"
        (panleft)="onPan($event, carouselSlide)" (panright)="onPan($event, carouselSlide)"
        (panend)="onPanEnd($event, carouselSlide)" (pancancel)="onPanEnd($event, carouselSlide)">
        <ng-container *ngIf="slide.load" [ngTemplateOutlet]="slide.templateRef"></ng-container>
    </li>
</ul>
  </ul>

  <button fxShow fxHide.lt-md *ngIf="!hideArrows" mat-icon-button type="button" tabindex="-1" aria-label="Previous slide" [color]="color"
    [disabled]="!loop && currentIndex == 0" (click)="previous()">
    <mat-icon *ngIf="svgIconOverrides?.arrowBack; else: defaultArrowBack" [svgIcon]="svgIconOverrides.arrowBack">
    </mat-icon>
    <ng-template #defaultArrowBack>
      <mat-icon class="primary-icon">arrow_back_ios</mat-icon>
    </ng-template>
  </button>
  <button fxShow fxHide.lt-md *ngIf="!hideArrows" mat-icon-button
   type="button" tabindex="-1" aria-label="Next slide" [color]="color"
    [disabled]="!loop && currentIndex == slidesList.length - 1" (click)="next()">
    <mat-icon  *ngIf="svgIconOverrides?.arrowForward; else: defaultArrowForward"
      [svgIcon]="svgIconOverrides.arrowForward"></mat-icon>
    <ng-template #defaultArrowForward>
      <mat-icon class="primary-icon">arrow_forward_ios</mat-icon>
    </ng-template>
  </button>

  <div fxShow fxHide.lt-md *ngIf="!hideIndicators" class="carousel-indicators" tabindex="-1"
    [style.flex-direction]="orientation === 'rtl' ? 'row-reverse' : 'row'">
    <button *ngFor="let slide of slidesList; let i = index" type="button" tabindex="-1" mat-mini-fab [color]="color"
      [attr.aria-label]="'Slide #' + i" [disabled]="i == currentIndex" (click)="slideTo(i)"
      (focus)="carouselContainer.focus()"></button>
  </div>
</div>