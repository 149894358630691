import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Constant } from '../constants/constant';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PartnerCartService {


  url = environment.serverAPI6 + "/" + "api/partner/cart";

  url2 = environment.serverAPI6 + "/" + "api/ig/partner/cart";

  cartCount: Response[];

  constructor(
    private http: HttpClient,
    private userService: UserService
    ) {}

    public responseCache1 = new Map();

    private cachedCartCount$: Observable<any>;

    private cachedCartItemCountValue$: Observable<any>;

  addCartPartnerItem(cart,id:any) {
    this.cachedCartCount$ = undefined;
    this.cachedCartItemCountValue$=undefined;
  let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
     
      return this.http.post<Response[]>(`${this.url}/${id}`, cart);
      
    }else{

      cart['cartId']=localStorage.getItem(Constant['CART_ID']);
  
      return this.http.post<Response[]>(`${this.url2}/${id}`, cart);
    }
    
  }

  getPartnerCartValue(id:any){
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN']);
    let response: any = null;
    let value=null;
    if(guestLoginEnabled=="0"||authourized){
      value =this.responseCache1.get(
      `${this.url}/customer/count/${id}`
    );
    }else{
      value =this.responseCache1.get(
        `${this.url2}/customer/count/${id}`
        );
    }

    return value;
  }

  setCartValue(value,id:any) {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN']);
    let response: any = null;
    if(guestLoginEnabled=="0"||authourized){
    this.responseCache1.set(`${this.url}/customer/count/${id}`, value);
    }else{
      this.responseCache1.set(`${this.url2}/customer/count/${id}`, value);
    }
  }

  cartItemCount(id:any): Observable<Response[]> {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
      return this.http.get<any>(`${this.url}/customer/cartCount/${id}`);
    }else{
      let headers = new HttpHeaders({
        [Constant['CART_ID']] :localStorage.getItem(Constant['CART_ID']),
      });
      let options = {
        headers: headers,
      };
      return this.http.get<any>(`${this.url2}/customer/cartCount/${id}`,options);
    }
    
  }

  getCartCountNew(): Observable<any> {
    let authorized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled = localStorage.getItem(Constant['GUEST_LOGIN']);
    const isGuestLoginEnabled = guestLoginEnabled === "0";
  
    let request$: Observable<any>;
  
    if (isGuestLoginEnabled || authorized) {
      request$ = this.http.get<any>(`${this.url}/customer/cartCount`).pipe(shareReplay(1));
    } else {
      const headers = new HttpHeaders({
        [Constant['CART_ID']]: localStorage.getItem(Constant['CART_ID']) || ''
      });
      const options = {
        headers: headers
      };
      request$ = this.http.get<any>(`${this.url2}/customer/cartCount`, options).pipe(shareReplay(1));
    }
  
    return request$;
  }

  cartItemCountValue(id:any): Observable<Response[]> {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
      return this.http.get<any>(`${this.url}/customer/cartCount/${id}`);
    }else{
      let headers = new HttpHeaders({
        [Constant['CART_ID']] :"saas",
      });
      let options = {
        headers: headers,
      };
      return this.http.get<any>(`${this.url2}/customer/cartCount/${id}`,options);
    }
    
  }




  getCount(id) {
    this.cartItemCount(id).subscribe(
      (count) => {
        this.cartCount = count;
      },
      (err) => {}
    );
  }
  getCount1(id) {
    this.cartItemCountValue(id).subscribe(
      (count) => {
        this.cartCount = count;
      },
      (err) => {}
    );
  }

  public getCartItemByUserId(cache: any, page: any, id:any): Observable<any> {
 
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
        return this.http.get<any>(`${this.url}/customer/${id}`);
      }else{
        let headers = new HttpHeaders({
          [Constant['CART_ID']] :localStorage.getItem(Constant['CART_ID']),
        });
        let options = {
          headers: headers,
        };
        return this.http.get<any>(`${this.url2}/customer/${id}`,options);
      }
    
    }

}
