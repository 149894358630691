<div *ngIf="item.customField26 == '1' ">
    <section  #typedSection class="py-36 lg:py-64 w-full table relative  bg-center bg-cover"
    [style.backgroundImage]="'url(' + serverPath1 + item.customField24 + ')'" id="home">
        <div class="absolute inset-0 bg-black opacity-80"></div>
        <div class="container relative">
            <div class="grid grid-cols-1 mt-12" id="textcontainer">
                <h4
                    class="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
                    {{item.title}} <br>{{item.customField4}}<ngx-typed-js *ngIf="isInView"
                        class="typewrite relative text-type-element inline-block" [typeSpeed]="80"
                        [strings]="strings">
                        <span class="typing"></span>
                    </ngx-typed-js>
    
                </h4>
    
                <p class="para">{{item.customField30}}</p>
    
                <div class="relative mt-10">
                    <a
                        class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md" (click)="goToLink(item.customField17)">{{item.customField12}}</a>
                </div>
            </div>
        </div>
    </section>
    </div>
    
    
    
    
    
    <div *ngIf="item.customField26 == '2' "   [style.height]="item.height"  [ngStyle.lt-md]="{'height': item.heightMob}" >
    <div fxLayoutAlign.gt-md="center start"   #typedSection  class="py-37 lg:py-64 w-full table relative  bg-center bg-cover "  [style.backgroundImage]="'url(' + serverPath1 + item.customField24 + ')'" id="home" fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
        <!-- <div fxFlex="2" >
        </div> -->
         <div class="absolute inset-0 bg-black opacity-80"></div> 
        <div fxFlex="40">
    
            <div fxLayout="column" class="container1 ">
    
                <div class="grid grid-cols-1 mt-12" id="textcontainer">
                    <h4   [ngStyle]="{'color': item.customField1}"
                        class="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
                        {{item.title}} <br>{{item.customField4}}<ngx-typed-js *ngIf="isInView"
                            class="typewrite relative text-type-element inline-block" [typeSpeed]="80"
                            [strings]="strings">
                            <span class="typing"></span>
                        </ngx-typed-js>
        
                    </h4>
        
                    <p    [ngStyle]="{'color': item.customField1}" class="para">{{item.customField30}}</p>
        
                    <div class="relative mt-10">
                        <a
                            class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md" (click)="goToLink(item.customField17)">{{item.customField12}}</a>
                    </div>
                </div>
    
            </div>
    
        </div>
    
        <div fxFlex="60"  class="second-image ">
    
           
            <img [src]="serverPath1+item.customField6" [style.height]="item.customField29"  class="imgcss" alt="">
    
        </div>
        <!-- <div fxFlex="2" >
        </div> -->
    
    </div>
    
    
    </div>