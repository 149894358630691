import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactService } from 'src/app/services/contact.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FieldConfig } from 'src/app/shared/component/field.interface';

@Component({
  selector: 'calsoft-step-form',
  templateUrl: './step-form.component.html',
  styleUrls: ['./step-form.component.scss']
})
export class StepFormComponent implements OnInit {
  init: boolean = false;
  @Input() formGroupId: any;
  @Input() layout: any = 2;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  constructor(private contactService:ContactService){

  }
  ngOnInit(): void {
   
   console.log(this.formGroupId);
   
   this.createDynamicForm(this.formGroupId);
  }

  regConfig: any[] = [

  ];

  gaId: any;
  createDynamicForm(formGroupId) {
    this.contactService.getDynmaicForm(formGroupId).subscribe((data) => {

      let value: any = data
      this.regConfig = value;
      this.gaId=this.regConfig[0].gaTrackingId;
      this.formGroupId=formGroupId;
      
      this.init = true;
    });
  }


  submitValue(value: any) {

    this.submit.emit(value);

  
   
  }

  
}
