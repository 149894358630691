<div class="custom-carousel" *ngIf="item.customField34=='1'">
    <owl-carousel-o [options]="LogoSlider" #owlCar class="no-arrow">
        <ng-container *ngFor="let  slide of item.images; let l = index">
            <ng-template carouselSlide>
                <div *ngIf="slide.type==0">
                    <video [style.width]="item.customField33" [ngStyle.lt-md]="{'width': item.customField28}" 
                    controls loop="loop" [ngStyle.lt-md]="{'height': item.heightMob}"
                        [style.height]="item.height" [style.margin-right]="item.gapBetweenImage"
                        [ngStyle.lt-md]="{'margin-right': item.mobileGapBetweenImage}">
                        <source [src]="serverPath + slide.img" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>

                </div>

                <div *ngIf="slide.type==1">
                    <video [style.width]="item.customField33" [ngStyle.lt-md]="{'width': item.customField28}" 
                    oncanplay="this.play()" onloadedmetadata="this.muted = true" autoplay loop="loop"
                        [ngStyle.lt-md]="{'height': item.heightMob}" [style.height]="item.height"
                        [style.margin-right]="item.gapBetweenImage"
                        [ngStyle.lt-md]="{'margin-right': item.mobileGapBetweenImage}">
                        <source [src]="serverPath + slide.img" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>

                </div>

                <div *ngIf="slide.type==2">
                    <video [style.width]="item.customField33" [ngStyle.lt-md]="{'width': item.customField28}" 
                    oncanplay="this.play()" onloadedmetadata="this.muted = true" autoplay muted loop="loop"
                        [ngStyle.lt-md]="{'height': item.heightMob}" [style.height]="item.height"
                        [style.margin-right]="item.gapBetweenImage"
                        [ngStyle.lt-md]="{'margin-right': item.mobileGapBetweenImage}">
                        <source [src]="serverPath + slide.img" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>

                </div>

                <div [ngStyle]="{'display': item.customField12 && item.customField12.length === 0 ? 'none' : 'block'}">
                    <div class="padding-10" *ngIf="item.customField13==1" fxLayoutAlign="center center">
                        <button (click)="goToCategories(slide.catId)" [style.color]="'#ffffff'"
                            [style.border-radius]="item.customField11" class="primary" mat-flat-button>
                            {{item.customField12}}
                        </button>
                    </div>
                    <div class="padding-10" *ngIf="item.customField13==0" fxLayoutAlign="start center">
                        <button (click)="goToCategories(slide.catId)" [style.color]="'#ffffff'"
                            [style.border-radius]="item.customField11" class="primary" mat-flat-button>
                            {{item.customField12}}
                        </button>
                    </div>

                    <div class="padding-10" *ngIf="item.customField13==2">
                        <button (click)="goToCategories(slide.catId)" fxFlex [style.color]="'#ffffff'"
                            [style.border-radius]="item.customField11" class="primary" mat-flat-button>
                            {{item.customField12}}
                        </button>
                    </div>
                </div>


            </ng-template>
        </ng-container>
    </owl-carousel-o>
    <div class="custom-nav" fxShow fxHide.lt-md>
        <div class="owl-prev" (click)="owlCarousel.prev()">
            <mat-icon class="custom-icon primary-icon">keyboard_arrow_left</mat-icon>
        </div>
        <div class="owl-next" (click)="owlCarousel.next()">
            <mat-icon class="custom-icon primary-icon">keyboard_arrow_right</mat-icon>
        </div>
    </div>
</div>


<div class="custom-carousel" *ngIf="item.customField34=='2'">
    <owl-carousel-o [options]="LogoSlider1" #owlCar class="no-arrow">
        <ng-container *ngFor="let  slide of item.images; let l = index">
            <ng-template carouselSlide>

                <div class="vedio"  >
                    <iframe [style.width]="item.customField33" [ngStyle.lt-md]="{'height': item.heightMob}"
                      [ngStyle.lt-md]="{'width': item.customField28}" [style.height]="item.height"
                        [src]="youtubeUrl+slide.customField15 + '?autoplay=0' | safe: 'resourceUrl'" frameborder="0"
                        allow="autoplay"></iframe>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
    <div class="custom-nav" fxShow fxHide.lt-md>
        <div class="owl-prev" (click)="owlCarousel.prev()">
            <mat-icon class="custom-icon primary-icon">keyboard_arrow_left</mat-icon>
        </div>
        <div class="owl-next" (click)="owlCarousel.next()">
            <mat-icon class="custom-icon primary-icon">keyboard_arrow_right</mat-icon>
        </div>
    </div>
</div>
