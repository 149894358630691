import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { PartnerDataService } from 'src/app/services/partner-data.service';


@Component({
  selector: 'calsoft-partner-section',
  templateUrl: './partner-section.component.html',
  styleUrls: ['./partner-section.component.scss']
})
export class PartnerSectionComponent {

  isEmpty:any =false;
  public categoryProducts: any=[];
  public count: any;
  spinnerMain:boolean=false;
  otherProductType: boolean=false;
  @Input() data: any;
  list: any=[];
  public viewCol: number = 25;
  counts: any = [12];
  public page: number = 0;
  mobileSize: number = 4;
  size: any = this.counts[0];

  spinner: boolean;
  search: any = "";

  public sortings = [

    { value: "pageId,DESC", viewValue: "Newest First" },
  ];
    filterMap = new Map<any, any>();
  paths: any = [];
  sortParam: any=this.sortings[0].value;
  mobileScreen: boolean=false;

  public sort: string = this.sortings[0].viewValue;
  blogSize: any;
  previousPage: any=0;
  noProductValue: boolean;


  constructor( private partnerDataService: PartnerDataService, 
    private activatedRoute: ActivatedRoute,  
    @Inject(PLATFORM_ID) private platformId: Object
    ) { }
    
    ngOnInit() {
      this.blogSize=this.data['blogSize']
      
      this.viewCol=100/this.blogSize;

      this.getPartnerPage();
      if (window.innerWidth < 1000) {
      
        this.mobileScreen=true;
  
      }else{
        this.mobileScreen=false;
      }

      
      
        
    }


    pageSize: any;
    config: any={};
    getPartnerPage(){

      let page: any = 0;
      let size: any = 10;
      let sortParam: any = 'partnerDataId' + "," + Constant.Dsc;
      
      this.partnerDataService.getPartnerPage(page, size, sortParam).subscribe(
        res => {
          this.list = res['content'];
          this.count = res["totalElements"];
          this.isEmpty = res["empty"];
          this.pageSize = res["totalPages"];

          console.log(this.list);

             (this.config.currentPage = this.page),
            (this.config.totalItems = this.count),
            (this.config.itemsPerPage = this.size);
            this.getcount();
        },
        error => {
        }
        )
    }

    
  getcount() {

    let value1=  localStorage.getItem('pgs');
      
    }

 

public onPageChanged(event) {

  this.page = event-1;
  this.getPartnerPage();
  

  this.page=event
  this.config = {
    itemsPerPage: this.size,
    currentPage: this.page,
    totalItems: this.count,
  };

    this.previousPage=event;
   
  

  }

  public onScroll() {
    if (window.innerWidth<850 && this.page<this.pageSize) {
      this.page++;

      this.spinner = true;
      if (this.list.length <this.count ) {

          this.partnerDataService.getPartnerPage(
              this.page,
              this.mobileSize,
              this.sortParam
            )
            .subscribe((res) => {
              this.spinner = false;
              this.list = [ ...this.list, ...res['content']];

            });
        
      } else {
        this.spinner = false;
        //this.noProduct();
        this.noProductValue=true;
      }
    }
  }


}
