import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from 'ngx-owl-carousel-o';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';

import { environment } from 'src/environments/environment';


@Component({
  selector: 'calsoft-image-carousel-two-hover',
  templateUrl: './image-carousel-two-hover.component.html',
  styleUrls: ['./image-carousel-two-hover.component.scss']
})
export class ImageCarouselTwoHoverComponent implements OnInit{
  @Input() brands: any[] = [];

  @Input() item: any;
  width:any;
  height:any;
  gapBetweenImage:any;
  customField12:any;
  customField11:any;

  public bannerServerPath = environment.commonImageApi + '/commonSlider/';

  public LogoSliderConfig: any;
  public LogoSliderConfig1: any;

  constructor(  private commonService: CommonService,
    private categoryService: CategoryService) { }

    LogoSlider = {
      ...sliderOpt,
      nav: false,
      dots: false,
      items: 3,
      margin: 20,
      loop: false,
      autoHeight: true,
      autoplay: true,
      
      responsive: {
          767: {
              items: 5
          },
          576: {
              items: 4
          },
          480: {
              items: 3
          },
          0: {
              items: 2
          }
      }
  }

  LogoSlider1 = {
    ...sliderOpt,
    nav: false,
    dots: true,
    items: 3,
    margin: 20,
    loop: false,
    autoHeight: true,
    autoplay: true,
    
    responsive: {
        767: {
            items: 5
        },
        576: {
            items: 4
        },
        480: {
            items: 3
        },
        0: {
            items: 2
        }
    }
}
  value:any;
  value2:any;
  ngOnInit(): void {
    
    this.value2=20*2;

    this.value=100-this.value2;



    
    if ( this.item.gapBetweenImage.endsWith('px')) {
      this.item.gapBetweenImage= this.item.gapBetweenImage.slice(0, -2); 
    }
  
    
    this.LogoSlider.margin=this.item.gapBetweenImage;
    this.LogoSliderConfig=this.LogoSlider;

    this.LogoSlider1.margin=this.item.gapBetweenImage;
    this.LogoSliderConfig1=this.LogoSlider1;

    this.LogoSlider1.responsive[767].items = this.item.customField14;
    this.LogoSlider1.responsive[576].items = this.item.customField14;
    
    this.LogoSlider.responsive[767].items = this.item.customField14;
    this.LogoSlider.responsive[576].items = this.item.customField14;

    this.LogoSlider1.responsive[480].items = this.item.customField33;
    this.LogoSlider1.responsive[0].items = this.item.customField33;
    

  }


customOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 1
      },
      760: {
        items: 1
      },
      1000: {
        items: 1
      }
    },
    nav: true
  }

  goToCategory(menu) {
    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);

  }

  
  imageNavigation(id){

    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  catId:any;
 
  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }


  link(url: string) {
    window.open(url, '_self');
  }

  @ViewChild('owlCar') owlCarousel: CarouselComponent;

 

  LogoSlider2 = {
    nav: false,
    dots: true,
    items: 1,
    margin: 20,
    loop: false,
    autoHeight: true,
    autoplay: true,
    
    responsive: {
        767: {
            items: 1
        },
        576: {
            items: 1
        },
        480: {
            items: 1
        },
        0: {
            items: 1
        }
    }
}

LogoSlider3 = {
  nav: false,
  dots: true,
  items: 1,
  margin: 20,
  loop: false,
  autoHeight: true,
  autoplay: true,
  
 responsive: {
        767: {
            items: 5
        },
        576: {
            items: 4
        },
        480: {
            items: 3
        },
        0: {
            items: 2
        }
    }
}
  

}
export const sliderOpt = {
  items: 1,
  loop: true,
  margin: 0,
  responsiveClass: true,
  nav: true,
  navText: [ '<i class="icon-angle-left">', '<i class="icon-angle-right">' ],
  dots: true,
  smartSpeed: 400,
  autoplay: false,
  autoplayTimeout: 15000
}
