import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calosft-mobileicon',
  templateUrl: './mobileicon.component.html',
  styleUrls: ['./mobileicon.component.scss']
})
export class MobileiconComponent {

  public serverPath = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() data: any;

  @Input() img: any;
  
  @Input() index: any;
  customField7: any;
  height: any;


  constructor(
    private sanitizer: DomSanitizer,
    private commonService: CommonService ) {}


    ngOnInit() {
    

    }
    sanitizeHtml(html: string): SafeHtml {
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
  
    sanitizeStyle(style: string): SafeStyle {
      return this.sanitizer.bypassSecurityTrustStyle(style);
    }

  
  imageNavigation(id){


    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  link(url) {

    this.commonService.goToLink(url)
  }


  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      this.commonService.goToLink(url)
    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }
  
}
