import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-plan-compare-type2',
  templateUrl: './plan-compare-type2.component.html',
  styleUrls: ['./plan-compare-type2.component.scss']
})
export class PlanCompareType2Component implements OnInit{
  isChecked: boolean = false;
  pricedetail: any;

  toggleChanged() {
    console.log('Slide toggle changed. New value:', this.isChecked);
  }

  @Input() item: any;
  @Input() data: any;

  constructor( private commonService: CommonService,) {

  }
  
  ngOnInit() {   
    this.pricedetail = JSON.parse(this.item.customField31);
  }      

  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      this.commonService.goToLink(url)
    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }


}
