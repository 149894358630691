<div>
    <div fxLayout="column">

        <div fxLayout="row wrap">

            <div *ngFor="let img of item.images; let i = index" fxFlex="33.33" fxFlex.xs="85" fxLayout="row">

                <img [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}"
                    [src]="serverPath+img.img"
                    (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)"
                    [alt]="img.altImage || 'images'" style="width: 100%;">

                <div class="padding-10" fxLayoutAlign="start center">
                    <mat-radio-button (change)="radioChangedeliveryOption(img.img)"
                        [checked]="img.img == optionControl.value" [value]="optionControl.value">
                    </mat-radio-button>
                </div>

            </div>

        </div>

    </div>
</div>