import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { Product } from 'src/app/models/app.models';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-common-product-details-grid',
  templateUrl: './common-product-details-grid.component.html',
  styleUrls: ['./common-product-details-grid.component.scss']
})
export class CommonProductDetailsGridComponent implements OnInit {
  @Input() product: any;
  @Input() type: any;
  @Input() showCartIcon: any;
  @Input() data: any;
  @Input() height: any;

  @Input() name: any;
  loaded:any=false;


  @Input() width: any;

  @Input() heightMobile: any;

  @Input() widthMobile: any;

  @Input() showCartIncremented: any;

  @Input() showConfigurableDropdown: any;


  @Input() imageType: any;
  
  public serverPath = environment.ImageAPI;
  countryCode: string;
  dropDownValue = new UntypedFormControl('');
  configurationDropDownList = new Map<any, any>();
  configurableProductDropdown: any = {};
  configurableProductActive: boolean = false;
  configurableProductId: any = 0;
  configProduct: any = {};
  configProductSelected: any = {};
  price: any;
  specialPrice: any;
  maintainStockIndConfProduct: any;
  manageImageSeperately: any;
  managePriceSeperately: any;
  manageNameSeperately:any;
  productIdAddedInCart: any = {};
  qty: any;
  manageStock: any;
  isInStock: any;
  imageList: any = [];
  averageRating: any;
  calculateAvgRating: any;
  customProductElament: any = [];
  encryptedProductName: string;
  discountPercentage: any;
  public image: any;
  description: any;
  configProductId: any = 0;
  configurableProductObject: any;
 
  prescriptionRequired: boolean = false;
  originalImage: any;
  showNewConfig: boolean = false;
  units: string;
  showExclusivePrice: any;
  entityId: any;
  config: any;
  variant1Value: any;
  variant1Name: string;
  variant2Value: string;
  variant2Name: string;
  disablePrice:any=1;
  displayNameFully: boolean=false;
  productNameCenter: any=0;
  showAnimation:boolean=false;
  subimage: any;
  b2bPricingEnabled:any;
  displayMrpPrice:any=0;
  inclTaxEnabled: any=0;
  productPriceCenter: boolean = false;
  outofstockEnabled: any=0;
  categoryPageProductNameHeight: any;
  categoryPageProductNameMobHeight: any;
  constructor(
    private commonService: CommonService,
    private router: Router,
    public cartServices: CartService,
    private userService:UserService

  ) { }
  courseList = this.userService.getCourseValue();
	list:any=[];
  subscribed: boolean=false;
  ngOnInit(): void {

    if (this.courseList == undefined) {
      this.courseList = this.userService.getCourseValue();
    } else {

      if (this.courseList.length > 0) {


        let recursiveIndex = this.courseList.findIndex(orderItem => orderItem.productId ==this.product.entityId);

     //   console.log(recursiveIndex);


        if (recursiveIndex > -1) {
          this.courseList[recursiveIndex].productId

          this.subscribed = true;
          localStorage.setItem('subscribed', JSON.stringify(this.subscribed));
          
        }




      }

    }

    if(this.name==undefined){
      this.name='';
    }
  
    this.configProductSelected=this.product;``
    this.config=this.product
    this.entityId=this.product['entityId'];
    this.productIdAddedInCart = this.product;
    this.serverPath = this.height == 200 ? environment.ImageAPI : environment.ImageAPI1;
    this.configurableProductObject = this.product;
    this.configProductId = 0;
    this.configurationDropDownList = new Map<any, any>();
    //this.disablePrice=this.data['disablePrice']
    this.configurableProductDropdown = {}
    this.name = this.configurableProductObject.name;
    this.description = this.product['productDescription']
    this.price = this.product['price']
    this.specialPrice = this.product['specialPrice']
    this.showExclusivePrice=this.data['showExclusivePrice']
    this.productNameCenter=this.data['productNameCenter']
    this.inclTaxEnabled=this.data['inclTaxEnabled'],
    this.productPriceCenter=this.data['productPriceCenter']
    this.categoryPageProductNameHeight=this.data['categoryPageProductNameHeight']
    this.categoryPageProductNameMobHeight=this.data['categoryPageProductNameMobHeight']
    this.displayMrpPrice=this.data['displayMrpPrice']
    
    if(this.data['displayNameFully']!=null){

      if(this.data['displayNameFully']=="1"){
        this.displayNameFully=true;
      }
      
    }

    if(this.data['outofstockEnabled']!=null){

      if(this.data['outofstockEnabled']=="1"){
        this.outofstockEnabled=true;
      }
      
    }

    // if(this.data['productNameCenter']!=null){

    //   if(this.data['productNameCenter']=="1"){
    //     this.productNameCenter=true;
    //   }
      
    // }

    if(this.data['showAnimation']!=null){

      if(this.data['showAnimation']=="1"){
        this.showAnimation=true;
      }
      
    }
    
    if(this.product['isCustomizedValue']!=null){
      this.b2bPricingEnabled=localStorage.getItem(Constant.B2B_USER)==null?0:localStorage.getItem(Constant.B2B_USER);

      if (this.b2bPricingEnabled==1){
        if(this.product['isCustomizedValue']['Whole Sale Price']!=undefined){

          this.price = this.product['isCustomizedValue']['Whole Sale Price'];
    
    
          this.specialPrice = this.product['isCustomizedValue']['Whole Sale Discount Price'];
         
         
          if(this.price==0.0){
            this.price=this.product['price']
            this.specialPrice=this.product['specialPrice']
            this.discountPercentage = this.product['discountPercentage'];
          }else{
            if(this.product['isCustomizedValue']['Whole Sale Discount Percentage']!=undefined){
              this.discountPercentage=this.product['isCustomizedValue']['Whole Sale Discount Percentage']
            }
          }
        }

        if(this.product['isCustomizedValue']['Whole_Sale_Price']!=undefined){

          this.price = this.product['isCustomizedValue']['Whole_Sale_Price'];
    
    
          this.specialPrice = this.product['isCustomizedValue']['Whole_Sale_Discount_Price'];
         
         
          if(this.price==0.0){
            this.price=this.product['price']
            this.specialPrice=this.product['specialPrice']
            this.discountPercentage = this.product['discountPercentage'];
          }else{
            if(this.product['isCustomizedValue']['Whole_Sale_Discount_Percentage']!=undefined){
              this.discountPercentage=this.product['isCustomizedValue']['Whole_Sale_Discount_Percentage']
            }
          }
        }

      }


    }

    if( this.showExclusivePrice==1){
      if (this.product['isCustomizedValue'] != null) {
      if( this.product['isCustomizedValue']
      ['specialPriceWithoutTaxamount'] != null){
        this.specialPrice= this.product['isCustomizedValue']
        ['specialPriceWithoutTaxamount'];
      }
      if( this.product['isCustomizedValue']
      ['priceWithoutTaxamount'] != null){
        this.price= this.product['isCustomizedValue']
        ['priceWithoutTaxamount'];
      }
    }
    }
    this.averageRating = this.product['averageRating'];
    this.calculateAvgRating = this.product['calculateAvgRating'];
    this.image = null;
    this.imageList = [];
    this.imageList = this.product['images'];
 
    // if(this.imageList[1]!=null){
      
    // }else{
    //   this.list.splice(1, 1);
    // }
   


    let object={

      width:'300',
      height:'300',
      url:this.image,
      pivot:{
        related_id:'76',
        upload_file_id:12321
      }
      }
  
      this.list.push(object);

      let object1={

        width:'300',
        height:'300',
        url:this.subimage,
        pivot:{
          related_id:'76',
          upload_file_id:12321
        }
        }

      this.list.push(object1);

      if(this.imageList!=undefined){
        if(this.imageList[0]!=null){

          if(this.imageList[0]!=''){
            this.image = this.serverPath + "/" + this.imageList[0];
            if(this.imageList[1]!=undefined){
              this.subimage=this.serverPath + "/" + this.imageList[1];
            }else{
              this.subimage = this.serverPath + "/" + this.imageList[0];
            }
           
          }else{
            this.image="assets/images/products/noImage/no-image.png";
          this.subimage="assets/images/products/noImage/no-image.png";
          }
         
        }else{
          
          this.image="assets/images/products/noImage/no-image.png";
          this.subimage="assets/images/products/noImage/no-image.png";
        }
       
      }

    this.list[0].url=this.image;

    this.list[1].url=this.subimage;
    
    this.originalImage = this.product['productImageUrl'];
    
    this.qty = this.product['qty'];
    this.manageStock = this.product['manageStock'];
    this.isInStock = this.product['isInStock'];

    
    if(this.product['units']=='sqft'){
      this.units='/sqft'
      }
    this.discountPercentage = this.product['discountPercentage'];
    if (this.product['isCustomizedValue'] != null) {

      if (this.product['isCustomizedValue']['Prescription Required'] != null && this.product['isCustomizedValue']['Prescription Required'] != undefined && this.product['isCustomizedValue']['Prescription Required'] != '') {

        this.prescriptionRequired = this.product['isCustomizedValue']['Prescription Required'] == 1 ? true : false;
      }

      if (this.product['isCustomizedValue']['Prescription_Required'] != null && this.product['isCustomizedValue']['Prescription_Required'] 
      != undefined && this.product['isCustomizedValue']['Prescription_Required'] != '') {

        this.prescriptionRequired = this.product['isCustomizedValue']['Prescription_Required'] == 1 ? true : false;
      }

    }
    if ((this.product['type'] == "Configurable"||this.product['type'] == "Variants")
    
    && this.product['configurableProductDropdown'] != null) {

    
      this.configurableProductActive = true;
      this.configurableProductDropdown = this.product['configurableProductDropdown'];
      this.maintainStockIndConfProduct = this.product['maintainStockIndConfProduct'];
      this.manageImageSeperately = this.product['maintainImageIndConfProduct'];
      this.managePriceSeperately = this.product['maintainPriceIndConfProduct'];
      
      if (this.product['isCustomizedValue'] != null) {
        if (this.product['isCustomizedValue']
        ['manageNameSeperately'] != null) {
      this.manageNameSeperately = this.product['isCustomizedValue']['manageNameSeperately'];

    }
  }else{

      this.manageNameSeperately =0;
    }

      
      this.configProductId = this.product['entityId']
      let i = 0;


      if (this.configurableProductDropdown['new'] != undefined) {

        this.configurationDropDownList = JSON.parse(this.configurableProductDropdown['new']);
        this.configProduct = this.product['configProduct'];
        this.configurableProductId = this.configurationDropDownList[this.firstIndex]
        ['recursuive'][this.secondIndex].productId;
        this.configProductSelected = JSON.parse(this.configProduct[this.configurableProductId]);
        this.setConfigProductItem();
        this.showNewConfig = true;
      } else {
        delete this.configurableProductDropdown['configAttributeType']

        for (let key of Object.keys(this.configurableProductDropdown)) {
          if (i == 0) {
            let value = this.configurableProductDropdown[key];
            this.configProduct = this.product['configProduct'];
            this.variant1Name=key;
            this.getConfigProductId(value);
            // this.dropDownValue.patchValue(value);
            // this.configurableProductId = value.split("#")[1];

            //

            // this.configProductSelected = JSON.parse(this.product['configProduct'][this.configurableProductId]);

          }

          let valueList = [];
          valueList = this.configurableProductDropdown[key].split(',')


          this.configurationDropDownList.set(key, valueList)
          i++;
        }
      }



    }

    this.loaded=true;

  }

  // productShow(product) {
  //   if (this.configurableProductObject['type'] == "Configurable"
  //    && this.configurableProductObject['configurableProductDropdown'] != null) {
  //     this.commonService.productNavigation(this.config  )
  //   } else {

  //     if (this.router.url.includes('/product/')) {


  //       this.commonService.productNavigations(product)



  //     } else {
  //       this.commonService.productNavigation(this.config)
  //     }

  //   }

  // }

  productShow(product) {
    if(!this.subscribed){
    if ((this.configurableProductObject['type'] == "Configurable"||
    this.configurableProductObject['type'] == "Variants Subscription" ||
    this.configurableProductObject['type'] == "Variants")
     && this.configurableProductObject['configurableProductDropdown']
      != null) {
    
      this.configurableProductObject.configId=this.configurableProductId;
      this.commonService.productNavigation(this.configurableProductObject)
    }else{
      this.commonService.productNavigation(product)
    }
  }else{
    this.goToCourse(product)
  }

  }


  indexConfig: any = 0;
  firstIndex: any = 0;
  secondIndex: any = 0;

  getConfigProductIdNew(event, i, value, index) {
    if (event.isUserInput != false) {
      this[index] = i;
      this.configurableProductId = this.configurationDropDownList[this.firstIndex]
      ['recursuive'][this.secondIndex].productId;
      this.configProductSelected = JSON.parse(this.configProduct[this.configurableProductId]);
      this.setConfigProductItem();
    }
  }

  getConfigProductId(event) {

    //  let value = event.split("#")[0];
    if (event.includes(",")) {
      event = event.split(",")[0];
    }
    this.configurableProductId = event.split("#")[1];

    if (this.configurableProductId.includes(",")) {
      this.configurableProductId = this.configurableProductId.split(",")[0];
    }

    this.configProductSelected = JSON.parse(this.configProduct[this.configurableProductId]);

    this.product = this.configProductSelected;
    this.dropDownValue.patchValue(event);

    this.variant1Value=event.split("#")[0];


    this.variant2Value=''
    this.variant2Name=''
    this.setConfigProductItem();




  }


  setConfigProductItem() {

    if (this.manageNameSeperately == 1) {
      this.name = this.configProductSelected['name'];
    }

   
    if (this.managePriceSeperately == 1) {
      this.price = this.configProductSelected['price'];
      this.specialPrice = this.configProductSelected['specialPrice'];
      this.discountPercentage = this.configProductSelected['discountPercentage'];

         
      if (this.configProductSelected['isCustomizedValue'] != null) {
        this.b2bPricingEnabled=localStorage.getItem(Constant.B2B_USER)==null?0:localStorage.getItem(Constant.B2B_USER);
        if(this.b2bPricingEnabled==0){
          this.price = this.configProductSelected['price'];
          this.specialPrice = this.configProductSelected['specialPrice'];
          this.discountPercentage = this.configProductSelected['discountPercentage'];
        }else{
  
          if(this.configProductSelected['isCustomizedValue']['Whole Sale Price']!=undefined){
  
          
          this.price = this.configProductSelected['isCustomizedValue']['Whole Sale Price'];
  
          if(this.price==0.0){
            this.price=this.configProductSelected['price']
          }
         
        this.specialPrice = this.configProductSelected['isCustomizedValue']['Whole Sale Discount Price'];
        if(this.specialPrice==0.0){
          this.specialPrice=this.configProductSelected['specialPrice']
        } 
  
        if(this.configProductSelected['isCustomizedValue']['Whole Sale Discount Percentage']!=null){
          this.discountPercentage = parseFloat(this.configProductSelected['isCustomizedValue']['Whole Sale Discount Percentage']);
        }
        
        
        }

        if(this.configProductSelected['isCustomizedValue']['Whole_Sale_Price']!=undefined){
  
          
          this.price = this.configProductSelected['isCustomizedValue']['Whole_Sale_Price'];
  
          if(this.price==0.0){
            this.price=this.configProductSelected['price']
          }
         
        this.specialPrice = this.configProductSelected['isCustomizedValue']['Whole_Sale_Discount_Price'];
        if(this.specialPrice==0.0){
          this.specialPrice=this.configProductSelected['specialPrice']
        } 
  
        if(this.configProductSelected['isCustomizedValue']['Whole_Sale_Discount_Percentage']!=null){
          this.discountPercentage = parseFloat(this.configProductSelected['isCustomizedValue']['Whole_Sale_Discount_Percentage']);
        }
        
        
        }

        }
      }
    }else{
      this.discountPercentage = parseFloat(this.configProductSelected['discountPercentage']);
    }

    if(this.product['type'] == "Variants"){
      if (this.configProductSelected['isCustomizedValue'] != null) {

        if (this.configProductSelected['isCustomizedValue']['Prescription Required'] 
        != null && this.product['isCustomizedValue']['Prescription Required'] != undefined && 
        this.configProductSelected['isCustomizedValue']['Prescription Required'] != '') {
  
          this.prescriptionRequired = this.configProductSelected['isCustomizedValue']['Prescription Required'] == 1 ? true : false;
        }
      }
  
    }
 
    if(this.showExclusivePrice==undefined){
      this.showExclusivePrice=0;
    }
    if( this.showExclusivePrice==1){
      if (this.configProductSelected['isCustomizedValue'] != null) {
      if( this.configProductSelected['isCustomizedValue']
      ['specialPriceWithoutTaxamount'] != null){
        this.specialPrice= this.configProductSelected['isCustomizedValue']
        ['specialPriceWithoutTaxamount'];
      }
      if( this.configProductSelected['isCustomizedValue']
      ['priceWithoutTaxamount'] != null){
        this.price= this.configProductSelected['isCustomizedValue']
        ['priceWithoutTaxamount'];
      }
    }
    } 
    let orginalProduct=this.productIdAddedInCart;
    this.productIdAddedInCart=this.configProductSelected;
    if (this.maintainStockIndConfProduct == 1) {
      this.qty = this.configProductSelected['qty'];
      this.manageStock = this.configProductSelected['manageStock'];
      this.isInStock = this.configProductSelected['isInStock'];
      this.productIdAddedInCart = this.configProductSelected;
      this.averageRating = this.configProductSelected['averageRating'];
      this.calculateAvgRating = this.configProductSelected['calculateAvgRating'];

    }else{
      this.productIdAddedInCart.qty = orginalProduct['qty'];
      this.productIdAddedInCart.manageStock = orginalProduct['manageStock'];
      this.productIdAddedInCart.isInStock = orginalProduct['isInStock'];
      this.productIdAddedInCart = orginalProduct;
      this.productIdAddedInCart.averageRating = orginalProduct['averageRating'];
      this.productIdAddedInCart.calculateAvgRating = orginalProduct['calculateAvgRating'];
    }

    if (this.manageImageSeperately == 1) {


      if (this.configProductSelected['productImageUrl'] != 'no_selection') {
        this.image = this.serverPath + "/" + this.configProductSelected['productImageUrl'];
        this.originalImage = this.configProductSelected['productImageUrl'];
      } else {
        this.image = this.configProductSelected['productImageUrl'];
        this.originalImage = this.configProductSelected['productImageUrl'];

      }
      
      this.imageList = this.configProductSelected['images'];

     

 
      if(this.imageList!=undefined){
      if(this.imageList[0]!=null){

        if(this.imageList[0]!=''){
          this.image = this.serverPath + "/" + this.imageList[0];
  
          if(this.imageList[1]!=undefined){
            this.subimage=this.serverPath + "/" + this.imageList[1];
          }else{
            this.subimage = this.serverPath + "/" + this.imageList[0];
          }
          
        }else{
          this.image="assets/images/products/noImage/no-image.png";
        this.subimage="assets/images/products/noImage/no-image.png";
        }
       
      }else{
        
        this.image="assets/images/products/noImage/no-image.png";
        this.subimage="assets/images/products/noImage/no-image.png";
      }
    }

      

      this.list[0].url=this.image;
      this.list[1].url=this.subimage;

      
      
    }


  }
  goToCourse(product) {

    this.commonService.goToCourse(product);


  }
  



  }


