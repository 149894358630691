<mat-vertical-stepper [linear]="isLinear" #stepper>
  <div *ngFor="let item of img.slice(0, 5); let i = index">
    <mat-step [stepControl]="item.customField22">
      <ng-template matStepLabel>{{item.customField1}}</ng-template>
      <calsoft-step-form (submit)="submitForm($event, i)" [formGroupId]="item.customField22">
      </calsoft-step-form>
    </mat-step>
  </div>
  <!-- Final step for submission -->
  <mat-step>
    <ng-template matStepLabel>Final Submission</ng-template>
    <button mat-raised-button color="primary" (click)="finalSubmit()">Submit</button>
  </mat-step>
</mat-vertical-stepper>
