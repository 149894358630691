import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoldRateService {

  constructor(private http:HttpClient) { }

  url = environment.serverAPI4 + "/api/ig/goldLiveRate/dropdown";


  getAllGoldLiveRate(): Observable<Object> {
    return this.http.get(`${this.url}`);
  }

}