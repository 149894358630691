<div class="container mainClass" fxLayout="row" fxLayoutWrap="wrap">
  <div (click)="viewPartner(item.tenantId,item.storeName)" class="pointer padding-5" *ngFor="let item of partnerList"
    fxFlex="25" fxFlex.xs="50">
    <div class="images">
      <div fxLayout="row" fxLayoutAlign="start center">
 
       <calsoft-partner-logo [data]="data" [src]="'logo/logo.png'" [tenantId]="item.tenantId" ></calsoft-partner-logo>
      </div>
    </div>

    <div class="padding-5">

    </div>

    <div class="productPrice headers" fxLayout="row" fxLayoutAlign="start center">
      <span>{{item.storeName}}</span>
    </div>

    <div *ngIf="item?.rating.length>0" class="productPrice" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="primary-icon">stars</mat-icon>
      <p class="padding-5">{{item.rating}}</p>
    </div>

    <div class="productPrice Cuisines" fxLayout="row" fxLayoutAlign="start center">
      <span>{{item.cuisines}}</span>
    </div>

    <div class="productPrice location" fxLayout="row" fxLayoutAlign="start center">
        <p>{{item.primaryOutlet}}</p>
      </div>
  </div>
</div>
