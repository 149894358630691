import { Component, Inject, Input, OnInit } from '@angular/core';
import { DoctorService } from 'src/app/services/doctor.service';
import { environment } from 'src/environments/environment';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-slot-detail',
  templateUrl: './slot-detail.component.html',
  styleUrls: ['./slot-detail.component.scss']
})
export class SlotDetailComponent  implements OnInit {
  bookerId: any;
  productData: any;
  data: any;

  constructor( 
  
    private dialogRef: MatDialogRef<SlotDetailComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
    public doctorService:DoctorService) { 
    console.log(data);
    
    this.bookerId=data.id;
    this.productData=data.productData
    this.data=data.data
  
}
  ngOnInit(): void {
   
  }

  close(event){
    this.dialog.closeAll();
  }

}
