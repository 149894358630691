import { UntypedFormGroup, UntypedFormControl, AbstractControl, ValidatorFn } from '@angular/forms';

export function emailValidator(control: UntypedFormControl): { [key: string]: any } {
  var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {

  return (group: UntypedFormGroup) => {
    let password = group.controls[passwordKey];
    let passwordConfirmation = group.controls[passwordConfirmationKey];
    if (password.value !== passwordConfirmation.value) {
      return passwordConfirmation.setErrors({ mismatchedPasswords: true })
    }
  }
}

export function noWhitespaceValidator(control: UntypedFormControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { 'required': true };
}
export function dateComparision(fromDate: any, toDate: any) {
  return (group: UntypedFormGroup) => {
    let startDate = group.controls[fromDate];
    let endDate = group.controls[toDate];
    if (endDate.value < startDate.value) {
      return endDate.setErrors({ mismatchedDate: true })
    }
  }


}
export function validateAllFormFields(formGroup: UntypedFormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    control.markAsTouched({ onlySelf: true });
  });
}

export function conditionalValidator(condition: (() => boolean), validator: ValidatorFn): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!condition()) {
      return null;
    }
    return validator(control);
  }
}

export function constructRule(inputJson: any) {
  inputJson = inputJson.statement;
  inputJson = inputJson.groups[0];
  return constructRuleStmt(inputJson, '', false, '');
}

export function constructRuleStmt(inputJson: any, ruleStmt: string, isGroupNestedGroup: boolean, conjunctor: string) {
  if (conjunctor === 'AND') {
    conjunctor = '&&';
  }
  if (conjunctor === 'OR') {
    conjunctor = '||';
  }
  if (isGroupNestedGroup) {
    ruleStmt = ruleStmt + ' ' + conjunctor + ' ';
    ruleStmt = ruleStmt + ' ( ';
  }

  if (inputJson.hasOwnProperty('conditions')) {
    const jsonArray = (inputJson.conditions as Array<any>);
    for (let i = 0; i < jsonArray.length; i++) {
      const jsonObject = jsonArray[i] as object;
      // @ts-ignore
      ruleStmt = ruleStmt + '$.' + jsonObject.filedName + ' ' + jsonObject.operators + ' "' + jsonObject.fieldValue + '"';
      if (i !== jsonArray.length - 1) {
        let conjunctorTemp = inputJson.conjunctor;
        if (conjunctorTemp === 'AND') {
          conjunctorTemp = '&&';
        }
        if (conjunctorTemp === 'OR') {
          conjunctorTemp = '||';
        }
        ruleStmt = ruleStmt + ' ' + conjunctorTemp + ' ';
      }
    }
  }
  if (inputJson.hasOwnProperty('groups')) {
    const jsonArray = (inputJson.groups as Array<any>);
    for (let i = 0; i < jsonArray.length; i++) {
      const jsonObject = jsonArray[i] as object;
      if(jsonObject['conditions'].length>0){
      
        ruleStmt = constructRuleStmt(jsonObject, ruleStmt, true, inputJson.conjunctor);
        ruleStmt = ruleStmt + ' ) ';
      }
    
    }
  }
  return ruleStmt;
}

export const trimValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (control.value.startsWith(' ')) {
    return {
      'trimError': { value: 'control has leading whitespace' }
    };
  }
  if (control.value.endsWith(' ')) {
    return {
      'trimError': { value: 'control has trailing whitespace' }
    };
  }
  return null;
};

export function download(data: any) {


  //var filename=headers['filename'];
  if (data.headers.get('filename') != null) {
    var filename = data.headers.get('filename');
  }
  else {
    filename = 'local_.xls';
  }
  var contentType = data.headers.get('content-type');
  var linkElement = document.createElement('a');
  try {
    var blob = new Blob([data.body], { type: contentType });
    var url = window.URL.createObjectURL(blob);

    linkElement.setAttribute('href', url);
    linkElement.setAttribute("download", filename);

    var clickEvent = new MouseEvent("click", {
      "view": window,
      "bubbles": true,
      "cancelable": false
    });
    linkElement.dispatchEvent(clickEvent);
  } catch (ex) {
  }
}

export function downloadImage(data: any,fileName) {


  //var filename=headers['filename'];
 
   var filename = fileName;
   var contentType = data.headers.get('content-type');
  var linkElement = document.createElement('a');
  try {
    var blob = new Blob([data.body], { type: contentType });
    var url = window.URL.createObjectURL(blob);

    linkElement.setAttribute('href', url);
    linkElement.setAttribute("download", filename);

    var clickEvent = new MouseEvent("click", {
      "view": window,
      "bubbles": true,
      "cancelable": false
    });
    linkElement.dispatchEvent(clickEvent);
  } catch (ex) {
  }
}



export function downloads(data: any,fileName) {
  var filename = fileName;
  var contentType = data.headers.get('content-type');
  var linkElement = document.createElement('a');
  try {
    var blob = new Blob([data.body], { type: contentType });
    var url = window.URL.createObjectURL(blob);

    linkElement.setAttribute('href', url);
    linkElement.setAttribute("download", filename);

    var clickEvent = new MouseEvent("click", {
      "view": window,
      "bubbles": true,
      "cancelable": false
    });
    linkElement.dispatchEvent(clickEvent);
  }
  catch (ex) {
  }
}


export function view(data: any,fileName) {
  var filename = fileName;
  var contentType = data.headers.get('content-type');
  var linkElement = document.createElement('a');
  try {
    var blob = new Blob([data.body], { type: contentType });
    var url = window.URL.createObjectURL(blob);

    return url;

  
  }
  catch (ex) {
  }
}

export function getPageSize(size) {
  if (size >= 10) {
    if (size > 10) {
      return [5, 10, 15];
    }
    else if (size <= 10 && size > 5) {
      return [5, 10];
    }
  }
  else if (size <= 10 && size > 5) {
    return [5, 10];
  }
  else {
    return null;
  }

}


export function resource(size) {



  // let object = {
  //   read: privilegeMapping[0].hasReadPermission,
  //   write:
  // }



}


export class ResourceAccess {
  static MigrateDatamappingService(MigrateDatamappingService: any) {
    throw new Error('Method not implemented.');
  }
  public static CREDIT_MEMO = 'creditMemo';
  public static ORDER = 'order';
  public static INVOICE = 'invoice';
  public static SHIPMENT = 'shipment';
  public static TAX = 'tax';
  public static PROFILE = 'profile';
  public static ADMIN_CUSTOMER = 'adminCustomer';
  public static PRODUCT = 'product';
  public static CATEGORY = 'category';
  public static ATTRIBUTE = 'attribute';
  public static ATTRIBUTE_SET = 'attributeSet';
  public static REVIEW = 'adminReview';
  public static CATALOG_SEARCH_QUERY = 'catalogSearchQuery';
  public static CUSTOMER = 'adminCustomer';
  public static ACCESS = 'userRole';
  public static ACCESS_SET = 'accessSet';
  public static CATALOG_RULE = 'catalogRule';
  public static NEWS_LETTER_SUBSCRIBER = 'adminNewsletterSubscriber';
  public static CMS = 'cmsPage';
  public static ABANDONED_CART = 'abandonedCart';
  public static CART_PRODUCT = 'cartProduct';
  public static CURRENCY = 'adminCurrency';
  public static INDEX = 'Index';
  public static SHIPPING_TABLE_RATE = 'adminShippingTablerate';
  public static ORDER_STATUS = 'orderStatus';
  public static CONFIG = 'adminConfig';
  public static DELIVERY_COUNTRY = 'deliveryCountry';
  public static PRODUCT_CONFIG = 'productCustomization';
  public static COUPON = 'adminCoupon';
  public static PINCODE = 'pincode';
  public static BANNER = 'coreConfigSlider';
  public static TRANSLATE = 'coreTranslate';
  public static CONSULTATION = 'consultation';
  public static LAYOUT = 'layout';
  public static BANNER_ADVANCED = 'coreConfigSliderAdvanced';
  public static UNITS = 'units';
  public static LANGUAGE = 'language';
  public static PAYMENT_MODE = 'paymentMode';
  public static SUPPLIER = 'supplier';
  public static WAREHOUSE = 'warehouse';
  public static CONTACT = 'contact';
  public static FOOTERICON = 'footerIcon';
  public static MEDICAL_DELIVERY_OPTION = 'medicalDelivery';
  public static PURCHASE = 'purchase';
  public static PRODUCT_QTY_REPORT = 'productQtyReport';
  public static MEDICAL_DATA_MAPPING= 'medicalDataMapping';
  public static SHOPDATA = 'shopdata';
  public static SUPPLIER_DISCOUNT = 'supplierDiscount';
  public static SUPPLIER_COMMISSION = 'supplierCommission';
  
  public static REFERRAL = 'referral';
  public static REFERRALPRODUCT = 'referralProduct';
  public static REFERALPRODUCTITEM = 'referalProductItem';
  public static REFERALORDERITEMS = 'referalOrderItems';
  public static REFERALCUSTOMER = 'referalCustomer';


}

export class Audit {
  public static AUDIT_PRODUCT = "com.calsoft.ecom.model.productindex.ProductIDX2";
  public static AUDIT_USER = "com.calsoft.ecom.model.customerindex.CustomerIDX";
  public static AUDIT_CATEGORY = "com.calsoft.ecom.model.categoryindex.CategoryIDX";
}




export class comparisonValidator {
  /**
* Must match validator
*
* @param controlPath A dot-delimited string values that define the path to the control.
* @param matchingControlPath A dot-delimited string values that define the path to the matching control.
*/
  static mustMatch(controlPath: string, matchingControlPath: string): ValidatorFn {
    return (formGroup: UntypedFormGroup): null => {

      // Get the control and matching control
      const control = formGroup.get(controlPath);
      const matchingControl = formGroup.get(matchingControlPath);

      // Return if control or matching control doesn't exist
      if (!control || !matchingControl) {
        return;
      }

      // Delete the mustMatch error to reset the error on the matching control
      if (matchingControl.hasError('mustMatch')) {
        delete matchingControl.errors.mustMatch;
        matchingControl.updateValueAndValidity();
      }

      // Don't validate empty values on the matching control
      // Don't validate if values are matching
      if (this.isEmptyInputValue(matchingControl.value) || control.value === matchingControl.value) {
        return;
      }

      // Set the validation error on the matching control
      matchingControl.setErrors({ mustMatch: true });
    };
  }

  /**
* Check for empty (optional fields) values
*
* @param value
*/
  static isEmptyInputValue(value: any): boolean {
    return value == null || value.length === 0;
  }

}


export class nullCheckValidator {
  /**
* Must match validator
*
* @param controlPath A dot-delimited string values that define the path to the control.
* @param matchingControlPath A dot-delimited string values that define the path to the matching control.
*/
  static mustMatch(controlPath: string, matchingControlPath: string): ValidatorFn {
    return (formGroup: UntypedFormGroup): null => {

      // Get the control and matching control
      const control = formGroup.get(controlPath);
      const matchingControl = formGroup.get(matchingControlPath);

      // Return if control or matching control doesn't exist
      if (!control || !matchingControl) {
        return;
      }

      // Delete the mustMatch error to reset the error on the matching control
      if (matchingControl.hasError('empty')) {
        delete matchingControl.errors.mustMatch;
        matchingControl.updateValueAndValidity();
      }

      // Don't validate empty values on the matching control
      // Don't validate if values are matching
      if (this.isEmptyInputValue(matchingControl.value)) {
        matchingControl.setErrors({ empty: true });
      }

      if (this.isEmptyInputValue(control.value)) {
        control.setErrors({ empty: true });
      }

      // Set the validation error on the matching control
      matchingControl.setErrors({ empty: true });
    };
  }

  /**
* Check for empty (optional fields) values
*
* @param value
*/
  static isEmptyInputValue(value: any): boolean {
    return value == null || value.length === 0;
  }

}


export class lessThanValidator {
  /**
* Must match validator
*
* @param controlPath A dot-delimited string values that define the path to the control.
* @param matchingControlPath A dot-delimited string values that define the path to the matching control.
*/
  static mustMatchDate(controlPath: string, matchingControlPath: string): ValidatorFn {
    return (formGroup: UntypedFormGroup): null => {

      // Get the control and matching control
      const control = formGroup.get(controlPath);
      const matchingControl = formGroup.get(matchingControlPath);

      // Return if control or matching control doesn't exist
      if (!control || !matchingControl) {
        return;
      }

      // Delete the mustMatch error to reset the error on the matching control
      if (matchingControl.hasError('mismatchedDate')) {
        delete matchingControl.errors.mismatchedDate;
        matchingControl.updateValueAndValidity();
      }

      // Don't validate empty values on the matching control
      // Don't validate if values are matching
      if (control.value <= matchingControl.value) {
        return;
      }

      // Set the validation error on the matching control
      matchingControl.setErrors({ mismatchedDate: true });
    };
  }

  /**
* Check for empty (optional fields) values
*
* @param value
*/
  static isEmptyInputValue(value: any): boolean {
    return value == null || value.length === 0;
  }

}