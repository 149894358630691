<div  fxHide fxShow.lt-md class="mobile-menu">
    <div class="menu-header"  fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="logo">freshworks</h2>
        <button><mat-icon>close</mat-icon></button>
    </div>
  
    <ul class="menu-list">
      <li *ngFor="let item of menuItems" class="menu-item">
        <div class="menu-title" [class.active]="item.isExpanded" (click)="toggleSubmenu(item)">
          <h2>{{ item.title }}</h2>
          <mat-icon *ngIf="item.submenu">{{ item.isExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
        </div>
        <ul *ngIf="item.isExpanded && item.submenu" class="submenu">
          <li *ngFor="let sub of item.submenu" class="submenu-item">
            <div class="submenu-title"  (click)="toggleSubSubmenu(sub)">
              <h2>{{ sub.title }}</h2>
              <!-- <mat-icon *ngIf="sub.submenu">{{ sub.isExpanded ? 'expand_less' : 'expand_more' }}</mat-icon> -->

            </div>
            
            <!-- Sub-submenu -->
            <ul  class="sub-submenu">
              <li *ngFor="let subSub of sub.submenu">
                <div>
                    <h3>{{ subSub.title }}</h3>
                    <p *ngIf="subSub.description" class="submenu-description">{{ subSub.description }}</p>
                  </div>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  
