<owl-carousel-o [options]="LogoSlider">
    <ng-container *ngFor="let  img of item.images; let l = index">
        <ng-template carouselSlide>
            <div class="owl-container">
                <div class="owl-stage-outer snipcss-AdrWZ">
                    <div class="owl-stage style-YbmAK" id="style-YbmAK">
                        <div class="owl-item cloned style-FNSaW" id="style-FNSaW">
                            <div _ngcontent-wxv-c52="" class="testimonials-box">
                                <blockquote _ngcontent-wxv-c52="" class="testimonials text-center">
                                    <div _ngcontent-wxv-c52="" class="avatar">
                                        <!-- <img _ngcontent-wxv-c52="" [src]="serverPath + img.img" alt="User" width="100"
                                            height="100"> -->
                                        <calsoft-ng-optimized-image [altImage]="User" [image]="serverPath + img.img"
                                            [i]="'0'">
                                        </calsoft-ng-optimized-image>
                                    </div>
                                    <div _ngcontent-wxv-c52="" class="ratings-contianer">
                                        <div _ngcontent-wxv-c52="" class="ratings">
                                            <div _ngcontent-wxv-c52="" class="ratings-val style-PMOQJ" id="style-PMOQJ">
                                            </div>
                                        </div>
                                    </div>
                                    <h5 _ngcontent-wxv-c52="" class="comment-title" [style.font-size]="item.customField39"  [ngStyle.lt-md]="{ 'font-size': item.customField40}">
                                        {{img.customField4}}
                                    </h5>
                                    <p _ngcontent-wxv-c52="">
                                        Dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque
                                        aliquet.{{description}}
                                    </p>
                                    <cite _ngcontent-wxv-c52="">
                                        {{img.customField3}}
                                    </cite>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>