<div  [style.background-color]="customField25" class="commonHeader" fxLayout="row" fxLayoutAlign="space-between center">
   
    <span style=" 
    font-weight: 500;"  [ngStyle.gt-md]="{'font-size': item?.customField39 || customField39 }"
                        [ngStyle.lt-md]="{
                            
                            'font-size': item?.customField40 || customField40
                        }"  [innerHTML]="header | uppercase"></span>
                        

<button *ngIf="displayHeader" style="color:white" class="primary"
 (click)="goToCategory()" mat-raised-button>View All</button>
</div>

