import { Component, Input, OnInit } from '@angular/core';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'calsoft-banner-over-content',
  templateUrl: './banner-over-content.component.html',
  styleUrls: ['./banner-over-content.component.scss']
})
export class BannerOverContentComponent {

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() img: any;

  @Input() data: any;

  customField19:any='fadeInRightShorter';

  customField21:any;

  customField15: any = 'fadeInRightShorter';

  customField30:any='white';

 introSlider = {
    ...sliderOpt,
    nav: true, 
    navText: ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>'],
    loop: false,
    responsive: {
        992: {
            nav: true
        }
    }
}

constructor(  private commonService: CommonService,
  private categoryService: CategoryService) { }

  ngOnInit(): void {

if (this.customField19 === null || this.customField19 === undefined ) {
  this.customField19 = 'fadeInRightShorter';
}

if (this.customField21 === null || this.customField21 === undefined ) {
  this.customField21 = 'fadeInRightShorter';
}

if (this.customField15 === null || this.customField15 === undefined ) {
  this.customField15 = 'fadeInRightShorter';
}

  }

 
  link(url) {

    window.open(url, '_blank');
  }
  

  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  goToCategory(menu) {
    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);

  }

  
  imageNavigation(id){

    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  catId:any;

}

export const sliderOpt = {
  items: 1,
  loop: true,
  margin: 0,
  responsiveClass: true,
  nav: true,
  navText: ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>'],
  dots: false,
  smartSpeed: 400,
  autoplay: false,
  autoplayTimeout: 15000
}

