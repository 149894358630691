
<!-- 
<div fxlayout="row" fxFill fxLayoutAlign="start stretch" fxFlex="100"  >
  <img class="imgH" [src]="serverPath +item.customField6"
  onerror="this.src='assets/images/products/noImage/no-image.png';" alt="images">
</div> -->
  <!-- <div class="banner" [style.backgroundImage]="'url(' + serverPath +item.customField6 + ')'" 
>
    <div class="parallax-content" *ngFor="let slide of item.images" >

      <div  class="top">
        <h1  [style.color]="slide.customField1" [innerHTML]="slide.customField11"> 
        </h1>
      </div>
   
      <div class="middle">
        <h3 [style.color]="slide.customField2" [innerHTML]="slide.customField12">
        </h3>
      </div>

      <div class="bottom">
        <h2 [style.color]="slide.customField3" [innerHTML]="slide.customField13">
        </h2>
      </div>
    
    </div>
  </div> -->

  <!-- <div fxFlex
  fxLayout="column"
  fxLayoutAlign="center center"
  [style.backgroundImage]="'url(' + serverPath + item.customField6 + ')'"
  style="background-size: cover; background-position: center; height: 100vh;">

</div> -->
<div fxFlex 
     fxLayout="column"
     fxLayoutAlign="center center"
     class="background-container"
     [ngStyle]="{
      'backgroundImage': 'url(' + serverPath + item.customField6 + ')',
      'height': item.customField25,
      'width':item.customField27
    }" [ngStyle.lt-md]="{
      'backgroundImage': 'url(' + serverPath + item.customField21 + ')',
      'height': item.customField26,
      'width':item.customField28
    }">
     <div  [style.font-size]="item.customField22"  [ngStyle.lt-md]="{ 'font-size': item.customField23}" class="parallax-content" *ngFor="let slide of item.images" >

      <div  class="top">
        <h1 [style.font-size]="item.customField34"  [ngStyle.lt-md]="{ 'font-size': item.customField35}"  [style.color]="slide.customField1" [innerHTML]="slide.customField11"> 
        </h1>
      </div>
   
      <div class="middle">
        <h3 [style.font-size]="item.customField37"  [ngStyle.lt-md]="{ 'font-size': item.customField38}" [style.color]="slide.customField2" [innerHTML]="slide.customField12">
        </h3>
      </div>

      <div class="bottom">
        <h2 [style.font-size]="item.customField39"  [ngStyle.lt-md]="{ 'font-size': item.customField40}" [style.color]="slide.customField3" [innerHTML]="slide.customField13">
        </h2>
      </div>
    
    </div>
</div>