import { Component,AfterViewInit, HostListener, ElementRef, ViewChild, Input } from '@angular/core';



@Component({
  selector: 'app-video-page',
  templateUrl: './video-page.component.html',
  styleUrls: ['./video-page.component.scss']
})
export class VideoPageComponent {
  constructor() { }

  // Method to handle 'canplay' event
  videoCanPlay(event: Event) {
    let videoElement = event.target as HTMLVideoElement;
    videoElement.play(); // Autoplay once video can play
  }

  // Method to handle 'loadedmetadata' event
  videoLoadedMetadata(event: Event) {
    let videoElement = event.target as HTMLVideoElement;
    videoElement.muted = true; // Mute the video once metadata is loaded
  }
}