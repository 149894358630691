<div fxHide fxShow.gt-sm >
    <div class="container">
        <h3 style="font-size: 15px; font-weight: 700; margin-left: 30px;  margin-bottom: -11px; box-shadow: 0 10px 0px 0 white;" [innerHTML]="item.title"></h3>
        <div class="brands" fxLayout="row" fxLayoutAlign="space-around center" [fxLayoutGap]="item.gapBetweenImage"
            style="width: 100%; height: 100%;padding: 1%; border-style:dashed;border-color: rgb(241, 236, 236);">
            <div class="brand" *ngFor="let item of item.images">
                <a href="">
                    <!-- <img [src]="bannerServerPath + item.img"  (click)="item.navigation==0?goToCategories(item.catId):link(item.navigatedUrl)" [alt]="item.altImage"> -->
                    <calsoft-ng-optimized-image [navigation]="0" [altImage]="item.altImage"  [image]="bannerServerPath + item.img" [i]="'0'"> </calsoft-ng-optimized-image>


                </a>
            </div>
        </div>
    </div>
</div>

<div fxHide fxShow.lt-sm class="custom-carousel ">
    <div class="container">
        <owl-carousel-o [options]="LogoSlider" #owlCar class="no-arrow">
            <ng-container *ngFor="let  brand of item.images; let l = index">
                <ng-template carouselSlide>
                        <div class="brand" style="width: 96%;padding: 0%; border-style:dashed;border-color: rgb(241, 236, 236);">
                            <!-- <img style="padding: 2px;" [ngStyle.lt-md]="{'height': item.heightMob}"
                            [style.height]="item.height"
                                (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
                                [alt]="brand.altImage" [src]="bannerServerPath+brand.img"> -->
                            <calsoft-ng-optimized-image style="padding: 2px;" [height]="item.height" [heightMob]="item.heightMob" [navigation]="0" [altImage]="brand.altImage" [image]="bannerServerPath+brand.img" [i]="'0'">
                            </calsoft-ng-optimized-image>


                        </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>

</div>