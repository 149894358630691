
<div class="card-container" fxFlex="100" [fxLayoutGap]="item.mobileGapBetweenImage"  >
    <div>
        <div class="card" fxFlex="100" (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [ngClass]="{ 'first-card': index === 0, 'last-card': index === item.images.length - 1 }">
            <calsoft-ng-optimized-image [height]="item.height" [heightMob]="item.heightMob" [navigation]="0"
                [altImage]="img.altImage || 'images'" [image]="serverPath+img.img" [i]="'0'">
            </calsoft-ng-optimized-image>

            <div class="info" *ngIf="img?.customField1.length>0">
                <div class="row-1 u-font--bold u-font--20 u-t-c--grey_7" *ngIf="img.customField13 == 0"
                    fxLayoutAlign="start center">
                    <span class="description" [innerHTML]="img.customField1"></span>
                </div>

                <div class="row-1 u-font--bold u-font--20 u-t-c--grey_7" *ngIf="img.customField13 == 1"
                    fxLayoutAlign="center center">
                    <span class="description" [innerHTML]="img.customField1"></span>
                </div>

                <div class="row-2 u-font-14 u-t-c--grey_1" *ngIf="img.customField13 == 0" fxLayoutAlign="start center">
                    <span class="label" [innerHTML]="img.customField21"></span>
                </div>


                <div class="row-2 u-font-14 u-t-c--grey_1" *ngIf="img.customField13 == 1" fxLayoutAlign="center center">
                    <span class="label" [innerHTML]="img.customField21"></span>
                </div>
            </div>
        </div>
    </div>
</div>