import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageLazyLoadingComponent } from './image-lazy-loading.component';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { ImageCacheModule } from '../image-cache/image-cache.module';
import { ImageLazyMouseOverComponent } from './image-lazy-mouse-over/image-lazy-mouse-over.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ImageLazyMainMouseOverMainComponent } from './image-lazy-main-mouse-over-main/image-lazy-main-mouse-over-main.component';
@NgModule({
  declarations: [ImageLazyLoadingComponent, ImageLazyMouseOverComponent, ImageLazyMainMouseOverMainComponent],
  imports: [
    CommonModule,
    CustomFlexLayoutModule,
    ImageCacheModule,
    LazyLoadImageModule
  ],
  exports:[ImageLazyLoadingComponent,ImageLazyMouseOverComponent,ImageLazyMainMouseOverMainComponent]
})
export class ImageLazyLoadingModule { }
