import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'calsoft-image-over-content2',
  templateUrl: './image-over-content2.component.html',
  styleUrls: ['./image-over-content2.component.scss']
})
export class ImageOverContent2Component {
  showContent: boolean = false;

  public serverPath = environment.commonImageApi + '/home/';
  
  @Input() item: any;

  @Input() img: any;

  @Input() i: any;

  @Input() mobileLayout: any;
  
  customField16:any;
  customField17:any;
  customField6:any;

  image: string;

  constructor(
    private commonService: CommonService,
    private configService :ConfigServiceService,    
  ) {

  }


  
  ngOnInit() {
 

} 
isFlipped: boolean = false;

flipCard(value: boolean) {
  this.isFlipped = value;
}


link(url: string) {
  window.open(url, '_self');
}


imageNavigation(id){


  if(id>0){
    this.commonService.goToCategory(1, parseInt(id));
  }

}




toggleFlip() {
  if (window.innerWidth <= 768) {
    this.isFlipped = !this.isFlipped;
  }
}

}

