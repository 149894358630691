
import { Component, Inject, OnInit, ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Constant } from 'src/app/constants/constant';
import { CartItem } from 'src/app/models/cart-item';
import { getPageSize } from 'src/app/pages/util/calsoft-validators';
import { ProductService } from 'src/app/services/product.service';
import { PurchaseService } from 'src/app/services/purchase.service';

@Component({
  selector: 'app-addpurchase',
  templateUrl: './addpurchase.component.html',
  styleUrls: ['./addpurchase.component.scss'],
  
})
export class AddpurchaseComponent implements OnInit{
  
  
  columns: any;
  public page: any = Constant.PAGE;
  public size: any = Constant.SIZE;
  productList: any = [];
  pageList: any;
  spinner: boolean;
  sortParam: any = 'entityId' + "," + Constant.Dsc;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  paginationDetail = new BehaviorSubject(
    {
      length: 10,
      pageIndex: 0,
      pageSize: 10
    });
  search: any;
  cartItemList: CartItem;
  data: any;
  cartItems: any;
  elements: any;
  text: string;
  pageSizeValue: any;
  
  filterActive: boolean = false;
  configData: any;
  userId: any;
  productData: any[] = [];
  finalData: any[] = [];
  searchTermValue: string;
  category: any;
  categoryId: any;


  constructor(
    private purchaseService: PurchaseService,
     public productService: ProductService,
    private router: Router,
    public snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddpurchaseComponent>,
    @Inject(MAT_DIALOG_DATA) data,

  ) {
    this.data = data;

    this.configData = data['configData'];

    this.userId = data['userId'];

    this.finalData= data['finalData'];

    this.category= data['category'];
    
    
  }

  ngOnInit() {
    this.text = "OK";
    
     this.categoryId = this.category;
 
    this.searchTermValue= "categoryId_txt:" + '*' + this.categoryId + '*'+"' "
    this.getProduct(this.searchTermValue);

  }




  getProduct(searchTerm:any) {
    this.filterActive = false;
    this.spinner = true;


    this.productService.getProductBySearch(searchTerm,this.page, this.size, this.sortParam).subscribe(
      res => {
        this.spinner = false;
        this.productList = new MatTableDataSource();
        this.elements = res['numberOfElements'];
        this.productList = res['content'];        
        this.productList.sort = this.sort;
        this.pageList = res['totalElements'];
        this.productList.paginator = this.paginator;
        this.pageSizeValue = getPageSize(this.pageList);
      },
      error => {
        this.spinner = false;
      }
    )
  }

  getUpdate(event) {
    this.paginationDetail.next(event);
    this.page = event.pageIndex;
    this.size = event.pageSize;
    this.DetectingRequestType();
  }

  DetectingRequestType() {
    if (!this.filterActive) {
      this.getProduct(this.searchTermValue);
    }
    else {
      this.filterOption(this.search);
    }
  }

  onFilterChange(value) {

 if (value == ''||value==undefined ) {
      this.getProduct(this.searchTermValue);
      this.search = '';
    }
    else {
      this.page = Constant.PAGE;
      this.size = Constant.SIZE;

     let val= parseInt(localStorage.getItem(Constant.Sup))
     if(val>0){
      let searchTerm = "name:" + '*' + value.trim() + '*'
      + "' AND supplierId_cust:" + '*' + val + '*'
      + "' OR sku:" + '*' + value.trim() + '*' + "' "
    this.filterOption(searchTerm)
     }else{
      let searchTerm = "name:" + '*' + value.trim() + '*'
      + "' AND categoryId_txt:" + '*' + this.categoryId + '*'
      + "' OR sku:" + '*' + value.trim() + '*' + "' "
    this.filterOption(searchTerm)
     }
    
    }
  }


  filterOption(event) {
    this.filterActive = true;
    this.search = event;
    this.spinner = true;
    this.productService.getProductBySearch(this.search, this.page, this.size, this.sortParam).subscribe(
      res => {
        this.spinner = false;
        this.productList = res['content'];
        this.elements = res['numberOfElements'];
        this.pageList = res['totalElements'];
        this.pageSizeValue = getPageSize(this.pageList);
      },
      error => {
        this.spinner = false;
      })

  }

  ProductAddedData(cartList: any[]) {
    this.productData = cartList;  // Store the cart data
  }

  close() {
    this.dialogRef.close({ event: 'close', data: this.productData }); 
  }

  }
