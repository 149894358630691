<div class="image-container" contentAnim [aniName]="img.customField7" fxLayout="column" fxFlex="100"
  [fxLayoutAlign]="'center ' + img.customField28">
  <div class="text-container">
    <h2 [style.color]="item.customField22"  fxLayout="row" style="font-size: 14px;">{{ img.customField1 }}</h2>
    <p [style.color]="item.customField22" fxLayout="row" class="description padding-tb10" [style.font-size]="item.customField18"
      [ngStyle.lt-md]="{ 'font-size': item.customField17}" [innerHTML]="img.customField21"></p>
    <h3 [style.color]="item.customField22" fxLayout="row" class="description">{{ img.altImage }}</h3>
    <button *ngIf="img.customField30==0" class="btn1" fxLayout="row"
      (click)="img.navigation==0 ? imageNavigation(img.catId) : link(img.navigatedUrl)">
      {{ img.customField12 }}
    </button>
    <a [style.color]="item.customField33" *ngIf="img.customField30 == 1" fxLayout="row"
      (click)="img.navigation == 0 ? imageNavigation(img.catId) : link(img.navigatedUrl)"
      class="banner-link primary-icon link-with-hover">
      {{ img.customField12 }}
      <mat-icon>keyboard_arrow_right</mat-icon>
    </a>


  </div>
  <!-- <img [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}" [src]="serverPath + img.img"
  [alt]="img.altImage || 'images'"> -->
  <calsoft-ng-optimized-image [height]="item.height" [heightMob]="item.heightMob" [navigation]="0" [altImage]="img.altImage || 'images'" [image]="serverPath + img.img" [i]="'0'"> </calsoft-ng-optimized-image>


</div>