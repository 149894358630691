import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'calsoft-plan-compare-type4',
  templateUrl: './plan-compare-type4.component.html',
  styleUrls: ['./plan-compare-type4.component.scss']
})
export class PlanCompareType4Component implements OnInit {

  isChecked: boolean = false;
  pricedetail: any;
  title1: any;
  title2: any;
  title3: any;

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  toggleChanged() {
    console.log('Slide toggle changed. New value:', this.isChecked);
  }

  @Input() item: any;
  @Input() data: any;

  constructor( private commonService: CommonService,) {

  }
  
  ngOnInit() {   
    this.pricedetail = JSON.parse(this.item.customField29);

    this.title1= this.pricedetail[0].title1;
    this.title2= this.pricedetail[0].title2;
    this.title3= this.pricedetail[0].title3;


    console.log(this.pricedetail);
  }      

  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      this.commonService.goToLink(url)
    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }




}
