import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  constructor(  private http: HttpClient) { }

  url = environment.serverAPI + "/api/catalog";

  
  url1 = environment.serverAPI4 + "/api/doctorIdx";

  getDoctorDetail(id: any): Observable<Object> {
    return this.http.get(`${this.url}/product/id?productId=${id}`);
  }


  getDoctorById(id: number): Observable<Object> {
    return this.http.get(`${this.url1}/${id}`);
  }

}
