import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-vedio-banner',
  templateUrl: './vedio-banner.component.html',
  styleUrls: ['./vedio-banner.component.scss']
})
export class VedioBannerComponent {


  image: string;

  constructor(
    private dialog: MatDialog,
  ) {
  }

  autoplay:any;

  @Input() img: any;

  @Input() item: any;

  public serverPath = environment.commonImageApi + '/home/';

  public youtubeUrl = environment.youtubeUrl;

  url: string;

  customField6: any;

  ngOnInit() {

    if(this.customField6==undefined){
      this.customField6=this.item.customField6;
    }

    this.image= this.serverPath+this.customField6;    
    
  }


  @ViewChild('vedio') vedio: TemplateRef<any>;
  
  showModal(event: Event) {
    if (window.innerWidth > 768) {

      let dialogRef = this.dialog.open(this.vedio, {
        width: '790px',
        height:'500px'
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();


      });

    }
    else {
      let dialogRef = this.dialog.open(this.vedio, {
        minWidth: '100vw', height: '100vh',
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();


      });
    }
	}

  closeModal(){
    this.dialog.closeAll();
  }
}
