import { Component, OnInit } from '@angular/core';
import { Constant } from 'src/app/constants/constant';

@Component({
  selector: 'calsoft-dynamic-footer',
  templateUrl: './dynamic-footer.component.html',
  styleUrls: ['./dynamic-footer.component.scss']
})
export class DynamicFooterComponent implements OnInit {


  public PageTitle = Constant.footer

  constructor() { }

  ngOnInit() {
  }
  
}
