<div class="container" *ngIf="item.customField33 != '4' ">


    <div fxLayout="row" class="table-align" *ngIf="item.customField15 == '1' ">
        <div fxLayout="column" fxFlex="25" class="cell">
        </div>

        <div fxLayout="column" fxFlex="25" class="cell">
            <mat-icon class="maticon1">check_circle</mat-icon>
            <h4><b>Starter</b></h4>
            <button mat-stroked-button color="primary" class="trybtn1">TRY NOW</button>
        </div>

        <div fxLayout="column" fxFlex="25" class="cell">
            <mat-icon class="maticon2">check_circle</mat-icon>
            <h4><b>Professional</b></h4>
            <button mat-stroked-button color="primary" class="trybtn2">TRY NOW</button>
        </div>

        <div fxLayout="column" fxFlex="25" class="cell">
            <mat-icon class="maticon_3">check_circle</mat-icon>
            <h4><b>Advanced</b></h4>
            <button mat-stroked-button color="primary" class="trybtn3">TRY NOW</button>
        </div>
    </div>
    <div fxLayout="row" class="table-align" [style.background-color]="item.customField20"
        [style.color]="item.customField21" *ngIf="item.customField15 == '2' ">
        <div fxLayout="column" fxFlex="25" class="cell">
            <h4><b>{{item.customField16}}</b></h4>
        </div>

        <div fxLayout="column" fxFlex="25" class="cell">
            <h4><b>{{item.customField17}}</b></h4>
        </div>

        <div fxLayout="column" fxFlex="50" class="cell">
            <h4><b>{{item.customField18}}</b></h4>
        </div>

        <div fxLayout="column" [fxFlex]="item.customField19 == '' ? 50 : 25" class="cell"
            *ngIf="item.customField19.length > 0">
            <h4><b>{{item.customField19}}</b></h4>
        </div>
    </div>

    <div *ngFor="let data of pricedetail">
        <div fxLayout="row" class="subTitle" *ngIf="data.title">
            <p><b>{{data.title}}</b></p>
        </div>

        <div *ngFor="let item of data.list_detail">
            <div [style.background]="item.table_color" fxLayout="row" class="rowtype">
                <div fxFlex="25" class="celltype">
                    <p>{{item.sub_title}}</p>
                </div>
                <div fxFlex="25" class="celltype">
                    <p style="text-align: center;" *ngIf="item.starter !== 'yes' && item.starter !== 'no'">
                        {{item.starter}}</p>
                    <mat-icon class="maticon3" *ngIf="item.starter === 'yes'">check_circle</mat-icon>
                    <mat-icon class="maticon4" *ngIf="item.starter === 'no'">cancel</mat-icon>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-evenly center" fxFlex="70"
                    class="celltype">
                    <p fxFlex="40" fxFlex.lt-md style="text-align: center;padding:  0px;"
                        *ngIf="item.profession !== 'yes' && item.profession !== 'no'">{{item.profession}}</p>
                    <div *ngIf="item.navigation==1" fxLayout="row" fxLayoutAlign="center center" style="padding:5px;">
                        <button  [ngStyle.gt-md]="{'font-size': '16px' }"
                        [ngStyle.lt-md]="{ 'font-size': '10px'
          }" (click)="link(item.button_link)" fxLayout="row" fxLayoutAlign="center center"
                            [style.background-color]="item.customField29" class="price-breakup-button">
                            {{item.button_name}}
                        </button>
                        <span (click)="dynamicFormPopup(item.value)" class="arrow1"
                            [style.background-color]="item.customField20"
                            fxLayoutAlign="center center"><mat-icon>arrow_right_alt</mat-icon></span>
                    </div>
                    <div *ngIf="item.navigation==2" fxLayout="row" fxLayoutAlign="center center" style="padding:5px;">
                        <button  [ngStyle.gt-md]="{'font-size': '16px' }"
                        [ngStyle.lt-md]="{ 'font-size': '10px'
          }" (click)="dynamicFormPopup(item.value)" fxLayout="row" fxLayoutAlign="center center"
                            [style.background-color]="item.customField29" class="price-breakup-button">
                            {{item.button_name}}
                        </button>
                        <span (click)="dynamicFormPopup(item.value)" class="arrow1"
                            [style.background-color]="item.customField20"
                            fxLayoutAlign="center center"><mat-icon>arrow_right_alt</mat-icon></span>
                    </div>
                    <div style="padding-bottom: 5px;" *ngIf="item.navigation==0" fxLayout="row"
                        fxLayoutAlign="center center">
                        <button (click)="scrollDown(item.id_element)" [ngStyle.gt-md]="{'font-size': '16px' }"
                            [ngStyle.lt-md]="{ 'font-size': '10px'
              }" fxLayout="row" fxLayoutAlign="center center" [style.background-color]="item.customField29"
                            class="price-breakup-button">
                            {{item.button_name}}
                        </button>
                        <span (click)="scrollDown(item.id_element)" class="arrow1"
                            [style.background-color]="item.customField20"
                            fxLayoutAlign="center center"><mat-icon>arrow_right_alt</mat-icon></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>