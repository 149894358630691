import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[cache]'
})
export class ImageCacheDirective implements AfterViewInit {

  @Input() cache: string;

  constructor(public el: ElementRef) { }

  ngAfterViewInit() {
      this.el.nativeElement.crossOrigin = null; // CORS enabling
      let cache = localStorage.getItem(this.cache);

      if (cache) {
          this.el.nativeElement.src = JSON.parse(cache);
      }
      else {
          this.cacheImage();
      }
  }

  cacheImage(proxy: boolean = false) {
      let xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
          let reader = new FileReader();
          reader.onloadend = () => {
              let value =JSON.stringify(reader['result']);
              localStorage.setItem(this.cache, value);
              this.el.nativeElement.src = JSON.parse(value);
          }
          reader.readAsDataURL(xhr.response);
      };

      xhr.onerror = (error) => {
          console.warn(error, 'trying with proxy...')
          if(!proxy) {
              this.cacheImage(true);
          }
          else {
              this.el.nativeElement.src = this.cache;
          }
      }
      
      let url = this.cache;
      if (proxy) {
          url = 'https://crossorigin.me/' + url;
      }

      xhr.open('GET', url);
      xhr.send();
  }

}
