<div class="swiper-container" #swiper>
    <div class="swiper-wrapper">
      <div *ngFor="let image of img" class="swiper-slide" [style.height]="item.height" [style.width]="item.customField25" 
      [ngStyle.lt-md]="{
        'height': item.heightMob, 'width':item.customField26
      }"
      
      [style.backgroundImage]="'url(' + bannerServerPath+image.img + ')'">
        <div class="slide-content">
            <h4>{{image.customField20}}</h4>
            <p class="linkcss" (click)="openlink(image.navigatedUrl)">{{item.customField12}}</p>
        </div>

      </div>
    </div>
    <div class="swiper-pagination"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </div>