<h1 [class.scrolled]="isScrolled">
  <calsoft-editor-text [data]="data" [text]="item.title"
  [align]="item.customField34">
 </calsoft-editor-text>
</h1>

<div class="container" [style.background-color]="item.customField8">
    <div fxLayout="row wrap" >
      <div class="card" [fxFlex]="(100/ item.customField33) + '%'" *ngFor="let section of item.images" >
        <div fxLayout="column" fxLayoutAlign="start center">
          <div class="card1">
            <h2 class="main"><span class="main1">{{ section.customField20 }}</span></h2>
            <span class="main2">{{ section.customField21 }}</span>
            <div class="image">
              <img  [style.height]="item.height"
              [ngStyle.lt-md]="{'height': item.heightMob}" [src]="bannerServerPath+section.img" [alt]="section.altImage">
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
