<div class="video-container">
    <div *ngIf="item.customField25=='0'">
        <video oncanplay="this.play()" onloadedmetadata="this.muted = true"  [ngStyle.lt-md]="{'height': item.heightMob}" [style.height]="item.height" class="video-sc-desktop" autoplay muted loop>
            <source [src]="serverPath1 + item.customField6" type="video/mp4">
        </video>
    </div>
    <div *ngIf="item.customField25=='1'">
        <!-- <img class="video-sc-desktop" onerror="" [style.height]="item.height"
        [ngStyle.lt-md]="{'height': item.heightMob}"
        [src]="serverPath1+item.customField6" /> -->
        <calsoft-ng-optimized-image class="video-sc-desktop" [height]="item.height" [heightMob]="item.heightMob"  [altImage]="'images'"  [image]="serverPath1+item.customField6"  [i]="'0'">
        </calsoft-ng-optimized-image>
    </div>

    <div class="text-overlay">
        <p class="title">
            <calsoft-editor-text [data]="data" [text]="item.customField4" [align]="item.customField34"
            [style.font-size]="item.customField37" [ngStyle.lt-md]="{ 'font-size': item.customField38 }">
            </calsoft-editor-text>
        </p>
        <p class="descrption">
            <calsoft-editor-text [data]="data" [text]="item.customField5" [align]="item.customField34"
            [style.font-size]="item.customField39" [ngStyle.lt-md]="{ 'font-size': item.customField40 }">
            </calsoft-editor-text>
        </p>
    </div>
    <!-- <div class="search-box">
        <input type="text" placeholder="Search...">
        <button (click)="searchPage()">Search</button>
    </div> -->
        <div class="search-box-new">
            <calsoft-search3></calsoft-search3>
        </div>
    </div>