import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Constant } from '../constants/constant';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  constructor(
    private http: HttpClient,
    private userService: UserService
    ) {}
    
	url1 = environment.serverAPI4 + '/' + 'api/ig/product/solr';
	url2 = environment.serverAPI4 + '/' + 'api/product/solr';
  url6 = environment.serverAPI4 + '/' + 'api/product';

  getProductBySearch(query, Page: any, size: any, sort: any): Observable<Object> {
		return this.http.get(`${this.url6}/filter?search=${query}&page=${Page}&size=${size}&sort=${sort}`);
	}

  getProducts(Page: any, size: any, sort: any): Observable<Object> {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
		return this.http.get(`${this.url2}?page=${Page}&size=${size}&sort=${sort}`);
    }else{
      return this.http.get(`${this.url1}?page=${Page}&size=${size}&sort=${sort}`);
    }
	}
}
