import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'calsoft-plan-compare-type5',
  templateUrl: './plan-compare-type5.component.html',
  styleUrls: ['./plan-compare-type5.component.scss']
})
export class PlanCompareType5Component implements OnInit{

  @Input() item: any;
  @Input() data: any;
  pricedetail:any;
  price_detail:any;  
  items:any;
  constructor() {

  }
  
  ngOnInit() {   
    this.pricedetail = JSON.parse(this.item.customField30);
    
  }      



}
