<owl-carousel-o [options]="introSlider" *ngIf="item.customField1=='type1' || item.customField1=='type2' || item.customField1=='type3' || item.customField1=='type4' ">
  <ng-container *ngFor="let  img of item.images; let l = index">
  <ng-template carouselSlide >

      <div style="width:100%" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob
           }"  class="intro-slide" [style.backgroundImage]="'url(' + serverPath +img.img + ')'">
          <div  [style.text-align]="img.customField28" style="width:100%" class="container intro-content"  contentAnim [aniName]="item.customField7">
            <span [style.text-align]="img.customField28">
           
              <span  [ngStyle]="{'font-family': item.fontFamily}"  [style.color]="item.customField22" [style.font-size]="item.customField31"  [ngStyle.lt-md]="{ 'font-size': item.customField32}"  class="intro-subtitle text-white" [innerHTML]="img.customField4"></span>
              <h1   [ngStyle]="{'font-family': item.fontFamily}" [style.color]="item.customField22" [style.font-size]="item.customField39"  [ngStyle.lt-md]="{ 'font-size': item.customField40}" 
              class="intro-title text-white" [innerHTML]="img.customField5"></h1>

              <ng-container  *ngIf="img.customField3.length !== 0; else noCustomField3">
                <button (click)="link(img.customField6)" class="btn btn-outline-white-4"  [style.background-color]="item.customField33" target="_blank">
                  <span  [ngStyle]="{'font-family': item.fontFamily}"  style="align-items: center;">{{img.customField3}}</span>
                </button>
              </ng-container>
              
                
                <ng-template #noCustomField3>
                  <span  [ngStyle]="{'font-family': item.fontFamily}">{{img.customField3}}</span>
                </ng-template>

            </span>
     
          </div>

          <!-- <div *ngIf="item.customField13 == 1" fxLayoutAlign="center center" style="width:100%" [style.text-align]="item.customField13" class="container intro-content"  contentAnim [aniName]="item.customField7">
            <span [ngStyle]="{'text-align': 'center'}">
              <h3 [style.color]="item.customField22" [style.font-size]="item.customField31"  [ngStyle.lt-md]="{ 'font-size': item.customField32}" class="intro-subtitle text-white">{{img.customField4}}</h3>
              <h1 [style.color]="item.customField22" [style.font-size]="item.customField39"  [ngStyle.lt-md]="{ 'font-size': item.customField40}"
               class="intro-title text-white"  [innerHTML]="img.customField5"></h1>

              <ng-container *ngIf="img.customField3.length !== 0; else noCustomField3">
                <button (click)="link(img.customField6)" class="btn btn-outline-white-4" target="_blank">
                  <span style="align-items: center;">{{img.customField3}}</span>
                </button>
              </ng-container>
              
                
                <ng-template #noCustomField3>
                  <span>{{img.customField3}}</span>
                </ng-template>

            </span>
        </div>

        <div *ngIf="item.customField13 == 2" fxLayoutAlign="end center" style="width:100%" [style.text-align]="item.customField13" class="container intro-content"  contentAnim [aniName]="item.customField7">
          <span [ngStyle]="{'text-align': 'center'}">
            <h3 [style.color]="item.customField22" [style.font-size]="item.customField31"  [ngStyle.lt-md]="{ 'font-size': item.customField32}"  class="intro-subtitle text-white">{{img.customField4}}</h3>
          <h1 [style.color]="item.customField22" [style.font-size]="item.customField39"  [ngStyle.lt-md]="{ 'font-size': item.customField40}"
          class="intro-title text-white"  [innerHTML]="img.customField5"></h1>

          <ng-container *ngIf="img.customField3.length !== 0; else noCustomField3">
              <a (click)="link(img.customField6)" class="btn btn-outline-white-4" target="_blank">
                <span>{{img.customField3}}</span>
              </a>
            </ng-container>
            
            <ng-template #noCustomField3>
              <span>{{img.customField3}}</span>
            </ng-template>

          </span>
          
            
        
          
          
      </div> -->
      </div>


  </ng-template>
  </ng-container>

</owl-carousel-o>

<div [class.container]="item.customField1 == 'type4'" class="banner-group" style="cursor: pointer;" *ngIf="item.customField1=='type2' || item.customField1=='type4'">
<div fxLayout="row" fxLayoutAlign="center center" ngClass.gt-sm="my-md-n5" ngClass.lt-sm="my-md-n6" class="no-gutters">
  <div *ngIf="item.customField1!='type4'" fxFlex="5%">

  </div>
  <div fxFlex="100%" fxLayout.xs="column">
    <div fxFlex="33.33%" fxFlex.xs="100%" contentAnim [aniName]="item.customField19">
      <div class="banner1 mb-0">
        <!-- <img width="100%" [style.height]="item.customField25" [ngStyle.lt-md]="{'height': item.customField26
      }" (click)="goToCategories(item.categoryId1)" [src]="serverPath1+item.customField2" onerror="" [alt]="item.customField2" > -->
      <calsoft-ng-optimized-image [height]="item.customField25" [heightMob]="item.customField26" [navigation]="0" [altImage]="item.customField2" [image]="serverPath1+item.customField2" [i]="'0'"> </calsoft-ng-optimized-image>
        <div class="banner-content text-center banner-content-center mb-0 my-md-4" fxLayout="column" fxLayoutAlign=" center" >
            <h4   [ngStyle]="{'font-family': item.fontFamily}" [style.color]="item.customField27"[style.font-size]="item.customField37"  [ngStyle.lt-md]="{ 'font-size': item.customField38}" 
             class="banner-subtitle font-size-normal letter-spacing-large text-white text-uppercase font-weight-normal">{{item.customField5}}</h4>
            <h3   [ngStyle]="{'font-family': item.fontFamily}" [style.color]="item.customField27" [style.font-size]="item.customField27"  [ngStyle.lt-md]="{ 'font-size': item.customField28}"
             class="banner-title text-white font-weight-normal text-uppercase my-4 mb-0">{{item.customField6}}</h3>
            <h3    [ngStyle]="{'font-family': item.fontFamily}" [style.color]="item.customField27"[style.font-size]="item.customField29"  [ngStyle.lt-md]="{ 'font-size': item.customField30}"
             class="banner-price text-white text-uppercase my-4 mt-0">{{item.customField8}}</h3>
            <a    [ngStyle]="{'font-family': item.fontFamily}"  [style.color]="item.customField27" *ngIf="item.customField10.length > 0" 
              style="cursor: pointer; text-decoration: underline;"
              (click)="goToCategories(item.categoryId1)"
              class="btn font-size-normal letter-spacing-large btn-dark text-uppercase mt-0 mt-md-3 font-weight-normal text-uppercase">
              {{item.customField10}}
           </a>
        </div>
    </div>
       
    </div>
    <div fxFlex="33.33%" fxFlex.xs="100%" contentAnim [aniName]="item.customField21">
        <!-- <img onerror=""  (click)="goToCategories(item.categoryId2)"  [src]="serverPath1+item.customField3"> -->

        <div class="banner1 mb-0">
          <!-- <img width="100%" [style.height]="item.customField25" [ngStyle.lt-md]="{'height': item.customField26
        }" (click)="goToCategories(item.categoryId1)" [src]="serverPath1+item.customField3" onerror="" [alt]="item.customField2" > -->
        <calsoft-ng-optimized-image  width="100%" [height]="item.customField25" [heightMob]="item.customField26" [navigation]="0" [altImage]="item.customField2"  [image]="serverPath1+item.customField3" [i]="'0'"> </calsoft-ng-optimized-image>
          <div class="banner-content text-center banner-content-center mb-0 my-md-4" fxLayout="column" fxLayoutAlign=" center">
              <h4  [ngStyle]="{'font-family': item.fontFamily}" [style.color]="item.customField29" [style.font-size]="item.customField37"  [ngStyle.lt-md]="{ 'font-size': item.customField38}"
              class="banner-subtitle font-size-normal letter-spacing-large text-white text-uppercase font-weight-normal">{{item.customField9}}</h4>
              <h3   [ngStyle]="{'font-family': item.fontFamily}" [style.color]="item.customField29" [style.font-size]="item.customField27"  [ngStyle.lt-md]="{ 'font-size': item.customField28}"
               class="banner-title text-white font-weight-normal text-uppercase my-4 mb-0">{{item.customField14}}</h3>
              <h3   [ngStyle]="{'font-family': item.fontFamily}" [style.color]="item.customField29" [style.font-size]="item.customField29"  [ngStyle.lt-md]="{ 'font-size': item.customField30}"
              class="banner-price text-white text-uppercase my-4 mt-0">{{item.customField20}}</h3>
              <a   [ngStyle]="{'font-family': item.fontFamily}"  [style.color]="item.customField29" *ngIf="item.customField23.length > 0" style="cursor: pointer; text-decoration: underline; " (click)="goToCategories(item.categoryId2)" class="btn font-size-normal letter-spacing-large btn-dark text-uppercase mt-0 mt-md-3 font-weight-normal text-uppercase">{{item.customField23}}</a>
          </div>
      </div>

    </div>
    <div fxFlex="33.33%" fxFlex.xs="100%" contentAnim [aniName]="item.customField15">
      <div class="banner1 mb-0">
        <a>
            <!-- <img width="100%" [style.height]="item.customField25" [ngStyle.lt-md]="{'height': item.customField26
          }" onerror="" (click)="goToCategories(item.categoryId3)" [src]="serverPath1+item.customField4" [alt]="item.customField4"  
          fixedPt="49.9rem" > -->
          <calsoft-ng-optimized-image  width="100%" [height]="item.customField25" [heightMob]="item.customField26" [navigation]="0" [altImage]="item.customField4" [image]="serverPath1+item.customField4" [i]="'0'"> </calsoft-ng-optimized-image>
        </a>
        <div class="banner-content text-center banner-content-center  mt-md-5" fxLayout="column" fxLayoutAlign=" center">
            <h3  [ngStyle]="{'font-family': item.fontFamily}"  [style.color]="item.customField31" [style.font-size]="item.customField37"  [ngStyle.lt-md]="{ 'font-size': item.customField38}"
            class="banner-title font-weight-normal text-uppercase mt-1 my-1">{{item.customField16}}</h3>
            <h3   [ngStyle]="{'font-family': item.fontFamily}" [style.color]="item.customField31" [style.font-size]="item.customField27"  [ngStyle.lt-md]="{ 'font-size': item.customField28}"
            class="banner-title text-white font-weight-normal text-uppercase my-4 mb-0">{{item.customField11}}</h3>
            <h3  [ngStyle]="{'font-family': item.fontFamily}"  [style.color]="item.customField31" [style.font-size]="item.customField29"  [ngStyle.lt-md]="{ 'font-size': item.customField30}"
            class="banner-price text-white text-uppercase my-4 mt-0">{{item.customField24}}</h3>
            <a   [ngStyle]="{'font-family': item.fontFamily}" [style.color]="item.customField31" *ngIf="item.customField12.length > 0"  style="cursor: pointer; text-decoration: underline; "  (click)="goToCategories(item.categoryId3)" class="btn font-size-normal letter-spacing-large btn-dark text-uppercase mt-4 font-weight-normal text-uppercase">{{item.customField12}}</a>
        </div>
    </div>
    </div>
    </div>

    <div *ngIf="item.customField1!='type4'" fxFlex="5%">

    </div>
</div>
</div>

<div  *ngIf="item.customField1=='type5'">
  <calsoft-imagewidget [item]="item" [img]="item.images"></calsoft-imagewidget>

</div>

<owl-carousel-o [options]="introSlider" *ngIf="item.customField1=='type8' ">

  <ng-container *ngFor="let  img of item.images; let l = index">
  <ng-template carouselSlide >

      <div style="width:100%" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob
           }"  class="intro-slide1" [style.backgroundImage]="'url(' + serverPath +img.img + ')'">

          <div style="width:100%;" class="padding-10"
            [fxLayoutAlign]="img.customField28" contentAnim [aniName]="item.customField7">
            <span>
        
              <span   [ngStyle]="{'font-family': item.fontFamily}"  [fxLayoutAlign]="img.customField28" [style.color]="item.customField22" [style.font-size]="item.customField31"  [ngStyle.lt-md]="{ 'font-size': item.customField32}"  class="padding-10"  [innerHTML]="img.customField4"></span>
              
              <p   [ngStyle]="{'font-family': item.fontFamily}"  [fxLayoutAlign]="img.customField28" style="padding: 10px;" [style.color]="item.customField22" 
              >
              <calsoft-editor-text  [data]="data"  [fontFamily]="item.fontFamily"  [fontSize]="item.customField39"  [ngStyle.lt-md]="{ 'font-size': item.customField40}"   [text]="img?.customField5" [align]="item.customField34"> </calsoft-editor-text>
            </p>

              <ng-container  *ngIf="img.customField3.length !== 0; else noCustomField3">
                <div [fxLayoutAlign]="img.customField28"> 
                <button   (click)="link(img.customField6)" class="btn btn-outline-white-5" target="_blank">
                  <span   [fontFamily]="item.fontFamily" style="align-items: center;" class="padding-10">{{img.customField3}}</span>
                </button>
                </div>
              </ng-container>                              
                <ng-template #noCustomField3>
                  <div [fxLayoutAlign]="img.customField28"> 
                  <span  [ngStyle]="{'font-family': item.fontFamily}"  class="padding-10">{{img.customField3}}</span>
                  </div>
                </ng-template>
            </span>     
          </div>
      </div>
  </ng-template>
  </ng-container>

</owl-carousel-o>