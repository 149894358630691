import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatorComponent } from './translator.component';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';



@NgModule({
  declarations: [TranslatorComponent],
  imports: [
    CommonModule,
    CustomFlexLayoutModule
  ],exports:[
    TranslatorComponent

  ]
})
export class TranslateModule { }
