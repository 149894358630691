import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogSectionRoutingModule } from './blog-section-routing.module';
import { BlogSectionComponent } from './blog-section.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { BlogLayoutComponent } from '../blog-layout/blog-layout.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [
    BlogSectionComponent,
    BlogLayoutComponent
  ],
  imports: [
    CommonModule,
    BlogSectionRoutingModule,
    NgxPaginationModule,
    InfiniteScrollModule,
    CustomFlexLayoutModule,
    MatIconModule,
    MatButtonModule 
  ],exports:[
    BlogSectionComponent
  ]
})
export class BlogSectionModule { }
