import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-anime-image',
  templateUrl: './anime-image.component.html',
  styleUrls: ['./anime-image.component.scss']
})
export class AnimeImageComponent  implements OnInit{


  @Input() item: any;
  @Input() img: any;
  @Input() data: any;

  constructor(
    private commonService: CommonService,

  ){
    
  }

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  ngOnInit() {
    
    
  }

  link(url) {

    this.commonService.goToLink(url)
   
  }

  imageNavigation(id){


    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

}
