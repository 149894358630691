import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrandsRoutingModule } from './brands-routing.module';
import { BrandsComponent } from './brands.component';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { SwiperModule } from 'src/app/theme/swiper/swiper.module';
import { TextEditorModule } from 'src/app/shared/text-editor/text-editor.module';
import { PipesModule } from 'src/app/theme/pipes/pipes.module';
import { YoutubeVideoModule } from '../youtube-video/youtube-video.module';
import { CommonImagesModule } from '../../common-images/common-images.module';
import { EditorTextModule } from '../editor-text/editor-text.module';

@NgModule({
  declarations: [
    BrandsComponent,
  ],
  imports: [
    CommonModule,
    BrandsRoutingModule,
    SwiperModule,
    MatIconModule,
    MatButtonModule,
    CarouselModule,
    CustomFlexLayoutModule,
    TextEditorModule,
    PipesModule,
    YoutubeVideoModule,
    CommonImagesModule,
    EditorTextModule
    

  ],exports:[
    BrandsComponent,
  ]
})
export class BrandsModule { }
