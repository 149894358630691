<div *ngIf="item.images.length>0">
    <div fxLayout.gt-md="row" *ngIf="item.customField1=='type1'">
        <div fxFlex>
            <div class="contain" >            
                 <calsoft-carousel [order]="'first'" [type]="'common'" [bannerHeight]="bannerHeight" [item]="item"
                [bannerTabletHeight]="bannerTabletHeight" [bannerTabletWeight]="bannerTabletWeight"
                [bannerWidth]="bannerWidth" [bannerMobWidth]="bannerMobWidth" [bannerMobHeight]="bannerMobHeight"
                [layout]="item.images"></calsoft-carousel>
            </div>
        </div>
    </div>

    <div fxLayout.gt-md="row" *ngIf="item.customField1=='type2'">
        <div fxFlex>
            <div class="contain">            
                 <calsoft-carousel [bannerHeight]="bannerHeight" [bannerTabletHeight]="bannerTabletHeight" [item]="item"
                [bannerTabletWeight]="bannerTabletWeight" [bannerWidth]="bannerWidth" [bannerMobWidth]="bannerMobWidth"
                [bannerMobHeight]="bannerMobHeight" [order]="'first'" [type]="'common'"
                [layout]="item.images"></calsoft-carousel>
            </div>
        </div>
    </div>

    <div *ngIf="item.customField1=='type3'" fxLayout="row" fxLayoutGap="15px">

        <div fxLayout="column" fxFlex="65" fxLayoutGap="15px">
            <div fxFlex>
                <div class="contain">            
                <calsoft-carousel [bannerHeight]="'500px'" [bannerTabletHeight]="bannerTabletHeight" [item]="item"
                    [bannerTabletWeight]="bannerTabletWeight" [bannerWidth]="bannerWidth"
                    [bannerMobWidth]="bannerMobWidth" [bannerMobHeight]="bannerMobHeight" [order]="'second'"
                    [bannerLayout]="'bannerLayout'" [type]="'common'" [layout]="item.images">
                </calsoft-carousel>
                </div>
            </div>
            <!-- your.component.html -->

            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start stretch">
                <!-- First column -->
                <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                    <calsoft-layout-image  fxLayout="column" fxLayoutAlign="start stretch" [id]="item.categoryId1"
                        [image]="serverPath+item.customField2" [height]="imageHeight"></calsoft-layout-image>
                </div>

                <!-- Second column -->
                <div fxFlex class="images" fxLayout="column" fxLayoutAlign="start stretch">
                    <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch" [id]="item.categoryId2"
                        [image]="serverPath+item.customField3" [height]="imageHeight"></calsoft-layout-image>
                </div>
            </div>



        </div>
        <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
            <calsoft-layout-image fxLayout="row" fxFlex="100" fxLayoutAlign="start stretch" [layout]="'row'"
                [id]="item.categoryId3" [image]="serverPath+item.customField4">
            </calsoft-layout-image>
        </div>
    </div>
    <div *ngIf="item.customField1=='type4'" fxLayout="row" fxLayoutGap="15px">

        <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
            <calsoft-layout-image fxLayout="row" fxLayoutAlign="start stretch" [layout]="'row'" [id]="item.categoryId3"
                [image]="serverPath+item.customField4">
            </calsoft-layout-image>
        </div>
        <div fxLayout="column" fxFlex="65" fxLayoutGap="15px">
            <div fxFlex>
                <div class="contain">            
                <calsoft-carousel [bannerHeight]="bannerHeight" [bannerTabletHeight]="bannerTabletHeight" [item]="item"
                    [bannerTabletWeight]="bannerTabletWeight" [bannerWidth]="bannerWidth"
                    [bannerMobWidth]="bannerMobWidth" [bannerMobHeight]="bannerMobHeight" [order]="'second'"
                    [bannerLayout]="'bannerLayout'" [type]="'common'" [layout]="item.images">
                </calsoft-carousel>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start stretch">
                <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                    <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch" [id]="item.categoryId1"
                        [image]="serverPath+item.customField2">
                    </calsoft-layout-image>
                </div>
                <div fxFlex class="images" fxLayout="column" fxLayoutAlign="start stretch">

                    <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch" [id]="item.categoryId2"
                        [image]="serverPath+item.customField3">
                    </calsoft-layout-image>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="item.customField1=='type5'" fxLayout="row" fxLayoutGap="15px">

        <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
            <calsoft-layout-image fxLayout="row" fxLayoutAlign="start stretch" [layout]="'row'" [id]="item.categoryId3"
                [image]="serverPath+item.customField4">
            </calsoft-layout-image>
        </div>
        <div fxLayout="column" fxFlex="65" fxLayoutGap="5px">
            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
                <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                    <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch" [id]="item.categoryId1"
                        [image]="serverPath+item.customField2">
                    </calsoft-layout-image>
                </div>
                <div fxFlex class="images" fxLayout="column" fxLayoutAlign="start stretch">

                    <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch" [id]="item.categoryId2"
                        [image]="serverPath+item.customField3">
                    </calsoft-layout-image>
                </div>
            </div>
            <div fxFlex>
                <div class="contain">
                    <calsoft-carousel [bannerHeight]="bannerHeight" [bannerTabletHeight]="bannerTabletHeight" [item]="item"
                        [bannerTabletWeight]="bannerTabletWeight" [bannerWidth]="bannerWidth"
                        [bannerMobWidth]="bannerMobWidth" [bannerMobHeight]="bannerMobHeight"
                        [bannerLayout]="'bannerLayout'" [type]="'common'" [layout]="item.images">
                    </calsoft-carousel>
                </div>
            </div>

        </div>
    </div>
    <div *ngIf="item.customField1=='type6'" fxLayout="row" fxLayoutGap="5px">
        <div fxLayout="column" fxFlex="65" fxLayoutGap="5px">
            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
                <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                    <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch" [id]="item.categoryId1"
                        [image]="serverPath+item.customField2">
                    </calsoft-layout-image>
                </div>
                <div fxFlex class="images" fxLayout="column" fxLayoutAlign="start stretch">

                    <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch" [id]="item.categoryId2"
                        [image]="serverPath+item.customField3">
                    </calsoft-layout-image>
                </div>
            </div>
            <div fxFlex>
                <div class="contain">
                <calsoft-carousel [bannerHeight]="bannerHeight" [bannerTabletHeight]="bannerTabletHeight" [item]="item"
                    [bannerTabletWeight]="bannerTabletWeight" [bannerWidth]="bannerWidth"
                    [bannerMobWidth]="bannerMobWidth" [bannerMobHeight]="bannerMobHeight"
                    [bannerLayout]="'bannerLayout'" [type]="'common'" [layout]="item.images">
                </calsoft-carousel>
                </div>
            </div>

        </div>
        <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
            <calsoft-layout-image fxLayout="row" fxLayoutAlign="start stretch" [layout]="'row'" [id]="item.categoryId3"
                [image]="serverPath+item.customField4">
            </calsoft-layout-image>
        </div>
    </div>
    <!--  -->
    <div *ngIf="item.customField1=='type7'" fxLayout="row" fxLayoutGap="5px">
        <div fxLayout="column" fxFlex="65" fxLayoutGap="5px">
            <div fxFlex>
                <div class="contain">
                <calsoft-carousel [bannerLayout]="'bannerLayout'" [type]="'common'" [layout]="item.images" [item]="item">
                </calsoft-carousel>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
                <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                    <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch" [id]="item.categoryId1"
                        [image]="serverPath+item.customField2">
                    </calsoft-layout-image>
                </div>
            </div>
        </div>
        <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
            <calsoft-layout-image fxLayout="row" fxLayoutAlign="start stretch" [layout]="'row'" [id]="item.categoryId2"
                [image]="serverPath+item.customField3">
            </calsoft-layout-image>
        </div>
    </div>
    <div *ngIf="item.customField1=='type8'" fxLayout="row" fxLayoutGap="5px">

        <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
            <calsoft-layout-image fxLayout="row" fxLayoutAlign="start stretch" [layout]="'row'" [id]="item.categoryId2"
                [image]="serverPath+item.customField3">
            </calsoft-layout-image>
        </div>
        <div fxLayout="column" fxFlex="65" fxLayoutGap="5px">
            <div fxFlex>
                <div class="contain">
                <calsoft-carousel [bannerHeight]="bannerHeight" [bannerTabletHeight]="bannerTabletHeight" [item]="item"
                    [bannerTabletWeight]="bannerTabletWeight" [bannerWidth]="bannerWidth"
                    [bannerMobWidth]="bannerMobWidth" [bannerMobHeight]="bannerMobHeight"
                    [bannerLayout]="'bannerLayout'" [type]="'common'" [layout]="item.images">
                </calsoft-carousel>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
                <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                    <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch" [id]="item.categoryId1"
                        [image]="serverPath+item.customField2">
                    </calsoft-layout-image>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="item.customField1=='type9'" fxLayout="row" fxLayoutGap="5px">

        <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
            <calsoft-layout-image fxLayout="row" fxLayoutAlign="start stretch" [layout]="'row'" [id]="item.categoryId2"
                [image]="serverPath+item.customField3">
            </calsoft-layout-image>
        </div>
        <div fxLayout="column" fxFlex="65" fxLayoutGap="5px">
            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
                <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                    <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch" [id]="item.categoryId1"
                        [image]="serverPath+item.customField2">
                    </calsoft-layout-image>
                </div>
            </div>
            <div fxFlex>
                <div class="contain">
                <calsoft-carousel [bannerHeight]="bannerHeight" [bannerTabletHeight]="bannerTabletHeight" [item]="item"
                    [bannerTabletWeight]="bannerTabletWeight" [bannerWidth]="bannerWidth"
                    [bannerMobWidth]="bannerMobWidth" [bannerMobHeight]="bannerMobHeight"
                    [bannerLayout]="'bannerLayout'" [type]="'common'" [layout]="item.images">
                </calsoft-carousel>
                </div>
            </div>

        </div>
    </div>
    <div *ngIf="item.customField1=='type10'" fxLayout="row" fxLayoutGap="5px">
        <div fxLayout="column" fxFlex="65" fxLayoutGap="5px">
            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
                <div class="images" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                    <calsoft-layout-image fxLayout="column" fxLayoutAlign="start stretch" [id]="item.categoryId1"
                        [image]="serverPath+item.customField2">
                    </calsoft-layout-image>
                </div>
            </div>
            <div fxFlex>
                <div class="contain">
                <calsoft-carousel [bannerHeight]="bannerHeight" [bannerTabletHeight]="bannerTabletHeight" [item]="item"
                    [bannerTabletWeight]="bannerTabletWeight" [bannerWidth]="bannerWidth"
                    [bannerMobWidth]="bannerMobWidth" [bannerMobHeight]="bannerMobHeight"
                    [bannerLayout]="'bannerLayout'" [type]="'common'" [layout]="item.images">
                </calsoft-carousel>
                </div>
            </div>

        </div>
        <div fxFlex class="images" fxLayout="row" fxLayoutAlign="start stretch">
            <calsoft-layout-image fxLayout="row" fxLayoutAlign="start stretch" [layout]="'row'" [id]="item.categoryId2"
                [image]="serverPath+item.customField3">
            </calsoft-layout-image>
        </div>
    </div>

    <div fxLayout.gt-md="row" *ngIf="item.customField1=='type11'" >
        <div fxFlex>
            <div class="contain" >
                <calsoft-carousel [order]="'first'" [type]="'common'" [bannerHeight]="bannerHeight" [item]="item"
                [bannerTabletHeight]="bannerTabletHeight" [bannerTabletWeight]="bannerTabletWeight"
                [bannerWidth]="bannerWidth" [bannerMobWidth]="bannerMobWidth" [bannerMobHeight]="bannerMobHeight"
                [layout]="item.images"></calsoft-carousel>
                
            </div>
        </div>
    </div>

    <div *ngIf="item.customField1=='type12'">
    <div fxLayout.gt-md="row" >
        <div fxFlex>
            <div class="contain" >            
                 <calsoft-carousel [order]="'first'" [type]="'headsearch'" [bannerHeight]="bannerHeight" [item]="item"
                [bannerTabletHeight]="bannerTabletHeight" [bannerTabletWeight]="bannerTabletWeight"
                [bannerWidth]="bannerWidth" [bannerMobWidth]="bannerMobWidth" [bannerMobHeight]="bannerMobHeight"
                [layout]="item.images" ></calsoft-carousel>
            </div>
        </div>
    </div>
        <div fxLayout.gt-md="row">
            <calsoft-booking-search-box style="width: 70%;margin-left: auto;margin-right: auto;margin-top: -40px;position: relative;"></calsoft-booking-search-box>
        </div>
    </div>

    <div *ngIf="item.customField1=='type13'">
        <calsoft-banner-type-anim [bannerHeight]="bannerHeight" [bannerWidth]="bannerWidth"
        [bannerMobWidth]="bannerMobWidth" [bannerMobHeight]="bannerMobHeight"
        [bannerTabletHeight]="bannerTabletHeight" [bannerTabletWeight]="bannerTabletWeight"
        [item]="item" [img]="item.images"></calsoft-banner-type-anim>
    </div>

    <div *ngIf="item.customField1=='type14'">
        <div fxLayout.gt-md="row" >
            <div fxFlex>
                <div class="contain" >            
                     <calsoft-carousel [order]="'first'" [type]="'headsearch'" [bannerHeight]="bannerHeight" [item]="item"
                    [bannerTabletHeight]="bannerTabletHeight" [bannerTabletWeight]="bannerTabletWeight"
                    [bannerWidth]="bannerWidth" [bannerMobWidth]="bannerMobWidth" [bannerMobHeight]="bannerMobHeight"
                    [layout]="item.images" ></calsoft-carousel>
                </div>              
            </div>
        </div>
        
        <div class="search" fxHide fxShow.md fxShow.lg fxShow.gt-lg>
            <calsoft-search-box1  [item]="item"></calsoft-search-box1>
        </div>
               
    </div>

    <div *ngIf="item.customField1=='type15'">
        <calsoft-imagecircleanimate [bannerHeight]="bannerHeight" [bannerWidth]="bannerWidth"
        [bannerMobWidth]="bannerMobWidth" [bannerMobHeight]="bannerMobHeight"
        [bannerTabletHeight]="bannerTabletHeight" [bannerTabletWeight]="bannerTabletWeight"
        [item]="item" [img]="item.images"></calsoft-imagecircleanimate>
    </div>
    

    <div *ngIf="item.customField1=='type16'">
        <calsoft-bennerwithlogin [bannerHeight]="bannerHeight" [bannerWidth]="bannerWidth"
        [bannerMobWidth]="bannerMobWidth" [bannerMobHeight]="bannerMobHeight"
        [bannerTabletHeight]="bannerTabletHeight" [bannerTabletWeight]="bannerTabletWeight"
        [item]="item" [img]="item.images"></calsoft-bennerwithlogin>
    </div>

</div>