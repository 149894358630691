import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel/carousel.component';
import { MatCarouselModule } from './lib/carousel.module';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { ImageComponent } from './image/image.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HammerModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [ CarouselComponent, ImageComponent],
  imports: [
    CommonModule,
    MatCarouselModule,
    CustomFlexLayoutModule,
    CarouselModule,
    HammerModule,
    MatButtonModule
  ],
  exports:[CarouselComponent,ImageComponent]
})
export class SliderModule { }
