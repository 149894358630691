<div class="container">
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="20px">
        <div *ngFor="let  img of item.images; let l = index" fxFlex="100" fxFlex.gt-sm="33" contentAnim [aniName]="item.customField21" class="cardzoom">
            <div class="shadow" [style.height]="img.customField10" [style.background]="getDynamicBackground(l)"
                (mouseenter)="onMouseEnter(l)" (mouseleave)="onMouseLeave(l)">
                <!-- <img [src]="serverPath1+img.customField6" class="imgcss" [alt]="img.altImage || 'images'" /> -->
                <calsoft-ng-optimized-image  class="imgcss" [altImage]="img.altImage || 'images'" [image]="serverPath1+img.customField6" [i]="'0'"> </calsoft-ng-optimized-image>


                <div>
                    <h3 style="font-size: 22px;"><b>{{img.customField4}}</b></h3>
                    <!-- <p style="font-size: 16px;">{{img.customField16}}
                </p> -->

                    <calsoft-editor-text [data]="data" [text]="img.customField16" [align]="item.customField34">
                    </calsoft-editor-text>
                    
                    <div style="display: flex;margin-top: 7px;" (click)="openInNewWindow(img.customField7)">
                        <p class="para">{{img.customField5}}</p>&#160;
                        <mat-icon>arrow_forward</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>