import { Component, OnInit } from '@angular/core';
import { AboutService } from 'src/app/services/about.service';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  public PageTitle = Constant.terms
  public sitename = Constant.siteName

  constructor() { }

  ngOnInit() {
  }



}
