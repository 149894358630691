import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LighteningDealsComponent } from './LighteningDeals/LighteningDeals.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { RouterModule } from '@angular/router';
import { CommonProductDetailsCarouselComponent } from './common-product-details-carousel/common-product-details-carousel.component';
import { ImageLazyLoadingModule } from '../image-lazy-loading/image-lazy-loading.module';
import { ControlsModule } from '../controls/controls.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '../translate/translate.module';
import { CalsoftProductcarouselComponent } from './productcarousel/productcarousel.component';

import { CommonHeaderCarouselComponent } from './common-header-carousel/common-header-carousel.component';
import { TopProductsComponent } from './TopProducts/TopProducts.component';
import { PipesModule } from '../pipes/pipes.module';
import { CommonProductDetailsGridComponent } from './common-product-details-grid/common-product-details-grid.component';
import { SwiperModule } from '../swiper/swiper.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ServiceProductDetailNewComponent } from './service-product-detail-new/service-product-detail-new.component';
import { PartnersListComponent } from './partners-list/partners-list.component';
import { OutOfStockComponent } from './out-of-stock/out-of-stock.component';
import { CourseDetailMobModule } from 'src/app/shared/course-detail-mob/course-detail-mob.module';
import { ProductModule } from 'src/app/pages/product/product.module';


@NgModule({
  declarations: [
    LighteningDealsComponent,
    CommonProductDetailsCarouselComponent,
    CalsoftProductcarouselComponent,
    CommonHeaderCarouselComponent,
    TopProductsComponent,
    CommonProductDetailsGridComponent,
    ServiceProductDetailNewComponent,
    PartnersListComponent,
    OutOfStockComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    CustomFlexLayoutModule,
    RouterModule,
    ImageLazyLoadingModule,
    ControlsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    TranslateModule,
    SwiperModule,
    PipesModule,
    LazyLoadImageModule,

    

  ],
  exports:[CommonProductDetailsCarouselComponent, LighteningDealsComponent,TopProductsComponent,CalsoftProductcarouselComponent,CommonProductDetailsGridComponent,ServiceProductDetailNewComponent,PartnersListComponent]
})
export class ProductLayoutModule { }
