<div fxLayout="row" style="padding: 30px 18px;">
    <div fxFlex="50">
        <!-- <mat-icon class="ratingcss1">radio_button_checked</mat-icon> -->
        <p style="font-size: 20px;"><b>{{product.name}}</b></p>
        <p style="font-size: 17px;"><b>&#8377; {{product.price}}</b></p>
        <div class="rowrating">
            <mat-icon class="ratingcss">star</mat-icon>
            <p style="font-size: 14px;"><b>{{product.averageRating}}</b></p>&#160;
            <!-- <p style="font-size: 14px;" >({{product.ratingcnt}})</p> -->
        </div>

    </div>
    <div fxFlex="50" fxLayoutAlign="end end">
        <div style="width: 160px;height: 150px;">
            <div *ngIf="product.productImageUrl">
                <div
                    *ngIf="!product.productImageUrl.includes('medicineimages')&&!product.productImageUrl.includes('http')&&!product.productImageUrl.includes('commonimages')">
                    <img [src]="bigImage+product.productImageUrl" class="imgcss" />
                </div>
                <div *ngIf="product.productImageUrl.includes('http')">
                    <img [src]="bigImage+product.productImageUrl" class="imgcss" />
                </div>
                <div *ngIf="product.productImageUrl.includes('commonimages')">
                    <img [src]="serverPath7+product.productImageUrl" class="imgcss" />
                </div>


            </div>
            <div *ngIf="!product.productImageUrl">
                <img src="assets/images/products/noImage/no-image.png" class="imgcss" />
            </div>
            <div fxLayout="row" style="padding-top: 5px;padding-bottom: 5px;" fxLayoutAlign="center center">

                <calsoft-controls [product]="configProductSelected" [data]="data" [variant1Name]="variant1Name"
                    [variant2Name]="variant2Name" [variant1Value]="variant1Value" [variant2Value]="variant2Value"
                    [configproduct]="config" [type]="'swiggyCart'" [showCartIcon]="showCartIcon"
                    [showCartIncremented]="showCartIncremented">
                </calsoft-controls>

            </div>
        </div>
    </div>
</div>