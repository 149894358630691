import { Component, Input, OnInit } from '@angular/core';
import { Constant } from 'src/app/constants/constant';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @Input() layout: any;
  @Input() layouts: any;
  @Input() type: any;
  @Input() category: any
  @Input() bannerLayout: any
  @Input() order: any
  @Input() bannerHeight: any;
  @Input() bannerWidth: any;
  @Input() bannerMobWidth: any;
  @Input() bannerMobHeight: any;
  @Input() bannerTabletHeight:any = '100px';
  @Input() bannerTabletWeight:any;
  @Input() item: any;
  
  imageUrl: string;
  mouseValue:any
  public url = environment.commonImageApi
  height: string;
  width: string;
  maintainAspectRatio: boolean = true;
  finalLayout: any=[];
  textvalue:any;
  constructor() {

  }

  ngOnInit(): void {

    this.textvalue = this.layout; 
    this.screenWidth = window.innerWidth;

    this.organizeData()    

    if (window.innerWidth > 1024) {
      // Desktop view
      if (this.bannerHeight === undefined) {
        this.bannerHeight = localStorage.getItem(Constant.BANNER_HEIGHT);
        this.bannerWidth = localStorage.getItem(Constant.BANNER_WIDTH);
      }
    
      if (this.order === "first") {
        this.height = this.bannerHeight;
        this.width = this.bannerWidth;
        this.maintainAspectRatio = false;
        this.mouseValue = "false";
      } else {
        this.height = this.bannerHeight;
        this.width = this.bannerWidth;
        this.maintainAspectRatio = false;
        this.mouseValue = "false";
      }
    } else if (window.innerWidth >= 767 && window.innerWidth <= 1024) {
      // Tablet view
      if (this.bannerTabletHeight === undefined) {
        this.bannerTabletHeight = localStorage.getItem(Constant.BANNER_HEIGHT_TABLET);
        this.bannerTabletWeight = localStorage.getItem(Constant.BANNER_WIDTH_TABLET);
      }
    
      this.height = this.bannerTabletHeight;
      this.width = this.bannerTabletWeight;
      this.maintainAspectRatio = false; // You can set this value accordingly
      this.mouseValue = "false"; // You can set this value accordingly
    } else if (window.innerWidth < 767) {
      // Mobile view
      if (this.bannerMobHeight === undefined) {
        this.bannerMobHeight = localStorage.getItem(Constant.BANNER_HEIGHT_MOBILE);
        this.bannerMobWidth = localStorage.getItem(Constant.BANNER_WIDTH_MOBILE);
      }
    
      this.height = this.bannerMobHeight;
      this.width = this.bannerMobWidth;
      this.maintainAspectRatio = false;
      this.mouseValue = "true";
    }
    
    

  

    if (this.type == 'common') {
      this.imageUrl = this.url + "commonSlider/";
   

    } if (this.type == 'layouts') {
      this.imageUrl = this.url + "slider/";
    }
    if (this.type == 'headsearch') {
      this.imageUrl = this.url + "commonSlider/";
    }

    if(this.height=='auto'){
    
      this.maintainAspectRatio=true;
    }else{
      this.maintainAspectRatio=false;
    }
    
  }

  screenWidth: number;



  organizeData(): void {
    const isMobile = this.screenWidth <= 767;
    const isTab = this.screenWidth > 767 && this.screenWidth <= 1024;
    const isWeb = this.screenWidth > 1024;


    this.finalLayout=this.layout;

    this.layout=[];

    for (const item of this.finalLayout) {
      const customField22 = item.customField22;
      if (customField22 === '1') {
        if (isMobile || isTab || isWeb) {
          this.layout.push(item);
        }
      } else if (customField22 === '2') {
        if (isMobile) {
          this.layout.push(item);
        }
      } else if (customField22 === '3') {
        if (isTab) {
          this.layout.push(item);
        } 
      } else if (customField22 === '4') {
        if (isWeb) {
          this.layout.push(item);
        }
      }
    }
  }
}

