import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DoctorService } from 'src/app/services/doctor.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { TimeService } from 'src/app/services/time.service';
import { EcryptServiceService } from 'src/app/services/ecrypt-service.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDatepicker } from '@angular/material/datepicker';
import { UserService } from 'src/app/services/user.service';
import { CommonSignInComponent } from 'src/app/shared/CommonSignIn/CommonSignIn.component';
import { ConfigServiceService } from 'src/app/services/config-service.service';

@Component({
  selector: 'app-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss']
})
export class SlotComponent implements OnInit {
  @Input() bookerId: any;
  @Input() data: any;
  @Input() type: any = 1;
  @Input() productData: any;
  @Input() appointmentType: any;

  @Input() doctorSpecialist: any;

  @Input() doctorPhoneNo: any;

  @Input() doctorEmail: any;

  @Input() doctorId: any;

  slotEnabled: boolean = false;

  @Output() onClosePopup: EventEmitter<any> = new EventEmitter<any>();
  doctorData: any;
  bigImage: any;
  timeSlot1: any = [];
  timeSlot2: any = [];
  timeSlot3: any = [];
  timeSlot4: any = [];
  timeSlot5: any = [];
  timeSlot6: any = [];
  timeSlot7: any = [];
  filterRequest: any[];

  dates: any = [];
  timeSlotMap = new Map<any, any>();
  initDateComponent1: boolean = false;
  encryptedProductName: any;
  name: any;
  private sub: any;

  productImageUrl: any;
  dataValue: any;
  slotType: any = 1;
  startDate = new Date();
  selectedDate: any;
  showtiming: boolean;
  confirmBtn: boolean;
  selectedTime: any;
  doctorTime: any = [];
  todayDate = new Date();
  page1: string;
  authourized: string;
  productArray: any;
  timeSlotDate: any;
  timeSlotDuration: any;
  timeSlotSlot: any;

  constructor(
    private timeService: TimeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private ecryptService: EcryptServiceService,
    private configService: ConfigServiceService,
    public doctorService: DoctorService) {
    this.bigImage = environment.ImageAPI1;
  }


  @ViewChild('picker') picker: MatDatepicker<Date>;

  openDatePicker() {
    this.picker.open();
  }


  ngOnInit() {
    
    this.getConfigData();

    this.slotType = this.data.slotType != undefined ? this.data.slotType : 1;
    
    if(this.slotType==0){
    this.getDateDetails(this.bookerId)
    }
   

  }

  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {
          this.data=data;
        
          if (this.data.slotType != undefined) {
            this.slotType = this.data.slotType;
          }

          
        }, error => {

        }
      );
  }


  getDateDetails(bookerId: any) {
    const today = new Date();
    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      let object = {
        parentId: bookerId,
        scheduleDoctorTime: nextDate,
        currentDate: i == 0 ? true : false
      }



      if (i == 0) {
        let objectValue = {
          viewValue: "Today",
          value: nextDate,

        }
        this.dates.push(objectValue);
        this.schuduleDotorTime(object, "Today")
      } else if (i == 1) {
        let objectValue = {
          viewValue: "Tomorrow",
          value: nextDate
        }
        this.dates.push(objectValue);
        this.schuduleDotorTime(object, "Tomorrow")
      } else {

        let value = this.formatMyDate(nextDate)
        this.schuduleDotorTime(object, value)
        let objectValue = {
          viewValue: value,
          value: nextDate
        }
        this.dates.push(objectValue);
      }

    }

    this.initDateComponent1 = true;

  }

  formatMyDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
    };
    return date.toLocaleDateString('en-US', options);
  }

  schuduleDotorTime(object, date) {
    this.timeService.getSchuduleDoctorTime(object).subscribe(
      (time: any) => {

        this.timeSlotMap.set(date, time);

      },
      (error) => {

        console.error('Error fetching time:', error);
      }
    )
  }

  movetomyappoint(slot, item) {

    if (this.authourized == "" || this.authourized == undefined) {
      this.authourized = this.userService.getAuthenticatedUser();
    }
    if (this.authourized || this.data['guestLogin'] == 1) {

      let object = this.productData

      object.slottiming = slot.show_time;

      object.slottimingValue = slot.time;

      object.date = item.value;

      object.displayDate = item.value;

      object.appointmentType = this.appointmentType;

      object.doctorEmail = this.doctorEmail;

      object.doctorId = this.doctorId;

      object.doctorPhoneNo = this.doctorPhoneNo;

      object.doctorSpecialist = this.doctorSpecialist;

      console.log(this.productData);
      
      let value = this.ecryptService.encryptionAES(JSON.stringify(this.productData));

      let navigationExtras: NavigationExtras = {
        queryParams: {
          "sid": value
        },

      };
      if (this.type == 1) {
        this.onClosePopup.emit('closeCheckout')
      }

      this.router.navigate([`/checkout-appointments`], navigationExtras);

    } else {

      this.loginModel();
      this.productArray = {
        slot: slot,
        item: item,
        type: 'booking',

      };

    }




  }

  loginModel() {
    if (window.innerWidth > 768) {

      let dialogRef = this.dialog.open(CommonSignInComponent, {
        width: '800px',
        data: { page: this.page1, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        if (result["event"] != "close") {

          if (this.slotType == 0) {
            this.movetomyappoint(
              this.productArray.slot,
              this.productArray.item);
          } else {
            this.checkout()
          }



          let value = this.ecryptService.encryptionAES(JSON.stringify(this.productData));

          let navigationExtras: NavigationExtras = {
            queryParams: {
              "sid": value
            },

          };

          this.router.navigate([`/checkout-appointments`], navigationExtras);
        }

      });

    }
    else {
      let dialogRef = this.dialog.open(CommonSignInComponent, {
        minWidth: '100vw', height: '100vh',
        data: { page: this.page1, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        if (result["event"] != "close") {

          let value = this.ecryptService.encryptionAES(JSON.stringify(this.productData));

          let navigationExtras: NavigationExtras = {
            queryParams: {
              "sid": value
            },

          };

          this.router.navigate([`/checkout-appointments`], navigationExtras);
        }

      });
    }

  }


  checkout() {
    if (this.authourized == "" || this.authourized == undefined) {
      this.authourized = this.userService.getAuthenticatedUser();
    }

    if (this.authourized || this.data['guestLogin'] == 1) {
      var allData = {

        doctor_id: this.data.id,
        a_date: this.selectedDate,
        a_time: this.selectedTime
      }


      const providedDate = new Date(this.selectedDate);

      // Subtract one day (24 hours) from the provided date
      providedDate.setDate(providedDate.getDate() + 1);

      // Convert the result back to a string in ISO 8601 format
      let previousDate = providedDate.toISOString();
      let object = this.productData

      object.slottiming = this.selectedTime;

      object.slottimingValue = this.selectedTime;

      object.appointmentEndTime = this.timeSlotDate['end-time'];

      object.startEndTimeSlot = this.timeSlotDate['slot-time-slot'];

      object.appointmentDuration = this.timeSlotDate['appointmentDuration'];

      object.date = previousDate;

      object.displayDate = this.selectedDate;

      if (this.type == 1) {
        this.onClosePopup.emit('closeCheckout')
      }
      console.log(this.productData);
      
      let value = this.ecryptService.encryptionAES(JSON.stringify(this.productData));

      let navigationExtras: NavigationExtras = {
        queryParams: {
          "sid": value
        },

      };
      this.router.navigate([`/checkout-appointments`], navigationExtras);

    } else {

      this.loginModel();
      this.productArray = {
        a_date: this.selectedTime,
        a_time: this.selectedDate,
        type: 'booking',

      };
    }
  }

  onSelectionChange(event: any,i) {
    if (event.isUserInput != false) {
    console.log(i);
    
    this.selectedTime = event.source.value;
    this.timeSlotDate=i;
    // console.log('Selected value:', this.selectedTime);
    this.confirmBtn = true;
    }
  }


  onDatePicked(date) {
console.log(date);

    const providedDate = new Date(date);
    let   currentDate = new Date();
    let value =false;
    if (providedDate.toDateString() === currentDate.toDateString() ) {
      value=true; // Dates are the same
    } else {
      value=false; // Dates are not the same
    }

    // Subtract one day (24 hours) from the provided date
    providedDate.setDate(providedDate.getDate() + 1);



    // Convert the result back to a string in ISO 8601 format
    const previousDate = providedDate.toISOString();

    let object = {
      parentId: this.bookerId,
      scheduleDoctorTime: previousDate,
      currentDate:value
    }

    this.timeService.getSchuduleDoctorTime(object).subscribe(
      (time: any) => {
        this.showtiming = true;
        this.doctorTime = time;
        this.selectedTime = null;
        this.confirmBtn = false;
      },
      (error) => {
        console.error('Error fetching time:', error);
        this.showtiming = false;
      }
    )


  }

}


