import { Component, Input } from '@angular/core';

@Component({
  selector: 'calsoft-out-of-stock',
  templateUrl: './out-of-stock.component.html',
  styleUrls: ['./out-of-stock.component.scss']
})
export class OutOfStockComponent  {
  @Input() type: any;
  @Input() product: any;
}
