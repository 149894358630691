<div fxHide.lt-md>
    <div fxLayout="column"  class="layout-container " [style.background-color]="item.customField27">
    
        <div class="container" fxLayout="row"  fxLayoutAlign="center">
            <div fxFlex="40" fxLayoutAlign="center" class="image-panel" fxHide fxShow.gt-xs>
                <img  [style.height]="item.customField25"  [src]="activeImage" alt="Service Image">
            </div>
    
    
            <div fxFlex="60" fxLayout="column">
                <div fxLayoutAlign="center " >
                    <h1 [id]="item.customField35" [innerHTML]="item.title"></h1>
                </div>
    
                <div fxLayout="row wrap" fxLayoutAlign="start">
                    <div *ngFor="let images of item.images;let i=index" fxFlex="100" fxFlex.gt-xs="50" 
                     [style.height]="item.height" class="text-panel"
                        (mouseenter)="changeImage(serverPath1 +images.img)">
                        <div (click)="images.navigation==0?goToCategories(images.catId):link(images.navigatedUrl)" 
                             fxFlex class="text-panel1" [style.background-color]="item.customField28">
                            <h3  [ngStyle.gt-md]="{'font-size': item.customField39 }"
                            [ngStyle.lt-md]="{'font-size': item.customField40}"  fxLayoutAlign=" start center" >
                                {{images.customField5}} <span style="margin-left: 10px;">
                                    <mat-icon>arrow_forward</mat-icon>
                                </span>
                            </h3>
                            <p  [ngStyle.gt-md]="{'font-size': item.customField21 }"
                            [ngStyle.lt-md]="{'font-size': item.customField22}" >{{images.customField8}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    
    </div>
</div>

<div fxHide fxShow.lt-md>
    <div fxLayout="column"  class="layout-container " [style.background-color]="item.customField27">
    
        <div class="container" fxLayout="column"  fxLayoutAlign="center">
            <div fxFlex="100"  fxLayoutAlign="center" class="image-panel" >
                <img style="width:100%" [style.height]="item.customField25"  [src]="activeImage" alt="Service Image">
            </div>
    
    
            <div fxFlex="100" fxLayout="column">
                <div fxLayoutAlign="center " >
                    <h1 [id]="item.customField35" [innerHTML]="item.title"></h1>
                </div>
    
                <div fxLayout="row wrap" fxLayoutAlign="start">
                    <div *ngFor="let images of item.images;let i=index" fxFlex="100" fxFlex.gt-xs="50" 
                     [style.height]="item.height" class="text-panel"
                        (mouseenter)="changeImage(serverPath1 +images.img)">
                        <div (click)="images.navigation==0?goToCategories(images.catId):link(images.navigatedUrl)" 
                             fxFlex class="text-panel1" [style.background-color]="item.customField28">
                            <h3  [ngStyle.gt-md]="{'font-size': item.customField39 }"
                            [ngStyle.lt-md]="{'font-size': item.customField40}"  fxLayoutAlign=" start center" >
                                {{images.customField5}} <span style="margin-left: 10px;">
                                    <mat-icon>arrow_forward</mat-icon>
                                </span>
                            </h3>
                            <p  [ngStyle.gt-md]="{'font-size': item.customField21 }"
                            [ngStyle.lt-md]="{'font-size': item.customField22}" >{{images.customField8}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    
    </div> 
</div>

