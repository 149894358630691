<div ngClass.gt-md="container" class="container" *ngIf="isEmpty">
  <div id="noProducts" fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
      <div>
        <img src="assets/images/no-product.png" alt="no-product">
      </div>
      <div class="notFound">
        <span>Sorry, no results found! </span>
      </div>
      <div class="spell">
        <span fxLayoutAlign="center center">Please check the spelling or try searching for something else </span>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="list.length>0">



  <div ngClass.gt-md="container" ngClass.md="container" id="productsPage">
    <div class="inner-container ">
      <div *ngIf="!isEmpty">
        <div fxLayout="row wrap">


          <div *ngIf="!isEmpty" fxFlex.lt-md="100" fxFlex.gt-sm class="all-products padding-2"
          >

            <div style="padding: 0px 10px;" *ngIf="!mobileScreen" fxHide fxShow.md fxShow.gt-md fxShow.lg fxShow.xl
              fxLayout="row wrap" class="products-wrapper  " id="list">

              <div *ngFor="let blogObject of list | paginate: config"
               fxFlex.xs="50" [fxFlex.lg]="viewCol"
                [fxFlex.gt-sm]="viewCol" fxFlex.lt-md="33.33" class="border-lb" class="col product-mobile backg">

                <calsoft-blog-layout [data]="data" [blog]="blogObject">

                </calsoft-blog-layout>

              </div>
            </div>

            <div id="list" style="padding: 0px 10px;" *ngIf="mobileScreen" fxHide fxShow.lt-sm fxShow.sm fxShow.md
              fxLayout="row wrap" class="products-wrapper ">
              <div *ngFor="let blogObject of list " fxFlex.xs="50" 
              [fxFlex.lg]="viewCol" [fxFlex.gt-sm]="viewCol"
                fxFlex.lt-md="33.33" class="border-lb" class="col product-mobile backg">

                <calsoft-blog-layout [data]="data" [blog]="blogObject">

                </calsoft-blog-layout>

              </div>
            </div>


            <div *ngIf="!isEmpty" fxHide fxShow.gt-sm fxLayout="row wrap">
              <div fxFlex="100">
                <div class="product-elevation text-center">
                  <pagination-controls class="product-pagination" autoHide="false" (pageChange)="onPageChanged($event)">
                  </pagination-controls>
                </div>
              </div>
            </div>
         
          </div>
        </div>

      </div>

    </div>
  </div>

</ng-container>

<div fxHide fxShow.lt-sm *ngIf="list.length <= count">
  <div class="search-results" infiniteScroll (scrolled)="onScroll()">
    <div fxLayout=row fxLayoutAlign="center center" *ngIf="spinner !=false">

      <mat-spinner [diameter]="20"></mat-spinner>
      <!-- <mat-spinner  *ngIf="this.list.length==this.count"></mat-spinner> -->
    </div>
  </div>
</div>

<!-- <div fxHide fxShow.lt-md fxLayout=row class="padding-10"
 *ngIf="list.length==count"
  fxLayoutGap="10px" fxLayoutAlign="center center">

  <p>No more Blogs</p>


</div> -->





<div fxLayout="row" fxLayoutAlign="center center" *ngIf="spinnerMain !=false" class="overlay">
  <mat-spinner color="primary" [diameter]="45" class="spinner">
  </mat-spinner>
</div>