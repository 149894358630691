import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-mobile-icon2',
  templateUrl: './mobile-icon2.component.html',
  styleUrls: ['./mobile-icon2.component.scss']
})
export class MobileIcon2Component {
  isHovered = false;
  public serverPath = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() data: any;

  @Input() img: any;

  constructor(
    private commonService: CommonService ) {}

  ngOnInit() {


    console.log(this.img);


    if (this.item.customField7 != undefined) {
      if (this.item.customField7.length > 0) {
        document.documentElement.style.setProperty('--hover--icon--colour', this.item.customField7);

      } else {
        document.documentElement.style.setProperty('--hover--icon--colour', 'var(--primary-color)');
      }
    }

    if (this.item.height != undefined) {
      if (this.item.height.length > 0) {
        document.documentElement.style.setProperty('--image--icon--size', this.item.height);
      } else {
        document.documentElement.style.setProperty('--image--icon--size', '280px');
      }
    }

  }


  imageNavigation(id){


    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  link(url) {

    this.commonService.goToLink(url)
  }

}
