import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-textwithimage',
  templateUrl: './textwithimage.component.html',
  styleUrls: ['./textwithimage.component.scss']
})
export class TextwithimageComponent {
  @Input() item: any;
  @Input() data: any;
  customField18:any;
  customField17:any;

  constructor(
    private commonService: CommonService,
  ) {

  }

}
