import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'calsoft-plan-compare',
  templateUrl: './plan-compare.component.html',
  styleUrls: ['./plan-compare.component.scss']
})
export class PlanCompareComponent implements OnInit{
  @Input() item: any;
  @Input() data: any;
  pricedetail:any;
  price_detail:any;  
  items:any;
  constructor() {

  }
  
  ngOnInit() {   
    this.pricedetail = JSON.parse(this.item.customField32);
    this.price_detail = JSON.parse(this.item.customField32);
    this.price_detail[0].open = true;
    
  }      

 
  toggleSection(i) {
    if(this.price_detail[i].list_detail.length >0){
      this.price_detail[i].open = !this.price_detail[i].open;
    }
  }

  // pricedetail = [{
  //   "title":"Price (Local taxes (VAT, GST, etc.) will be charged in addition to the prices mentioned.)",
  //   "list_detail":[
  //     {"sub_title":"Per Month (Billed Annually)", "starter":"₹799", "profession":"₹2,399", "advanced":"₹5,599"},
  //     {"sub_title":"Per Month (Billed Monthly)", "starter":"₹999", "profession":"₹2,999", "advanced":"₹6,999"},  
  //     {"sub_title":"Transaction Fee", "starter":"₹799", "profession":"₹2,399", "advanced":"₹5,599"},  
  //     ]
  //   },
  //   {
  //   "title":"Website Features",
  //   "list_detail":[
  //     {"sub_title":"Website Builder", "starter":"done", "profession":"done", "advanced":"done"},
  //     {"sub_title":"Responsive Website", "starter":"done", "profession":"done", "advanced":"done"},  
  //     {"sub_title":"SSL Certificate", "starter":"done", "profession":"done", "advanced":"done"}, 
  //     {"sub_title":"Bandwidth", "starter":"Unmetered", "profession":"Unmetered", "advanced":"Unmetered"},      
  //     ]
  //   },
  //   {
  //     "title":"Marketing Features",
  //     "list_detail":[
  //       {"sub_title":"Facebook Pixel", "starter":"done", "profession":"done", "advanced":"done"},
  //       {"sub_title":"Google Shopping", "starter":"done", "profession":"done", "advanced":"done"},  
  //       {"sub_title":"Fraud Analytics", "starter":"none", "profession":"none", "advanced":"none"}, 
  //       {"sub_title":"Traffic Analytics", "starter":"none", "profession":"none", "advanced":"none"},      
  //       ]
  //     },
  // ]
  

}
