import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss']
})
export class ImageCardComponent {

  public serverPath = environment.commonImageApi + '/home/';
  
  @Input() item: any;

  @Input() img: any;
  
  @Input() index: any;

  customField21:any;
  customField1:any;

  constructor(
    private commonService: CommonService ) {}
  
  imageNavigation(id){


    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }


  link(url: string) {
    window.open(url, '_self');
  }

}
