
<div  *ngIf="type=='product' else lazyProduct;" >
    
<img  onerror="this.src='assets/images/products/noImage/no-image.png';"
 [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
[ngStyle.gt-sm]="{visibility:isLoading?'hidden':'',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}"
 defaultImage="assets/images/products/placeholder.jpg"
 [lazyLoad]="src" [offset]="100" [alt]="name">
</div>
<ng-template #lazyProduct>
 
<img  onerror="this.src='assets/images/products/noImage/no-image.png';"
 [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
[ngStyle.gt-sm]="{visibility:isLoading?'hidden':'',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}"

 [src]="src" [offset]="100" [alt]="name"></ng-template>