import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-partner-detail',
  templateUrl: './partner-detail.component.html',
  styleUrls: ['./partner-detail.component.scss']
})
export class PartnerDetailComponent {

  data: any;
  Imagedata: any;

  public youtubeUrl = environment.youtubeUrl;

  public serverPath = environment.commonImageApi + '/partnerLogo/';
  
  public serverPath1 = environment.commonImageApi + '/partnerLogo1/';
  
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data,

  ) {

    this.data = data
  }

  
  ngOnInit(): void {
    // console.log(this.data);
    this.Imagedata = this.data.data.partnerDataItem;


  }

  closeModal(){
    this.dialog.closeAll();
  }


  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    // navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  };
}
