<div class="container">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="images">
        <img src="assets/images/1.png" alt="image1">
        <img src="assets/images/2.png" alt="image2">
        <img src="assets/images/3.png" alt="image3">
        <img src="assets/images/4.png" alt="image4">
        <img src="assets/images/5.png" alt="image5">
    </div>
</div>

