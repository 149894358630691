<div fxLayout="column" class="box-container">
    <div class="pricing-box bordertop" [style.background-color]="img.customField10">
        <span>{{img.customField1}}</span>
    </div>

    <div class="pricing-box borderBottom" [style.background-color]="img.customField5">
        <div class="pricing-inner">
            <div class="pricing-header">
                <span>{{img.customField21}}</span>
            </div>
            <div class="pricing-body">
                <button mat-button class="addbtn">{{img.customField12}}</button>
            </div>
        </div>
    </div>
</div>
