<div class="container ">
    <div class="heading gap">
        <div class="gap" fxLayout="row" fxLayoutAlign="space-between center">
            <span style=" font-size: 1.3rem;
            font-weight: 600;" [innerHTML]="item.title"></span>

            <mat-tab-group>
                <mat-tab label="title">
                </mat-tab>
            </mat-tab-group>

        </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="60" fxFlex.xs="100">

            <div fxLayout="row wrap">
                <ng-container *ngFor="let product of categoryProducts; let i=index">
                    <div class="br5" fxFlex.xs="50" fxFlex.lt-md="33.33" fxLayoutGap="10px" fxFlex="33.33">
                        <div ngClass.gt-md="carousel-gap" style="border: 2px solid #eee;">
                            <calsoft-common-product-details-carousel [data]="data" [width]="width"
                                [imageType]="imageType" [height]="height" [heightMobile]="heightMobile"
                                [widthMobile]="widthMobile" [showCartIcon]="showCartIcon"
                                [showCartIncremented]="showCartIncremented"
                                [product]="product"></calsoft-common-product-details-carousel>

                        </div>

                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>