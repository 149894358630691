<div class="time-picker-component">
    <h4>Select Country </h4>
    <form [formGroup]="pinform">
        <mat-form-field appearance="outline" fxFlex="5">
            <mat-select formControlName="ampm" (selectionChange)="onSelectionChange($event)">
                <mat-select-trigger>
                    {{ getCountryCode(pinform.get('ampm').value) }}
                </mat-select-trigger>
                <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search"
                        [formControl]="searchControl"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let country of filteredCountries" [value]="country.id">
                    <img [src]="country.flag">&#160; {{country.name}}</mat-option>
            </mat-select>   
        </mat-form-field>

        <div fxLayout="column" fxFlex="30">
            <mat-form-field fxFlex appearance="outline">
                <input
                  (input)="changeValue('endTimeHour', pinform.value.endTimeHour)"
                  matInput
                  type="tel"
                  placeholder="Enter Number"
                  id="end_time_hour"
                  formControlName="endTimeHour"
                  (click)="preventMatSelectOpen($event)"
                  (keyup)="errmsg($event)"
                />
                
                <!-- Required field error -->
                <mat-error *ngIf="pinform.get('endTimeHour').hasError('required') && pinform.get('endTimeHour').touched">
                  This field is required
                </mat-error>
              
                <!-- Invalid number length error -->
                <mat-error *ngIf="pinform.get('endTimeHour').hasError('customError')">
                  Enter a Valid Number
                </mat-error>
              </mat-form-field>
         
        </div>

        <!-- <button (click)="onSubmit()" [disabled]="pinform.invalid || !isSubmitEnabled()">submit</button>
      <button mat-flat-button color="primary" (click)="onSubmit()" [disabled]="pinform.invalid || !isSubmitEnabled()">Submit</button>
  -->
    </form>
</div>