import { Component, VERSION, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList } from '@angular/cdk/drag-drop';

import { Board } from './models/board.model';
import { Column } from './models/column.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-kanbanform',
  templateUrl: './kanbanform.component.html',
  styleUrls: ['./kanbanform.component.scss']
})
export class KanbanformComponent implements OnInit  {
  board: any;
  public serverPath = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() data: any;

  @Input() img: any;
  @Input() index: any;
 
  // public board: Board = new Board('Test Board', [
  //   new Column('Ideas', '21', [
  //     'Some random idea',
  //     'This is another random idea'
  //   ]),
  //   new Column('Research', '32', [
  //     'Lorem ipsum',
  //     'foo'
  //   ]),
  //   new Column('Product', '25', [
  //     'Lorem ipsum',
  //     'foo',
  //     'This is another random idea'
  //   ])
  // ]);

  constructor(){}

  connectedDropLists: string[] = [];

  ngOnInit(): void {
    this.item.images.forEach(image => {
      this.connectedDropLists.push(image.customField6);
    });
  }

  public dropGrid(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.item.images, event.previousIndex, event.currentIndex);
  }

  public drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
    }
  }
}