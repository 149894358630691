import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Constant } from 'src/app/constants/constant';
import { MenuItems } from 'src/app/Core/menu/menu-items/menu-items';
import { SupplierDialogComponent } from 'src/app/pages/supplier-registration/supplier-dialog/supplier-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-footerlayout',
  templateUrl: './footerlayout.component.html',
  styleUrls: ['./footerlayout.component.scss']
})
export class FooterlayoutComponent {
  public serverPath = environment.commonImageApi + '/home/';

  public bannerServerPath = environment.commonImageApi + '/commonSlider/';
  
  @Input() data: any;

  @Input() item: any;

  @Input() img: any;
  
  @Input() index: any;

  footerIconData: any=[];
  showFooterIcon:any=0;
  customField21:any;
  customField1:any;
  footerLogoSrc: any;
  display: boolean;
  footerBrandImg: any;
  footerImageEnable: any;
  dspeedupNavigationEnable: any;
  menuData: any=[];
 

  constructor(
    private commonService:CommonService,
      public menuItems: MenuItems,
    private configService:ConfigService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private dialog: MatDialog ) {

      this.matIconRegistry.addSvgIcon(
        "Facebook",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/facebook.svg")
      );
      this.matIconRegistry.addSvgIcon(
        "Twitter",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/twitter.svg")
      );
      this.matIconRegistry.addSvgIcon(
        "Youtube",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/youtube.svg")
      );
      this.matIconRegistry.addSvgIcon(
        "Tumblr",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/tumblr.svg")
      );

      this.matIconRegistry.addSvgIcon(
        "Linkedin",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/linkedin.svg")
      );
      this.matIconRegistry.addSvgIcon(
        "Instagram",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/instagram.svg")
      );  
     }

  
  imageNavigation(id){


    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  currentYear: number = new Date().getFullYear();


  link(url: string) {
    window.open(url, '_self');
  }


  groupItems(arr: any[], size: number) {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  }
  
  
  getFooterIcon() {
    this.configService.getFooterData()
      .subscribe(
        data => {

        
          if(data['datalist'].length==0){
            this.showFooterIcon=0;
            this.footerIconData=[];
          }else{
            this.footerIconData=data['datalist'];
          }

          if(this.data.footerLogoSrc!=undefined){
            this.footerLogoSrc=this.data.footerLogoSrc;
          }
          
          }, error => {

          }
          );
}


ngOnInit() {

  if(window.innerWidth>959){
    this.display=true;
  }

   
  if(this.data.footerLogoSrc!=undefined){
    this.footerLogoSrc=this.data.footerLogoSrc;
  }

  if (this.data.footerBrandImg != undefined) {
    this.footerBrandImg = this.data.footerBrandImg;
  }

  if(this.data.footerImageEnable!=undefined){
    this.footerImageEnable=this.data.footerImageEnable;
  }

  if(this.data.dspeedupNavigationEnable!=undefined){
    this.dspeedupNavigationEnable=this.data.dspeedupNavigationEnable;
  }
  
  this.getFooterMenuData()
  if(this.data.showFooterIcon==1){

    this.showFooterIcon=1;
    this.getFooterIcon();
  }
 }
  // getFooterMenuData() {
  //   throw new Error('Method not implemented.');
  // }

  navigate(data:any) {

    if(data.linkNavigated==1){
    
      if(data.link!=null){
        let   url = data.link
        window.location.href=url;
      }
      
    }
  }
      
  
  Supplierlogin() {
    if (window.innerWidth > 768) {
  
      let dialogRef = this.dialog.open(SupplierDialogComponent, {
      width: '790px',
      });
  
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
  
  
      });
  
    }
    else {
      let dialogRef = this.dialog.open(SupplierDialogComponent, {
      minWidth: '100vw', height: '100vh',
  
      });
  
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
  
  
      });
    }
  
    }
  
    composeEmail(smtpUsername) {
      window.location.href = 'mailto:' + smtpUsername;
    }
  
    getDirections(googleMapNavigation:string): void{
      if (googleMapNavigation && googleMapNavigation.toLowerCase() !== 'none') {
        let url = googleMapNavigation.toLowerCase();
        
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          url = 'http://' + url;
        }
    
        this.commonService.goToLink(url);
      }
  
    }
  
    gotoLink(url: string) {
      if (url) {
        const fullURL = this.prependProtocol(url);
        this.commonService.goToLink(url);
  
      }
    }
    
    prependProtocol(url: string): string {
      if (!url.includes('http://') && !url.includes('https://')) {
        return 'https://' + url;
      }
      return url;
    }
  
    imageLoaded: boolean = true; // Initially true to attempt loading the image
  
    onImageError() {
      this.imageLoaded = false; // Hide the image if it fails to load
    }
  
    
   callPhone() {
    let phoneNumber = "tel:" + this.data.storePhoneNumber;
    window.location.href = phoneNumber;
  }
  

  subscribe(){ }

  getFooterMenuData(){

    if((this.data.showContactPage==1&&this.data.showBlogPage==0)){

      this.menuData=this.menuItems.getFooterOneMenu()
    }else if(this.data.showContactPage==0 &&this.data.showBlogPage==0 ){
      this.menuData=this.menuItems.getFooterTwoMenu()
    }else if(this.data.showContactPage==0 &&this.data.showBlogPage==1 ){
      this.menuData=this.menuItems.getFooterThirdMenu()
    }else if(this.data.showContactPage==1 &&this.data.showBlogPage==1 ){
      this.menuData=this.menuItems.getFooterFourthMenu()
    }
  }
}





//   images = [
//     {
//       name: 'yrtvschool',
//       description: 'Yennarkay R. Ravindran Thilagavathy Vidhyasala Matriculation Higher Secondary School',
//       subimages: [
//         { icon: 'mail', value: 'kavan@gmail.com', link: 'mailto:kavan@gmail.com' },
//         { icon: 'local_phone', value: '23164654', link: 'tel:23164654' },
//         { icon: 'location_on', value: 'asthd', link: '#' }
//       ]
//     },
//     {
//       name: 'USEFUL LINKS',
//       subimages1: [
//         { name: 'Home', link: '' },
//         { name: 'YRTV', link: 'https://yrtvschool.dspeedup.in/pages/YRTV' },
//         { name: 'History', link: 'https://yrtvschool.dspeedup.in/pages/history' },
//         { name: 'Curriculum', link: 'https://yrtvschool.dspeedup.in/pages/curriculum' },
//         { name: 'Club Activities', link: 'https://yrtvschool.dspeedup.in/pages/Club%20Activities' },
//         { name: 'Sports', link: 'https://yrtvschool.dspeedup.in/pages/Sports' },
//         { name: 'Accreditation', link: 'https://yrtvschool.dspeedup.in/pages/accreditation' },
//         { name: 'Academics', link: 'https://yrtvschool.dspeedup.in/pages/Academics' },
//         { name: 'Faculties', link: 'https://yrtvschool.dspeedup.in/pages/Faculties' },
//         { name: 'News Events', link: 'https://yrtvschool.dspeedup.in/pages/News%20and%20Events' },
//         { name: 'Gallery', link: 'https://yrtvschool.dspeedup.in/pages/Gallery' },
//       ]
      
//     },
//     {
//       name: 'GALLERY',
//       link:'https://yrtvschool.dspeedup.in/pages/Gallery',
//       subimages2: [
//         { img: 'https://www.yrtvschool.com/assets/images/gallery/junior-block-yrtv-sivakasi.jpg', link: 'https://yrtvschool.dspeedup.in/pages/Gallery', view:'0' },
//         { img: 'https://www.yrtvschool.com/assets/images/gallery/auditorium-yrtv-school-sivakasi.jpg', link: 'https://yrtvschool.dspeedup.in/pages/Gallery',view:'0' },
//         { img: 'https://www.yrtvschool.com/assets/images/gallery/kg-class-room-sivakasi-yrtv-school.jpg', link: 'https://yrtvschool.dspeedup.in/pages/Gallery',view:'0' },
//         { img: 'https://www.yrtvschool.com/assets/images/gallery/maths-lab-yrtv.jpg', link: 'https://yrtvschool.dspeedup.in/pages/Gallery',view:'0' },
//         { img: 'https://www.yrtvschool.com/assets/images/gallery/chess-class-sivakasi.jpg', link: 'https://yrtvschool.dspeedup.in/pages/Gallery',view:'0' },
//         { img: 'https://www.yrtvschool.com/assets/images/gallery/pre-kg-block-yrtv.jpg', link: 'https://yrtvschool.dspeedup.in/pages/Gallery',view:'0' },
//         { img: 'https://www.yrtvschool.com/assets/images/gallery/sivakasi-library.jpg', link: 'https://yrtvschool.dspeedup.in/pages/Gallery',view:'0' },
//         { img: 'https://www.yrtvschool.com/assets/images/gallery/sivakasi-yrtv-school-dining-hall-students.jpg', link: 'https://yrtvschool.dspeedup.in/pages/Gallery',view:'0' },
//         { img: 'https://www.yrtvschool.com/assets/images/gallery/sivakasi-yrtv-school-physics-lab.jpg', link: 'https://yrtvschool.dspeedup.in/pages/Gallery' ,view:'1'}
   
//       ]
//     },
//     {
//       name: 'Principals Message',
//       content: ' Dream as high as you can, as best dreams happen if you keep working at it. Make each day your master piece. Manifest plainness, embrace simplicity and give up selfishness He who sows virtue reaps great honour.<br>- Mr. L. MURALI',
//       img2:'"https://www.yrtvschool.com/images/yrtv-principal.jpg'
    
      
//     },
   
   
//   ]
//   footerLinks = [
//     { name: 'HOME', url: 'https://yrtvschool.dspeedup.in/pages/YRTV' },
//     { name: 'YRTV', url: 'https://yrtvschool.dspeedup.in/pages/YRTV' },
//     { name: 'GALLERY', url: 'https://yrtvschool.dspeedup.in/pages/Gallery' },
//     { name: 'ACHIEVEMENTS', url: 'https://yrtvschool.dspeedup.in/pages/Achievements' },
//     { name: 'CONTACT', url: '' },
//   ];

//   nwsiplLink = { name: 'NWSIPL', url: '' };
// }

