import { Component, Input, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'calsoft-speechtext',
  templateUrl: './speechtext.component.html',
  styleUrls: ['./speechtext.component.scss']
})
export class SpeechtextComponent implements OnInit {
  @Input() data: any = [];
  items: string[] = ['Chicken Rice', 'Veg Rice', 'Salad', 'Soup'];
  selectedItems: string[] = [];
  playButtonText: string = 'Play';
  playIconText: string = 'play_arrow';
  isPlaying: boolean = false;
  currentIntervalSubscription: Subscription | undefined;

  voices: SpeechSynthesisVoice[] = [];
  selectedVoice: SpeechSynthesisVoice | null = null;
  @Input() speechArray: any;
  newArray: string[];

  constructor() {}

  ngOnInit(): void {
    // Listen for the voiceschanged event to populate the voices
    speechSynthesis.onvoiceschanged = () => {
      this.populateVoices();
    };
  }

  populateVoices(): void {
    // Clear existing voices
    this.voices = [];
    // Get the new list of voices
    this.voices = speechSynthesis.getVoices();
    // Select the first voice by default
    this.selectedVoice = this.voices.find(voice => voice.name === 'Google UK English Female') || this.voices[0] || null;
  }

  play(): void {
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.playButtonText = 'Stop';
      this.playIconText = 'pause';
      let currentIndex = 0;
      const totalItems = this.speechArray.length;
      this.currentIntervalSubscription = interval(1000).subscribe(() => {
        if (currentIndex < totalItems) {
          this.readOut(this.speechArray[currentIndex]);
          currentIndex++;
        } else {
          this.stopPlaying();
        }
      });
    } else {
      this.stopPlaying();
    }
  }

  stopPlaying(): void {
    if (this.currentIntervalSubscription) {
      this.currentIntervalSubscription.unsubscribe();
    }
    this.isPlaying = false;
    this.playButtonText = 'Play';
    this.playIconText = 'play_circle_outline';
  }

  readOut(text: string): void {
    // console.log(text);
    const speech = new SpeechSynthesisUtterance();
    speech.lang = 'en-US'; // English
    speech.voice = this.selectedVoice;
    speech.text = text;
    window.speechSynthesis.speak(speech);
  }

  onVoiceChange(voiceName: string): void {
    this.selectedVoice = this.voices.find((voice) => voice.name === voiceName) || null;
  }
}
