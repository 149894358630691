<div class="container">

    <div fxLayout="row">

        <div fxFlex="50">
            <div fxFlex="25" fxLayoutAlign="center center">

                <img width="100%" src="assets/images/map/map.jpg">

            </div>

            <div class="content" fxFlex="25">

                <div class="title">
                    <p>BEACH RETREATS</p>
                </div>

                <div class="description">
                    <p>
                        Wake up to the sound of waves lapping lazily at the shore. Uncover serenity and marvel at the
                        wealth
                        of beauty within the glittering blue seas. Give in to the siren song of the sun, sand and surf
                        with
                        Beach Retreats.
                    </p>
                </div>

                <div class="navigation">
                    <span>
                        VIEW DETAILS
                    </span>

                </div>

            </div>
        </div>

        <div fxFlex="50">
            <div fxFlex="25" fxLayoutAlign="center center">

                <img width="100%" src="assets/images/map/map.jpg">

            </div>

            <div class="content" fxFlex="25">

                <div class="title">
                    <p>BEACH RETREATS</p>
                </div>

                <div class="description">
                    <p>
                        Wake up to the sound of waves lapping lazily at the shore. Uncover serenity and marvel at the
                        wealth
                        of beauty within the glittering blue seas. Give in to the siren song of the sun, sand and surf
                        with
                        Beach Retreats.
                    </p>
                </div>

                <div class="navigation">
                    <span>
                        VIEW DETAILS
                    </span>

                </div>

            </div>
        </div>

    </div>
    <div fxLayout="row">

        <div fxFlex="25" fxLayoutAlign="center center">

            <img width="100%" src="assets/images/map/map.jpg">

        </div>

        <div class="content" fxFlex="25">

            <div class="title">
                <p>BEACH RETREATS</p>
            </div>

            <div class="description">
                <p>
                    Wake up to the sound of waves lapping lazily at the shore. Uncover serenity and marvel at the wealth
                    of beauty within the glittering blue seas. Give in to the siren song of the sun, sand and surf with
                    Beach Retreats.
                </p>
            </div>

            <div class="navigation">
                <span>
                    VIEW DETAILS
                </span>

            </div>

        </div>

    </div>

</div>