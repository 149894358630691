import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-new-menu-sub',
  templateUrl: './new-menu-sub.component.html',
  styleUrls: ['./new-menu-sub.component.scss']
})
export class NewMenuSubComponent implements OnInit{
  constructor(private commonService: CommonService,
    
    private elementRef: ElementRef) { }

  @Input() category: any;

  ngOnInit(): void {
    this.setDynamicWidth();
  }

  goToCategory(menu) {
    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);
  }

  calculateWidth(): number {
    // Your calculation logic here (e.g., based on content, number of columns, etc.)
    // For example, let's say you want to set the width to 50% of the height
    const height = this.elementRef.nativeElement.clientHeight;
    return height * 0.5; // Adjust the multiplier as needed
  }

  setDynamicWidth(): void {
    const width = this.calculateWidth();
    this.elementRef.nativeElement.style.width = `${width}px`;
  }
}
