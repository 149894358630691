<div class="search-bar">
  <mat-select [(value)]="selectedCategory" placeholder="All Categories"
   class="category-select">
    <mat-option  *ngFor="let menu of categoryArray" 
    [value]="menu.categoryId">
      {{ menu.categoryName }}
    </mat-option>
  </mat-select>



  <input type="search" [ngStyle]="{'border-radius':searchbarborderRadius }" autocomplete="off" spellcheck="false"
    class="search-input " [(ngModel)]="search" (input)="filterOption($event)" (keyup.enter)="onKeydown($event)"
    (focus)="openDropdown()" placeholder="Search..." #inputElem placeholder="What do you need?"
    [matAutocomplete]="auto">

  <mat-autocomplete class="mat-elevation-z2 autocmp" #auto="matAutocomplete">
    <mat-option *ngFor="let filter of filteredOptions" (keydown.enter)="getProducts(filter.name)"
      (click)="getProducts(filter.name)" [value]="filter.name">
      <mat-icon>search</mat-icon>
      <span>{{ filter.name }}</span>
    </mat-option>
  </mat-autocomplete>

  <button *ngIf="search=='' || search==null" class="search-icon" style=" cursor: pointer;" (click)="hideSearchBar()"
    class="search-button">Search</button>
</div>