import { Component, HostListener, Input, OnInit } from '@angular/core';
import { trigger, style, transition, animate, query, stagger } from '@angular/animations';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'calsoft-text-animation',
  templateUrl: './text-animation.component.html',
  styleUrls: ['./text-animation.component.scss']

})
export class TextAnimationComponent implements OnInit {


  isScrolled = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isScrolled = window.scrollY > 0;
  }


  @Input() item: any;

  @Input() data: any;

  @Input() img: any;

  public bannerServerPath = environment.commonImageApi + '/commonSlider/';

  constructor() { }

  ngOnInit(): void {
    
  }
}
