import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-image-contact-form',
  templateUrl: './image-contact-form.component.html',
  styleUrls: ['./image-contact-form.component.scss']
})
export class ImageContactFormComponent {

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() img: any;
  @Input() data: any;

  isMobileView(): boolean {
    return window.innerWidth <= 768; // Adjust breakpoint as needed for mobile
  }
}
