import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GeneralComponent } from './general.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';

import { ShippingPolicyComponent } from './shipping-policy/shipping-policy.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';
import { PaymentPolicyComponent } from './payment-policy/payment-policy.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

const routes: Routes = [
  {
  path: 'aboutus', component: AboutusComponent,
  data: { breadcrumb: 'about us' }

},
{
  path: 'contact-us', component: ContactUsComponent,
  data: { breadcrumb: 'contact-us' }

},

{
  path: 'pages/:pageId',
  component: GeneralComponent,
  data: { breadcrumb: 'pages' }
},
{
  path: ':pageId',
  component: GeneralComponent,
  data: { breadcrumb: 'pages' }
},
{
  path: 'privacy',
  component: PrivacyComponent,
  data: { breadcrumb: 'Privacy Policy' }

},
{
  path: 'shippingPolicy',
  component: ShippingPolicyComponent,
  data: { breadcrumb: 'Shipping Policy' }

},
{
  path: 'returnPolicy',
  component: ReturnPolicyComponent,
  data: { breadcrumb: 'Return Policy' }

},

{
  path: 'paymentPolicy',
  component: PaymentPolicyComponent,
  data: { breadcrumb: 'Payment Policy' }
},

{
  path: 'terms&conditions',
  component: TermsConditionsComponent,
  data: { breadcrumb: 'TERMS & CONDITIONS' }
},


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GeneralRoutingModule { }
