<div fxLayout="row" fxLayout.xs="column" fxFlexFill style="height: 100%;">

  <!-- Left Side (Video Upload) -->
  <div  fxHide fxShow.gt-sm fxFlex="60"   fxLayoutAlign="center center" class="left-side" style="height: auto;">
    
    <!-- Display Video inside the Left Half -->
    <video oncanplay="this.play()" onloadedmetadata="this.muted = true" 
           class="video-sc-desktop" 
           autoplay muted loop 
           style="width: 100%; height: 100%; object-fit: cover;">
      <source [src]="serverPath1 + item.customField10" type="video/mp4">
    </video>

  </div>

  <!-- Right Side (Contact Form) -->
  <div fxFlex="40" fxFlex.xs="100" class="right-side" style="height: 100%; position: relative;">
    
    <!-- Close Button in the Top Right Corner -->
    <button mat-icon-button (click)="close()" 
            style="position: absolute; top: 10px; right: 10px; z-index: 10;">
      <mat-icon>close</mat-icon>
    </button>

    <div fxFlex.xs="100" fxLayoutAlign="end center" style="height: 100%;">
      <calsoft-contact-form [item]="item" [input]="item.title">
      </calsoft-contact-form>
    </div>

  </div>

</div>
