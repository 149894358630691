

<ng-template  >

 <span class="imageResize" fxHide.lt-sm style="color:white;"></span>
  <div (click)="
  data.navigation==0?goToCategories():link(data.navigatedUrl)" class="carousel-slide" 
  [style.background-image]="image" [alt]="data.altImage">
    <div class="carousel-slide-content" ><ng-content></ng-content></div>
    <div
      *ngIf="!hideOverlay"
      class="carousel-slide-overlay"
      [attr.aria-label] = "ariaLabel"
      [style.background-color]="overlayColor"
    ></div>
  </div>
</ng-template>