import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {
  calculatorForm!: FormGroup;
  calculatedResult: number | null = null;
  dynamicFormula: string = '';  // This will hold the dynamic formula input from the user

  calculatorData = {
    title: 'Tax Calculator',
    fields: [
      { label: 'Income', type: 'number', name: 'income', placeholder: 'Enter your income' },
      { label: 'Deductions', type: 'number', name: 'deductions', placeholder: 'Enter deductions' },
      { label: 'Tax Rate', type: 'number', name: 'taxRate', placeholder: 'Enter tax rate' }
    ],
    submitText: 'Calculate Tax',
    formula: '(income - deductions) * taxRate / 100', // Re-add the formula here
  };

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    const controls = this.calculatorData.fields.reduce((acc: any, field: any) => {
      acc[field.name] = [''];
      return acc;
    }, {});
    this.calculatorForm = this.fb.group(controls);
  }

  onSubmit() {
    if (this.calculatorForm.valid) {
      const formValues = this.calculatorForm.value;

      // Use dynamic formula entered by user
      const formula = this.dynamicFormula || this.calculatorData.formula;

      try {
        this.calculatedResult = this.evaluateFormula(formula, formValues);
        console.log('Calculated Result:', this.calculatedResult);
        alert(`Result: ${this.calculatedResult}`);
        
        // Redirect to lead collection form or open modal
        alert('Redirecting to lead collection form...');
      } catch (error) {
        console.error('Error evaluating formula:', error);
        alert('Invalid formula or input values!');
      }
    }
  }

  evaluateFormula(formula: string, values: any): number {
    // Replace variable names in the formula with actual values
    const formulaWithValues = formula.replace(/(\w+)/g, (match) => {
      return values[match] !== undefined ? values[match] : match;
    });

    // Evaluate the formula using JavaScript's eval
    return eval(formulaWithValues);
  }
}
