<!-- <div  fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" style=" background-image: url('assets/images/seller.jpg');padding: 30px;background-size: cover;background-repeat:no-repeat;" >
    <div fxLayout="column" fxLayoutAlign="center center">
        <h5>About</h5>
        <h2>YRTV Matriculation Higher Secondary School</h2>
    </div>
    <div  fxLayout="column" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
        <-- Topmost card
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <div class="card">
            <img src="assets/images/others/1.jpg" alt="Top Image" />
            <p class="title">Our Guiding Lights</p>
            <p class="name">The Late Shri Yennarkay Rajaratnam</p>
            <p class="name">The Late Smt. Poothayammal</p>
          </div>
        </div>
      
        <-- Second level
        <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px">
          <div class="card">
            <img src="assets/images/others/1.jpg" alt="Second Image" />
            <p class="name">Sri Yennarkay R. Ravindran</p>
            <p class="role">Founder & Our Former Patron</p>
          </div>
          <div class="card">
            <img src="assets/images/others/1.jpg" alt="Third Image" />
            <p class="name">Smt. Thilagavathy Ravindran</p>
            <p class="role">Patroness & Correspondent</p>
          </div>
        </div>
      
        <-- Third level 
        <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px">
          <div class="card">
            <img src="assets/images/others/1.jpg" alt="Fourth Image" />
            <p class="name">Mr. Yennarkay R. Rajaratnam</p>
            <p class="role">Management Committee Member</p>
          </div>
          <div class="card">
            <img src="assets/images/others/1.jpg" alt="Fifth Image" />
            <p class="name">Mr. Yennarkay R. Chiranjeevirathnam</p>
            <p class="role">Management Committee Member</p>
          </div>
          <div class="card">
            <img src="assets/images/others/1.jpg" alt="Sixth Image" />
            <p class="name">Mr. Yennarkay R. Selvarathnam</p>
            <p class="role">Management Committee Member</p>
          </div>
        </div>
      </div>
      
</div> -->


<div fxHide fxShow.lt-md>
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" style="margin-bottom:15px;">
    <div fxLayout="column" fxLayoutAlign="center center">
      <!-- <h5>About</h5> -->
      <h2 [innerHTML]="item.title"> </h2>
    </div>

    <!-- Generate the rows of cards -->
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutGap="20px">
        <div class="card"   [ngStyle.lt-md]="{'height': item.heightMob}" *ngFor="let img of item.images; let i = index">
          <img [src]="serverPath+img.img" [alt]="img.altImage" />
          <p class="name">{{img.customField20}}</p>
          <p class="role">{{img.customField21}}</p>
        </div>
      </div>
    </div>
  </div>
</div>


<div fxHide.lt-md>
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" style="margin-bottom:15px;">

    <div fxLayout="column" fxLayoutAlign="center center">
      <h2 [innerHTML]="item.title"></h2>
    </div>

    <!-- Generate the rows of cards dynamically -->
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <div *ngFor="let row of pyramidRows; let rowIndex = index" fxLayoutAlign="center center" fxLayoutGap="20px">
        <div *ngFor="let card of row" [style.height]="item.height"  class="card">
          <img [src]="serverPath + card.img" [alt]="card.altImage" />
          <p class="name">{{card.customField20}}</p>
          <p class="role">{{card.customField21}}</p>
        </div>
      </div>
    </div>

  </div>
</div>