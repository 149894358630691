import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'calsoft-banner-carousel-text',
  templateUrl: './banner-carousel-text.component.html',
  styleUrls: ['./banner-carousel-text.component.scss']
})
export class BannerCarouselTextComponent implements OnInit{
  @Input() item: any;
  @Input() img: any;

  ngOnInit(): void {
    //console.log('ss');
  }

  handleMouseOver(button: HTMLButtonElement, item: any): void {
    button.style.backgroundColor = '#000';
    button.style.color = '#fff';
    button.style.borderColor = '#000';
  }

  handleMouseOut(button: HTMLButtonElement, item: any): void {
    button.style.backgroundColor = item.customField34;
    button.style.color = item.customField33;
    button.style.borderColor = item.customField33;
  }

}
