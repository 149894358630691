import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'calsoft-shipping-policy',
  templateUrl: './shipping-policy.component.html',
  styleUrls: ['./shipping-policy.component.scss']
})
export class ShippingPolicyComponent implements OnInit {
  public PageTitle = "shippingPolicy";
  constructor() { }

  ngOnInit(): void {
  }

}
