<!-- <div fxFlex="100">
    <div class="banner-group">
        <div fxLayout="row">
            <div fxFlex="50">
   
                <div class="banner banner-big banner-border" contentAnim aniName="fadeInRightShorter">
                    <a >
                        <img  style="width:100%" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}"
                         [src]="serverPath+image" [alt]="serverPath+image" style="background-color: #c2cdd1;">
                    </a>

                    <div class="banner-content">
                        <h4 class="banner-subtitle"><a >{{img.customField4}}</a></h4>
                        <h3 class="banner-title"><a ><span>{{img.customField5}}<br></span></a></h3>
                        <a  class="btn btn-outline-primary-2 banner-link">{{img.customField3}}<i class="icon-long-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div fxFlex="50">
                <div class="banner banner-border-hover" contentAnim [aniName]="item.customField19">
                    <a>
                        <img style="width:100%" [style.height]="item.customField25" [ngStyle.lt-md]="{'height': item.customField26 }" [src]="serverPath1+item.customField2" alt="Banner"  style="background-color: #ecebe9;">
                    </a>

                    <div class="banner-content">
                        <h4 class="banner-subtitle"><a >{{item.customField5}}</a></h4>
                        <h3 class="banner-title"><a ><span>{{item.customField6}}<br>{{item.customField8}}</span></a></h3>
                        <a  class="btn btn-outline-primary-2 banner-link">{{item.customField10}}<i class="icon-long-arrow-right"></i></a>
                    </div>
                </div>
                

                <div class="banner banner-border-hover"  contentAnim [aniName]="item.customField21">
                    <a >
                        <img style="width:100%" [style.height]="item.customField25" [ngStyle.lt-md]="{'height': item.customField26 }" 
                        [src]="serverPath1+item.customField3" alt="Banner"  style="background-color: #ecebe9;">
                    </a>

                    <div class="banner-content">
                        <h4 class="banner-subtitle"><a >{{item.customField9}}</a></h4>
                        <h3 class="banner-title"><a ><span>{{item.customField14}} <br>{{item.customField20}}</span></a></h3>
                        <a  class="btn btn-outline-primary-2 banner-link">{{item.customField23}}<i class="icon-long-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

 

<div fxLayout="row" fxLayout.xs="column">

    <div fxFlex="50" fxFlex.xs="100" class="banner banner-big banner-border" contentAnim aniName="fadeInRightShorter" >
        <a>
            <img width="100%" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}"
             [src]="serverPath+image" [alt]="serverPath+image" style="background-color: #c2cdd1;">
             <!-- <calsoft-ng-optimized-image width="100%" style="background-color: #c2cdd1;" [height]="item.height" [heightMob]="item.heightMob" [altImage]="serverPath+image"  [image]="serverPath + img.img" [i]="'0'"></calsoft-ng-optimized-image> -->

        </a>

        <div class="banner-content">
            <h4   [ngStyle]="{'font-family': item.fontFamily}" class="banner-subtitle hover-effect"><a style="text-decoration: none; color: white;">{{img[0].customField4}}</a></h4>
            <h3   [ngStyle]="{'font-family': item.fontFamily}" class="banner-title"><a style="text-decoration: none;"><span [style.color]="item.customField22" [innerHTML]="img[0].customField5"><br></span></a></h3>
            <a   [ngStyle]="{'font-family': item.fontFamily}" style="cursor: pointer;" class="banner-link primary-icon link-with-hover"  (click)="openInNewWindow(img[0].customField6)"  [style.color]="item.customField22">{{img[0].customField3}}<i class="icon-long-arrow-right"></i></a>
        </div>
    </div>
  
    <div [fxFlex]="item.gapBetweenImage">

    </div>

    <div fxFlex="50" fxFlex.xs="100" fxLayout="column">
  
    
      <div fxFlex="50" class="banner banner-border-hover" contentAnim [aniName]="item.customField19">
        
        <a>
            <!-- <img width="100%" [style.height]="item.customField25" [ngStyle.lt-md]="{'height': item.customField26 }" [src]="serverPath1+item.customField2" alt="Banner"  style="background-color: #ecebe9;"> -->
            <calsoft-ng-optimized-image style="background-color: #ecebe9;" width="100%" [height]="item.customField25" [heightMob]="item.customField26 " [altImage]="'Banner'"  [image]="serverPath1+item.customField2" [i]="'0'"></calsoft-ng-optimized-image>

        </a>

        <div class="banner-content1">
            <h4   [ngStyle]="{'font-family': item.fontFamily}" class="banner-subtitle"><a style="text-decoration: none; color: white;">{{item.customField5}}</a></h4>
            <h3  [ngStyle]="{'font-family': item.fontFamily}"  class="banner-title"><a style="text-decoration: none;"><span>{{item.customField6}}<br>{{item.customField8}}</span></a></h3>
            <a   [ngStyle]="{'font-family': item.fontFamily}" style="cursor: pointer;"  class="banner-link primary-icon link-with-hover"  (click)="goToCategories(item.categoryId1)" >{{item.customField10}}<i class="icon-long-arrow-right"></i></a>
        </div>

      </div>

      <div [style.margin-top]="item.gapBetweenImage">
    </div>

  
      
      <div fxFlex="50" class="banner banner-border-hover"  contentAnim [aniName]="item.customField21">
        <a >
            <!-- <img width="100%" [style.height]="item.customField25" [ngStyle.lt-md]="{'height': item.customField26 }" 
            [src]="serverPath1+item.customField3" alt="Banner"  style="background-color: #ecebe9;"> -->
            <calsoft-ng-optimized-image width="100%" [height]="item.customField25" style="background-color: #ecebe9;" [heightMob]="item.customField26 " [altImage]="'Banner'"  [image]="serverPath1+item.customField3" [i]="'0'"></calsoft-ng-optimized-image>

        </a>

        <div class="banner-content1">
            <h4   [ngStyle]="{'font-family': item.fontFamily}" class="banner-subtitle"><a style="text-decoration: none; color: white;">{{item.customField9}}</a></h4>
            <h3   [ngStyle]="{'font-family': item.fontFamily}" class="banner-title"><a style="text-decoration: none;"><span>{{item.customField14}} <br>{{item.customField20}}</span></a></h3>
            <a   [ngStyle]="{'font-family': item.fontFamily}" style="cursor: pointer;" class="banner-link primary-icon link-with-hover" (click)="goToCategories(item.categoryId2)">{{item.customField23}}<i class="icon-long-arrow-right"></i></a>
        </div>
      </div>
  
    </div>
  
  </div>
  