import { Component, Input, OnInit } from '@angular/core';
import { GoldRateService } from 'src/app/services/gold-rate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-live-rate',
  templateUrl: './live-rate.component.html',
  styleUrls: ['./live-rate.component.scss']
})
export class LiveRateComponent implements OnInit {
  rateList: any;

  
  @Input() item: any;
  
  @Input() img: any;

  constructor( public goldRate: GoldRateService,){
   
  }


  public serverPath1 = environment.commonImageApi + "gold/";

  ngOnInit() {

    this.getAllLiveRate()
   
  }

  getAllLiveRate() {
    this.goldRate.getAllGoldLiveRate().subscribe(
      res => {
        this.rateList = res;

        console.log(this.rateList);
        
        
      },
      error => {
      }
    )
  }

}
