import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'calsoft-category-lists',
  templateUrl: './category-lists.component.html',
  styleUrls: ['./category-lists.component.scss']
})
export class CategoryListsComponent implements OnInit{

  public serverPath = environment.commonImageApi + 'category/image/150x150/';
  categoryArray: any;
  public viewCol: number = 25;
  categoryArray1:any;

  constructor(
    private categoryService: CategoryService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getCategories();
    this.categoryArray1 = [{"categoryName":"grocery1", 
    "subcategroty":[{'categoryName':"title test1"}, {'categoryName':"title test2"}]
    },
    {"categoryName":"grocery2", 
    "subcategroty":[{'categoryName':"title test2"}, {'categoryName':"title test33"}]
    }]
  
  }
  getCategories() {
    this.categoryService.categoryListNotInMenu().subscribe(
      result => {
        this.categoryArray = result['data'];
        console.log(this.categoryArray)
      },
      error => {
      }
    )
  }

  goToCategory(menu) {
    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);

  }

  selectedCategoryIndex: number | null = 0;

  toggleSubcategory(index: number): void {
    // If the selected category is clicked again, hide its subcategroty list
    this.selectedCategoryIndex = this.selectedCategoryIndex === index ? null : index;
  }

}
