<!-- <div class="main" fxLayoutAlign="center center" style="font-weight: bolder; z-index: 1000;">
    <h1>Welcome to the video page</h1>
</div>
<div>
    <div style="z-index: 1;">
    <p fxLayoutAlign="center center" style="font-weight: bolder;"> Capture the undeniable benefits of omnichannel
        commerce. Utilize a customizable solution to craft dynamic and personalized shopping experiences, enhancing
        loyalty and boosting ROI </p>
        </div>
    
</div>
<div class="image" fxLayoutAlign="center center" style="z-index: 0;">
    <img src='https://static.wixstatic.com/media/535075_310af5f792924bd887cba6132759c6c9~mv2.png/v1/fill/w_740,h_740,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/Add%20a%20heading%20(1).png'
        alt="Description">
</div>
<div  fxLayoutAlign="center center" style="z-index: 0;">
    <video  loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" style="z-index: 0;">
        <source src="/assets/videos/file.mp4" type="video/mp4">
    </video>
    </div> -->
    
    <div class="main" fxLayoutAlign="center center" style="font-weight: bolder; z-index: 0;">
        <h1>Welcome to the video page</h1>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
        <div style="z-index: 1; text-align: center;">
            <p style="font-weight: bolder;"> Capture the undeniable benefits of omnichannel commerce. Utilize a customizable solution to craft dynamic and personalized shopping experiences, enhancing loyalty and boosting ROI </p>
        </div>
    </div>
    <div class="image" fxLayoutAlign="center center" style="z-index: 0;">
        <img src='https://static.wixstatic.com/media/535075_310af5f792924bd887cba6132759c6c9~mv2.png/v1/fill/w_740,h_740,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/Add%20a%20heading%20(1).png' alt="Description">
    </div>
    <div fxLayoutAlign="center center" style="z-index: 0;">
        <video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" class="responsive-video">
            <source src="/assets/videos/file.mp4" type="video/mp4">
        </video>
    </div>
    
  