<div [style.backgroundImage]="'url(' + serverPath+item.customField24 + ')'"
    [style.background-color]="item.customField27">

    <div class="container" 
        [fxLayout.gt-md]="item.customField3=='right'?'row':'row-reverse'" fxLayout.xs="column">



        <div fxFlex>

            <div fxLayout="row" style="height: 100%;">

                <div fxLayout="row" [style.align-items]="item.customField30">


                    <div fxLayout="column">
    
                        <div fxLayout="row">
    
                            <h2 [style.color]="item.customField28">
                                <calsoft-editor-text [data]="data"  [ngStyle.gt-md]="{'font-size': item.customField18 }"
                                [ngStyle.lt-md]="{
                                    'text-align': item.customField30,
                                    'font-size': item.customField17
                                }"
                      [text]="item.title" 
                                [align]="item.customField34">
                                </calsoft-editor-text>
                            </h2>
    
    
                            <!-- <h2 [style.color]="item.customField28" [innerHTML]="item.title"></h2> -->
    
                        </div>
    
                        <div fxLayout="row">
    
    
                            <span [style.color]="item.customField28">
    
                                <calsoft-editor-text [data]="data"   [ngStyle.gt-md]="{'font-size': item.customField37 }"
                                [ngStyle.lt-md]="{
                                    'text-align': item.customField30,
                                    'font-size': item.customField38
                                }"
              [text]="item.customField1" 
                                [align]="item.customField34">
                                </calsoft-editor-text>
                            </span>
    
    
                            <!-- <span [style.color]="item.customField28" class="description"
                                [style.font-size]="item.customField18" [ngStyle.lt-md]="{ 'font-size': item.customField17}"
                                [innerHTML]="item.customField1"></span> -->
    
                        </div>
    
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center center" style="padding-top: 10px;">
    
                            <button class="buttonClass" [style.border-radius]="item.customField11"
                            [ngStyle.gt-md]="{'font-size': item.customField25 }"
                            [ngStyle.lt-md]="{'font-size': item.customField26}"
                                *ngIf="item.images[0].navigation==0  && item.customField5.length > 0"
                                (click)="goToCategories(item.images[0].customField2)">{{item.customField5}}</button>
                            <button class="buttonClass" [style.border-radius]="item.customField11"
                            [ngStyle.gt-md]="{'font-size': item.customField25 }"
                            [ngStyle.lt-md]="{'font-size': item.customField26}"
                                *ngIf="item.images[0].navigation==1   && item.customField5.length > 0"
                                (click)="link(item.images[0].navigatedUrl)">{{item.customField5}}</button>
                            <button class="buttonClass" [style.border-radius]="item.customField11"
                            [ngStyle.gt-md]="{'font-size': item.customField25 }"
                            [ngStyle.lt-md]="{'font-size': item.customField26}"
                                *ngIf="item.images[0].navigation==2   && item.customField5.length > 0"
                                (click)="dynamicFormPopup(item.customField2)">{{item.customField5}}</button>
    
                        </div>
    
                    </div>
    
                </div>
            </div>
         



        </div>

        <div fxFlex [ngStyle]="{'margin-top': item.gapBetweenImage, 'margin-bottom': item.gapBetweenImage}" fxLayoutAlign="center center" *ngFor="let img of item.images">
            <img style="width: 100%;" [ngStyle.lt-md]="{'height': item.heightMob}" [style.height]="item.height" [src]="serverPath+img.img"
                [alt]="img.altImage">

        </div>

    </div>

</div>