<ng-container class="theme-container" *ngFor="let childrenSubItem of category">
   
    <div fxLayout="row" class="sub_menu">
        <a class="font" style="font-size: 15px;padding-bottom: 8px;" (click)="goToCategory(childrenSubItem)" [routerLink]="childrenSubItem.path" >
            {{childrenSubItem.categoryName}}
         </a>
    </div>
                            
    <ng-container *ngIf="childrenSubItem.subcategories.length>0"  > 
     
        <div fxLayout="row" [style.display]="childrenSubItem.subcategories.length === 0 ? 'none' : 'block'">
            <calsoft-new-menu-sub [category]="childrenSubItem.subcategories"></calsoft-new-menu-sub>
        </div>    

    </ng-container>
 </ng-container>

                        
              
