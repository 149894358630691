<div fxFlex="100" class="textWithImage">
 
    <div fxLayout="row" fxLayout.lt-sm="column"> 
        <div fxFlex="100"  fxLayout.lt-sm="row">
            <div class="iconType9" fxFlex="50">

                <div fxLayoutAlign="center center" class="icon-container">
                    <!-- <img  class="icon"
                    [src]="serverPath+item.images[0].img" [alt]="item.images[0].altImage || 'images'"> -->
                    <calsoft-ng-optimized-image class="icon" [altImage]="item.images[0].altImage || 'images'" [image]="serverPath+item.images[0].img" [i]="'0'"> </calsoft-ng-optimized-image>


                </div>
                  <div class="padding-10">
                
                  </div>
                  <div class="text-container padding-10">
                    <h3 fxLayoutAlign="center center">{{item.images[0].customField1}}</h3>
                    <p fxLayoutAlign="center center"> {{item.images[0].customField12}}</p>
                  </div>


            </div>
            <div class="iconType9" fxFlex="50">

                <div fxLayoutAlign="center center" class="icon-container">
                    <!-- <img fxLayoutAlign="center center"  class="icon"
                    [src]="serverPath+item.images[1].img" [alt]="item.images[1].altImage || 'images'"> -->
                    <calsoft-ng-optimized-image  fxLayoutAlign="center center" class="icon" [altImage]="item.images[1].altImage || 'images'" [image]="serverPath+item.images[1].img" [i]="'0'"> </calsoft-ng-optimized-image>
                </div>
                  <div class="padding-10">
                
                  </div>
                  <div class="text-container padding-10">
                    <h3 fxLayoutAlign="center center">{{item.images[1].customField1}}</h3>
                    <p fxLayoutAlign="center center"> {{item.images[1].customField12}}</p>
                  </div>


            </div>
        </div>
    </div>

    <div fxLayout="row" fxLayout.lt-sm="column"> 
        <div fxFlex="100"  fxLayout.lt-sm="row">
            <div class="iconType9" fxFlex="50">

                <div  fxLayoutAlign="center center" class="icon-container">
                    <!-- <img  class="icon"
                    [src]="serverPath+item.images[2].img" [alt]="item.images[2].altImage || 'images'"> -->
                    <calsoft-ng-optimized-image class="icon" [altImage]="item.images[2].altImage || 'images'" [image]="serverPath+item.images[2].img" [i]="'0'"> </calsoft-ng-optimized-image>
                </div>
                  <div class="padding-10">
                
                  </div>
                  <div class="text-container padding-10">
                    <h3 fxLayoutAlign="center center">{{item.images[2].customField1}}</h3>
                    <p fxLayoutAlign="center center"> {{item.images[2].customField12}}</p>
                  </div>


            </div>

            <div class="iconType9" fxFlex="50">

                <div fxLayoutAlign="center center" class="icon-container">
                    <!-- <img  class="icon"
                    [src]="serverPath+item.images[3].img" [alt]="item.images[3].altImage || 'images'"> -->
                    <calsoft-ng-optimized-image class="icon" [altImage]="item.images[3].altImage || 'images'" [image]="serverPath+item.images[3].img" [i]="'0'"> </calsoft-ng-optimized-image>
                </div>
                  <div class="padding-10">
                
                  </div>
                  <div class="text-container padding-10">
                    <h3 fxLayoutAlign="center center">{{item.images[3].customField1}}</h3>
                    <p fxLayoutAlign="center center"> {{item.images[3].customField12}}</p>
                  </div>


            </div>
        </div>
    </div>
</div>
