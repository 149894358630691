<div class="bgcss">
    <div fxLayout="row" fxLayoutGap="20px" fxHide.lt-md>
        <div fxFlex="16">
            <h4>E-mail</h4>
            <input matInput placeholder="Please enter your E-mail" class="formcss">
        </div>
        <div fxFlex="16">
            <h4>Number</h4>
            <input matInput placeholder="Please enter your Contact" class="formcss">
        </div>
        <div fxFlex="20">
            <h4>Destinations</h4>
            <select class="formcss2">
                <option value="" disabled selected>Destinations</option>
                <option value="Goa">Goa</option>
                <option value="Delhi">Delhi</option>
                <option value="Lonavala">Lonavala</option>
            </select>
        </div>

        <div fxFlex="16">
            <h4>Check-in</h4>
            <input matInput placeholder="Check-in" type="date" class="formcss">
        </div>

        <div fxFlex="16">
            <h4>Check-out</h4>
            <input matInput placeholder="Check-out" type="date" class="formcss">
        </div>
        <div fxFlex="16">
            <div class="popover-container">
                <h4>Guests</h4>
                <input matInput placeholder="{{totaluser}}" class="formcss" (click)="togglePopover()">
                <div *ngIf="isPopoverOpen" class="popover-content">
                    <select class="formcss1" [(ngModel)]="selectedAdults" (change)="onAdultChange()">
                        <option value="1">1 Adult</option>
                        <option value="2">2 Adult</option>
                        <option value="3">3 Adult</option>
                    </select>
                    <select class="formcss1" [(ngModel)]="selectedChildren" (change)="onChildChange()">
                        <option value="0">0 children</option>
                        <option value="1">1 children</option>
                        <option value="2">2 children</option>
                    </select>
                    <button (click)="closePopover()" mat-button class="btndone">Done</button>
                </div>
            </div>
        </div>
    </div>

    <div fxHide fxShow.lt-md>
        <div fxLayout="row" fxLayoutGap="20px" style="margin-bottom: 15px;">
            <div fxFlex="50">
                <h4>E-mail</h4>
                <input matInput placeholder="Please enter your E-mail" class="formcss">
            </div>
            <div fxFlex="50">
                <h4>Number</h4>
                <input matInput placeholder="Please enter your Contact" class="formcss">
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px" style="margin-bottom: 15px;">
            <div fxFlex="50">
                <h4>Destinations</h4>
                <select class="formcss2">
                    <option value="" disabled selected>Destinations</option>
                    <option value="Goa">Goa</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Lonavala">Lonavala</option>
                </select>
    
            </div>
            <div fxFlex="50">
                <h4>Check-in</h4>
                <input matInput placeholder="Check-in" type="date" class="formcss">
            </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px" style="margin-bottom: 15px;">
            <div fxFlex="50">
                <h4>Check-out</h4>
                <input matInput placeholder="Check-out" type="date" class="formcss">
            </div>
            <div fxFlex="50">
                <div class="popover-container">
                    <h4>Guests</h4>
                    <input matInput placeholder="1" class="formcss" (click)="togglePopover()">
                    <div *ngIf="isPopoverOpen" class="popover-content">
                        <select class="formcss1">
                            <option value="1">1 Adult</option>
                            <option value="2">2 Adult</option>
                            <option value="3">3 Adult</option>
                        </select>
                        <select class="formcss1">
                            <option value="1">0 children</option>
                            <option value="2">1 children</option>
                            <option value="3">2 children</option>
                        </select>
                        <button (click)="closePopover()" mat-button class="btndone">Done</button>
                    </div>
                </div>
            </div>
        </div>

    </div>


    <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="17">
            <button mat-button class="btncss">SEND ENQUIRY</button>
        </div>
        <div fxFlex="18">
            <button mat-button class="btncss">BOOK NOW</button>
        </div>
    </div>

</div>