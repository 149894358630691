<div>
    <mat-toolbar  *ngIf="type==1" class="primary" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="clrbg">Available Slots</span>
        <button class="clrbg" mat-dialog-close mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar>

    <div *ngIf="slotType==0">

   
    <mat-tab-group >
        <mat-tab *ngFor="let item of dates;let i=index">

            <div *ngIf="timeSlotMap.get(item.viewValue)!=undefined">
                <ng-template mat-tab-label>

                    <span class="today-text">{{item.viewValue}}</span>
                    <span
                        class="slots-text padding-10">&#160;{{timeSlotMap.get(item.viewValue).length>0?timeSlotMap.get(item.viewValue).length
                        +"
                        Slots Available":'No Slots'}}</span>

                </ng-template>

                <div>
                    <div fxLayout="row wrap" *ngIf="timeSlotMap.get(item.viewValue).length>0 ;else other_content">
                        <div *ngFor="let slot1 of timeSlotMap.get(item.viewValue)" fxLayout="row">
                            <button class="primary" (click)="movetomyappoint(slot1, item)" mat-stroked-button
                                style="color: white; margin: 10px;">
                                {{ slot1.show_time }}
                            </button>
                        </div>
                    </div>

                    <ng-template #other_content>
                        <div fxLayoutAlign="center center" class="padding-10">
                            <div fxLayout="column" fxLayoutAlign="center center">
                              <img height="80" src="assets/images/noslot.png">
                              <div class="padding-10">
                                <h3>No Slots Available</h3>
                              </div>                              
                            </div>
                          </div>
                          
                        </ng-template>

                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<div *ngIf="slotType==1">

    <div class="formcss">
   
    <h3><b>Select Date & Time</b></h3>
    <div (click)="openDatePicker()">
        <mat-form-field class="datepick">
          <input matInput [matDatepicker]="picker" disabled [min]="todayDate" placeholder="Choose a date" [(ngModel)]="selectedDate" (dateChange)="onDatePicked(selectedDate)">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker disabled="false" #picker startView="month" [startAt]="startDate"></mat-datepicker>
        </mat-form-field>
      </div>
      

        <div *ngIf="doctorTime.length > 0">
            <mat-form-field class="datepick" *ngIf="showtiming">
                <mat-label>Available Time</mat-label>
                <mat-select style="padding-bottom: 0.34375em;padding-top: 5px;" [disableOptionCentering]="true">
                    <mat-option  (onSelectionChange)="onSelectionChange($event,timeObject)" style="font-size: 14px;" [value]="timeObject.show_time"
                    *ngFor="let timeObject of doctorTime;let i=index">
                    {{timeObject.show_time}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
       

        <div fxLayoutAlign="center center" class="padding-10" *ngIf="!showtiming || doctorTime.length === 0">
            <div fxLayout="column" fxLayoutAlign="center center">
              <img height="80" src="assets/images/noslot.png">
              <div class="padding-10">
                <h3>No Slots Available</h3>
              </div>                              
            </div>
          </div>

          <div *ngIf="!showtiming || doctorTime.length > 0">
            <button mat-raised-button color="primary" *ngIf="confirmBtn" class="btn-css" (click)="checkout()">Confirm Booking</button>
          </div>
        
    </div>

    </div>
</div>