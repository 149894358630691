<div class="main-container" [style.background-color]="item.customField8">
    <div class="section" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
      <div class="image-container" fxFlex="25" *ngFor="let items of img; let i = index">
        <div class="image-wrapper">
          <img [src]="serverPath + items.customField6" class="image imgcss">
          <div class="icon-container">
            <div class="icon" *ngIf="items.customField10" (click)="openInNewWindow(items.customField10)"><img src="/assets/icons/circle.png" alt=""></div>
            <div class="icon" *ngIf="items.customField7" (click)="openInNewWindow(items.customField7)"><img src="/assets/icons/linkedin.png" alt=""></div>
            <div class="icon" *ngIf="items.customField5" (click)="openInNewWindow(items.customField5)"><img src="/assets/icons/email.png" alt=""></div>
          </div>
        </div>
        <div class="header">
          <h5>{{items.customField4}}</h5>
          <h6>{{items.customField17}}</h6>
        </div>
      </div>
    </div>
  </div>
  
  