import { Component, OnInit } from "@angular/core";
import { Constant } from "src/app/constants/constant";

@Component({
  selector: "app-aboutus",
  templateUrl: "./aboutus.component.html",
  styleUrls: ["./aboutus.component.scss"],
})
export class AboutusComponent implements OnInit {
  public PageTitle = Constant.about
  constructor() { }

  ngOnInit() {
  }




}
