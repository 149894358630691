import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.scss']
})
export class EnquiryFormComponent {

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EnquiryFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }


  cancel() {
      this.dialogRef.close();
  }

}
