import { Component, Input } from '@angular/core';

@Component({
  selector: 'calsoft-type-form',
  templateUrl: './type-form.component.html',
  styleUrls: ['./type-form.component.scss']
})
export class TypeFormComponent {


  typeformId: string;

  @Input() item: any;
  @Input() img: any;
  

  ngOnInit() {
         
    this.loadTypeformScript();

    this.typeformId=this.item['customField11']

  }

 

 

  private loadTypeformScript() {
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }

}
