import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-bannerimage-text2',
  templateUrl: './bannerimage-text2.component.html',
  styleUrls: ['./bannerimage-text2.component.scss']
})
export class BannerimageText2Component implements  OnInit {

  

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() data: any;

  @Input() img: any;
  
  @Input() index: any;
  ngOnInit(): void {

    
  }

  @ViewChild('owlElement', { static: false }) owlElement: CarouselComponent;
  

  LogoSlider = {
    nav: false,
    dots: false,
    items: 1,
    margin: 20,
    loop: true,
    autoHeight: true,
    autoplay: true,
    autoplayTimeout: 3000,

    responsive: {
      767: {
        items: 1
      },
      576: {
        items: 1
      },
      480: {
        items: 1
      },
      0: {
        items: 1
      }
    }
  }


}
