import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import Swiper from 'swiper';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'calsoft-carousel-slide',
  templateUrl: './carousel-slide.component.html',
  styleUrls: ['./carousel-slide.component.scss']
})
export class CarouselSlideComponent implements AfterViewInit {
  @Input() item: any;
  @Input() img: any;
  public serverPath = environment.commonImageApi + '/home/';
  public bannerServerPath = environment.commonImageApi + '/commonSlider/';

  @ViewChild('swiper', { static: true }) swiperContainer: any;
  dynamicSlides:any;

  constructor(
    
  ) {
    this.dynamicSlides = [
      { id: 1, src:'https://www.lemontreehotels.com/Ka_assest/images/LemonTreeHotelHaridwar.jpg', alt:'Side 1', title:'LEMON TREE HOTEL, CHENNAI' },
      { id: 2, src:'https://www.lemontreehotels.com/Images/slider/Kasauli-Facade-Evening.jpg', alt:'Side 2', title:'LEMON TREE HOTEL, CHENNAI' },
      { id: 3, src:'https://www.lemontreehotels.com/Images/slider/facade-malad.jpg', alt:'Side 3', title:'LEMON TREE HOTEL, CHENNAI' },
      { id: 4, src:'https://www.lemontreehotels.com/Images/slider/Kasauli-Facade-Evening.jpg', alt:'Side 4', title:'LEMON TREE HOTEL, CHENNAI' },
      { id: 5, src:'https://via.placeholder.com/600/f66b97', alt:'Side 5', title:'LEMON TREE HOTEL, CHENNAI' },
      { id: 6, src:'https://www.lemontreehotels.com/Ka_assest/images/LemonTreeHotelHaridwar.jpg', alt:'Side 1', title:'LEMON TREE HOTEL, Mumabi' },
      { id: 7, src:'https://picsum.photos/400/300.jpg', alt:'Side 5', title:'LEMON TREE HOTEL, CHENNAI' },
    ];
  
  }

  ngAfterViewInit() {
  new Swiper(this.swiperContainer.nativeElement, {
    effect: 'coverflow',
   // grabCursor:true,
    centeredSlides: true,
    slidesPerView: 'auto',
    loopedSlides: 1,
    loop:true,
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 300,
      modifier: 1,
      slideShadows: true,
    },
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 40
      }
    }
  });
  }


  openlink(url: string) {
    console.log(url);
    if (url) {
      const fullURL = this.prependProtocol(url);
      window.open(fullURL, '_blank');


    }
  }
  
  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }



}
