<!-- <div fxLayout="row">
    <div fxFlex="40">
        <div fxLayout="row" *ngFor="let item of categoryArray" style="border:1px solid lightgray;padding: 10px;" >
            <div fxFlex="20">
                <img style="width: 100px;height: 60px;border-radius: 5px;"  onerror="this.src='assets/images/error/no-picture-icon.jpg';" [src]="serverPath+item.image" [alt]="item.categoryName">
            </div>
            <div fxFlex="80" fxLayoutAlign="start center">
                <h3 style="font-size: 20px;"> {{item.categoryName }} </h3>
            </div>            
        </div>
    </div>

    <div fxFlex="60">

    </div>

</div> -->



<div fxLayout="row" fxHide.lt-md>
    <div fxFlex="40" style="height: 400px; overflow-y: auto;">
      <div fxLayout="row" *ngFor="let item of categoryArray; let i = index" style="border: 1px solid lightgray; padding: 10px;cursor: pointer;"
      [style.background-color]="selectedCategoryIndex === i ? '#d5d5d573' : 'transparent'"
      (click)="item.subcategories.length>0?toggleSubcategory(i):goToCategory(item)">
      
        <div fxFlex="20">

        <calsoft-ng-optimized-image style="width: 100px;height: 60px;border-radius: 5px;"
                                [navigation]="0" [altImage]="item.categoryName"  [image]="serverPath+item.image"
                                [i]="'0'">
        </calsoft-ng-optimized-image>
          <!-- <img style="width: 100px;height: 60px;border-radius: 5px;"  onerror="this.src='assets/images/error/no-picture-icon.jpg';" 
          [src]="serverPath+item.image" [alt]="item.categoryName"> -->
        </div>
        <div fxFlex="80" fxLayoutAlign="start center" >
          <h3 style="font-size: 20px;">{{ item.categoryName }}</h3>
        </div>

      </div>
    </div>
  
    <div fxFlex="60" style="padding: 15px;">
      <div *ngIf="selectedCategoryIndex !== null && selectedCategoryIndex >= 0">
        
        <div fxLayout="row"  *ngIf="categoryArray[selectedCategoryIndex]!=undefined" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="15px">
          <div *ngFor="let subcategory of categoryArray[selectedCategoryIndex]?.subcategories" fxFlex="33.33%">
            <div style="padding: 10px; ">
              <div  (click)="goToCategory(subcategory)"  style="display: flex;cursor: pointer;">
                <mat-icon style="font-size: 18px;">near_me</mat-icon>
                <p style="margin-top: 0px; text-transform: uppercase; margin-left: 5px;">{{ subcategory.categoryName }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    
  </div>
  
  <div fxHide fxShow.lt-md>

    <section fxLayout="row wrap" style="height: 200px; overflow-y: auto;">
      <section style="border: 1px solid #eee"  *ngFor="let item of categoryArray; let i = index" fxFlex.xs="33" [fxFlex.lg]="viewCol"
          [fxFlex.gt-sm]="viewCol" fxFlex.lt-md="33.33" class="col padding-5 product-mobile backg" [style.background-color]="selectedCategoryIndex === i ? '#d5d5d573' : 'transparent'"
          (click)="item.subcategories.length>0?toggleSubcategory(i):goToCategory(item)">
          <section fxLayout="row" fxLayoutAlign="center center">
              <!-- <img style="width: 80px;height: 50px;border-radius: 5px;margin-top: 5px;margin-bottom: 5px;" 
              onerror="this.src='assets/images/error/no-picture-icon.jpg';" [src]="serverPath+item.image" [alt]="item.categoryName"> -->
              <calsoft-ng-optimized-image style="width: 80px;height: 50px;border-radius: 5px;margin-top: 5px;margin-bottom: 5px;" [altImage]="item.categoryName"  [image]="serverPath+item.image" [i]="'0'"> </calsoft-ng-optimized-image>

              
          </section>
          <section fxLayout="row" fxLayoutAlign="center center">
              <b> {{item.categoryName}} </b>
          </section>
      </section>
  </section>


  <div *ngIf="selectedCategoryIndex !== null && selectedCategoryIndex >= 0" style="margin-top: 10px;">
    <div fxLayout="row" fxLayoutWrap fxLayoutGap="5px">
      <div *ngFor="let subcategory of categoryArray[selectedCategoryIndex]?.subcategories" fxFlex="50%" style="border-right: 1px solid lightgray;">
        <div style="padding: 10px; ">
          <div (click)="goToCategory(subcategory)" style="display: flex;cursor: pointer;">
            <mat-icon style="font-size: 18px;">near_me</mat-icon>
            <p style="margin-top: 0px; text-transform: uppercase; margin-left: 5px;">{{ subcategory.categoryName }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  </div>
