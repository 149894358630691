import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseRoutingModule } from './purchase-routing.module';
import { PurchaseComponent } from './purchase.component';
import { SharedModule } from '../shared.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AddpurchaseComponent } from './addpurchase/addpurchase.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ListModule } from '../list/list.module';
import { ControlsCartModule } from '../controls-cart/controls-cart.module';
import { ProductdetailesComponent } from './productdetailes/productdetailes.component';
import { PurchaseCartComponent } from './purchase-cart/purchase-cart.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DirectiveModule } from '../utils/directive/directive.module';
import { BarcodePutModule } from '../utils/directive/barcode-put/barcode-put.module';
import { PipesModule } from 'src/app/theme/pipes/pipes.module';


@NgModule({
  declarations: [
    PurchaseComponent,
    AddpurchaseComponent,
    ProductdetailesComponent,
    PurchaseCartComponent,
  ],
  imports: [
    CommonModule,
    PurchaseRoutingModule,
    SharedModule,
    CommonModule,
    MatTooltipModule,
    MatDialogModule,
    MatToolbarModule,
    NgxMatSelectSearchModule,
    ListModule,
    ControlsCartModule,
    MatDatepickerModule,
    NgxMatSelectSearchModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    BarcodePutModule,
    PipesModule,
    DirectiveModule
  ],
  exports:[
    PurchaseComponent,
    AddpurchaseComponent,
    ProductdetailesComponent,
    PurchaseCartComponent,
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PurchaseModule { }
