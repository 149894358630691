import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeneralRoutingModule } from './general-routing.module';
import { GeneralComponent } from './general.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { MatButtonModule } from '@angular/material/button';
import { PrivacyComponent } from './privacy/privacy.component';

import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';

import { ReturnPolicyComponent } from './return-policy/return-policy.component';
import { PaymentPolicyComponent } from './payment-policy/payment-policy.component';
import { ShippingPolicyComponent } from './shipping-policy/shipping-policy.component';
import { LayoutModule } from '../layout/layout.module';
import { ContactUsComponent } from './contact-us/contact-us.component';


@NgModule({
  declarations: [GeneralComponent, AboutusComponent, 
    PrivacyComponent,TermsConditionsComponent,
    ShippingPolicyComponent,
    ReturnPolicyComponent, PaymentPolicyComponent, ContactUsComponent],
  imports: [
    CommonModule,
    GeneralRoutingModule,
    CustomFlexLayoutModule,
    MatButtonModule,
    LayoutModule
  ],
  exports:[GeneralComponent]
})
export class GeneralModule { }
