import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { FormPopupComponent } from '../form-popup/form-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'calsoft-plan-compare-type10',
  templateUrl: './plan-compare-type10.component.html',
  styleUrls: ['./plan-compare-type10.component.scss']
})
export class PlanCompareType10Component implements OnInit{
  @Input() item: any;
  @Input() data: any;
  pricedetail:any;
  price_detail:any;  
  items:any;
  constructor(private commonService: CommonService,    public dialog: MatDialog,) {

  }
  
  ngOnInit() {   
    this.pricedetail = JSON.parse(this.item.customField32);
    this.price_detail = JSON.parse(this.item.customField32);
    this.price_detail[0].open = true;
    
  }      

 
  toggleSection(i) {
    if(this.price_detail[i].list_detail.length >0){
      this.price_detail[i].open = !this.price_detail[i].open;
    }
  }

  link(url) {

    this.commonService.goToLink(url)
   
  }
  

  dynamicFormPopup(formGroupId:any) {
    if (window.innerWidth > 768) {

       let dialogRef = this.dialog.open(FormPopupComponent, {
          width: '790px',
          data: { formId: formGroupId}
       });

       dialogRef.disableClose = true;
       dialogRef.afterClosed().subscribe(result => {

       });

    }
    else {
       let dialogRef = this.dialog.open(FormPopupComponent, {
          minWidth: '100vw', height: '100vh',
          data: { formId: formGroupId}
       });

       dialogRef.disableClose = true;
       dialogRef.afterClosed().subscribe(result => {

       });
    }

 }

 active: string;

 defaultIndex: number;

	scrollDown(value){
		this.active =value;
		this.ScrollIntoView(this.active);
	}
 
	ScrollIntoView(elem: string) {
		this.active = "#" + elem;
		const ele = document.querySelector(this.active) as HTMLElement;
	  
		if (ele) {
		  const offsetTop = ele.getBoundingClientRect().top + window.pageYOffset;
		  const paddingTop = 96;
		  window.scrollTo({
			top: offsetTop - paddingTop,
			behavior: 'smooth'
		  });
		} else {
		  console.error('Element not found:', this.active);
		}
	  }

}
