import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  url = environment.serverAPI + "/api/catalog/schedule-doctor-time";

  constructor(private http: HttpClient) { }

  getSchuduleDoctorTime(doctorData: any): Observable<Object> {
    return this.http.post<Response[]>(`${this.url}`, doctorData);

  }

}
