
  <div class="container" fxLayout="row wrap" fxLayoutGap="20px">
    <div class="card" *ngFor="let service of services" (click)="openPopup(service)">
      <img [src]="service.imgUrl" [alt]="service.altText">
      <h2 class="main">{{ service.title }}</h2>
      <p class="main2">{{ service.description }}</p>
      <span class="read-more">&#x25B6; <u>Read more</u></span>
    </div>
  </div>
  
  <div class="popup" *ngIf="isPopupVisible">
    <div class="popup-content">
      <span class="close" (click)="closePopup()">&times;</span>
      <div class="rms-popup__content" fxLayout="row" fxLayout.lt-sm="column">
        <!-- Left Sidebar (30%) -->
        <div class="rms-catalog__popup-sidebar" fxFlex="30" fxFlex.lt-sm="100">
          <div class="rms-catalog__popup-logo-cont">
            <img class="rms-catalog__popup-logo" [src]="selectedService.imgUrl" loading="lazy" [alt]="selectedService.altText">
          </div>
          <div class="rms-rte rms-catalog__popup-info">
            <div class="rms-catalog__popup-name">
              <h6>Partner</h6>
              <h4 style="font-size: 30px;">{{ selectedService.partnerName }}</h4>
            </div>
            <div class="rms-catalog__popup-category">
              <h6>Category</h6>
              <p>{{ selectedService.category }}</p>
            </div>
            <div class="rms-catalog__popup-status">
              <h6>Status</h6>
              <p>{{ selectedService.status }}</p>
            </div>
          </div>
        </div>
        <!-- Right Content (70%) -->
        <div class="rms-popupContent__inner" fxFlex="70" fxFlex.lt-sm="100" style="display: block;">
          <img [src]="selectedService.imageUrl" [alt]="selectedService.altText" class="popup-image">
          <h2>{{ selectedService.title }}</h2>
          <p>{{ selectedService.description }}</p>
        </div>
      </div>
    </div>
  </div>
  