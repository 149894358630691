import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlsComponent } from './controls.component';
import { RouterModule } from '@angular/router';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { MatDialogModule } from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from 'src/app/shared/shared.module';
import { DirectiveModule } from 'src/app/shared/utils/directive/directive.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatNativeDateModule } from '@angular/material/core';


@NgModule({
  declarations: [ControlsComponent],
  imports: [
    CommonModule,
    RouterModule,
    CustomFlexLayoutModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    SharedModule,
    DirectiveModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule


  ],  exports:[ControlsComponent]
})
export class ControlsModule { }
