<div fxFlex="100">
    <div fxFlex="100" fxFlex.xs="100" *ngIf="item.customField1=='type1' || item.customField1=='type6'">
        <div class="banner content-right" contentAnim [aniName]="item.customField7">
            <img onerror="this.src='assets/images/products/noImage/no-image.png';" [style.height]="item.customField25" [ngStyle.lt-md]="{'height': item.customField26}" width="100%" [src]="serverPath1+item.customField2" [alt]="item.customField2">
            <div [style.text-align]="item.customField28" class="banner-content text-right">
                <h4 [style.font-size]="item.customField37"
                [ngStyle.lt-md]="{ 'font-size': item.customField38 }" class="banner-subtitle12">{{item.customField5}}</h4>
                <h4 class="banner-price12"  [style.font-size]="item.customField37"
                [ngStyle.lt-md]="{ 'font-size': item.customField38 }">{{item.customField6}}<span class="price1"  [style.font-size]="item.customField29"
                [ngStyle.lt-md]="{ 'font-size': item.customField30 }">{{item.customField8}}</span></h4>
                <button *ngIf="item.customField10.length > 0" (click)="link(img.customField6)" class="btn1">
                    <span style="align-items: center;">{{item.customField10}}</span>
                </button>
            </div>
        </div>
    </div>

    <div fxFlex="100" fxFlex.xs="100" *ngIf="item.customField1=='type2' || item.customField1=='type3' || item.customField1=='type4' ">
        <div class="banner content-right" contentAnim [aniName]="item.customField7">
            <!-- <img onerror="this.src='assets/images/products/noImage/no-image.png';" [style.height]="item.customField25" [ngStyle.lt-md]="{'height': item.customField26}" 
            width="100%" [src]="serverPath1+item.customField2" [alt]="item.customField2"> -->
            <calsoft-ng-optimized-image  width="100%" [height]="item.customField25" [heightMob]="item.customField26" [altImage]="item.customField2"  [image]="serverPath1+item.customField2" [i]="'0'"> </calsoft-ng-optimized-image>

            <div [style.text-align]="item.customField28"  class="banner-content text-right">
                <h4 class="banner-subtitle12"  [style.font-size]="item.customField37"
                [ngStyle.lt-md]="{ 'font-size': item.customField38 }">{{item.customField5}}</h4>
                <h4 class="banner-price12"  [style.font-size]="item.customField37"
                [ngStyle.lt-md]="{ 'font-size': item.customField38 }">{{item.customField6}}<span class="price1"  [style.font-size]="item.customField29"
                [ngStyle.lt-md]="{ 'font-size': item.customField30 }">{{item.customField8}}</span></h4>
                <button *ngIf="item.customField10.length > 0" (click)="goToCategories(item.categoryId1)" class="btn1">
                    <span style="align-items: center;">{{item.customField10}}</span>
                </button>
            </div>
        </div>
        
        <div [style.margin-top]="item.gapBetweenImage"></div>

        <div class="banner content-right" contentAnim [aniName]="item.customField15" *ngIf="item.customField4.length > 0">
            <!-- <img onerror="this.src='assets/images/products/noImage/no-image.png';" width="100%"  [style.height]="item.customField25"
             [ngStyle.lt-md]="{'height': item.customField26}" [src]="serverPath1+item.customField4" [alt]="item.customField4"> -->
             <calsoft-ng-optimized-image [height]="item.customField25" [heightMob]="item.customField26" [altImage]="item.customField4" [image]="serverPath1+item.customField4" [i]="'0'"> </calsoft-ng-optimized-image>
            <div [style.text-align]="item.customField29" class="banner-content text-right">
                <h4 class="banner-subtitle12" [style.font-size]="item.customField37"
                [ngStyle.lt-md]="{ 'font-size': item.customField38 }">{{item.customField16}}</h4>
                <h4 class="banner-price12"  [style.font-size]="item.customField37"
                [ngStyle.lt-md]="{ 'font-size': item.customField38 }">{{item.customField11}}<span class="price1"  [style.font-size]="item.customField29"
                [ngStyle.lt-md]="{ 'font-size': item.customField30 }">{{item.customField24}}</span></h4>
                <button *ngIf="item.customField12.length > 0" (click)="goToCategories(item.categoryId3)" class="btn1">
                    <span style="align-items: center;">{{item.customField12}}</span>
                </button>
            </div>
        </div>
    </div>

    <div fxFlex="100" fxFlex.xs="100" *ngIf="item.customField1==='type5'">
        <div class="banner content-right" contentAnim [aniName]="item.customField7">
            <!-- <img onerror="this.src='assets/images/products/noImage/no-image.png';" [style.height]="item.customField25" [
            ngStyle.lt-md]="{'height': item.customField26}" width="100%" [src]="serverPath1+item.customField2" [alt]="item.customField2"> -->
            <calsoft-ng-optimized-image [height]="item.customField25" [heightMob]="item.customField26"  [altImage]="item.customField2"  [image]="serverPath1+item.customField2"  [i]="'0'"> </calsoft-ng-optimized-image>
            <div [style.text-align]="item.customField28"  class="banner-content text-right">
                <h4 class="banner-subtitle12"  [style.font-size]="item.customField37"
                [ngStyle.lt-md]="{ 'font-size': item.customField38 }">{{item.customField5}}</h4>
                <h4 class="banner-price12"  [style.font-size]="item.customField37"
                [ngStyle.lt-md]="{ 'font-size': item.customField38 }">{{item.customField6}}<span class="price1"  [style.font-size]="item.customField29"
                [ngStyle.lt-md]="{ 'font-size': item.customField30 }">{{item.customField8}}</span></h4>
                <button *ngIf="item.customField10.length > 0" (click)="goToCategories(item.categoryId1)" class="btn1">
                    <span style="align-items: center;">{{item.customField10}}</span>
                </button>
            </div>
        </div>
        
        <div [style.margin-top]="item.gapBetweenImage"></div>

        <div class="banner content-right" contentAnim [aniName]="item.customField15" *ngIf="item.customField4.length > 0">
            <!-- <img onerror="this.src='assets/images/products/noImage/no-image.png';" width="100%"  [style.height]="item.customField25" 
            [ngStyle.lt-md]="{'height': item.customField26}" [src]="serverPath1+item.customField4" [alt]="item.customField4"> -->
            <calsoft-ng-optimized-image [height]="item.customField25" [heightMob]="item.customField26"  [altImage]="item.customField4" [image]="serverPath1+item.customField4" [i]="'0'"> </calsoft-ng-optimized-image>
            <div [style.text-align]="item.customField29"  class="banner-content text-right">
                <h4 class="banner-subtitle12"  [style.font-size]="item.customField37"
                [ngStyle.lt-md]="{ 'font-size': item.customField38 }">{{item.customField16}}</h4>
                <h4 class="banner-price12"  [style.font-size]="item.customField37"
                [ngStyle.lt-md]="{ 'font-size': item.customField38 }">{{item.customField11}}<span class="price1"  [style.font-size]="item.customField29"
                [ngStyle.lt-md]="{ 'font-size': item.customField30 }">{{item.customField24}}</span></h4>
                <button *ngIf="item.customField12.length > 0" (click)="goToCategories(item.categoryId3)" class="btn1">
                    <span style="align-items: center;">{{item.customField12}}</span>
                </button>
            </div>
        </div>

        <div [style.margin-top]="item.gapBetweenImage"></div>

        <div class="banner content-right" contentAnim [aniName]="item.customField20">
            <!-- <img onerror="this.src='assets/images/products/noImage/no-image.png';" [style.height]="item.customField25" 
            [ngStyle.lt-md]="{'height': item.customField26}" width="100%" [src]="serverPath1+item.customField3" [alt]="item.customField3"> -->
            <calsoft-ng-optimized-image [height]="item.customField25" [heightMob]="item.customField26"  [altImage]="item.customField3"  [image]="serverPath1+item.customField3" [i]="'0'">  </calsoft-ng-optimized-image>
            <div [style.text-align]="item.customField30" class="banner-content text-right">
                <h4 class="banner-subtitle12"  [style.font-size]="item.customField37"
                [ngStyle.lt-md]="{ 'font-size': item.customField38 }">{{item.customField14}}</h4>
                <h4 class="banner-price12"  [style.font-size]="item.customField37"
                [ngStyle.lt-md]="{ 'font-size': item.customField38 }">{{item.customField19}}<span class="price1"  [style.font-size]="item.customField29"
                [ngStyle.lt-md]="{ 'font-size': item.customField30 }">{{item.customField20}}</span></h4>
                <button *ngIf="item.customField21.length > 0" (click)="goToCategories(item.categoryId4)" class="btn1">
                    <span style="align-items: center;">{{item.customField21}}</span>
                </button>
            </div>
        </div>

    </div>
</div>