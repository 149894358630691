import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EditorTextRoutingModule } from './editor-text-routing.module';
import { EditorTextComponent } from './editor-text.component';
import { EditorTextChildComponent } from './editor-text-child/editor-text-child.component';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';


@NgModule({
  declarations: [
    EditorTextComponent,
    EditorTextChildComponent
  ],
  imports: [
    CommonModule,
    EditorTextRoutingModule,
    CustomFlexLayoutModule
  ],
  exports:[
    EditorTextComponent,
    EditorTextChildComponent
  ]
})
export class EditorTextModule { }
