<td data-th="ProductName" fxFlex="20">
    <span>{{product.name}}</span>
</td>
<td data-th="Price" fxFlex="20">
    <span>{{product.price}}</span>
</td>
<td data-th="Special Price" fxFlex="20">
    <span>{{product.specialPrice}}</span>
</td>
<td data-th="Qty" fxFlex="10">
    <span>{{product.qty}}</span>
</td>
<td data-th="Action" fxFlex>

<div *ngIf="product.qty>0; else elseBlock" fxLayoutAlign="start center" fxLayout="row">
    <span fxHide.xs
        style="border: 1px solid #ddd;;color: red">

        <button
            [disabled]='product.qty<= 0'
            (click)="decrementQty()"
            mat-icon-button>
            <mat-icon
                class="material-icons-outlined">
                remove
            </mat-icon>
        </button>
    </span>

    <input 
    [autocomplete]="'off'"
    fxLayoutAlign="center center"
    #input
    ngClass.lt-md="border-input"
    numbersOnly
    readonly
    [formControl]="qty"
    ngStyle.gt-md="text-align: center;width:80px;"
    ngStyle.md="text-align: center;width:80px;"
    ngStyle.sm="text-align: center;width:40px;"
    ngStyle.xs="text-align: center;width:40px;"
    class="qty"
    type="text">


    <span fxHide.xs
        style="border: 1px solid #ddd;;color: green">
        <button
            (click)="incrementQty()"
            mat-icon-button>
            <mat-icon
                class="material-icons-outlined">
                add
            </mat-icon>
        </button>
    </span>


</div>


    <!-- <button mat-raised-button style="color: white;" (click)="getProducts(product)" class="primary">Buy Now</button> -->
</td>

<ng-template #elseBlock>

    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2px">
        <div fxLayout="row">
            <h2>Not Available</h2>
        </div>
        <div fxLayout="row" class="padding-3" fxFlex>
            <span>This item is currently Not Available</span>
        </div>

    </div>

</ng-template>