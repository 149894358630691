import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-iconflow',
  templateUrl: './iconflow.component.html',
  styleUrls: ['./iconflow.component.scss']
})
export class IconflowComponent implements OnInit {


  public serverPath = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() img: any;

  @Input() index: any;

  @Input() totalItems: any;

  constructor(
    private commonService: CommonService ) {}
  

  ngOnInit() {

   
  }


  imageNavigation(id){


    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }
  link(url: string) {
    window.open(url, '_self');
  }


}
