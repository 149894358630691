import { Component, Input, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'calsoft-multiple-imagetext',
  templateUrl: './multiple-imagetext.component.html',
  styleUrls: ['./multiple-imagetext.component.scss']
})
export class MultipleImagetextComponent {
  constructor(
    private commonService: CommonService ) {}

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

  @Input() item: any;

  @Input() img: any;


  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      this.commonService.goToLink(url)
    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }


}
