import { Component, Input, OnInit } from '@angular/core';
declare var pannellum: any;
import 'pannellum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-direction-view',
  templateUrl: './direction-view.component.html',
  styleUrls: ['./direction-view.component.scss']
})
export class DirectionViewComponent implements OnInit {

  public serverPath = environment.commonImageApi + '/home/';

  @Input() item: any;

  customField6: any;

  height:any;
  heightMob:any;

  imagesrc: string;

  viewer: any;
  
  ngOnInit(): void {

      if(this.customField6==undefined){
        this.customField6=this.item.customField6;
      }

      if(this.heightMob==undefined){
        this.heightMob=this.item.heightMob;
      }
      if(this.height==undefined){
        this.height=this.item.height;
      }

    
      let panoramaImage = this.serverPath + this.customField6;

      this.viewer = pannellum.viewer('panorama', {
        "panorama": panoramaImage,
        "autoLoad": true,
        // "showControls": false
      });

      console.log(panoramaImage);

    }
      
    
  }

