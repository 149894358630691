import { ListKeyManagerOption } from '@angular/cdk/a11y';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common.service';

import { MatCarouselSlide } from './carousel-slide';

@Component({
  selector: 'mat-carousel-slide',
  templateUrl: './carousel-slide.component.html',
  styleUrls: ['./carousel-slide.component.scss']
})
export class MatCarouselSlideComponent
  implements ListKeyManagerOption, MatCarouselSlide, OnInit {
  @Input() public image: SafeStyle;
  @Input() public overlayColor = '#00000040';
  @Input() public hideOverlay = false;
  @Input() public ariaLabel = '';
  @Input() public disabled = false; // implements ListKeyManagerOption
  @Input() public load = false;
  @Input() public categoryId: any
  @Input() public data: any
  @ViewChild(TemplateRef) public templateRef: TemplateRef<any>;

  constructor(public sanitizer: DomSanitizer,
    private commonService: CommonService

  ) {
  }

  public ngOnInit(): void {



    if (this.image) {
      this.image = this.sanitizer.bypassSecurityTrustStyle(`url("${this.image}")`);
    }
  }

  goToCategories() {
    if (this.categoryId > 0) {


      this.commonService.goToCategory(1, parseInt(this.categoryId));
    }
  }
  link(url) {

    this.commonService.goToLink(url);
  }

}
