<div class="section-gap pb-0">
  <div>
    <mat-toolbar style="padding: 0px;" class="primary" class="section-title-toolbar h220">
      <mat-toolbar-row style="height: 40px;">
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">

          <h2 fxFlex="95" class="text-inverse mb-0 text-xxl" [ngStyle.gt-md]="{'font-size': item.customField39 }"
          [ngStyle.lt-md]="{
              
              'font-size': item.customField40
          }" >{{header}}</h2>

          <button fxFlex="5" (click)="goToCategory()" style="color:white" class="primary" mat-button>View All</button>
        </div>

      </mat-toolbar-row>
    </mat-toolbar>
    <div class="shop-card-gap px-4">
      <div fxLayout="row wrap" class="col-gap">
        <div fxFlex.xs="50" fxFlex.lt-md="33.33" fxFlex="20" *ngFor="let product of categoryProducts">

          <calsoft-common-product-details-carousel
          [data]="data"
          [width]="width" 
          [imageType]="imageType"  
           [height]="height" 
           [showConfigurableDropdown]="showConfigurableDropdown"
            [showCartIcon]="showCartIcon" [showCartIncremented]="showCartIncremented" [product]="product">
          </calsoft-common-product-details-carousel>

        </div>
      </div>
    </div>
  </div>
</div>
