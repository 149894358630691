<div class="custom-carousel">
  <owl-carousel-o [options]="LogoSlider" #owlCar class="no-arrow">
      <ng-container *ngFor="let  slide of item.images; let l = index">
          <ng-template carouselSlide>

            <div class="image-card padding-10" fxFlex="100">   
              <calsoft-ng-optimized-image [height]="item.height" 
              [heightMob]="item.heightMob"
              [altImage]="slide.img"
              [image]="serverPath + slide.img" [i]="'0'">
            </calsoft-ng-optimized-image>

              <div class="location_link_main">  
                <span class="location_flag">
                  <i class="material-icons location_icon">location_on</i>
                </span>
                <div class="direction_name" (click)="openInNewWindow(slide.customField4)">Get Direction</div>
              </div>
            
              <div class="bottom_content_main">
                <h3 class="location_name" [style.font-size]="item.customField16"  [ngStyle.lt-md]="{ 'font-size': item.customField17}">
                    {{slide.customField1}}
                </h3>
              </div>
            
              <div class="add_phn">
                <div class="add_main">
                  <span><i class="material-icons">location_on</i></span>
                  <div class="address_main">
                   <span [style.font-size]="item.customField18"  [ngStyle.lt-md]="{ 'font-size': item.customField19}">{{slide.customField2}}</span> 
                  </div>
                </div>
                <div class="phn_main" (click)="callPhoneNumber(slide.customField3)">
                  <span><i class="material-icons">phone</i></span>
                  <p><u [style.font-size]="item.customField20"  [ngStyle.lt-md]="{ 'font-size': item.customField21}">{{slide.customField3}}</u></p>
                </div>
                
              </div>
              </div>          
          </ng-template>
      </ng-container>
  </owl-carousel-o>
  <div class="custom-nav" fxShow fxHide.lt-md>
      <div class="owl-prev" (click)="owlCarousel.prev()">
          <mat-icon class="custom-icon primary-icon">keyboard_arrow_left</mat-icon>
      </div>
      <div class="owl-next" (click)="owlCarousel.next()">
          <mat-icon class="custom-icon primary-icon">keyboard_arrow_right</mat-icon>
      </div>
  </div>
</div>