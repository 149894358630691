<div  fxHide.lt-md class="container">
    <div fxLayout="column" fxLayoutAlign="space-around start"  class="why-medplusmart">
        <h2>Why MedPlusMart</h2>
        <div  fxLayout="row" fxLayoutAlign="center center" >
            <div fxFlex="25" *ngFor="let item of images" fxLayout="column" fxLayoutAlign="space-around start" class="feature-box">
                <div class="feature-icon" style="width:65px ;">
                    <img  fxLayoutAlign="center center" style="width:100% ;" src="{{item.img}}" alt="safe and secure">
                </div>
                <h3>{{item.title}}</h3>
                <p>{{item.content}}</p>
            </div>
        
            <!-- <div fxFlex="25" fxLayout="column" fxLayoutAlign="space-around start" class="feature-box">
                <div class="feature-icon"  style="width:65px ;">
                    <img  fxLayoutAlign="center center" style="width:100% ;" src="/assets/images/delivery-truck.png" alt="safe and secure">
                </div>
                <h3>Click and Pick</h3>
                <p>Click to buy a range of products across categories.</p>
            </div>
        
            <div fxFlex="25" fxLayout="column" fxLayoutAlign="space-around start"class="feature-box">
                <div class="feature-icon"  style="width:65px ;">
                    <img  fxLayoutAlign="center center" style="width:100% ;" src="/assets/images/delivery-truck.png" alt="safe and secure">
                </div>
                <h3>Home Delivery</h3>
                <p>We offer convenient home delivery of medicines & general goods.</p>
            </div>
        
            <div fxFlex="25" fxLayout="column" fxLayoutAlign="space-around start" class="feature-box">
                <div class="feature-icon"  style="width:65px ;">
                    <img  fxLayoutAlign="center center" style="width:100% ;" src="/assets/images/delivery-truck.png" alt="safe and secure">
                </div>
                <h3>Unlimited Health Records</h3>
                <p>Save all your health records (Medical Records...)</p>
            </div> -->
        </div>
        
    </div>
</div>


<div  fxHide fxShow.lt-md class="container">
    <div fxLayout="column" fxLayoutAlign="space-around start"  class="why-medplusmart">
        <h2>Why MedPlusMart</h2>
        <div  fxLayout="column" fxLayoutAlign="center center" >
            <div fxFlex="25" *ngFor="let item of images" fxLayout="column" fxLayoutAlign="space-around start" class="feature-box">
                <div class="feature-icon" style="width:65px ;">
                    <img  fxLayoutAlign="center center" style="width:100% ;" src="{{item.img}}" alt="safe and secure">
                </div>
                <h3>{{item.title}}</h3>
                <p>{{item.content}}</p>
            </div>
        
            <!-- <div fxFlex="25" fxLayout="column" fxLayoutAlign="space-around start" class="feature-box">
                <div class="feature-icon"  style="width:65px ;">
                    <img  fxLayoutAlign="center center" style="width:100% ;" src="/assets/images/delivery-truck.png" alt="safe and secure">
                </div>
                <h3>Click and Pick</h3>
                <p>Click to buy a range of products across categories.</p>
            </div>
        
            <div fxFlex="25" fxLayout="column" fxLayoutAlign="space-around start"class="feature-box">
                <div class="feature-icon"  style="width:65px ;">
                    <img  fxLayoutAlign="center center" style="width:100% ;" src="/assets/images/delivery-truck.png" alt="safe and secure">
                </div>
                <h3>Home Delivery</h3>
                <p>We offer convenient home delivery of medicines & general goods.</p>
            </div>
        
            <div fxFlex="25" fxLayout="column" fxLayoutAlign="space-around start" class="feature-box">
                <div class="feature-icon"  style="width:65px ;">
                    <img  fxLayoutAlign="center center" style="width:100% ;" src="/assets/images/delivery-truck.png" alt="safe and secure">
                </div>
                <h3>Unlimited Health Records</h3>
                <p>Save all your health records (Medical Records...)</p>
            </div> -->
        </div>
        
    </div>
</div>
