import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import { Router } from '@angular/router';
import { ListColumn } from 'src/app/models/list-coloum.model';


// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};;

@Component({
  selector: 'calsoft-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit{

  @Input() filterSelectObj = [];

  @Input() name: string;
  @Input() progress: any;



  @Input() type: any = 1;
  @Input() displayFilerOption: any = false;
   @Input() columns: ListColumn[];
  @ViewChild('filter', { static: true }) filter: ElementRef;
  @Output() filterChange = new EventEmitter<string>();
  value = new UntypedFormControl('');
  @Input() searchRequired;

  @Input() refreshRequired = false;

  @Input() displayColumnRequired: any;


  @Output() filterChangeColumn = new EventEmitter<any>();


  @Output() filterChangeColumnNew = new EventEmitter<any>();
  firstValue: any = null;
  secondValue: any = null;
  thirdValue: any = null;
  fourthValue: any = null;
  searchTerm: string = undefined;
  selected = 'ALL';
  searchValues: any;
  index: any;
  j: any;
  searchTermPresent: any = true;
  searchTermMain: any;
  searchTermMainEmit: any = 0;
  searchTermEmit: any = 0;
  searchTermMainPresent: any = true;
  rangeEnable: boolean=false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
   // private leadStatusService: LeadStatusService,
    // private leadSourceService: LeadSourceService,
    private router: Router,
    // private usersService: UsersService,
  ) {

  }
  range: UntypedFormGroup;
  ngOnInit() {
    let url = this.router.url;
    let cleanUrl = url.split('?')[0];
    let range: any = JSON.parse(localStorage.getItem(cleanUrl + "range"))
    let filterObject = [];
    this.initializeForm();
   
    filterObject = JSON.parse(localStorage.getItem(cleanUrl))
    let searchTerm = localStorage.getItem(cleanUrl + "se")
    let type = localStorage.getItem(cleanUrl + "type")
    if (filterObject == null) {
      filterObject = [];
    }


    if (type == "2" && filterObject.length > 0 && searchTerm != null) {
      

      let selected: any = JSON.parse(localStorage.getItem(cleanUrl + "selected"))

      if (selected != null) {

        this.selectedValues = selected;
      }

      let indexValue=0;
      this.filterSelectObj.forEach(element => {

        if(element.type=='multiSelect'){
          console.log(element);
          
          this[element.position]=this.selectedValues[indexValue];

          console.log( this[element.position]);
        }
       
        indexValue++;
      });
      this.searchTerm = searchTerm;
      this.searchTermPresent = false;
    } if (type == "1" && searchTerm != null) {
      this.filter.nativeElement.value = searchTerm;

      this.refreshRequired = false;
    }



    // let indexForleadStatus = this.filterSelectObj.findIndex(x => x.name == 'Status Name')
    // if (indexForleadStatus > -1) {
    //   this.getLeadDropDownList();
    // }
    // this.initializeForm();
    // let indexForleadSource = this.filterSelectObj.findIndex(x => x.name == 'Source Name')
    // if (indexForleadSource > -1) {
    //   this.getLeadSourceDownList();
    // }
    // this.initializeForm();
    // let indexForleadUser = this.filterSelectObj.findIndex(x => x.name == 'User Name')
    // if (indexForleadUser > -1) {
    //   this.getUserDropDownList();
    // }
    // let indexForleadActivity = this.filterSelectObj.findIndex(x => x.name == 'Activity Type')
    // if (indexForleadActivity > -1) {
    //   this.getLeadActivityTypeDownList();
    // }

    let indexForleadUserOwner = this.filterSelectObj.findIndex(x => x.name == 'Owner')
    if (indexForleadUserOwner > -1) {
      this.getUserDropDownList();
    }

    if (this.refreshRequired == undefined) {
      this.refreshRequired = false;
    }

    if (this.searchRequired == undefined) {
      this.searchRequired = true;
    }
    if (this.displayColumnRequired == undefined) {
      this.displayColumnRequired = true;
    }
    fromEvent(this.filter.nativeElement, 'keyup').pipe(
      distinctUntilChanged(),
      debounceTime(150)
    ).subscribe((e) => {

      let value = (<HTMLInputElement>e['target']).value;

      if (e['code'] == 'Space' && value == ' ') {
        if (this.searchTermMainPresent) {
          this.searchTermMainPresent = true;
        }
        this.filter.nativeElement.value = this.filter.nativeElement.value.trim()
      } else if (e['key'] == 'Backspace') {
        this.searchTermMain = this.filter.nativeElement.value;
        if (this.searchTermMainEmit == 0 && this.searchTermMain.trim().length == 0) {
          this.searchTermMainPresent = true;
        } else {
          this.searchTermMainPresent = false;
        }

        //  this.filterChange.emit(this.filter.nativeElement.value.trim())
      }
      else if (e['code'] != 'Space' && value.trim().length == 0) {
        value = value.trim();
      }
      else if (value.trim().length > 0) {
        this.searchTermMainPresent = false;
        this.searchTermMain = this.filter.nativeElement.value;
        // this.filterChange.emit(this.filter.nativeElement.value.trim())
      }
    });

    this.modelChanged
      .pipe(
        debounceTime(150))
      .subscribe((event) => {

        let value = (<HTMLInputElement>this.searchValues['target']).value;
        let code = this.searchValues['code'];
        if (code == 'Space' && value == ' ') {
          value = value.trim();
        } else if (code == 'Backspace') {
          this.filterColumnText(value, this.index, this.j)
          if (this.searchTermEmit == 0 && this.searchTerm == undefined) {
            this.searchTermPresent = true;

          } else {
            this.searchTermPresent = false;
          }

        }
        else if (code != 'Space' && value.trim().length == 0) {
          value = value.trim();
        }
        else if (value.trim().length > 0) {
          this.filterColumnText(value, this.index, this.j)
        }
      })
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }


  filtersForm: UntypedFormGroup;
  initializeForm(): void {
    this.filtersForm = this._formBuilder.group({
      ranges: this._formBuilder.array([])
    });

    this.addDateRangeFormGroup();
  }

  addDateRangeFormGroup(): void {

    let i=0;

    let url = this.router.url;
    let cleanUrl = url.split('?')[0];
    let range: any = JSON.parse(localStorage.getItem(cleanUrl + "range"))
  

    
    this.filterSelectObj.forEach(() => {

      let start:any='';
      let end:any=''

      if(range!=null){
        if(range.ranges[i].start!=null){

          start=new Date(range.ranges[i].start);
          end=new Date(range.ranges[i].end);
  
        }
      }
      const rangeGroup = this._formBuilder.group({
        start: start,
        end: end
      });
      (this.filtersForm.get('ranges') as FormArray).push(rangeGroup);
      i++;
    });

    
  }

  modelChanged = new Subject<string>();
  search(value, index, j) {
    this.searchValues = value;
    this.index = index;
    this.j = j;

  }



  onPaste(event: ClipboardEvent, index, j) {
    let clipboardData = event.clipboardData
    let pastedText = clipboardData.getData('text');
    let obj = {
      data: pastedText,
      inputType: "Text"
    }
    this.filterColumn(obj, index, pastedText)

  }

  // toggleColumnVisibility(column, event,index) {


  //   event.stopPropagation();
  //   event.stopImmediatePropagation();


  //   column.visible = !column.visible;
  //   this.columns[index].visible = !this.columns[index].visible; 

  //   console.log(this.columns[index].visible);


  // }

  toggleColumnVisibility(column, event, index) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    // this.columns[index].visible = !this.columns[index].visible;
  }


  setValue(index: any, checked) {
    // console.log(this.columns[index].visible);

    event.stopPropagation();
    event.stopImmediatePropagation();

  }

  resetFilters() {
    this.searchTermMain = ''
    if (this.type == 1) {

      this.filter.nativeElement.value = '';
    }

    this.filtersForm.reset();
    this.filterSelectObj.forEach((value, key) => {
      value.modelValue = undefined;
      this[value.position] = undefined
    })
    this.selected = 'ALL'
    this.searchTerm = null;
    this.searchTerm = undefined;

    let url = this.router.url;
    let cleanUrl = url.split('?')[0];
    localStorage.removeItem(cleanUrl);
    localStorage.removeItem(cleanUrl + "se");
    localStorage.removeItem(cleanUrl + "type");
    localStorage.removeItem(cleanUrl + "range");

    this.filterChangeColumn.emit(this.searchTerm)
    this.selectedValues = [];
  }



  resetFiltersMobiletest() {
    this.searchTermMain = ''
    this.filter.nativeElement.value = '';

    this.selected = 'ALL'
    this.searchTerm = null;
    this.searchTerm = '';
    this.filterChange.emit(this.searchTerm)
    // this.customersList.filter = "";
  }


  filterColumnText(value, index, j) {

    console.log((<HTMLInputElement>value['target']).value + 'sa')
    if (value.code == 'Space' && (<HTMLInputElement>value['target']).value.length > 0) {

      (<HTMLInputElement>value['target']).value = (<HTMLInputElement>value['target']).value + Array(1).fill('\xa0').join('')
    }

    let finalValue = (<HTMLInputElement>value['target']).value;


    console.log(finalValue + "sas");
    if (finalValue.trim().length > 0) {



      this.searchValues = value;
      if (value.code == 'Space' && (<HTMLInputElement>value['target']).value.trim().length > 0) {

        (<HTMLInputElement>value['target']).value = (<HTMLInputElement>value['target']).value = (<HTMLInputElement>value['target']).value + Array(1).fill('\xa0').join('')
      }
      let finalValue = (<HTMLInputElement>value['target']).value;


      console.log(finalValue + "sas");
      if (this[this.filterSelectObj[index].position] == null) {

        this[this.filterSelectObj[index].position] = finalValue != null ? finalValue : finalValue;

      }
      else {

        if (finalValue != undefined) {

          if (finalValue.length == 0) {
            this[this.filterSelectObj[index].position] = undefined;
          } else {
            this[this.filterSelectObj[index].position] = finalValue;
          }

        }
        else if (j.length == 0) {
          this[this.filterSelectObj[index].position] = undefined;
        }
        else if (this[this.filterSelectObj[index].position] != ''
          && this.filterSelectObj[index].type != 'select'
          && this[this.filterSelectObj[index].position].length > 1) {
          this[this.filterSelectObj[index].position] = this[this.filterSelectObj[index].position].slice(0, -1);
        }
        else {
          this[this.filterSelectObj[index].position] = undefined;
        }

      }

     
    }else{

      this[this.filterSelectObj[index].position] = undefined;
    }
    this.filterOutput();
  }

  filterColumn(value, index, j) {

    let finalValue = value.value

    if (this[this.filterSelectObj[index].position] == null) {
      this[this.filterSelectObj[index].position] = finalValue;
    } else {
      if (finalValue == undefined && finalValue != 'ALL') {
        this[this.filterSelectObj[index].position] = finalValue;
      }
      else if (j.length == 0) {
        this[this.filterSelectObj[index].position] = undefined;
      }

      else if (this.filterSelectObj[index].type == 'select') {
        if (finalValue != 'ALL') {
          this[this.filterSelectObj[index].position] = finalValue;
        }
        else {
          this[this.filterSelectObj[index].position] = undefined;
        }
      }
      else if (this.filterSelectObj[index].type == 'multiSelect') {
        // if (finalValue != 'ALL') {
        //   this[this.filterSelectObj[index].position] = finalValue;
        // }
        // else {
        //   this[this.filterSelectObj[index].position] = undefined;
        // }
      }
      else {
        this[this.filterSelectObj[index].position] = undefined;
      }
    }


    this.filterOutput();
  }

  selectedValues: any[] = [];
  filterColumnMultiSelect(value, index, j) {

    console.log(this.searchTerm);
    
    let indexForloop = 0;
    let i = index;
    

    if( value.value.length>0){
      value.value.forEach(element => {

        if (indexForloop == 0) {
          this[this.filterSelectObj[index].position] = element + ","
        } else if(indexForloop==1){
          this[this.filterSelectObj[index].position] = this[this.filterSelectObj[index].position]  + element
        }
        
        else {
          this[this.filterSelectObj[index].position] = this[this.filterSelectObj[index].position] + "," + element
        }
  
        indexForloop++;
        i++;
      });
    }else{
      this[this.filterSelectObj[index].position] =undefined

    }



    console.log(this.filterSelectObj);

    this.filterOutput();


  }


  filterOutput() {

    this.searchTerm = undefined;
    this.filterSelectObj.forEach((element, indexForLoop) => {
      //   console.log(this.filterSelectObj[indexForLoop].type)
      if (this[element['position']] != undefined) {
        if (this.searchTerm == undefined) {

          if (this.filterSelectObj[indexForLoop].type == 'price') {
            this.searchTerm = this.filterSelectObj[indexForLoop].property + ':' + this[element['position']];
          }
          else if (this.filterSelectObj[indexForLoop].type != 'select') {
            this.searchTerm = this.filterSelectObj[indexForLoop].property + ':' + "'*" + this[element['position']] + "*'"
          }
          else if (this.filterSelectObj[indexForLoop].type == 'select') {

            this.searchTerm = this.filterSelectObj[indexForLoop].property + ':' + this[element['position']];

          }
        }
        else {

          if (this.filterSelectObj[indexForLoop].type == 'price') {
            this.searchTerm = this.searchTerm + ' AND ' + this.filterSelectObj[indexForLoop].property + ':' + this[element['position']];
          }
          else if (this.filterSelectObj[indexForLoop].type != 'select') {
            this.searchTerm = this.searchTerm + ' AND ' + this.filterSelectObj[indexForLoop].property + ':' + "'*" +
              this[element['position']] + "*'"
          } else {
            this.searchTerm = this.searchTerm + ' AND ' + this.filterSelectObj[indexForLoop].property + ':' + this[element['position']]

          }
        }

      }

    });

    this.searchTermPresent = false;

  }

  start(event, type, index) {
    // event.value = this.datepipe.transform(event.value, 'yyyy-MM-dd');
    let startDate = event.value;

    this[this.filterSelectObj[index].position] = startDate;

    let stringToSplit = this[this.filterSelectObj[index].position].split("~");

    console.log(this.filtersForm);

  }


  end(event, type, index) {

    // event.value = this.datepipe.transform(event.value, 'yyyy-MM-dd');
    let endDate = event.value;


    if (this.filtersForm.value.ranges[index] != null && this.filtersForm.value.ranges[index] != null && endDate != null) {
      this[this.filterSelectObj[index].position] = this[this.filterSelectObj[index].position] + '~' + endDate;
      this.filterOutput();
    }
    console.log(this.filtersForm);

  }


  searchFilter() {

    if (!this.searchTermPresent) {
      if (this.searchTerm == undefined) {
        this.searchTermPresent = true;
        this.searchTermEmit = 0
      } else {
        this.searchTermPresent = false;
        this.searchTermEmit++;
      }

      var rule = /\s{1,}/g;

      if( this.searchTerm !=undefined){
        this.searchTerm = this.searchTerm.split(rule).join(" ");  
      }
     
      let url = this.router.url;
      let cleanUrl = url.split('?')[0];

      if (this.type == 1) {
        localStorage.removeItem(cleanUrl);
        localStorage.setItem(cleanUrl, JSON.stringify(this.filterSelectObj));
        localStorage.setItem(cleanUrl + "se", this.searchTerm);
        localStorage.setItem(cleanUrl + "type", "2");

        localStorage.setItem(cleanUrl + "range", JSON.stringify(this.filtersForm.value));

        localStorage.setItem(cleanUrl + "selected", JSON.stringify(this.selectedValues));
      }


      this.filterChangeColumn.emit(this.searchTerm)

    } else {
      this.searchTermEmit = 0;
      this.searchTermPresent = true;
    }
  }


  searchFilterMain() {

    if (!this.searchTermMainPresent) {
      if (this.searchTermMain.trim().length == 0) {
        this.searchTermMainPresent = true;
        this.searchTermMainEmit = 0
      } else {
        this.searchTermMainPresent = false;
        this.searchTermMainEmit++;
        let url = this.router.url;
        let cleanUrl = url.split('?')[0];
        localStorage.removeItem(cleanUrl);
        localStorage.removeItem(cleanUrl + "type");
        localStorage.setItem(cleanUrl + "se", this.searchTermMain);
        localStorage.setItem(cleanUrl + "type", "1");
      }

      this.filterChange.emit(this.searchTermMain)

    } else {
      this.searchTermMainEmit = 0;
      this.searchTermMainPresent = true;
    }
  }

  mobileSearch() {
    let value = this.value.value;


    this.searchTermMain = value;
    this.searchTermMainPresent = false;

    this.searchFilterMain()

  }

  resetFiltersMobile() {
    this.value.reset();
    this.filter.nativeElement.value = '';
    this.resetFiltersMobiletest()
    this.selectedValues = [];
  }

  createRangeForm() {
    this.range = this._formBuilder.group({
      start: [''],
      end: [''],



    },


    )
  }


  //lead status

  filtereddropdownTypeList: any;

  public typeYFilterControl: UntypedFormControl = new UntypedFormControl();
  protected _onDestroy = new Subject<void>();

  defaultLeadStatus: FormControl = new FormControl('');

  dropdownTypeList: any;
  dropdownList: any;


  // getLeadDropDownList() {

  //   this.leadStatusService.getAllLeadStatusDropDown().subscribe(
  //     res => {
  //       this.dropdownList = res;
  //       this.dropdownTypeList = res;
  //       this.filtereddropdownTypeList = this.dropdownTypeList;
  //     },
  //     error => {
  //     })
  // }


  typeYFilter() {
    this.typeYFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.typeidYFilter();
      });
  }


  protected typeidYFilter() {
    if (!this.dropdownTypeList) {
      return;
    }
    let search = this.typeYFilterControl.value;
    if (!search) {
      this.filtereddropdownTypeList = this.dropdownTypeList.slice();
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filtereddropdownTypeList = this.dropdownTypeList.filter(user => user['statusName'].toLowerCase().indexOf(search) > -1)
  }

  getAlldropdowntype() {
    this.filtereddropdownTypeList = this.dropdownTypeList;
  }


  //leadSource

  filteredLeadSourceTypeList: any;

  LeadSourceTypeList: any;
  LeadSourceList: any;

  // getLeadSourceDownList() {

  //   this.leadSourceService.getAllLeadSourceDropDown().subscribe(
  //     res => {
  //       this.LeadSourceList = res;
  //       this.LeadSourceTypeList = res;
  //       this.filteredLeadSourceTypeList = this.LeadSourceTypeList;
  //     },
  //     error => {
  //     })
  // }


  LeadSourceYFilter() {
    this.typeYFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.typeLeadSourceYFilter();
      });
  }


  protected typeLeadSourceYFilter() {
    if (!this.LeadSourceTypeList) {
      return;
    }
    let search = this.typeYFilterControl.value;
    if (!search) {
      this.filteredLeadSourceTypeList = this.LeadSourceTypeList.slice();
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredLeadSourceTypeList = this.LeadSourceTypeList.filter(source => source['sourceName'].toLowerCase().indexOf(search) > -1)
  }

  getAllSourcedropdowntype() {
    this.filteredLeadSourceTypeList = this.LeadSourceTypeList;
  }

  //ActivityType

  filteredActivityTypeList: any;

  LeadActivityTypeList: any;
  LeadActivityList: any;

  // getLeadActivityTypeDownList() {

  //   this.leadSourceService.getAllActivityTypeDropDown().subscribe(
  //     res => {
  //       this.LeadActivityList = res;
  //       this.LeadActivityTypeList = res;
  //       this.filteredActivityTypeList = this.LeadActivityTypeList;
  //     },
  //     error => {
  //     })
  // }


  LeadActivityTypeYFilter() {
    this.typeYFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.typeLeadActivityTypeYFilter();
      });
  }


  protected typeLeadActivityTypeYFilter() {
    if (!this.LeadActivityTypeList) {
      return;
    }
    let search = this.typeYFilterControl.value;
    if (!search) {
      this.filteredActivityTypeList = this.LeadActivityTypeList.slice();
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredActivityTypeList = this.LeadActivityTypeList.filter(source => source['activityType'].toLowerCase().indexOf(search) > -1)
  }

  getAllActivityTypedropdowntype() {
    this.filteredActivityTypeList = this.LeadActivityTypeList;
  }


  

  //user Group

  defaultLeadOwner: FormControl = new FormControl('');

  userTypeList: any;
  userList: any;
  filtereduserTypeList: any;

  getUserDropDownList() {

    let val: any = localStorage.getItem('userType')

    if (val == null) {
      val = 0;
    } else if (val == 'salesUser') {
      val = 1;
    } else {
      val = 0;
    }

    console.log(val);

  //   if (!this.router.url.includes('crmAnalytics') && val == 0) {

  //     this.usersService.getAllUserStatusDropDown().subscribe(
  //       res => {
  //         this.userList = res;
  //         this.userTypeList = res;
  //         this.filtereduserTypeList = this.userTypeList;
  //       },
  //       error => {
  //       })
  //   } else {
  //     this.usersService.getAllUserStatusDropDownSales().subscribe(
  //       res => {
  //         this.userList = res;
  //         this.userTypeList = res;
  //         this.filtereduserTypeList = this.userTypeList;
  //       },
  //       error => {
  //       })
  //   }

  // }

  // usertypeYFilter() {
  //   this.typeYFilterControl.valueChanges
  //     .pipe(takeUntil(this._onDestroy))
  //     .subscribe(() => {
  //       this.usertypeidYFilter();
  //     });
  // }


  // protected usertypeidYFilter() {
  //   if (!this.userTypeList) {
  //     return;
  //   }
  //   let search = this.typeYFilterControl.value;
  //   if (!search) {
  //     this.filtereduserTypeList = this.userTypeList.slice();
  //     return;
  //   } else {
  //     search = search.toLowerCase();
  //   }
  //   // filter the banks
  //   this.filtereduserTypeList = this.userTypeList.filter(user => user['email'].toLowerCase().indexOf(search) > -1)
  // }

}
}

