import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-ng-optimized-image',
  templateUrl: './ng-optimized-image.component.html',
  styleUrls: ['./ng-optimized-image.component.scss']
})
export class NgOptimizedImageComponent {


  @Input() height: any;

  @Input() i: any;

  @Input() heightMob: any;

  @Input() categoryId6: any;

  @Input() navigation: any;

  @Input() altImage: any;

  @Input() catId: any;

  @Input() navigatedUrl: any;

  @Input() customField9: any;

  @Input() image: any;

  constructor( private commonService: CommonService,) { }




  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }
  

  link(url) {

    this.commonService.goToLink(url)
  }


}
