import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'calsoft-banner-type-anim',
  templateUrl: './banner-type-anim.component.html',
  styleUrls: ['./banner-type-anim.component.scss']
})
export class BannerTypeAnimComponent implements OnInit{


  

  @Input() item: any;
  @Input() img: any;
  @Input() bannerWidth: any;
  @Input() bannerHeight: any;
  @Input() bannerMobWidth: any;
  @Input() bannerMobHeight: any;
  @Input() bannerTabletHeight:any;
  @Input() bannerTabletWeight:any;
  
  public serverPath = environment.commonImageApi + '/home/';
  imageUrl: string;
  public url = environment.commonImageApi;
  bannertextset:any;

  ngOnInit(): void {

    this.imageUrl = this.url + "commonSlider/";
    this.bannertextset = this.img[0].customField29;

  }

  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      window.open(fullURL, '_blank');


    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }


}
