import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubscriptionRoutingModule } from './subscription-routing.module';
import { SubscriptionComponent } from './subscription.component';
import { PurchaseModule } from '../purchase/purchase.module';
import { SubscriptionPlanModule } from '../subscription-plan/subscription-plan.module';


@NgModule({
  declarations: [
    SubscriptionComponent
  ],
  imports: [
    CommonModule,
    SubscriptionRoutingModule,
    PurchaseModule,
    SubscriptionPlanModule,
    
  ],exports:[
    SubscriptionComponent
  ]
})
export class SubscriptionModule { }
