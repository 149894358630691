<div  fxHide.lt-md >
  <div class="intro container">
    <div class="bannerImage" [style.height]="item.height" [style.background]="'url(\'' + image + '\') center center / cover no-repeat rgb(51, 51, 51)'">
      <div (mouseenter)="showInfo(i)" *ngFor="let item of item.images; let i=index" class="images">
        <div  [class.imageHover]="i === hoverIndex">
            <h3 [ngStyle]="{ 'display': i === hoverIndex ? 'block' : 'none' }" class="title"  ngStyle.gt-md]="{'font-size': item.customField18 }"
            [ngStyle.lt-md]="{
                
                'font-size': item.customField17
            }" >{{ item.customField1}}</h3>
            <h3 class="title" style="color: white; padding-bottom: 10px;" ngStyle.gt-md]="{'font-size': item.customField18 }"
            [ngStyle.lt-md]="{
                
                'font-size': item.customField17
            }">{{ item.customField1}}</h3>
            <span [ngStyle]="{ 'display': i === hoverIndex ? 'block' : 'none' }" class="description">{{ item.customField12}}</span>      
        </div>
      </div>
    </div>
  </div>
</div>

<div  fxHide fxShow.lt-md>
  <div class="intro container">
    <div class="bannerImage" [style.height]="item.height" [style.background]="'url(\'' + image + '\') center center / cover no-repeat rgb(51, 51, 51)'">
      <div (mouseenter)="showInfo(i)" *ngFor="let item of item.images; let i=index" class="images">
        <div  [class.imageHover]="i === hoverIndex">
            <h3 [ngStyle]="{ 'display': i === hoverIndex ? 'block' : 'none' }" class="title" ngStyle.gt-md]="{'font-size': item.customField18 }"
            [ngStyle.lt-md]="{
                
                'font-size': item.customField17
            }">{{ item.customField1}}</h3>
            <h3 class="title" style="color: white; padding-bottom: 10px;" ngStyle.gt-md]="{'font-size': item.customField18 }"
            [ngStyle.lt-md]="{
                
                'font-size': item.customField17
            }">{{ item.customField1}}</h3>
            <span [ngStyle]="{ 'display': i === hoverIndex ? 'block' : 'none' }" class="description" ngStyle.gt-md]="{'font-size': item.customField18 }"
            [ngStyle.lt-md]="{
                
                'font-size': item.customField17
            }">{{ item.customField12}}</span>      
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div  fxShow.lt.md>
  <div class="swiper-container" #swiper>
    <div class="swiper-wrapper"  [style.height]="item.heightMob">
      <div *ngFor="let item of item.images" class="swiper-slide"
    
      [style.backgroundImage]="'url(' + serverPath+item.img + ')'">

        <div class="slide-content">
            <h4>{{item.customField1}}hsdhjahj</h4>
            <p class="linkcss" (click)="openlink(item.navigatedUrl)">{{item.customField12}}</p>
        </div>

      </div>
    </div>
    <div class="swiper-pagination"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </div>
</div> -->

