<!-- <div class="product-item product-elevat cat ext-center">
    <div class="productPrice padding-rl10" (click)="productShow(product)" fxLayout="row" fxLayoutAlign="start center">

        <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
            [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex>
            <div fxLayout="row">
                <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">
                    <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>

                </div>
            </div>
        </div>

        <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
            [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
            <div fxLayout="row">
                <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
                    [type]="'productsNew'"></calsoft-translator>
            </div>
        </div>
    </div>

    <div [ngClass]="{ overlayProduct: qty <= 0 && manageStock == 1 }" *ngIf="averageRating == 0"
        style="padding-bottom: 20px"></div>

    <div *ngIf="showConfigurableDropdown == 1">

        <ng-container *ngIf="!showNewConfig; else newconfig">
            <div class="padding-rl10 A" fxLayout="row" *ngFor="let item of 
        configurationDropDownList | keyvalue">
                <mat-form-field style="
              font-size: 10px;
              height: 54px;
              padding: 0px;
              margin: 0px;
              padding-bottom: 0px;
            " appearance="outline" fxFlex="100">
                    <mat-label style="font-size: 12px">
                        {{ item.key }}
                    </mat-label>
                    <mat-select style="padding-bottom: 0.34375em; padding-top: 5px"
                        (selectionChange)="getConfigProductId($event.value)" [disableOptionCentering]="true"
                        [formControl]="dropDownValue">
                        <mat-option style="font-size: 14px" [value]="object" *ngFor="let object of item.value">
                            {{ object | splitbyHash }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>

        <ng-template #newconfig>

            <div class="padding-rl10 A" fxLayout="row">
                <mat-form-field style="
              font-size: 10px;
              height: 54px;
              padding: 0px;
              margin: 0px;
              padding-bottom: 0px;
            " appearance="outline" fxFlex="100">
                    <mat-label style="font-size: 12px">
                        {{configurationDropDownList[0].key}}
                    </mat-label>
                    <mat-select [(ngModel)]="configurationDropDownList[firstIndex].value"
                        [disableOptionCentering]="true">
                        <mat-option (onSelectionChange)="getConfigProductIdNew($event,i,item,'firstIndex')"
                            [value]="item.value" *ngFor="let item of configurationDropDownList;let i=index;">
                            {{item.value}}
                        </mat-option>
                    </mat-select>

                </mat-form-field>
            </div>

            <div class="padding-rl10 A" fxLayout="row">
                <mat-form-field style="
          font-size: 10px;
          height: 54px;
          padding: 0px;
          margin: 0px;
          padding-bottom: 0px;
        " appearance="outline" fxFlex="100">
                    <mat-label style="font-size: 12px">
                        {{configurationDropDownList[0]
                        ['recursuive'][0].key}}

                    </mat-label>
                    <mat-select [(ngModel)]="configurationDropDownList[firstIndex]
                .recursuive[secondIndex].value" [disableOptionCentering]="true">
                        <mat-option (onSelectionChange)="getConfigProductIdNew($event,j,item1,'secondIndex')"
                            [value]="item1.value" *ngFor="let item1 of configurationDropDownList[firstIndex]
         ['recursuive'];let j=index;">
                            {{item1.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-template>
    </div>

    <div *ngIf="this.data.disablePrice==0">
        <div [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
            <div *ngIf="inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
                fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

                <span *ngIf="displayMrpPrice">
                    <span *ngIf="specialPrice > 0 && specialPrice != price">
                        <b>MRP :</b>
                        <span class="old-price text-strike text-muted font-16px">
                            {{ price | currencyConvert }}{{units}}
                        </span>
                    </span>
                </span>

                <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
                    <span *ngIf="displayMrpPrice">
                        <b>MRP :</b>
                    </span>
                    <b> {{ price | currencyConvert }}{{units}}</b> (incl. of tax)
                </span>

                <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
                    <b> {{ specialPrice | currencyConvert }}{{units}}</b> (incl. of tax)
                </span>

                <span *ngIf="!displayMrpPrice">
                    <span *ngIf="specialPrice > 0 && specialPrice != price"
                        class="old-price text-strike text-muted font-16px">
                        {{ price | currencyConvert }}{{units}}
                    </span>
                </span>
            </div>

            <div *ngIf="!inclTaxEnabled" class="padding-rl10" (click)="productShow(product)"
                class="pointer padding-rl10" fxLayout="row" fxLayoutGap="10px"
                fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

                <span *ngIf="specialPrice > 0 && specialPrice != price">
                    <b *ngIf="displayMrpPrice">MRP :</b>
                    <span class="old-price text-strike text-muted font-16px">
                        {{ price | currencyConvert }}{{units}}
                    </span>
                </span>

                <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
                    <span *ngIf="displayMrpPrice">
                        <b>MRP :</b>
                    </span>
                    <b> {{ price | currencyConvert }}{{units}}</b>
                </span>

                <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
                    <b> {{ specialPrice | currencyConvert }}{{units}}</b>
                    <span *ngIf="displayMrpPrice">
                        (incl. of tax)
                    </span>
                </span>
            </div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
        <div [ngClass]="{ overlayProduct: (qty <= 0 || isInStock==0) && (manageStock == 1 || isInStock==0) }">
            <calsoft-controls [product]="configProductSelected" [data]="data" [variant1Name]="variant1Name"
                [variant2Name]="variant2Name" [variant1Value]="variant1Value" [variant2Value]="variant2Value"
                [configproduct]="config " [type]="'miniCart'" [showCartIcon]="showCartIcon"
                [showCartIncremented]="showCartIncremented">
            </calsoft-controls>
        </div>
    </div>

</div> -->

<div>
    <div fxHide.lt-md>

        <div fxLayout="row"  class="cat">
            <div *ngIf="item.customField16 ==1" fxFlex="60" style="cursor: pointer;" (click)="productShow(product)" class="prod-detail">
                <h3 class="h3-title"><b>{{product.name}}</b></h3>
                <span class="description">{{product?.shortDescription}}</span>
            </div>

            <div *ngIf="item.customField16 !=1" fxFlex="60" style="cursor: pointer;" class="prod-detail">
                <h3 class="h3-title"><b>{{product.name}}</b></h3>
                <span class="description">{{product?.shortDescription}}</span>
            </div>

            <div fxFlex="30">
                <div fxLayout="column" style="justify-content: center; align-items: center; margin-top: 30px;" fxLayoutAlign="center center">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <div *ngIf="data.disablePrice === 0">
                            <div [ngClass]="{ overlayProduct: (qty <= 0 || isInStock === 0) && (manageStock === 1 || isInStock === 0) }">
                                <div *ngIf="inclTaxEnabled" class="padding-rl10 pointer" (click)="productShow(product)"
                                    fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">
            
                                    <span *ngIf="displayMrpPrice">
                                        <b *ngIf="specialPrice > 0 && specialPrice !== price">MRP :</b>
                                        <span class="old-price text-strike text-muted font-16px">
                                            {{ price | currencyConvert }}{{units}}
                                        </span>
                                    </span>
            
                                    <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
                                        <b *ngIf="displayMrpPrice">MRP :</b>
                                        {{ price | currencyConvert }}{{units}} (incl. of tax)
                                    </span>
            
                                    <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
                                        {{ specialPrice | currencyConvert }}{{units}} (incl. of tax)
                                    </span>
            
                                    <span *ngIf="!displayMrpPrice">
                                        <span *ngIf="specialPrice > 0 && specialPrice !== price"
                                            class="old-price text-strike text-muted font-16px">
                                            {{ price | currencyConvert }}{{units}}
                                        </span>
                                    </span>
                                </div>
            
                                <div *ngIf="!inclTaxEnabled" class="padding-rl10 pointer" (click)="productShow(product)"
                                    fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center">
                                    
            
                                    <span *ngIf="specialPrice > 0 && specialPrice !== price">
                                        <b *ngIf="displayMrpPrice">MRP :</b>
                                        <span class="old-price text-strike text-muted font-16px">
                                            {{ price | currencyConvert }}{{units}}
                                        </span>
                                    </span>
            
                                    <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
                                        <b *ngIf="displayMrpPrice">MRP :</b>
                                        {{ price | currencyConvert }}{{units}}
                                    </span>
            
                                    <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
                                        {{ specialPrice | currencyConvert }}{{units}}
                                        <span *ngIf="displayMrpPrice">(incl. of tax)</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <div fxLayout="row" style="padding-top: 5px;padding-bottom: 5px;" fxLayoutAlign="center center">
                        <calsoft-controls [product]="configProductSelected" [data]="data" [variant1Name]="variant1Name"
                            [variant2Name]="variant2Name" [variant1Value]="variant1Value" [variant2Value]="variant2Value"
                            [configproduct]="config" [type]="'miniCart'" [showCartIcon]="showCartIcon"
                            [showCartIncremented]="showCartIncremented">
                        </calsoft-controls>
                    </div>
                </div>
            </div>
            

            <div fxFlex="10" class="pointer"  (click)="toggleAccordion()" style="background-color: lightgray;" fxLayoutAlign="center center">

                <button *ngIf="!accordionOpen" mat-icon-button >
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <button *ngIf="accordionOpen" mat-icon-button >
                <mat-icon>keyboard_arrow_up</mat-icon>
                </button>
            </div>
        </div>


        <div *ngIf="accordionOpen" style="border: 1px solid lightgray;padding: 20px;">
            <div fxLayout="row" *ngFor="let value of product.description.split(',')">
                <div fxFlex="10">
                    <mat-icon class="maticoncss">check_circle</mat-icon>
                </div>
                <div fxFlex="80">
                    <h3 class="font-h3-cat" [innerHTML]="value.trim()">
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <!-- mobile view -->

    <div fxHide fxShow.lt-md>
        <div fxLayout="row" class="cat">
            <div *ngIf="item.customField16 ==1" (click)="productShow(product)"  fxFlex="65" class="prod-detail1">
                <h3 class="h3-title"><b>{{product.name}}</b></h3>
                <span class="decription">{{product?.shortDescription}}</span>
            </div>

            <div *ngIf="item.customField16 !=1"  fxFlex="65" class="prod-detail1">
                <h3 class="h3-title"><b>{{product.name}}</b></h3>
                <span class="decription">{{product?.shortDescription}}</span>
            </div>
            <div fxFlex class="padding-5">
                <h6 style="font-size: 19px; padding-left: 18px;"><b>
                    <span *ngIf="specialPrice > 0 && specialPrice !== price">                      
                        <span class="old-price text-strike text-muted font-16px">
                            {{ price | currencyConvert }}{{units}}
                        </span>
                    </span>
                    <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
                        {{ specialPrice | currencyConvert }}{{units}}
                    </span>
                    <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
                        {{ price | currencyConvert }}{{units}}
                    </span>
                  
                </b></h6>
                <calsoft-controls [product]="configProductSelected" [data]="data" [variant1Name]="variant1Name"
                            [variant2Name]="variant2Name" [variant1Value]="variant1Value" [variant2Value]="variant2Value"
                            [configproduct]="config" [type]="'miniCart'" [showCartIcon]="showCartIcon"
                            [showCartIncremented]="showCartIncremented">
                </calsoft-controls>
            </div>
        </div>
        <div fxLayout="row" style="background-color: #f3f2f2;">
            <div fxFlex="90" style="padding: 10px;">
                <span>More Details</span>
            </div>
            <div fxFlex="10" (click)="toggleAccordion()" style="padding-top: 5px;">
                <mat-icon  *ngIf="!accordionOpen">keyboard_arrow_down</mat-icon>
                <mat-icon  *ngIf="accordionOpen">keyboard_arrow_up</mat-icon>
            </div>
        </div>

        <!-- <div fxLayout="row" >
            <div fxFlex="10">
                <mat-icon class="maticoncss">check_circle</mat-icon>
            </div>
            <div fxFlex="80">
                <h3 class="font-h3-cat" [innerHTML]="value.trim()">
                </h3>
            </div>
        </div> -->
        <div *ngIf="accordionOpen" style="border: 1px solid lightgray;padding: 20px;">
            <div fxLayout="row" *ngFor="let value of product.description.split(',')">
                <div fxFlex="10">
                    <mat-icon class="maticoncss">check_circle</mat-icon>
                </div>
                <div fxFlex="80">
                    <h3 class="font-h3-cat" [innerHTML]="value.trim()">
                    </h3>
                </div>
            </div>        
        </div>

    </div>
</div>