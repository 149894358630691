<div class="swiper-container" #swiper>
    <div class="swiper-wrapper">
        <div *ngFor="let image of img" class="swiper-slide" [style.height]="item.height"
            [style.width]="item.customField25" [ngStyle.lt-md]="{
        'height': item.heightMob, 'width':item.customField26
      }" [style.backgroundImage]="'url(' + bannerServerPath+image.img + ')'">
        </div>
    </div>
    <div class="swiper-pagination"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>

    <!-- <div class="custom-nav" fxShow fxHide.lt-md>
        <div class="owl-prev" (click)="navigateSwiper('prev')">
          <mat-icon class="custom-icon primary-icon">keyboard_arrow_left</mat-icon>
        </div>
        <div class="owl-next" (click)="navigateSwiper('next')">
          <mat-icon class="custom-icon primary-icon">keyboard_arrow_right</mat-icon>
        </div>
      </div> -->
</div>