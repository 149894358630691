<div [ngClass]="item.customField18==0 ? 'imgcard' :'imgcard1'" [style.background-color]="item.customField20">
    <div fxLayout="column" [fxLayout.gt-md]="item.customField25=='0'?'row':'row-reverse'" fxLayout.gt-sm="row" fxLayoutGap="15px">
        <div fxFlex="100" fxFlex.gt-sm="50">
            <img [src]="serverPath1+item.customField21" [style.height]="item.customField28"
            [ngStyle.lt-md]="{ 'height': item.customField29 }" width="100%" style="border-radius: 15px;" [alt]="item.altImage || 'images'"/>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="50" fxLayoutAlign="center center">
            <div>
                <h3 [style.font-size]="item.customField30"
                [ngStyle.lt-md]="{ 'font-size': item.customField31 }"><b>{{item.customField7}}</b></h3>
                <p [style.font-size]="item.customField37"
                [ngStyle.lt-md]="{ 'font-size': item.customField38 }">
                    <calsoft-editor-text [data]="data" [text]="item.customField10"
                            [align]="item.customField34">
                           </calsoft-editor-text>
                </p>
                <h3 [style.font-size]="item.customField32"
                [ngStyle.lt-md]="{ 'font-size': item.customField33 }"><b>{{item.customField11}}</b></h3>
                <p [style.font-size]="item.customField35"
                [ngStyle.lt-md]="{ 'font-size': item.customField36 }">
                    <calsoft-editor-text [data]="data" [text]="item.customField14"
                    [align]="item.customField34">
                   </calsoft-editor-text>
                </p>
                <button class="btn btn-n" [style.font-size]="item.customField26" *ngIf="item.customField12"
                [ngStyle.lt-md]="{ 'font-size': item.customField27 }" (click)="openInNewWindow(item.customField18)">{{item.customField12}}</button>
            </div>
        </div>    
    </div>
</div>