<div fxLayout="column" fxLayoutGap="20px" class="card-container" fxLayoutAlign="start stretch" style="width: 100%;">
  <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
    <div fxFlex="0 1 calc(25% - 20px)" fxFlex.xs="0 1 calc(50% - 20px)"    *ngFor="let slide of rateList" 
         [style.height]="'250px'" 
         [style.background-color]="item.customField8"
        
           [fxFlex]="item.layout === 2 ? '100%' : 'auto'" [fxFlex]="item.layout == 2 ? 
          (item.gapBetweenImage ?  'calc(50% - ' + item.gapBetweenImage + ')' : '50%') 
          : (item.layout == 3 ? (item.gapBetweenImage ? 'calc(33.33% - ' + item.gapBetweenImage + ')' : '33.33%') 
          : (item.layout == 4 ? (item.gapBetweenImage ? 'calc(25% - ' + item.gapBetweenImage + ')' : '25%') 
          : '100'))" [fxFlex.lt-md]="item.displayMobileImage == 2 ? (item.mobileGapBetweenImage ? 'calc(50% - ' + item.mobileGapBetweenImage + ')' : '50%')
          : (item.displayMobileImage == 3 ? (item.mobileGapBetweenImage ? 'calc(33.33% - ' + item.mobileGapBetweenImage + ')' : '33.33%')
          : (item.displayMobileImage == 4 ? (item.mobileGapBetweenImage ? 'calc(25% - ' + item.mobileGapBetweenImage + ')' : '25%')
          : '100'))" class=" margin-t10 images"
         style="border-radius: 10px; box-shadow: 0 4px 8px rgba(0,0,0,0.1); overflow: hidden; display: flex; flex-direction: column; justify-content: space-between; margin-bottom: 20px;">
      <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%; padding: 10px;">
        <div class="card-image" style="flex: 1; display: flex; align-items: center;">
          <img [src]="serverPath1 + item.value" [alt]="item.value" style="max-width: 100%; max-height: 100%;">
        </div>
        <div class="card-content" style="text-align: center;">
          <h3 style="margin: 10px 0;">{{slide.name}}</h3>
          <p>{{slide.price | currencyConvert}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
