import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-home-banner-layout',
  templateUrl: './home-banner-layout.component.html',
  styleUrls: ['./home-banner-layout.component.scss']
})
export class HomeBannerLayoutComponent implements OnInit {
  @Input() item: any;
  @Input() img: any;
  @Input() bannerWidth: any;
  @Input() bannerHeight: any;
  @Input() bannerMobWidth: any;
  @Input() bannerMobHeight: any;
  @Input() bannerTabletHeight:any;
  @Input() bannerTabletWeight:any;
  
  public serverPath = environment.commonImageApi + '/home/';
  imageUrl: string;
  public url = environment.commonImageApi;
  bannertextset:any;
  constructor(
  ) { }

  ngOnInit(): void {

    this.imageUrl = this.url + "commonSlider/";
    this.bannertextset = this.img[0].customField29;

  }



}


