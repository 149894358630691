<div class="vertical-products sec-padding">
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start start">
    <div class="product-panel" fxFlex="100">
      <div class="widget-column" fxLayoutGap="10px">
        <div class="title" fxLayoutAlign="space-between center">
          <h4 class="widget-title" [style.font-size]="images.customField16"  [ngStyle.lt-md]="{ 'font-size': images.customField17}"> {{images.customField15}}</h4>

          <div *ngIf="images.customField3!='Image'&& item.customField4==0" class="primary-icon" style="cursor: pointer;"
            (click)="goToCategory()">
            VIEW MORE</div>

          <div *ngIf="images.customField3!='Image'&& item.customField4==1" class="custom-nav" fxShow fxHide.lt-md>
            <div class="owl-prev">
              <mat-icon (click)="previous()" class="custom-icon primary-icon">keyboard_arrow_left</mat-icon>
            </div>
            <div class="owl-next">
              <mat-icon (click)="next()" class="custom-icon primary-icon">keyboard_arrow_right</mat-icon>
            </div>
          </div>

        </div>
        <ul class="product-list" *ngIf="reInit">
          <li fxLayout="row">
            <div fxLayout="row wrap" fxFlex *ngIf="images.customField3!='Image'">
              <ng-container  *ngFor="let product of displayedObjects;
               let i=index">
                <div class="br5" fxLayoutGap="10px" fxFlex="100">
                  <div class="product-list">
                    <calsoft-top-selling-layout-product-child 
                    [item]="item" [data]="data" [width]="'100'"
                      [imageType]="imageType" [image]="image" [height]="'100'" [heightMobile]="'100'"
                      [widthMobile]="'100'" [showCartIncremented]="showCartIncremented" [product]="displayedObjects[i]"
                      [index]="i==categoryProducts.length-1"></calsoft-top-selling-layout-product-child>

                  </div>

                </div>
              </ng-container>
            </div>

            <div *ngIf="images.customField3=='Image'" class="gap">

              <img width="100%" [style.height]="height" [ngStyle.lt-md]="{'height':heightMob
            }" fxLayoutAlign="space-evenly stretch" (click)="navigation==0?goToCategories(catId):link(navigatedUrl)"
                [src]="serverPath1+images.customField6" [alt]="altImage || 'images'" loading="lazy">

            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>