<div  fxLayout="row" fxLayoutGap="10px" [ngStyle]="{'margin-bottom.px': !index ? '10' : 0}">
  <div class="media-image" style="cursor: pointer;">
    <a (click)="productShow(product)">
      <img fxFlex height="150" width="100%" onerror="this.src='assets/images/products/noImage/no-image.png';"
        [lazyLoad]="image" [offset]="100" [alt]="name" [src]="image" class="small" alt="">
    </a>
  </div>
  <div fxFlex="100" fxLayout="column" class="media-body" fxLayoutAlign="center start">
    <div fxLayout="row" fxLayoutAlign="start center">
      <a (click)="productShow(product)">
        <span>{{ name }}</span>
      </a>

    </div>

    <div fxLayout="row" *ngIf="data.disablePrice==0">
      <div [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
        <div *ngIf="inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
          fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

          <span *ngIf="displayMrpPrice">
            <span *ngIf="specialPrice > 0 && specialPrice != price">
              <b>MRP :</b>
              <span class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>
          </span>

          <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
            <span *ngIf="displayMrpPrice">
              <b>MRP :</b>
            </span>
            <b> {{ price | currencyConvert }}{{units}}</b> (incl. of tax)
          </span>

          <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
            <b> {{ specialPrice | currencyConvert }}{{units}}</b> (incl. of tax)
          </span>

          <span *ngIf="!displayMrpPrice">
            <span *ngIf="specialPrice > 0 && specialPrice != price" class="old-price text-strike text-muted font-16px">
              {{ price | currencyConvert }}{{units}}
            </span>
          </span>



        </div>

        <div *ngIf="!inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
          fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

          <span *ngIf="specialPrice > 0 && specialPrice != price">
            <b *ngIf="displayMrpPrice">MRP :</b>
            <span class="old-price text-strike text-muted font-16px">
              {{ price | currencyConvert }}{{units}}
            </span>
          </span>

          <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
            <span *ngIf="displayMrpPrice">
              <b>MRP :</b>
            </span>
            <b> {{ price | currencyConvert }}{{units}}</b>
          </span>

          <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
            <b> {{ specialPrice | currencyConvert }}{{units}}</b>
            <span *ngIf="displayMrpPrice">
              (incl. of tax)
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>


