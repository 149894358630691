<div fxLayout="row" contentAnim [aniName]="item.customField36" fxFlex.xs="100" fxLayout.xs="column">

  <div fxFlex="50" fxFlex.xs="100">
    <div class="title" fxLayoutAlign="center center">
        <b>
          <calsoft-editor-text [fontSize]="item.customField19" [MobfontSize]="item.customField20" [data]="data"
            [text]="item.customField7" [align]="item.customField34">
          </calsoft-editor-text>
        </b>
    </div>
    <div>

      <calsoft-editor-text [data]="data" [text]="item.customField14" [align]="item.customField34" [fontSize]="item.customField21" [MobfontSize]="item.customField22">
      </calsoft-editor-text>

    </div>
  </div>

  <div fxFlex="5">

  </div>

  <div fxFlex="50" fxFlex.xs="100">
    <div class="title" fxLayoutAlign="center center">
     <b>
        <calsoft-editor-text [fontSize]="item.customField19"  [fontFamily]="item.fontFamily" [MobfontSize]="item.customField20" [data]="data"
        [text]="item.customField11" [align]="item.customField34">
      </calsoft-editor-text>
        </b>
    </div>
    <div>

      <calsoft-editor-text [data]="data" [text]="item.customField10" [align]="item.customField34" [fontSize]="item.customField21" [MobfontSize]="item.customField22">
      </calsoft-editor-text>
    </div>
  </div>
</div>