
<ng-container *ngIf="type=='carousel'||type=='list';else lazyProduct" >


<ng-container *ngIf="type=='carousel'" >
   <div [ngClass]="{ 'collection-banner': !radius }">
      <img  [alt]="name"    
      [src]="image" onerror="this.src='assets/images/products/placeholder.jpg';" [style.border-radius]="radius"
      [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
      [ngStyle.gt-sm]="{visibility:isLoading?'hidden':'',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}"
      />
   </div>
</ng-container>

<ng-template  *ngIf="type=='product'">
   <div [ngClass]="{ 'collection-banner': !radius }">
      <img loading="lazy" [alt]="name" [style.border-radius]="radius"
      defaultImage="assets/images/products/placeholder.jpg"
      [lazyLoad]="image" 
      onerror="this.src='assets/images/products/placeholder.jpg';"
      [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
         [ngStyle.gt-sm]="{visibility:isLoading?'hidden':'',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}"  />
   </div>

</ng-template>

</ng-container>




<ng-template #lazyProduct>
   <div [ngClass]="{ 'collection-banner': !radius }">
   <img  [alt]="name"  [style.border-radius]="radius"
   defaultImage="assets/images/products/noImage/no-image.png"
   [lazyLoad]="image"   onerror="this.src='assets/images/products/noImage/no-image.png';"
   [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
      [ngStyle.gt-sm]="{visibility:isLoading?'hidden':
      '',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}" 
      />
   </div>
</ng-template>
