import { Component } from '@angular/core';

@Component({
  selector: 'calsoft-buttontypes',
  templateUrl: './buttontypes.component.html',
  styleUrls: ['./buttontypes.component.scss']
})
 export class ButtontypesComponent {

selectedOption: string = 'buy';
selectedOption1: string = 'Best';
selectedOption2: string = 'hatchback';

toggle(option: string) {
  this.selectedOption = option;
  
}
toggle1(option: string) {
  this.selectedOption1 = option;
}
toggle2(option: string) {
  this.selectedOption2 = option;
}


}
