import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-doubleimageform',
  templateUrl: './doubleimageform.component.html',
  styleUrls: ['./doubleimageform.component.scss']
})
export class DoubleimageformComponent implements OnInit {


  @ViewChild('containerDiv') containerDiv: ElementRef | undefined;
  @ViewChild('secondElement') secondElement: ElementRef | undefined;
  @ViewChild('positionedElement') positionedElement: ElementRef | undefined;

  constructor() {}

  ngAfterViewInit() {
   
    if (window.innerWidth >= 1280) {
      this.setPosition();
    }
  }

  setPosition() {
    if (this.containerDiv && this.secondElement && this.positionedElement) {
      // Get the height of the container and the second element
      const containerHeight = this.containerDiv.nativeElement.offsetHeight;
      const secondElementHeight = this.secondElement.nativeElement.offsetHeight;

      // Get the top position of the second element relative to the viewport
      const secondElementTop = this.secondElement.nativeElement.getBoundingClientRect().top;

      // // Calculate the center position: the middle point between the container and the second element
      // const centerPosition = containerHeight + (secondElementTop - containerHeight - secondElementHeight) / 2;

      // // Set the top property of the positioned element dynamically
      // this.positionedElement.nativeElement.style.top = `${centerPosition}px`;
    }
  }



  @Input() item: any;
  @Input() img: any;
  @Input() data : any;


  public serverPath = environment.commonImageApi + '/home/';

  ngOnInit() {

    
  }
  
}
