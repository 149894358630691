import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-courseimage',
  templateUrl: './courseimage.component.html',
  styleUrls: ['./courseimage.component.scss']
})
export class CourseimageComponent {
 

  @Input() input: any;
  @Input() data: any;
  @Input() item: any;
  @Input() layoutSub: any;

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public serverPath1 = environment.commonImageApi + '/home/';

}
