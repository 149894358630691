import { Component, Input, ViewChild } from '@angular/core';
import { CarouselComponent } from 'ngx-owl-carousel-o';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-slide-image-with-text',
  templateUrl: './slide-image-with-text.component.html',
  styleUrls: ['./slide-image-with-text.component.scss']
})
export class SlideImageWithTextComponent {

  @Input() img: any;

  @Input() item: any;

  @Input() data: any;

  width:any;
  height:any;
  gapBetweenImage:any;
  customField12:any;
  customField11:any;

  public serverPath = environment.commonImageApi + '/commonSlider/';

  public LogoSliderConfig: any;
  public LogoSliderConfig1: any;
  currentSlideIndex = 0;
  slideHeight = 0;
  constructor(  private commonService: CommonService,
    private categoryService: CategoryService) { 

    }


  value:any;
  value2:any;
  ngOnInit(): void {
    
    this.value2=20*2;

    this.value=100-this.value2;

    if ( this.item.gapBetweenImage.endsWith('px')) {
      this.item.gapBetweenImage= this.item.gapBetweenImage.slice(0, -2); 
    }
  
    
    this.LogoSlider.margin=this.item.gapBetweenImage;
    this.LogoSliderConfig=this.LogoSlider;

    this.LogoSlider.margin=this.item.gapBetweenImage;
    this.LogoSliderConfig1=this.LogoSlider;

    this.LogoSlider.responsive[480].items = this.item.customField33;
    this.LogoSlider.responsive[0].items = this.item.customField33;

  }


  goToCategory(menu) {
    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);

  }

  
  imageNavigation(id){

    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }

  catId:any;
 
  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }


  link(url) {
    this.commonService.goToLink(url)
  }

  @ViewChild('owlCar') owlCarousel: CarouselComponent;

 

  LogoSlider = {
    nav: false,
    dots: false,
    items: 1,
    margin: 20,
    loop: false,
    autoHeight: true,
    autoplay: true,
    
    responsive: {
        767: {
            items: 1
        },
        576: {
            items: 1
        },
        480: {
            items: 1
        },
        0: {
            items: 1
        }
    }
}

LogoSlider1 = {
  nav: true,
  dots: false,
  items: 1,
  margin: 20,
  loop: false,
  autoHeight: true,
  autoplay: true,
  navText: ['&#8249', '&#8250;'],
  responsive: {
      767: {
          items: 1
      },
      576: {
          items: 1
      },
      480: {
          items: 1
      },
      0: {
          items: 1
      }
  }
}

moveSlideUp() {
  const ulElement = document.querySelector('ul');
  const currentMarginTop = parseInt(ulElement.style.marginTop || '0');

  // Calculate the maximum negative margin based on the number of images
  const maxNegativeMargin = -(ulElement.children.length - 1) * 400;

  // Check if the current margin is already at the maximum
  if (currentMarginTop > maxNegativeMargin) {
      const newMarginTop = currentMarginTop - 400;
      ulElement.style.transition = 'all 1.5s ease-in-out';
      ulElement.style.marginTop = `${newMarginTop}px`;
  } else {
      // If at the last image, reset to the initial position (bottom) without transition
      ulElement.style.transition = '';
      ulElement.style.marginTop = '0';
  }
}

moveSlideDown() {
  const ulElement = document.querySelector('ul');
  const currentMarginTop = parseInt(ulElement.style.marginTop || '0');

  // Check if the current margin is not already at 0
  if (currentMarginTop < 0) {
      const newMarginTop = currentMarginTop + 400;
      ulElement.style.transition = 'all 1.5s ease-in-out';
      ulElement.style.marginTop = `${newMarginTop}px`;
  } else {
      // If at the first image, move to the last image (top) without transition
      const maxNegativeMargin = -(ulElement.children.length - 1) * 400;
      ulElement.style.transition = '';
      ulElement.style.marginTop = `${maxNegativeMargin}px`;
  }
}


openInNewWindow(url: string) {
  if (url) {
    const fullURL = this.prependProtocol(url);
    this.commonService.goToLink(url)
  }
}

prependProtocol(url: string): string {
  if (!url.includes('http://') && !url.includes('https://')) {
    return 'https://' + url;
  }
  return url;
}


}

