import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'calsoft-rate-star',
  templateUrl: './rate-star.component.html',
  styleUrls: ['./rate-star.component.scss']
})
export class RateStarComponent implements OnInit {

  @Input() rating: any;
  @Input() color: any='gold';
  ngOnInit(): void {
   
  }
  count: number = 5;
  get stars(): boolean[] {
    return Array(this.count).fill(false);
  }

}
