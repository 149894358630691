import { Component, Input } from '@angular/core';
import { ProductDetailsMainSlider } from 'src/app/models/product-details-main-slider';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-freetext',
  templateUrl: './freetext.component.html',
  styleUrls: ['./freetext.component.scss']
})
export class FreetextComponent {

  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  customField11:any;
  customField12:any;
  customField15:any;
  customField16:any;
  customField17:any;
  customField18
  
  @Input() item: any;
  index: number;
  items:any=[];


  ngOnInit() {
  
    if(this.item.length==1){
    this.slideConfig.touchDrag=false;
    this.slideConfig.mouseDrag=false;
    this.slideConfig.dots=false;
    }
    
  }

  constructor(
    private commonService: CommonService,
  ) {

  }
  
  left(){
    this.index --
  }
  right(){
    this.index ++
  }
  
  eventText = ''; 
  onSwipe(evt) {
  
    const x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    const y = Math.abs(evt.deltaY) > 40 ? (evt.deltaY > 0 ? 'down' : 'up') : '';
  
    if (this.eventText.includes("left")) {
      this.left();
         
    } else if (this.eventText.includes("right")) {
      this.right();
    
  
    }
  }
  
  
  slideConfig = {
    items: 1,
    nav: false,
    dots:true,
    autoplay: false,
    slideSpeed: 300,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
  };
  
  afterChange(event){
  
    this.index=event.currentSlide
  }


}

