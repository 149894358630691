<div class="reason-text lzl-ed" data-lzl-bg="" (mouseenter)="isHovered = true" (mouseleave)="isHovered = false">
  <div> <span class="re-img">
      <img (click)="img.navigation==0?imageNavigation(img.catId):link(img.navigatedUrl)" [src]="serverPath+img.img" [alt]="img.customField1" title="img.customField1" width="78" height="78"
        class=" lzl-cached lzl-ed"></span>
    <h3 [style.color]="img.customField10" [ngStyle]="{'font-family': img.customField11 || 'cursive'}" class="text"
      [ngStyle.lt-md]="{ 'font-size': item.customField17 }" [innerHTML]="img.customField1"></h3>

    <p [style.color]="img.customField15" [ngStyle]="{'font-family': img.customField16 || 'cursive'}"
      [innerHTML]="img.customField14">
    </p>
    <div fxLayout="row" fxLayoutAlign="start center" style="display: flex;margin-top: 4px;"  *ngIf="img.customField34==1">
      <p (click)="openInNewWindow(img.customField7)" style="color: #f46c0b;text-decoration: underline;cursor: pointer;">
        Read More </p>&#160;<mat-icon class="arrowicon" style="color:#f46c0b ;">arrow_forward</mat-icon>
    </div>
    <div fxLayoutAlign="start center">
      <button 
        class="button" [style.background-color]="item.customField33"
        mat-raised-button 
        color="primary" 
        [ngClass]="{'hover-button': isHovered}" 
        (click)="img.navigation == 0 ? imageNavigation(img.catId) : link(img.navigatedUrl)">
        {{ img.customField25 }}
      </button>
    </div>
  </div>
 
</div>