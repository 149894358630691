import { Component, Input } from '@angular/core';
import { DefaultShowHideDirective } from '../../../flex-layout/show-hide';
import { DefaultLayoutAlignDirective } from '../../../flex-layout/custom-layout-align';
import { DefaultLayoutDirective } from '../../../flex-layout/custom-layout';
import { DefaultLayoutGapDirective } from '../../../flex-layout/custom-layout-gap';
import { NgFor } from '@angular/common';
import { DefaultFlexDirective } from '../../../flex-layout/flex';
import { DefaultStyleDirective } from '../../../flex-layout/style';
import { MatIcon } from '@angular/material/icon';
import { FlexLayoutModule } from '../../flex-layout/flex-layout.module';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'calsoft-texthoverwithimage',

  templateUrl: './texthoverwithimage.component.html',
  styleUrls: ['./texthoverwithimage.component.scss']
})
export class TexthoverwithimageComponent {


  public serverPath1 = environment.commonImageApi + '/commonSlider/';

  public serverPath = environment.commonImageApi + '/home/';

  
  @Input() item: any;

  @Input() img: any;

  @Input() data: any;
 
  activeImage:string ;


  constructor(private commonService: CommonService) {
    
     }
 
  ngOnInit() {
    if (this.item && this.item.images && this.item.images.length > 0) {
      this.activeImage = this.serverPath1 + this.item.images[0].img;
    }
  }


  changeImage(imageUrl: string) {
    this.activeImage = imageUrl;
  }

  
  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }
  

  link(url: any) {

    this.commonService.goToLink(url)
  }

  openInNewWindow(url: string) {
    if (url) {
      const fullURL = this.prependProtocol(url);
      this.commonService.goToLink(url)
    }
  }

  prependProtocol(url: string): string {
    if (!url.includes('http://') && !url.includes('https://')) {
      return 'https://' + url;
    }
    return url;
  }

}
