import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonImagesRoutingModule } from './common-images-routing.module';
import { CommonImagesComponent } from './common-images.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    CommonImagesComponent
  ],
  imports: [
    CommonModule,
    CommonImagesRoutingModule,
    SharedModule
  ],
  exports:[
    CommonImagesComponent
  ]
})
export class CommonImagesModule { }
