import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestaurantsService {

  url = environment.serverAPI6 + "/api/catalog/restaurants";

  url1 = environment.serverAPI6 + "/api/partners/search/byLocation";

  constructor(private http: HttpClient) { }

  getAllRestaurants(Page: any, size: any, sort: any ,id : any): Observable<Object> {
    return this.http.get(`${this.url}/${id}?page=${Page}&size=${size}&sort=${sort}`);
  }

  getAllPartner(Page: any, size: any, sort: any, lat: any, long: any, distance: any): Observable<Object> {
    let params = new HttpParams()
    .set('lat', lat.toString())
    .set('lon', long.toString())
    .set('distance', long.toString());
    
    return this.http.get(`${this.url1}?page=${Page}&size=${size}&sort=${sort}`, { params });
  }

}
