import { Component, Input } from '@angular/core';

@Component({
  selector: 'calsoft-image-lazy-main-mouse-over-main',
  templateUrl: './image-lazy-main-mouse-over-main.component.html',
  styleUrls: ['./image-lazy-main-mouse-over-main.component.scss']
})
export class ImageLazyMainMouseOverMainComponent {

  @Input() list: any=[];
  @Input() qty: any;
 
  @Input() product: any;
  @Input() height: any;
  @Input() width: number;
  @Input() heightMobile: any;

  @Input() widthMobile: any;

  @Input() image: any;
  @Input() name: any;
  @Input() type: any="'carousel'";

  @Input() imageType: any=1;


  ngOnInit(): void {
  

	}

}
